import { Badge, Button, Input, Textarea } from "@/components/ui";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { CreatePostError, PostType, PostTypeEnum, Tag, TagType, useCreatePostMutation } from "@pairprogram/graphql";
import useGetTags from "@/hooks/use-get-tags";
import { ComboBoxItem } from "@/components/combo-box";
import { BarChart, Cuboid, Flame, HandIcon, ImageIcon, TargetIcon, Users, XCircle, XIcon } from "lucide-react";
import { Listbox, Transition } from "@headlessui/react";
import { cn } from "@/utils";
import { useGetPostTypes } from "@/hooks/use-get-post-types";
import toast from "react-hot-toast";
import mixpanel from "@/analytics";
import { AnalyticsEvent } from "@/analytics/analytics-event";
import { AuthContext } from "@/store/context/AuthContext";
import { useNavigate } from "react-router-dom";
import FileUploadModal from "@/components/file-upload-modal";
import { ApolloError } from "@apollo/client";
import useRequiresAuth from "@/hooks/use-requires-auth";
import MultiTagSelect from "@/components/multi-tag-select";
import { useTailwindBreakpoint } from "@/hooks/use-tailwind-breakpoint";
import { Icons } from "@/assets/icons";


function getRandomProgressDescriptionPlaceholder() {
  const descriptionPlaceholders = [
    "Just added user authentication to my full-stack web app using JWTs",
    "Implemented a new feature in my mobile app to support offline mode",
    "Refactored the backend of my projects for better performance and scalability",
    "Integrating third-party APIs to enhance the functionality of my web application",
    "Learning how to build a React app with TypeScript",
    "Exploring the fundamentals of machine learning algorithms in Python",
    "Experimenting with data visualization using D3.js and JavaScript",
    "Building a RESTful API with Node.js and Express for a personal projects",
    "Understanding the basics of blockchain technology and smart contracts",
    "Developing a mobile app using React Native for cross-platform functionality",
    "Learning about cyber security best practices and ethical hacking techniques",
    "Creating a responsive web design with HTML5 and CSS3 for a freelance gig",
    "Created a new landing page with dynamic content and SEO optimization",
    "Mastering advanced SQL queries for database management and analytic.",
    "Successfully deployed my first microservices architecture using Docker",
    "Experimenting with augmented reality in Unity for a new startup idea",
    "Updated my portfolio website with recent projects and testimonial.",
    "Added real-time chat functionality to my social networking ap.",
    "Developing a custom plugin for my website to improve user interactivity",
    "Experimented with AI algorithms to add smart recommendations in my app",
    "Studying the principles of user experience (UX) design for better app interfaces..."];


  return descriptionPlaceholders[Math.floor(Math.random() * descriptionPlaceholders.length)];
}

function getRandomFeedbackDescriptionPlaceholder() {
  const descriptionPlaceholders = [
    "Looking for feedback on the UI/UX design of my new e-commerce website...",
    "Requesting code review on my latest GitHub repository for best practices...",
    "Seeking suggestions for optimizing the database schema of my projects...",
    "Would appreciate feedback on the efficiency of my data processing script...",
    "Asking for opinions on my app's onboarding flow for new users...",
    "Interested in critique of my blog's layout and content relevance...",
    "Requesting thoughts on the scalability and security of my web application...",
    "Looking for advice on integrating analytics tools into my mobile app...",
    "Seeking insights on improving the load time and performance of my site...",
    "Would love feedback on the storytelling aspect of my data visualization projects..."
  ];

  return descriptionPlaceholders[Math.floor(Math.random() * descriptionPlaceholders.length)];
}

function getRandomCollaborationDescriptionPlaceholder() {
  const descriptionPlaceholders = [
    "Seeking a frontend developer to collaborate on an open-source projects...",
    "Looking for a data scientist to partner with on a machine learning challenge...",
    "Interested in teaming up with a graphic designer for a game development projects...",
    "Seeking a co-developer for building a mobile app focused on mental health...",
    "Looking for contributors to a community-driven educational platform...",
    "In search of a backend developer to collaborate on a SaaS product idea...",
    "Interested in partnering with a marketer to promote a tech startup...",
    "Looking for a collaborator with expertise in IoT for a smart home projects...",
    "Seeking a fellow developer to explore AR/VR application development...",
    "In need of a content creator to join a web development tutorial series projects..."
  ];

  return descriptionPlaceholders[Math.floor(Math.random() * descriptionPlaceholders.length)];
}

function getRandomMilestoneDescriptionPlaceholder() {
  const descriptionPlaceholders = [
    "Just hit 1,000 active users on my web application!",
    "Celebrating the successful launch of my first mobil app on app stores...",
    "Reached the milestone of 100 GitHub stars on my open-source projects...",
    "Successfully integrated a complex feature after weeks of development...",
    "My tech blog just crossed 10,000 views this month!",
    "Completed a professional certification in cloud computing...",
    "Launched the beta version of my SaaS platform with positive early feedback...",
    "Achieved a personal goal of coding every day for 100 days straight...",
    "My projects was featured in a popular tech newsletter...",
    "Celebrating the first profitable month for my online business..."
  ];

  return descriptionPlaceholders[Math.floor(Math.random() * descriptionPlaceholders.length)];
}

const minTitleLength = 8;
const maxTitleLength = 64;

const minDescriptionLength = 16;
const maxDescriptionLength = 2048;
const maxTagCount = 3;

export function handleCreatePostError(error: CreatePostError) {
  console.log('handleCreatePostError', error);
  switch (error) {
    case CreatePostError.Profanity:
      toast("Please remove profanity from your post", {
        icon: "🚨"
      });
      break;
    case CreatePostError.TitleRequired:
      toast("Please add a title to your post", {
        icon: "🚨"
      });
      break;
    case CreatePostError.TitleLengthUnmet:
      toast(`Title is too short, must be at least ${minTitleLength} characters`, {
        icon: "🚨"
      });
      break;
    case CreatePostError.TitleLengthExceeded:
      toast(`Title is too long, must less than ${maxTitleLength} characters`, {
        icon: "🚨"
      });
      break;
    case CreatePostError.DescriptionRequired:
      toast("Please add a brief description to your post", {
        icon: "🚨"
      });
      break;
    case CreatePostError.DescriptionLengthUnmet:
      toast(`Description is too short, must be at least ${minDescriptionLength} characters`, {
        icon: "🚨"
      });
      break;
    case CreatePostError.DescriptionLengthExceeded:
      toast(`Description is too long, must less than ${maxDescriptionLength} characters`, {
        icon: "🚨"
      });
      break;
    case CreatePostError.TagCountExceeded:
      toast(`You can only select up to ${maxTagCount} tags`, {
        icon: "🚨"
      });
      break;
    case CreatePostError.PostTypeRequired:
      toast("Please select a post type", {
        icon: "🚨"
      });
      break;
    default:
      toast("Error posting update", {
        icon: "🚨"
      });
      break;
  }
}

export default function CreatePostCard() {
  const postTypeTemplatesObj = {
    [PostTypeEnum.Progress]: {
      titlePlaceholder: "What are you working on today?",
      descriptionPlaceholder: getRandomProgressDescriptionPlaceholder(),
      icon: BarChart
    },
    [PostTypeEnum.Feedback]: {
      titlePlaceholder: "What would you like feedback on?",
      descriptionPlaceholder: getRandomFeedbackDescriptionPlaceholder(),
      icon: HandIcon
    },
    [PostTypeEnum.Collaboration]: {
      titlePlaceholder: "What would you like to collaborate on?",
      descriptionPlaceholder: getRandomCollaborationDescriptionPlaceholder(),
      icon: Users
    },
    [PostTypeEnum.Milestone]: {
      titlePlaceholder: "What milestone did you reach?",
      descriptionPlaceholder: getRandomMilestoneDescriptionPlaceholder(),
      icon: TargetIcon
    },
    [PostTypeEnum.Resource]: {
      titlePlaceholder: "What resource do you want to share?",
      descriptionPlaceholder: "Add a link to a blog post, video, or podcast",
      icon: Flame
    },
    [PostTypeEnum.Project]: {
      titlePlaceholder: "What projects are you working on?",
      descriptionPlaceholder: "Add a link to your projects's website or GitHub repository, include a description, and an image",
      icon: Cuboid
    },
    DEFAULT: {
      titlePlaceholder: "What are you working on today?",
      descriptionPlaceholder: getRandomProgressDescriptionPlaceholder(),
      icon: Icons.ChevronDown
    }
  };



  const { state: auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const { tags } = useGetTags();
  const { postTypes } = useGetPostTypes();
  const [postTypeTemplates, setPostTypeTemplates] = useState(postTypeTemplatesObj);
  const [currentTemplate, setCurrentTemplate] = useState(postTypeTemplates['DEFAULT']);
  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  // const defaultPostType = postTypes.find((item) => {
  //   return item.value === PostTypeEnum.Progress;
  // });


  // FIXME: temp hack to rerender component until cache writes are fixed
  const [, forceUpdate] = useState<boolean>(false);
  const refresh = () => forceUpdate(true);

  const handleSetSelectedTags = (tags: Tag[]) => {
    if (selectedTags.length >= maxTagCount) {
      toast(`You can only select up to ${maxTagCount} tags`, {
        icon: "🚨"
      });
    } else {
      setSelectedTags(tags);
    }
  };

  const { withAuth, handleShowModal } = useRequiresAuth();

  const [uploadedFileData, setUploadedFileData] = useState<{ key: string; url: string; } | null>(null);

  const [selectedPostType, setSelectedPostType] = useState<PostType | null>(null);
  // useEffect(() => {
  //   // if (defaultPostType) {
  //     // setSelectedPostType(defaultPostType);
  //     setPostTypeTemplates(postTypeTemplatesObj);
  //   // }
  // }, [defaultPostType]);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [createPost] = useCreatePostMutation({
    variables: {
      input: {
        title: postTitle,
        description: postDescription,
        postTypeId: selectedPostType?.id,
        tags: selectedTags.map((tag) => tag?.value) as string[] ?? [],
        uploadKeys: uploadedFileData?.key ? [uploadedFileData?.key] : []
      }
    },
    onError: (error: ApolloError) => {
      // @ts-ignore
      if (error?.graphQLErrors?.[0]?.extensions?.originalError?.statusCode === 401) {
        handleShowModal(true);
      }
    },
    onCompleted: async (data) => {
      if (data?.CreatePost?.error) {
        handleCreatePostError(data?.CreatePost?.error);
      } else {
        toast("Post created successfully", {
          icon: "🚀"
        });

        // reset state
        setSelectedPostType( null);
        setPostTitle("");
        setPostDescription("");
        setSelectedTags([]);
        setUploadedFileData(null);

        mixpanel.track(AnalyticsEvent.PostCreated, {
          user: auth?.user?.username,
          postType: selectedPostType?.value ?? "Unknown",
          tags: selectedTags.map((tag) => tag?.value) as string[] ?? []
        });

        // hack until cache writes are fixed
        refresh();
      }
    }
  });

  let PostTypeIcon = postTypeTemplates[selectedPostType?.value ?? "DEFAULT"].icon;
  const handleSetSelectedPostType = (postType: PostType) => {
    setSelectedPostType(postType);
    setCurrentTemplate(postTypeTemplates[postType.value!]);
    setPostTypeTemplates(postTypeTemplatesObj);
  };

  const handleDeselectTag = (item: ComboBoxItem) => {
    setSelectedTags(selectedTags.filter(i => i.value !== item.value));
  };

  const breakpoint = useTailwindBreakpoint();

  return (
    <div id={"create-post-container"}
         className="focus-within:outline-indigo-500 focus-within:outline-4 focus-within:outline focus-within:rounded-xl">
      <div className="bg-white rounded-xl border border-scale-300 shadow-sm">
        <div
          className="">
          <label htmlFor="title" className="sr-only">
            Title
          </label>
          <Input
            id="create-post-container-title"
            type="text"
            name="title"
            onChange={(e) => setPostTitle(e.target.value)}
            className="bg-transparent block w-full border-0 text-sm sm:text-lg font-medium placeholder:text-scale-400 focus:ring-0"
            placeholder={currentTemplate.titlePlaceholder}
            value={postTitle}
          />
          <div className="overflow-y-scroll">
            <label htmlFor="description" className="sr-only">
              Description
            </label>
            <Textarea
              // on small screens, show 3 rows + the number of tags selected
              // to give more breathing room for the tags
              rows={breakpoint === "xs" ? 3 + selectedTags.length : 3 }
              name="description"
              id="description"
              onChange={(e) => setPostDescription(e.target.value)}
              className="bg-transparent shadow-none block w-full resize-none border-0 py-0 text-scale-900 placeholder:text-scale-400 focus:ring-0 text-xs sm:text-sm sm:leading-6"
              placeholder={currentTemplate.descriptionPlaceholder}
              value={postDescription}
            />
          </div>
        </div>
        <div className="inset-0">
          <div className={"relative space-x-2 px-2 py-2 sm:px-1 flex items-end justify-between"}>
            <div className={"inline-flex w-fit items-center flex-wrap justify-start overflow-y-scroll"}>
              {
                selectedTags.length ? (
                  <div className="text-[8px]">
                    {selectedTags.map(item => {
                      let badgeColor = "default";
                      if (item.type === TagType.Technology) {
                        badgeColor = "red";
                      } else if (item.type === TagType.Career) {
                        badgeColor = "purple";
                      } else if (item.type === TagType.Topic) {
                        badgeColor = "blue";
                      }

                      return (
                        <Badge key={item.value} className="m-1" variant={badgeColor as any}>
                          <span>{item.label}</span>
                          <XIcon className={"ml-1 h-3 w-3"} onClick={() => handleDeselectTag(item as ComboBoxItem)} />
                        </Badge>
                      );
                    })}
                  </div>
                ) : null
              }
            </div>

            <div className="space-x-2 px-2 sm:px-1 inline-flex items-end flex-nowrap justify-end">
              <MultiTagSelect displayItemsSeparately={true} items={tags as ComboBoxItem[]}
                              selectedItems={selectedTags as ComboBoxItem[]} setSelectedItems={handleSetSelectedTags} />
              <div>
                {
                  !uploadedFileData ? (
                    <Button
                      onClick={() => withAuth(() => setIsUploadModalOpen(true))}
                      variant={"outline"}
                      size={"sm"}
                      className={"font-mono text-xs px-1 py-1"}
                    >
                      <ImageIcon className={"h-5 w-5 text-scale-500"} aria-hidden="true" />
                    </Button>
                  ) : (
                    <div className={"relative"}>
                      <div className="relative">
                        <XCircle
                          className={"cursor-pointer bg-scale-100 rounded-full absolute -top-1 -right-1 h-4 w-4 text-scale-400"}
                          aria-hidden="true" onClick={() => setUploadedFileData(null)} />
                        <img src={uploadedFileData.url} alt={"uploaded file"}
                             className={"rounded-md max-h-12 max-w-[140px] object-contain h-full border-2 border-scale-100/90 shadow "} />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>

          </div>
          <div
            className="pr-2 flex items-center justify-between space-x-3 border-t border-scale-200">
            <div className="flex items-start py-2 px-2">

              <div className="flex flex-nowrap justify-end space-x-2">
                <Listbox as="div" value={selectedPostType} onChange={(postTypeValue) => {
                  const postTypeObj = postTypes.find((item) => {
                    return item.value === postTypeValue;
                  });
                  handleSetSelectedPostType(postTypeObj!);
                }} className="flex-shrink-0">
                  {({ open }) => (
                    <>
                      <Listbox.Label className="sr-only">Select a post type</Listbox.Label>
                      <div className="relative">
                        <Listbox.Button
                          className="relative inline-flex items-center whitespace-nowrap rounded-full border border-scale-200 bg-scale-50 px-1 py-1 text-xs font-medium text-scale-400 hover:bg-scale-100 sm:px-3">
                          <PostTypeIcon
                            className={cn(
                              "text-scale-500",
                              "h-4 w-4 flex-shrink-0 sm:-ml-1"
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={cn(
                              "text-scale-900",
                              "hidden truncate sm:ml-2 sm:block"
                            )}
                          >
                      {!selectedPostType?.value ? "Select post type" : selectedPostType?.label}
                    </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            className="p-2 text-scale-600 absolute left-0 z-50 mt-1 max-h-64 w-60 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {postTypes.map((postType: PostType) => {
                              const CurrentPostTypeIcon = postTypeTemplates[postType.value!].icon;
                              return (
                                <Listbox.Option
                                  key={postType.value}
                                  className={({ active }) =>
                                    cn(
                                      active ? "bg-scale-100" : "bg-white",
                                      "cursor-default select-none px-3 py-2"
                                    )
                                  }
                                  value={postType.value}
                                >
                                  <div className="flex space-x-2 items-center">
                                    <CurrentPostTypeIcon
                                      className={cn(
                                        "text-scale-500",
                                        "h-4 w-4 flex-shrink-0 sm:-ml-1"
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="block truncate font-medium">{postType.label}</span>
                                  </div>
                                </Listbox.Option>
                              );
                            })}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>

              </div>
            </div>
            <div className="flex flex-wrap justify-end overflow-y-scroll w-full">
              <span
                className={cn(
                  "text-xs font-medium",
                  postDescription.length > maxDescriptionLength ? "text-red-500" : "text-scale-500"
                )}
              >
                {`${postDescription.length}/${maxDescriptionLength}`}
              </span>
            </div>
            <div className="flex-shrink-0">
              <Button
                onClick={() => createPost()}
                variant={"default"}
                size={"sm"}
                className="font-mono text-xs"
              >
                <Flame className="mr-1 h-3 w-3" aria-hidden="true" />
                <span>Share update</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      {
        isUploadModalOpen && (
          <FileUploadModal
            setFileUploadData={setUploadedFileData}
            open={isUploadModalOpen}
            setOpen={setIsUploadModalOpen}
            filePrefix={"post"}
          />
        )
      }

    </div>
  );
}
