import { marketingConfig } from "@/config/marketing";
import { Badge } from "@/components/ui/badge";
import { Link, Outlet } from "react-router-dom";
import { cn } from "@/utils";
import { buttonVariants } from "@/components/ui";
import React, { useContext } from "react";
import { MainNav } from "@/components/nav/main-nav";
import { SiteFooter } from "@/components/nav/site-footer";
import { authStore } from "@/store";
import { AuthContext } from "@/store/context/AuthContext";
import { AvatarDropdown } from "@/components/nav/avatar-dropdown";
import { Icons } from "@/assets/icons";
import { AppRoute } from "@/config/routes";

export default function MarketingLayout() {

  const { isRefreshTokenExpired } = authStore();
  const { state: auth } = useContext(AuthContext);
  const userLoggedIn = auth?.isAuthenticated && !isRefreshTokenExpired();

  return (
    <div className="flex min-h-screen flex-col bg-scale-100 dark:bg-scale-950">
      <header
        className="sticky top-0 z-40 backdrop-blur-xl bg-scale-100/50 dark:bg-scale-950/50 border-b border-b-scale-950/10 dark:border-b-scale-25/10">
        <div className="container flex h-16 items-center justify-between py-4">
          <MainNav items={marketingConfig.mainNav} />
          <nav className={"flex items-center space-x-4"}>
            <div>
              <Badge variant={"default"}>
                BETA
              </Badge>
            </div>
            {
              userLoggedIn ? (
                <AvatarDropdown />
              ) : (
                <Link
                  to={AppRoute.Login}
                  className={cn(
                    buttonVariants({ size: "sm", variant: "default" }),
                    "shrink-0",
                    "px-4",
                    "font-mono text-xs"
                  )}
                >
                  <div className="flex space-x-1">
                    <Icons.GitHub className="-ml-1 h-4 w-4 mr-1" />
                    <span>Get started</span>
                  </div>
                </Link>
              )
            }
            {/*<DarkModeToggle />*/}
          </nav>
        </div>
      </header>
      <main className="flex-1">
        <Outlet />
      </main>
      <SiteFooter />
    </div>
  );
}
