import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Textarea
} from "@/components/ui";
import { cn } from "@/utils";
import React, { useState } from "react";
import { Icons } from "@/assets/icons";
import { useSubmitPlatformFeedbackMutation } from "@pairprogram/graphql";
import toast from "react-hot-toast";
import useRequiresAuth from "@/hooks/use-requires-auth";

export default function FeedbackForm() {
  const [rating, setRating] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [submitFeedback] = useSubmitPlatformFeedbackMutation({
    onCompleted: (data) => {
      toast.success("Feedback submitted");
    },
    onError: (error) => {
      toast.error("Error submitting feedback");
    },
    variables: {
      input: {
        rating,
        description
      }
    }
  });

  const { withAuth } = useRequiresAuth();

  const handleSubmitFeedback = async () => {
    if (!rating && !description) {
      toast.error("Please select a rating and leave a brief description");
      return;
    }
    await submitFeedback();
    setRating(prevState => prevState === rating ? 0 : rating);
    setDescription("");
  };

  return (
    <div>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <Button onClick={() => withAuth(() => setOpen(true))} size={"sm"} variant="outline" className={"font-mono text-xs"}>
          <div className="inline-flex items-center font-normal text-scale-500 dark:text-scale-200">
            {/*<Icons.Feedback className={"h-4 w-4 md:mr-2 "} />*/}
            {/*<p className={"hidden md:block text-scale-300"}>*/}
            {/*  |*/}
            {/*</p>*/}
            {/*<Icons.Bug className={"h-4 w-4 md:ml-2 "} />*/}
            <p className={"text-scale-500 font-mono text-xs"}>Feedback</p>
          </div>
        </Button>
        <AlertDialogContent className={cn(
          "bg-scale-25 dark:bg-scale-950",
          "border border-scale-200 dark:border-scale-700"
        )}>
          <AlertDialogHeader>
            <AlertDialogTitle className={"text-scale-950 dark:text-scale-25 mb-6"}>
              <div className="inline-flex text-2xl font-bold w-full items-center justify-center">
                <Icons.Feedback className={"text-scale-700 h-8 w-8 mr-2 text-2xl"} />
                <div className="text-scale-300">|</div>
                <Icons.Bug className={"text-scale-700 h-8 w-8 ml-2 text-2xl"} />
              </div>
                <p className={'text-center text-scale-600'}>Leave feedback or report a bug</p>
            </AlertDialogTitle>
            <AlertDialogDescription className={"text-scale-700 dark:text-scale-200 space-y-8"}>
              <div className="flex flex-col space-y-8">
                <div className={"inline-flex w-full items-center justify-between space-x-6 px-4"}>
                  <Icons.FeedbackOne onClick={() => setRating(1)} className={cn(
                    "h-10 w-10 cursor-pointer hover:text-yellow-400",
                    rating === 1 ? "text-yellow-400" : "text-scale-400"
                  )} />
                  <Icons.FeedbackTwo onClick={() => setRating(2)} className={cn(
                    "h-10 w-10 cursor-pointer hover:text-yellow-400",
                    rating === 2 ? "text-yellow-400" : "text-scale-400"
                  )} />
                  <Icons.FeedbackThree onClick={() => setRating(3)} className={cn(
                    "h-10 w-10 cursor-pointer hover:text-yellow-400",
                    rating === 3 ? "text-yellow-400" : "text-scale-400"
                  )} />
                  <Icons.FeedbackFour onClick={() => setRating(4)} className={cn(
                    "h-10 w-10 cursor-pointer hover:text-yellow-400",
                    rating === 4 ? "text-yellow-400" : "text-scale-400"
                  )} />
                  <Icons.FeedbackFive onClick={() => setRating(5)} className={cn(
                    "h-10 w-10 cursor-pointer hover:text-yellow-400",
                    rating === 5 ? "text-yellow-400" : "text-scale-400"
                  )} />
                </div>
                <div className="">
                  <Textarea
                    id="feedback"
                    value={description}
                    placeholder={"Let us know how we're doing, send us feature requests, or tell us about a bug you've found!"}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              className={"font-mono text-xs"}
            >Cancel</AlertDialogCancel>
            <AlertDialogAction
              className={"font-mono text-xs"}
              onClick={handleSubmitFeedback}>Submit</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
