import React from "react";
import { Icons } from "@/assets/icons";
import NewPostButton from "@/components/new-post-button";
import { Button } from "@/components/ui";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/config/routes";

export default function ActivityFeedEmpty() {
  const navigate = useNavigate();
  return (
    <div
      className="relative block w-full rounded-lg border-2 border-dashed border-scale-300 dark:border-scale-700 p-12 text-center hover:border-scale-400 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
    >
      <Icons.Post
        className="mx-auto h-12 w-12 dark:text-scale-400 text-scale-600"
      />
      <div className="py-2">
        <span className="mt-2 block text-sm font-medium dark:text-scale-400 text-scale-600">No activity yet!</span>
      </div>
      <div className={"flex w-full py-4 items-center justify-center"}>
        <div>
          <Button
            variant={"default"}
            size={"sm"}
            className={'font-mono text-xs'}
            onClick={() => navigate(AppRoute.User)}
          >
            <Icons.Users className={"inline-block h-4 w-4 mr-2"} />
            <span>Be the change!</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
