import { useNavigate } from "react-router-dom";
import React, { Fragment, useState } from "react";
import { Button, Input, Label, Textarea } from "@/components/ui";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import useGetTags from "@/hooks/use-get-tags";
import toast from "react-hot-toast";
import { Tag, TaskDifficulty, TaskType, TaskTypeEnum, useAdminCreateTaskMutation } from "@pairprogram/graphql";
import { Icons } from "@/assets/icons";
import useGetTaskTypes from "@/hooks/use-get-task-types";
import { Listbox, Transition } from "@headlessui/react";
import { cn } from "@/utils";
import { mapTaskTypeIcon } from "@/components/tasks/task-card";
import { ImageIcon, XCircle } from "lucide-react";
import FileUploadModal from "@/components/file-upload-modal";
import { capitalizeFirstLetter } from "@/utils/string.utils";

export default function AdminTaskCreatePage() {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [estimatedMinutes, setEstimatedMinutes] = useState(0);
  const [url, setUrl] = useState("");
  const [prerequisites, setPrerequisites] = useState("");
  const [objectives, setObjectives] = useState("");
  const [instructions, setInstructions] = useState("");
  const [taskType, setTaskType] = useState<TaskType>();
  const [difficulty, setDifficulty] = useState<TaskDifficulty>();
  const [selectedTechTags, setSelectedTechTags] = useState<Tag[]>([]);
  const [selectedTrackTags, setSelectedTrackTags] = useState<Tag[]>([]);
  const [selectedTopicTags, setSelectedTopicTags] = useState<Tag[]>([]);

  const [primaryImageFileData, setPrimaryImageFileData] = useState<{ key: string; url: string; } | null>(null);
  const [uploadedFileData, setUploadedFileData] = useState<{ key: string; url: string; } | null>(null);

  const { techTags, trackTags, topicTags } = useGetTags();
  const { taskTypes } = useGetTaskTypes();

  const [isPrimaryImageModalOpen, setIsPrimaryImageModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const randomBackgroundNumbers = [98];
  function getRandomGradientBackground() {
    const randomIndex = Math.floor(Math.random() * randomBackgroundNumbers.length);
    return `mesh-gradient-${randomBackgroundNumbers[randomIndex]}`;
  }

  const [createTask] = useAdminCreateTaskMutation({
    onCompleted: (data) => {
      if (data?.AdminCreateTask?.error) {
        toast.error("Error creating task", { id: "task-created" });
        return;
      }
      if (data?.AdminCreateTask?.data) {
        toast.success("Task created", { id: "task-created" });
        navigate(`/task/${data?.AdminCreateTask?.data?.id}`);
      }
    },
    variables: {
      input: {
        title,
        description,
        estimatedMinutes,
        url,
        prerequisites,
        objectives,
        instructions,
        difficulty,
        imageKey: primaryImageFileData?.key ?? getRandomGradientBackground(),
        taskTypeId: taskType?.id,
        uploadKeys: uploadedFileData?.key ? [uploadedFileData?.key] : [],
        tagIds: [
          ...(selectedTechTags?.map(tag => tag?.id!) ?? []),
          ...(selectedTrackTags?.map(tag => tag?.id!) ?? []),
          ...(selectedTopicTags?.map(tag => tag?.id!) ?? [])
        ]
      }
    }
  });

  const taskDifficulties = Object.values(TaskDifficulty);

  return (
    <div>
      <DashboardPageHeadingSection title={"Create Task"} />
      <div className="flex flex-col space-y-4">

        <div className={"space-y-2"}>
          <Label htmlFor="title">Main Image</Label>
          <p className="mt-1 text-sm leading-6 text-scale-500">
            Images should be at least 1280x720px (16:9) to display properly on the project page.
          </p>
          <div>
            {
              !primaryImageFileData ? (
                <Button
                  onClick={() => setIsPrimaryImageModalOpen(true)}
                  variant={"outline"}
                  size={"sm"}
                  className={"font-mono text-xs px-1 py-1 bg-white"}
                >
                  <ImageIcon className={"h-5 w-5 text-scale-500"} aria-hidden="true" />
                </Button>
              ) : (
                <div className={"relative w-fit"}>
                  <div className="relative">
                    <XCircle
                      className={"cursor-pointer bg-scale-100 rounded-full absolute -top-1 -right-1 h-4 w-4 text-scale-400"}
                      aria-hidden="true" onClick={() => setPrimaryImageFileData(null)} />
                    <img src={primaryImageFileData.url} alt={"uploaded file"}
                         className={"rounded-md max-h-12 max-w-[140px] object-contain h-full border-2 border-scale-100/90 shadow "} />
                  </div>
                </div>
              )
            }
          </div>
        </div>

        <div>
          <Label htmlFor="title">Title</Label>
          <Input onChange={(e) => setTitle(e.currentTarget.value)} value={title} id="title" />
        </div>

        <div>
          <Label htmlFor="description">Description</Label>
          <Textarea className={'bg-white'} onChange={(e) => setDescription(e.currentTarget.value)} value={description}
                    id="description" />
        </div>
        {/* estimated minutes*/}
        <div>
          <Label htmlFor="estimatedMinutes">Estimated Minutes</Label>
          <Input type={"number"} onChange={(e) => setEstimatedMinutes(Number(e.currentTarget.value))}
                 value={estimatedMinutes} id="estimatedMinutes" />
        </div>

        {/* task type */}
        <div>
          <Label htmlFor="taskTypes">Task Type</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            <Listbox as="div" value={taskType} onChange={(taskTypeValue) => {
              const taskTypeObj = taskTypes.find((item) => {
                return item.value === taskTypeValue;
              });
              setTaskType(taskTypeObj!);
            }} className="flex-shrink-0">
              {({ open }) => {
                const TaskTypeIcon = mapTaskTypeIcon(taskType?.value as TaskTypeEnum);
                return (
                  <>
                    <Listbox.Label className="sr-only">Select a task type</Listbox.Label>
                    <div className="relative">
                      <Listbox.Button
                        className="relative inline-flex items-center whitespace-nowrap border border-scale-200 bg-scale-50 px-1 py-1 text-lg rounded-md font-medium text-scale-400 hover:bg-scale-100 sm:px-3">
                        <TaskTypeIcon
                          className={cn(
                            !taskType?.value ? "text-scale-300" : "text-scale-500",
                            "h-4 w-4 flex-shrink-0 sm:-ml-1"
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={cn(
                            !taskType?.value ? "" : "text-scale-900",
                            "hidden truncate sm:ml-2 sm:block"
                          )}
                        >
                      {!taskType?.value ? "Select task type" : taskType?.label}
                    </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          className="p-2 text-scale-600 absolute left-0 z-50 mt-1 max-h-64 w-60 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {taskTypes.map((taskType: TaskType) => {
                            const CurrentPostTypeIcon = mapTaskTypeIcon(taskType.value as TaskTypeEnum);
                            return (
                              <Listbox.Option
                                key={taskType.value}
                                className={({ active }) =>
                                  cn(
                                    active ? "bg-scale-100 rounded-md" : "bg-white",
                                    "cursor-default select-none px-3 py-2"
                                  )
                                }
                                value={taskType.value}
                              >
                                <div className="flex space-x-2 items-center">
                                  <CurrentPostTypeIcon
                                    className={cn(
                                      !taskType?.value ? "text-scale-300" : "text-scale-500",
                                      "h-4 w-4 flex-shrink-0 sm:-ml-1"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="block truncate font-medium">{taskType.label}</span>
                                </div>
                              </Listbox.Option>
                            );
                          })}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                );
              }}
            </Listbox>
          </div>
        </div>

        {/* task difficulty */}
        <div>
          <Label htmlFor="difficulty">Difficulty</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            <Listbox as="div" value={difficulty} onChange={(difficulty) => {
              const taskDifficultyObj = taskDifficulties.find((item) => {
                return item === difficulty;
              });
              setDifficulty(taskDifficultyObj!);
            }} className="flex-shrink-0">
              {({ open }) => {
                return (
                  <>
                    <Listbox.Label className="sr-only">Select a task difficulty</Listbox.Label>
                    <div className="relative">
                      <Listbox.Button
                        className="relative inline-flex items-center whitespace-nowrap border border-scale-200 bg-scale-50 px-1 py-1 text-lg rounded-md font-medium text-scale-400 hover:bg-scale-100 sm:px-3">
                        <span
                          className={cn(
                            !difficulty ? "" : "text-scale-900",
                            "hidden truncate sm:block"
                          )}
                        >
                      {!difficulty ? "Select task difficulty" : capitalizeFirstLetter(difficulty)}
                    </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          className="p-2 text-scale-600 absolute left-0 z-50 mt-1 max-h-64 w-60 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {taskDifficulties.map((difficulty: TaskDifficulty) => {
                            return (
                              <Listbox.Option
                                key={difficulty}
                                className={({ active }) =>
                                  cn(
                                    active ? "bg-scale-100 rounded-md" : "bg-white",
                                    "cursor-default select-none px-3 py-2"
                                  )
                                }
                                value={difficulty}
                              >
                                <div className="flex space-x-2 items-center">
                                  <span
                                    className="block truncate font-medium">{capitalizeFirstLetter(difficulty)}</span>
                                </div>
                              </Listbox.Option>
                            );
                          })}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                );
              }}
            </Listbox>
          </div>
        </div>


        <div>
          <Label htmlFor="techTags">Tech Tags</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            {techTags?.map((tag) => (
              <Button
                size={"xs"}
                className={"text-xs font-mono"}
                key={tag.id}
                variant={selectedTechTags?.includes(tag) ? "primary" : "default"}
                onClick={() => {
                  if (selectedTechTags?.includes(tag)) {
                    setSelectedTechTags(selectedTechTags.filter((t) => t.id !== tag.id));
                  } else {
                    setSelectedTechTags([...selectedTechTags, tag]);
                  }
                }}
              >
                {tag.label}
              </Button>
            ))}
          </div>
        </div>

        <div>
          <Label htmlFor="trackTags">Track Tags</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            {trackTags?.map((tag) => (
              <Button
                size={"xs"}
                className={"text-xs font-mono"}
                key={tag.id}
                variant={selectedTrackTags?.includes(tag) ? "primary" : "default"}
                onClick={() => {
                  if (selectedTrackTags?.includes(tag)) {
                    setSelectedTrackTags(selectedTrackTags.filter((t) => t.id !== tag.id));
                  } else {
                    setSelectedTrackTags([...selectedTrackTags, tag]);
                  }
                }}
              >
                {tag.label}
              </Button>
            ))}
          </div>
        </div>

        <div>
          <Label htmlFor="topicTags">Topic Tags</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            {topicTags?.map((tag) => (
              <Button
                size={"xs"}
                className={"text-xs font-mono"}
                key={tag.id}
                variant={selectedTopicTags?.includes(tag) ? "primary" : "default"}
                onClick={() => {
                  if (selectedTopicTags?.includes(tag)) {
                    setSelectedTopicTags(selectedTopicTags.filter((t) => t.id !== tag.id));
                  } else {
                    setSelectedTopicTags([...selectedTopicTags, tag]);
                  }
                }}
              >
                {tag.label}
              </Button>
            ))}
          </div>
        </div>

        <div>
          <Label htmlFor="file">File</Label>
          <div>
            {
              !uploadedFileData ? (
                <Button
                  onClick={() => setIsUploadModalOpen(true)}
                  variant={"outline"}
                  size={"sm"}
                  className={"font-mono text-xs px-1 py-1"}
                >
                  <ImageIcon className={"h-5 w-5 text-scale-500"} aria-hidden="true" />
                </Button>
              ) : (
                <div className={"relative w-fit"}>
                  <div className="relative">
                    <XCircle
                      className={"cursor-pointer bg-scale-100 rounded-full absolute -top-1 -right-1 h-4 w-4 text-scale-400"}
                      aria-hidden="true" onClick={() => setUploadedFileData(null)} />
                    <img src={uploadedFileData.url} alt={"uploaded file"}
                         className={"rounded-md max-h-12 max-w-[140px] object-contain h-full border-2 border-scale-100/90 shadow "} />
                  </div>
                </div>
              )
            }
          </div>
        </div>

        <div>
          <Label htmlFor="url">URL</Label>
          <Input onChange={(e) => setUrl(e.currentTarget.value)} value={url} id="url" />
        </div>
        <div>
          <Label htmlFor="prerequisites">Prerequisites</Label>
          <Textarea className={"bg-white"} onChange={(e) => setPrerequisites(e.currentTarget.value)}
                    value={prerequisites} id="prerequisites" />
        </div>
        <div>
          <Label htmlFor="objective">Objectives</Label>
          <Textarea className={"bg-white"} onChange={(e) => setObjectives(e.currentTarget.value)} value={objectives}
                    id="objectives" />
        </div>
        <div>
          <Label htmlFor="instructions">Instructions</Label>
          <Textarea className={"bg-white"} onChange={(e) => setInstructions(e.currentTarget.value)} value={instructions}
                    id="instructions" />
        </div>
        <Button
          onClick={() => createTask()}
          className={"flex items-center text-xs font-mono space-x-2"}
          variant={"default"}
          size={"lg"}
        >
          <Icons.Save className={"w-4 h-4"} />
          <span>Create task</span>
        </Button>
      </div>
      {
        isPrimaryImageModalOpen && (
          <FileUploadModal
            filePrefix={"task"}
            setFileUploadData={setPrimaryImageFileData}
            open={isPrimaryImageModalOpen}
            setOpen={setIsPrimaryImageModalOpen}
          />
        )
      }
      {
        isUploadModalOpen && (
          <FileUploadModal
            filePrefix={"task"}
            setFileUploadData={setUploadedFileData}
            open={isUploadModalOpen}
            setOpen={setIsUploadModalOpen}
          />
        )
      }
    </div>
  );
}
