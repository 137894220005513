import React from "react";

export default function PostItemSkeleton() {
  return (

    <li
      className="bg-scale-25 h-36 border border-scale-300 p-4 rounded-lg relative w-full flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8">
      <div className="min-w-0 flex-auto">
        <div className="flex items-center gap-x-3">
          <div className="animate-pulse h-10 w-10 rounded-full bg-scale-200 dark:bg-scale-700" />
          <h2 className="min-w-0 text-sm font-semibold leading-6 text-scale-950 dark:text-scale-25">
            <div className="flex gap-x-2">
              <div className="animate-pulse h-3 w-24 rounded-xl bg-scale-200 dark:bg-scale-700" />
              <div className="animate-pulse h-3 w-36 rounded-xl bg-scale-200 dark:bg-scale-700" />
            </div>
          </h2>
        </div>
        <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-scale-400">
          <div className="animate-pulse h-3 w-12 rounded-xl bg-scale-200 dark:bg-scale-700" />
          <div className="animate-pulse h-3 w-6 rounded-full bg-scale-200 dark:bg-scale-700" />
          <div className="animate-pulse h-3 w-full rounded bg-scale-200 dark:bg-scale-700" />
        </div>
        <div className="mt-4 animate-pulse h-8 w-full rounded-lg bg-scale-200 dark:bg-scale-700" />
      </div>
    </li>
  );
}
