import * as React from "react";
import { cn } from "@/utils";
import { Icons } from "@/assets/icons";
import { Button } from "@/components/ui/button";
import { useContext, useEffect } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import { authStore } from "@/store";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AuthError, useGetCurrentUserLazyQuery } from "@pairprogram/graphql";
import { AppRoute } from "@/config/routes";
import toast from "react-hot-toast";
import { AuthActions } from "@/store/actions/auth.actions";
interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  size?: "xxs" | "xs" | "sm" | "lg" | "default";
  variant?: "default" | "ghost" | "primary" | "secondary" | "destructive" | "outline" | "subtle" | "inverse" | "link" | "mono";
}

export function UserAuthForm({ text = "GitHub", size = 'sm', variant = "default", className, ...props }: UserAuthFormProps) {
  const [isGitHubLoading, setIsGitHubLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();
  // get current route
  const { pathname } = useLocation();

  const { state: auth, dispatch } = useContext(AuthContext);
  const { setAccessToken, setRefreshToken } = authStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const accessToken = searchParams.get("access_token");
  const refreshToken = searchParams.get("refresh_token");
  const authError = searchParams.get("error");

  if (authError) {
    if (authError === AuthError.ApplicationIncomplete) {
      if (pathname !== AppRoute.Apply) {
        toast.error("Please complete your application, it's really quick!", {
          id: "application-incomplete",
          position: "top-center",
          duration: 7500,
          icon: <Icons.Warning className={"h-6 w-6 mr-2"} />
        });
        navigate(AppRoute.Apply);
      }
    } else if (authError === AuthError.ApplicationPendingApproval) {
      toast.error("Your application is being reviewed, we'll be in touch soon!", {
        id: "application-pending-approval",
        position: "top-center",
        duration: 7500
      });
    } else {
      toast.error("An error occurred while trying to login, please try again.", {
        id: "login-error",
        position: "top-center"
      });
    }
  }


  const [getCurrentUser, _] = useGetCurrentUserLazyQuery();

  useEffect(() => {
    if (accessToken && refreshToken) {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      getCurrentUser().then((user) => {
        if (user?.data?.GetCurrentUser.data) {
          dispatch({ type: AuthActions.Login });
          dispatch({ type: AuthActions.Account, payload: user.data.GetCurrentUser.data });

          const isNotOnboarded = !user.data.GetCurrentUser.data.isOnboarded;
          if (isNotOnboarded) {
            navigate(AppRoute.Apply);
          } else {
            navigate(AppRoute.Home);
          }
        }
      });
    }
  }, [accessToken, refreshToken]);


  return (
    <div className={cn("grid gap-6")} {...props}>
      <a
        type="button"
        href={`/api/v1/auth/github`}>
        <Button
          type="button"
          variant={variant}
          size={size}
          className={cn(
            "cursor-pointer w-full font-mono text-xs",
            className,
          )}
          onClick={async () => {
            setIsGitHubLoading(true);
          }}
        >
          {isGitHubLoading ? (
            <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <Icons.GitHub className="mr-2 h-4 w-4" />
          )}{" "}
          { text }
        </Button>
      </a>
    </div>
  );
}
