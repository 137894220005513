import React, { ReactElement, useEffect, useState } from "react";
import { cn } from "@/utils";
import {
  GetLatestPlatformEventsDocument,
  GetLatestPlatformEventsQuery,
  PlatformEvent,
  PlatformEventType,
  PlatformEventTypeEnum,
  useGetLatestPlatformEventsQuery,
  useOnPlatformEventSubscription
} from "@pairprogram/graphql";
import { authStore } from "@/store";
import { Icons } from "@/assets/icons";
import { getRelativeTime } from "@/utils/datetime.utils";
import AvatarHoverCard from "@/components/avatar-hover-card";
import ActivityFeedEmpty from "@/components/feed/empty-activity-feed";
import ActivityFeedLoader from "@/components/video/activity-feed-loader";

export default function ActivityFeedV2() {
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);

  const [platformEvents, setPlatformEvents] = useState<PlatformEvent[]>([]);
  const [count, setCount] = useState<number>(0);
  const { getAccessToken, getRefreshToken } = authStore();
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  const { fetchMore, loading } = useGetLatestPlatformEventsQuery({
    onCompleted: (data) => {
      const olderPlatformEvents = data?.GetLatestPlatformEvents?.data?.platformEvents as PlatformEvent[] ?? [];
      setPlatformEvents([...platformEvents, ...olderPlatformEvents]);
      setCount(data?.GetLatestPlatformEvents?.data?.platformEvents?.length ?? 0);
    },
    // skip: !accessToken,
    variables: {
      input: {
        limit,
        offset
      }
    }
  });

  useEffect(() => {
    if (platformEvents.length) {
      setPlatformEvents(platformEvents);
    }
  }, [platformEvents]);

  useOnPlatformEventSubscription({
    skip: !accessToken || !refreshToken,
    onData: ({ client, data: newPlatformEvent }) => {
      const updatedPlatformEvents = [newPlatformEvent?.data?.OnPlatformEvent!, ...platformEvents];
      setPlatformEvents(updatedPlatformEvents);
      client.readQuery<GetLatestPlatformEventsQuery>({ query: GetLatestPlatformEventsDocument });
      client.writeQuery({
        query: GetLatestPlatformEventsDocument,
        data: {
          GetLatestPlatformEvents: {
            error: null,
            data: {
              platformEvents: [
                newPlatformEvent?.data?.OnPlatformEvent as PlatformEvent,
                ...platformEvents!
              ]
            }
          }
        }
      });
    }
  });

  if (loading) {
    return (
      <ActivityFeedLoader/>
    );
  }

  if (!loading && !count) {
    return (
      <div className="py-8">
        <ActivityFeedEmpty />
      </div>
    );
  }

  return (
    <div>
      <div className={"text-md text-center font-semibold text-gray-800 pb-2"}>
        Recent activity
      </div>
      <ul
        role="list"
        className={cn(
          "group hover:outline-brand-100 hover:outline-4 hover:outline",
          "border border-scale-300",
          platformEvents?.length && "ring-1 ring-gray-900/5",
          "py-1 divide-y divide-scale-100 border border-scale-300 overflow-hidden bg-white shadow-sm rounded-xl"
        )}
      >
        {platformEvents.map((platformEvent, i) => {
          return (
            <li
              key={platformEvent.id}
              className="w-full py-1 hover:bg-gray-50 px-4">
              <div className="my-0.5 flex justify-between min-w-0">
                <div className="flex items-center space-x-3">
                  <AvatarHoverCard user={platformEvent?.user!} size={6} statusSize={2} />
                  <div className="min-w-0 space-y-1">
                    <p className="text-xs font-normal text-scale-500 -mb-0.5">
                      <MappedEventType eventType={platformEvent?.PlatformEventType?.data!} />
                    </p>
                  </div>
                </div>
                <p className="text-[11px] font-normal text-gray-400/80 self-center">
                  {getRelativeTime(platformEvent?.createdAt)}
                </p>
              </div>

              {/*<div className="flex shrink-0 items-center gap-x-2">*/}
              {/*  <div className="hidden sm:flex sm:flex-col sm:items-end">*/}
              {/*    {!chatParticipant?.isOnline ? (*/}
              {/*      <p className="text-xs leading-5 font-medium text-gray-400/80 italic">*/}
              {/*      <span>Last online <time*/}
              {/*        dateTime={chatParticipant?.lastOnlineAt}>{getRelativeTime(chatParticipant?.lastOnlineAt)}</time></span>*/}
              {/*      </p>*/}
              {/*    ) : (*/}
              {/*      <div className="flex items-center gap-x-1.5">*/}
              {/*        <div className="animate-ping duration-1000 flex-none rounded-full bg-emerald-500/20 p-1">*/}
              {/*          <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />*/}
              {/*        </div>*/}
              {/*        <p className="text-xs leading-5 text-gray-500 italic">Online now</p>*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*  <ChevronRightIcon className="h-5 w-5 flex-none text-gray-600" aria-hidden="true" />*/}
              {/*</div>*/}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function MappedEventType({ eventType }: { eventType: PlatformEventType }): ReactElement {
  const { label, value } = eventType;
  switch (value) {
    case PlatformEventTypeEnum.UserRegister:
      return (
        <span className={"inline-flex items-center"}><Icons.Register className={"h-3 w-3 mr-1.5"} />{label}</span>);
    case PlatformEventTypeEnum.UserLogin:
      return (<span className={"inline-flex items-center"}><Icons.Login className={"h-3 w-3 mr-1.5"} />{label}</span>);
    case PlatformEventTypeEnum.UserApplied:
      return (
        <span className={"inline-flex items-center"}><Icons.Applied className={"h-3 w-3 mr-1.5"} />{label}</span>);
    case PlatformEventTypeEnum.PostCreated:
      return (
        <span className={"inline-flex items-center"}><Icons.NewPost className={"h-3 w-3 mr-1.5"} />{label}
        </span>);
    case PlatformEventTypeEnum.PostUpdated:
      return (
        <span className={"inline-flex items-center"}><Icons.UpdatedPost className={"h-3 w-3 mr-1.5"} />{label}
        </span>);
    case PlatformEventTypeEnum.PostDeleted:
      return (
        <span className={"inline-flex items-center"}><Icons.DeletedPost className={"h-3 w-3 mr-1.5"} />{label}
        </span>);
    case PlatformEventTypeEnum.SessionCreated:
      return (
        <span className={"inline-flex items-center"}><Icons.SessionCreated className={"h-3 w-3 mr-1.5"} />{label}
          started</span>);
    case PlatformEventTypeEnum.SessionEnded:
      return (
        <span className={"inline-flex items-center"}><Icons.SessionEnded className={"h-3 w-3 mr-1.5"} />{label}
        </span>);
    case PlatformEventTypeEnum.SessionJoined:
      return (
        <span className={"inline-flex items-center"}><Icons.SessionJoined
          className={"h-3 w-3 mr-1.5"} />{label}</span>);
    case PlatformEventTypeEnum.SessionLeft:
      return (
        <span className={"inline-flex items-center"}><Icons.SessionLeft className={"h-3 w-3 mr-1.5"} />{label}</span>);
    default:
      return (
        <span className={"inline-flex items-center"}><Icons.Smiley className={"h-3 w-3 mr-1.5"} />Did something cool
        </span>);
  }
}
