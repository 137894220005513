import React, { useContext } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "@/components/ui";
import { Github, LifeBuoy, LogOut, Settings, User } from "lucide-react";
import { Icons } from "@/assets/icons";
import { Link } from "react-router-dom";
import { AuthActions } from "@/store/actions/auth.actions";
import { AppRoute } from "@/config/routes";

export function AvatarDropdown() {
  const { state: auth, dispatch } = useContext(AuthContext);

  const handleLogout = () => {
    dispatch({ type: AuthActions.Logout });
  };


  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar className={"h-8 w-8 cursor-pointer"}>
          <AvatarImage
            src={auth?.user?.avatarUrl}
            alt={`${auth?.user?.name ?? "Anonymous User"}'s Avatar`}
          />
          <AvatarFallback></AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="border space-y-2 border-scale-100 text-scale-500 dark:border-scale-800 w-56 bg-scale-25 dark:text-scale-200 dark:bg-scale-950">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuGroup>
          <Link to={AppRoute.Home}>
            <DropdownMenuItem className={"cursor-pointer hover:bg-scale-400 dark:hover:bg-scale-700"}>
              <Icons.Dashboard className="mr-2 h-4 w-4" />
              <span>Dashboard</span>
              {/*<DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>*/}
            </DropdownMenuItem>
          </Link>
          <Link to={`${AppRoute.User}/${auth?.user?.username}`}>
            <DropdownMenuItem className={"cursor-pointer hover:bg-scale-200 dark:hover:bg-scale-700"}>
              <User className="mr-2 h-4 w-4" />
              <span>Profile Page</span>
              {/*<DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>*/}
            </DropdownMenuItem>
          </Link>
          {/*<DropdownMenuItem>*/}
          {/*  <CreditCard className="mr-2 h-4 w-4" />*/}
          {/*  <span>Billing</span>*/}
          {/*  <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>*/}
          {/*</DropdownMenuItem>*/}
          <Link to={AppRoute.Settings}>
            <DropdownMenuItem className={"cursor-pointer hover:bg-scale-200 dark:hover:bg-scale-700"}>
              <Settings className="mr-2 h-4 w-4" />
              <span>Edit Profile</span>
              {/*<DropdownMenuShortcut>⌘S</DropdownMenuShortcut>*/}
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>
        <a href={`https://github.com/${auth?.user?.profile?.githubUsername}`}
           target={"_blank"} rel={"noreferrer"}>
          <DropdownMenuItem className={"cursor-pointer hover:bg-scale-200 dark:hover:bg-scale-700"}>
            <Github className="mr-2 h-4 w-4" />
            <span>My GitHub</span>
          </DropdownMenuItem>
        </a>
        {/*<a href={"https://discord.gg/QXBMykXXCn"} target={"_blank"} rel={"noreferrer"}>*/}
        {/*  <DropdownMenuItem className={"cursor-pointer hover:bg-scale-25 dark:hover:bg-scale-800"}>*/}
        {/*    <Icons.DiscordOutline className="mr-2 h-4 w-4" />*/}
        {/*    <span>Discord</span>*/}
        {/*  </DropdownMenuItem>*/}
        {/*</a>*/}
        {/*<Link to={"/dashboard/support"}>*/}
        {/*  <DropdownMenuItem className={"cursor-pointer hover:bg-scale-25 dark:hover:bg-scale-800"}>*/}
        {/*    <LifeBuoy className="mr-2 h-4 w-4" />*/}
        {/*    <span>Support</span>*/}
        {/*  </DropdownMenuItem>*/}
        {/*</Link>*/}
        {/*<DropdownMenuItem disabled>*/}
        {/*  <Cloud className="mr-2 h-4 w-4" />*/}
        {/*  <span>API</span>*/}
        {/*</DropdownMenuItem>*/}
        <DropdownMenuSeparator className={'border-t-scale-100 dark:border-t-scale-700 border-t'} />
        <DropdownMenuItem className={"cursor-pointer hover:bg-scale-200 dark:hover:bg-scale-700"} onClick={handleLogout}>
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
          {/*<DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>*/}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
