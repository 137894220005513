const Logo = (props) => {
  return (
    <svg
      {...props}
      width="100%"
      height="100%"
      viewBox="0 0 1000 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g id="Icon" transform="matrix(1,0,0,1,-20.0248,0)">
        <g transform="matrix(0.144304,0,0,0.144304,-27.229,-73.1653)">
          <path
            style={{ fillRule: "nonzero", fill: "currentColor" }}
            d="M1573.95,944.614C1567.23,962.683 1568.17,962.892 1546.32,962.892L1215.41,962.916C1081.34,962.922 1034.36,982.318 963.739,1105.78C939.928,1147.41 916.001,1189.18 892.567,1230.68L820.882,1357.17C717.422,1357.66 788.899,1265.2 812.056,1226.51C841.153,1177.9 870.522,1129.19 899.044,1079.28C997.158,907.575 1023.38,886.988 1238.32,886.883L1505.59,886.901C1533.17,886.827 1563.42,889.154 1574.95,908.884C1581.59,920.244 1578.12,933.415 1573.95,944.614ZM1286.68,1039.31C1362.78,1039.33 1448.08,1035.68 1523.05,1039.71C1507.7,1077.51 1459.6,1146.85 1424.22,1174.3C1366.21,1219.31 1305.63,1208.22 1220.5,1208.19C1123.55,1208.15 1112.88,1233.77 1074.15,1295.15C1028.8,1367.02 1037.07,1365.17 905.854,1361.24C907.012,1351.55 979.182,1234.21 990.861,1214.36C1094.98,1037.39 1082.47,1039.27 1286.68,1039.31ZM1482.77,1227.35C1497.22,1211.35 1507.98,1203.06 1522.79,1181.21C1550.72,1140 1596.87,1059.6 1622.47,1016.17C1644.61,978.602 1668.19,934.874 1647.23,882.388C1631.34,842.593 1593.44,817.94 1540.25,811.399C1506.07,807.5 1286.51,810.226 1233.23,810.208C1124.79,810.169 1050.57,807.009 971.624,859.134C906.987,901.811 880.337,960.277 836.55,1034.61C798.054,1099.97 759.599,1163.78 720.919,1229.5C701.991,1261.65 679.385,1301.34 691.733,1349.12C717.9,1450.39 840.672,1435.31 915.044,1435.36C951.005,1435.38 1035.76,1440.24 1062.47,1428.58C1095.65,1414.1 1111.68,1375.61 1131.37,1342.13C1155.24,1301.56 1166.88,1284.5 1228.12,1284.55C1322.71,1284.64 1380.99,1296.59 1459.02,1244.71C1467.58,1239.02 1474.82,1231.99 1482.77,1227.35Z" />
        </g>
        <g transform="matrix(0.144304,0,0,0.144304,-27.229,-73.1653)">
          <path
            style={{ fillRule: "nonzero", fill: "currentColor" }}
            d="M1658.55,1098.08C1645.09,1118.98 1632.24,1139.69 1618.91,1160.25C1596.11,1195.42 1562.29,1254.04 1536.11,1277.97C1524.47,1297.52 1492.6,1323.95 1468.39,1338.07C1371.93,1394.37 1242.79,1335.36 1189.98,1382.98C1175.9,1395.68 1172.46,1404.77 1162.38,1421.56C1106.42,1514.74 1128.71,1512.51 996.499,1512.44C924.156,1512.4 811.093,1524.14 765.533,1485.52C763.774,1525.78 798.101,1559.51 824.465,1572.73C864.883,1593 908.787,1589.02 958.317,1589.09C999.103,1589.14 1070.71,1593.27 1107.97,1588.12C1197.77,1575.71 1187.48,1448.13 1259.11,1442.14C1306.29,1438.2 1360.3,1446.02 1407.64,1440.47C1476.63,1432.38 1536.73,1399.24 1577.54,1347.97C1612.9,1303.57 1707.77,1157.83 1711.33,1113.17C1713.97,1080.15 1705.15,1043.65 1686.32,1027.41C1679.48,1041.23 1683.32,1059.6 1658.55,1098.08Z" />
        </g>
      </g>
      <g transform="matrix(1.13113,0,0,1.13113,-87.0089,-33.1597)">
        <g transform="matrix(97.5916,0,0,97.5916,298,145)">
          <path
            d="M0.071,0.22L0.071,-0.528L0.203,-0.528L0.205,-0.464C0.222,-0.485 0.246,-0.503 0.276,-0.517C0.306,-0.531 0.338,-0.538 0.371,-0.538C0.417,-0.538 0.458,-0.526 0.494,-0.503C0.53,-0.48 0.558,-0.447 0.579,-0.406C0.6,-0.365 0.61,-0.318 0.61,-0.265C0.61,-0.212 0.6,-0.165 0.579,-0.124C0.558,-0.083 0.53,-0.05 0.494,-0.027C0.458,-0.003 0.417,0.009 0.371,0.009C0.34,0.009 0.309,0.003 0.28,-0.011C0.251,-0.023 0.227,-0.04 0.209,-0.06L0.209,0.22L0.071,0.22ZM0.341,-0.109C0.382,-0.109 0.415,-0.124 0.44,-0.153C0.465,-0.182 0.478,-0.219 0.478,-0.265C0.478,-0.31 0.465,-0.347 0.44,-0.377C0.415,-0.405 0.382,-0.42 0.341,-0.42C0.3,-0.42 0.267,-0.406 0.242,-0.377C0.217,-0.348 0.204,-0.311 0.204,-0.265C0.204,-0.219 0.217,-0.182 0.242,-0.153C0.267,-0.124 0.3,-0.109 0.341,-0.109Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,361.142,145)">
          <path
            d="M0.27,0.01C0.226,0.01 0.186,-0.002 0.151,-0.026C0.116,-0.05 0.088,-0.083 0.067,-0.124C0.046,-0.165 0.035,-0.212 0.035,-0.265C0.035,-0.319 0.046,-0.367 0.067,-0.408C0.088,-0.449 0.116,-0.481 0.153,-0.504C0.189,-0.527 0.23,-0.539 0.276,-0.539C0.313,-0.539 0.346,-0.532 0.374,-0.517C0.402,-0.501 0.424,-0.482 0.441,-0.458L0.441,-0.528L0.577,-0.528L0.577,-0L0.44,-0L0.44,-0.071C0.421,-0.048 0.397,-0.028 0.368,-0.013C0.338,0.002 0.305,0.01 0.27,0.01ZM0.307,-0.109C0.348,-0.109 0.381,-0.123 0.406,-0.152C0.431,-0.181 0.444,-0.218 0.444,-0.265C0.444,-0.312 0.431,-0.349 0.406,-0.378C0.381,-0.407 0.348,-0.421 0.307,-0.421C0.267,-0.421 0.235,-0.407 0.21,-0.378C0.185,-0.349 0.172,-0.312 0.172,-0.265C0.172,-0.218 0.185,-0.181 0.21,-0.152C0.235,-0.123 0.267,-0.109 0.307,-0.109Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,424.381,145)">
          <path
            d="M0.154,-0.638C0.127,-0.638 0.107,-0.645 0.092,-0.658C0.077,-0.671 0.069,-0.69 0.069,-0.713C0.069,-0.736 0.077,-0.754 0.092,-0.768C0.107,-0.782 0.127,-0.789 0.154,-0.789C0.181,-0.789 0.201,-0.782 0.216,-0.769C0.231,-0.756 0.238,-0.737 0.238,-0.713C0.238,-0.691 0.231,-0.673 0.216,-0.659C0.201,-0.645 0.18,-0.638 0.154,-0.638ZM0.086,-0L0.086,-0.528L0.223,-0.528L0.223,-0L0.086,-0Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,454.147,145)">
          <path
            d="M0.071,-0L0.071,-0.528L0.203,-0.528L0.205,-0.441C0.222,-0.47 0.245,-0.493 0.274,-0.512C0.302,-0.53 0.332,-0.539 0.363,-0.539C0.375,-0.539 0.386,-0.538 0.397,-0.537C0.407,-0.535 0.416,-0.533 0.423,-0.53L0.386,-0.38C0.379,-0.384 0.369,-0.387 0.358,-0.39C0.347,-0.393 0.335,-0.394 0.324,-0.394C0.291,-0.394 0.264,-0.383 0.242,-0.361C0.219,-0.338 0.208,-0.31 0.208,-0.276L0.208,-0L0.071,-0Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,496.404,145)">
          <path
            d="M0.071,0.22L0.071,-0.528L0.203,-0.528L0.205,-0.464C0.222,-0.485 0.246,-0.503 0.276,-0.517C0.306,-0.531 0.338,-0.538 0.371,-0.538C0.417,-0.538 0.458,-0.526 0.494,-0.503C0.53,-0.48 0.558,-0.447 0.579,-0.406C0.6,-0.365 0.61,-0.318 0.61,-0.265C0.61,-0.212 0.6,-0.165 0.579,-0.124C0.558,-0.083 0.53,-0.05 0.494,-0.027C0.458,-0.003 0.417,0.009 0.371,0.009C0.34,0.009 0.309,0.003 0.28,-0.011C0.251,-0.023 0.227,-0.04 0.209,-0.06L0.209,0.22L0.071,0.22ZM0.341,-0.109C0.382,-0.109 0.415,-0.124 0.44,-0.153C0.465,-0.182 0.478,-0.219 0.478,-0.265C0.478,-0.31 0.465,-0.347 0.44,-0.377C0.415,-0.405 0.382,-0.42 0.341,-0.42C0.3,-0.42 0.267,-0.406 0.242,-0.377C0.217,-0.348 0.204,-0.311 0.204,-0.265C0.204,-0.219 0.217,-0.182 0.242,-0.153C0.267,-0.124 0.3,-0.109 0.341,-0.109Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,559.545,145)">
          <path
            d="M0.071,-0L0.071,-0.528L0.203,-0.528L0.205,-0.441C0.222,-0.47 0.245,-0.493 0.274,-0.512C0.302,-0.53 0.332,-0.539 0.363,-0.539C0.375,-0.539 0.386,-0.538 0.397,-0.537C0.407,-0.535 0.416,-0.533 0.423,-0.53L0.386,-0.38C0.379,-0.384 0.369,-0.387 0.358,-0.39C0.347,-0.393 0.335,-0.394 0.324,-0.394C0.291,-0.394 0.264,-0.383 0.242,-0.361C0.219,-0.338 0.208,-0.31 0.208,-0.276L0.208,-0L0.071,-0Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,599.656,145)">
          <path
            d="M0.316,0.01C0.262,0.01 0.214,-0.002 0.172,-0.026C0.13,-0.049 0.097,-0.082 0.073,-0.123C0.049,-0.164 0.037,-0.212 0.037,-0.265C0.037,-0.318 0.049,-0.365 0.073,-0.406C0.097,-0.447 0.13,-0.48 0.172,-0.504C0.214,-0.527 0.262,-0.539 0.316,-0.539C0.369,-0.539 0.416,-0.527 0.458,-0.504C0.5,-0.48 0.533,-0.447 0.557,-0.406C0.581,-0.365 0.593,-0.318 0.593,-0.265C0.593,-0.212 0.581,-0.164 0.557,-0.123C0.533,-0.082 0.5,-0.049 0.458,-0.026C0.416,-0.002 0.369,0.01 0.316,0.01ZM0.316,-0.109C0.343,-0.109 0.367,-0.116 0.388,-0.13C0.409,-0.143 0.426,-0.162 0.438,-0.185C0.449,-0.208 0.455,-0.235 0.455,-0.265C0.455,-0.295 0.449,-0.322 0.438,-0.345C0.426,-0.368 0.409,-0.387 0.388,-0.4C0.367,-0.413 0.343,-0.42 0.316,-0.42C0.289,-0.42 0.264,-0.413 0.243,-0.4C0.222,-0.387 0.205,-0.368 0.193,-0.345C0.181,-0.322 0.175,-0.295 0.175,-0.265C0.175,-0.235 0.181,-0.208 0.193,-0.185C0.205,-0.162 0.222,-0.143 0.243,-0.13C0.264,-0.116 0.289,-0.109 0.316,-0.109Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,661.138,145)">
          <path
            d="M0.311,0.23C0.27,0.23 0.23,0.224 0.19,0.212C0.149,0.199 0.116,0.183 0.091,0.164L0.139,0.065C0.16,0.08 0.184,0.092 0.213,0.101C0.241,0.11 0.27,0.114 0.299,0.114C0.402,0.114 0.454,0.065 0.454,-0.032L0.454,-0.073C0.437,-0.052 0.414,-0.034 0.385,-0.02C0.355,-0.005 0.324,0.002 0.291,0.002C0.242,0.002 0.199,-0.01 0.161,-0.033C0.122,-0.056 0.092,-0.087 0.07,-0.128C0.048,-0.168 0.037,-0.214 0.037,-0.266C0.037,-0.319 0.048,-0.366 0.07,-0.407C0.091,-0.448 0.121,-0.481 0.159,-0.504C0.197,-0.527 0.24,-0.539 0.287,-0.539C0.322,-0.539 0.355,-0.532 0.386,-0.518C0.416,-0.503 0.44,-0.486 0.458,-0.466L0.46,-0.528L0.591,-0.528L0.591,-0.039C0.591,0.047 0.566,0.113 0.517,0.16C0.467,0.207 0.398,0.23 0.311,0.23ZM0.315,-0.11C0.358,-0.11 0.393,-0.124 0.42,-0.153C0.446,-0.182 0.459,-0.219 0.459,-0.265C0.459,-0.312 0.446,-0.349 0.419,-0.379C0.392,-0.407 0.358,-0.422 0.315,-0.422C0.273,-0.422 0.239,-0.407 0.213,-0.378C0.186,-0.349 0.173,-0.311 0.173,-0.265C0.173,-0.219 0.186,-0.182 0.213,-0.153C0.239,-0.124 0.273,-0.11 0.315,-0.11Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,725.646,145)">
          <path
            d="M0.071,-0L0.071,-0.528L0.203,-0.528L0.205,-0.441C0.222,-0.47 0.245,-0.493 0.274,-0.512C0.302,-0.53 0.332,-0.539 0.363,-0.539C0.375,-0.539 0.386,-0.538 0.397,-0.537C0.407,-0.535 0.416,-0.533 0.423,-0.53L0.386,-0.38C0.379,-0.384 0.369,-0.387 0.358,-0.39C0.347,-0.393 0.335,-0.394 0.324,-0.394C0.291,-0.394 0.264,-0.383 0.242,-0.361C0.219,-0.338 0.208,-0.31 0.208,-0.276L0.208,-0L0.071,-0Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,765.757,145)">
          <path
            d="M0.27,0.01C0.226,0.01 0.186,-0.002 0.151,-0.026C0.116,-0.05 0.088,-0.083 0.067,-0.124C0.046,-0.165 0.035,-0.212 0.035,-0.265C0.035,-0.319 0.046,-0.367 0.067,-0.408C0.088,-0.449 0.116,-0.481 0.153,-0.504C0.189,-0.527 0.23,-0.539 0.276,-0.539C0.313,-0.539 0.346,-0.532 0.374,-0.517C0.402,-0.501 0.424,-0.482 0.441,-0.458L0.441,-0.528L0.577,-0.528L0.577,-0L0.44,-0L0.44,-0.071C0.421,-0.048 0.397,-0.028 0.368,-0.013C0.338,0.002 0.305,0.01 0.27,0.01ZM0.307,-0.109C0.348,-0.109 0.381,-0.123 0.406,-0.152C0.431,-0.181 0.444,-0.218 0.444,-0.265C0.444,-0.312 0.431,-0.349 0.406,-0.378C0.381,-0.407 0.348,-0.421 0.307,-0.421C0.267,-0.421 0.235,-0.407 0.21,-0.378C0.185,-0.349 0.172,-0.312 0.172,-0.265C0.172,-0.218 0.185,-0.181 0.21,-0.152C0.235,-0.123 0.267,-0.109 0.307,-0.109Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
        <g transform="matrix(97.5916,0,0,97.5916,828.996,145)">
          <path
            d="M0.07,-0L0.07,-0.528L0.202,-0.528L0.204,-0.453C0.223,-0.478 0.246,-0.499 0.274,-0.515C0.302,-0.53 0.331,-0.538 0.362,-0.538C0.4,-0.538 0.432,-0.53 0.457,-0.514C0.482,-0.497 0.503,-0.471 0.519,-0.436C0.538,-0.467 0.565,-0.492 0.598,-0.511C0.631,-0.529 0.665,-0.538 0.7,-0.538C0.821,-0.538 0.881,-0.465 0.881,-0.32L0.881,-0L0.744,-0L0.744,-0.31C0.744,-0.383 0.714,-0.42 0.654,-0.42C0.622,-0.42 0.596,-0.41 0.575,-0.39C0.554,-0.37 0.543,-0.345 0.543,-0.315L0.543,-0L0.406,-0L0.406,-0.312C0.406,-0.347 0.398,-0.373 0.383,-0.392C0.368,-0.411 0.346,-0.42 0.317,-0.42C0.286,-0.42 0.26,-0.41 0.239,-0.39C0.218,-0.37 0.207,-0.346 0.207,-0.317L0.207,-0L0.07,-0Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }}
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
