import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import FeedNavigation from "@/components/nav/feed-navigation";
import useRequiresAuth from "@/hooks/use-requires-auth";
import ApplyNowModal from "@/components/apply-now-modal";

export default function TasksLayout() {
  const { pathname } = useLocation();
  const { showModal } = useRequiresAuth();


  return (
    <div className={`bg-transparent`}>
      <FeedNavigation />
      <div className="w-full flex justify-center">
        <div className="justify-center max-w-6xl w-full">
          <div className="px-8 py-8">
            <Outlet />
          </div>
        </div>
      </div>
      {
        showModal && (
          <ApplyNowModal />
        )
      }
    </div>
  );
}
