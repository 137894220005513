export enum AppRoute {
  Home = '/',
  Feed = '/feed',
  Login = '/login',
  Register = '/register',
  Chat = '/chat',
  Project = '/project',
  User = '/user',
  Settings = '/settings',
  Post = '/post',
  Join = '/join',
  Session = '/session',
  Task = '/task',
  Apply = '/apply',
  PrivacyPolicy = '/privacy-policy',
  TermsOfService = '/terms-of-service',
  NotFound = '/not-found',
  Manifesto = '/manifesto',
}

export const AuthRoutes = [
  AppRoute.Chat,
  AppRoute.Settings,
  AppRoute.Session,
  AppRoute.Task,
  AppRoute.Project,
]
