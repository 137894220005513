// ... (same imports for Apollo hooks, toast, GraphQL queries etc.)

import { SessionContext } from "@/store/context/SessionContext";
import { useContext } from "react";
import toast from "react-hot-toast";
import { SessionActions } from "@/store/actions/session.actions";
import { useCreateVideoSessionTokenMutation } from "@pairprogram/graphql";
import mixpanel from "@/analytics";
import { AnalyticsEvent } from "@/analytics/analytics-event";
import { AuthContext } from "@/store/context/AuthContext";

export const useCreateVideoSession = () => {

  const { state: session, dispatch } = useContext(SessionContext);
  const { state: auth } = useContext(AuthContext);

  const [createVideoSessionTokenMutation, { data: createVideoSessionData }] = useCreateVideoSessionTokenMutation({
    onCompleted: (data) => {
      if (data?.CreateVideoSessionToken?.error) {
        return toast.error("Error joining session", { icon: "🚨" });
      }

      // Assuming `dispatch` is from your component's state management logic
      dispatch({ type: SessionActions.SessionJoined, payload: data?.CreateVideoSessionToken?.data?.token });
      mixpanel.track(AnalyticsEvent.SentChatMessage, {
        sessionStartedBy: auth?.user?.username,
      });
    },
    onError: (error) => {
      return toast.error("Unknown error joining session", { icon: "🚨" });
    },
    variables: {
      input: {
        roomName: '',  // Placeholder, to be set when function is called
        participantName: ''  // Placeholder, to be set when function is called
      }
    }
  });

  const createVideoSessionToken = ({ roomId, username }: { roomId: string, username: string }) => {
    createVideoSessionTokenMutation({
      variables: {
        input: {
          roomName: roomId,
          participantName: username
        }
      }
    });
  };

  return {
    createVideoSessionToken,
    createVideoSessionData
  };
};
