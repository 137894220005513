import { Button } from "@/components/ui";
import { Icons } from "@/assets/icons";
import { useNavigate } from "react-router-dom";
import React from "react";
import { FlameIcon } from "lucide-react";
import { AppRoute } from "@/config/routes";

export default function NewPostButton() {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(AppRoute.Home)} className={"w-full shrink-0 font-mono text-xs"} size={"sm"}
            variant={"default"}>
      <FlameIcon className={"hidden sm:block h-4 w-4 text-scale-25 dark:text-scale-950 mr-1"} />
      <span className={"shrink-0"}>Share a progress update</span>
    </Button>
  );
}
