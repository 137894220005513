import { Theme } from "@/index";
import { LocalStorage } from "@pairprogram/common";
import { Chat, Post, Tag, User } from "@pairprogram/graphql";

export interface AppState {
  theme: Theme;
  isOnboardingModalOpen: boolean;
  displayLoginModal: boolean;
}

export const initialAppState: AppState = {
  theme: localStorage.getItem(LocalStorage.Theme) as Theme,
  isOnboardingModalOpen: false,
  displayLoginModal: false,
};

export interface AuthState {
  isAuthenticated: boolean;
  isAdmin: boolean;
  user?: User | null;
  roles: string[]
  accessToken?: string | null;
  refreshToken?: string | null;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isAdmin: false,
  user: null,
  roles: [],
  accessToken: null,
  refreshToken: null
};

export interface ChatState {
  selectedChatIndex: number;
}

export const initialChatState: ChatState = {
  selectedChatIndex: 0,
}

export interface SessionState {
  sessionToken?: string | null;
  post?: Post | null;
  chat?: Chat | null;
}

export const initialSessionState: SessionState = {
  sessionToken: null,
  post: null
};

export interface DashboardState {
  selectedTagFilters: Tag[];
}

export const initialDashboardState: DashboardState = {
  selectedTagFilters: [],
}
