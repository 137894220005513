import React from "react";
import ActivityFeedV2 from "@/components/activity-feed-v2";
import FeedSidebarProfile from "@/components/feed/feed-sidebar-profile";
import { authStore } from "@/store";
import FeedSidebarLogin from "@/components/feed/feed-sidebar-login";
import { Button } from "@/components/ui";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/config/routes";

export default function FeedSidebar() {
  const navigate = useNavigate();
  const {
    getAccessToken,
  } = authStore();

  const accessToken = getAccessToken();

  return (
    <div className="top-20 sticky flex flex-col space-y-4">
      {
        accessToken ? (
          <FeedSidebarProfile />
        ): (
          <FeedSidebarLogin/>
        )
      }
      {
        !accessToken ? (
          <div>
            <Button
              variant={"subtle"}
              size={"lg"}
              className={"w-full font-mono font-bold text-sm outline-2 text-brand-500 outline-brand-200 hover:outline-brand-300 bg-brand-100 hover:bg-brand-200/80 outline-dashed"}
              onClick={() => navigate(AppRoute.Manifesto)}
            >
              <span>👉 Read Our Manifesto</span>
            </Button>
          </div>
        ): null
      }
      <ActivityFeedV2 />
    </div>
  );
}
