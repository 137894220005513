import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import React, { useContext, useState } from "react";
import { Button } from "@/components/ui";
import { Icons } from "@/assets/icons";
import FeedEmpty from "@/app/dashboard/home/feed-empty";
import UserCard from "@/components/users/user-card";
import useGetUsers from "@/hooks/use-get-users";
import UserSkeleton from "@/app/dashboard/users/skeleton";
import UsersEmpty from "@/app/dashboard/users/empty";
import { AuthContext } from "@/store/context/AuthContext";
import { authStore } from "@/store";
import { useSearchParams } from "react-router-dom";

export default function UsersPage() {
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const { users, fetchMore, loading, refetch, count: totalUserCount } = useGetUsers(limit, offset);

  if (loading) {
    return (
      <div>
        <DashboardPageHeadingSection title={"Users"} description={'Browse user profiles from the community'} />
        <ul className="space-y-6">
          {
            Array.from({ length: 10 }).map((_, i) => (
              <li key={i}>
                <UserSkeleton />
              </li>
            ))
          }
        </ul>
      </div>
    );
  }

  if (!loading && !totalUserCount) {
    return (
      <div>
        <DashboardPageHeadingSection title={"Users"} description={'Browse user profiles from the community'} />
        <div>
          <UsersEmpty />
        </div>
      </div>
    );
  }

  return (
    <div>
      <DashboardPageHeadingSection title={"Users"} description={'Browse user profiles from the community'} />
      <div className={"py-2"}>
        <ul className={"space-y-6"}>
          {users.map((user, key) => (
            <li key={key}>
              <UserCard user={user} />
            </li>
          ))}
          {
            offset + limit < totalUserCount ? (
              <li key={"load-more-users"} className="px-4 py-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-center">
                  <Button
                    className={"font-mono text-xs"}
                    size={"sm"} variant={"ghost"}
                    onClick={() => {
                      setOffset(offset + limit);
                      fetchMore({
                        variables: {
                          input: {
                            limit,
                            offset: offset + limit
                          }
                        }
                      });
                    }}
                  >
                    <Icons.LoadMore className={"h-3 w-3 mr-2"} />
                    Load more
                  </Button>
                </div>
              </li>
            ) : null
          }
        </ul>
      </div>

    </div>
  );
}

