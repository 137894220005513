import { Action } from "@/store/reducers/index";
import { ChatActions } from "@/store/actions/chat.actions";
import { ChatState } from "@/store/initial-state";


const chatReducer = (state: ChatState, action: Action) => {
  switch (action.type) {
    case ChatActions.SetSelectedChatIndex:
      return {
        ...state,
        selectedChatIndex: action.payload,
      };
    default:
      return state;
  }
};

export default chatReducer;
