import * as React from "react";
import { cn, navigateAccordingly } from "@/utils";
import { MobileNav } from "@/components/nav/mobile-nav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icons } from "@/assets/icons";
import { authStore } from "@/store";
import { useContext } from "react";
import { AuthContext } from "@/store/context/AuthContext";

interface MainNavProps {
  items?: any //MainNavItem[];
  children?: React.ReactNode;
}

export function MainNav({ items, children }: MainNavProps) {
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);
  const segment = 'FIX_ME';

  const { isRefreshTokenExpired } = authStore();
  const { state: auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const userLoggedIn = auth?.isAuthenticated && !isRefreshTokenExpired();

  return (
    <div className="flex justify-start grow gap-6 md:gap-10">
      <button onClick={() => navigateAccordingly({ userLoggedIn, navigate, location })} className="hidden self-start items-center cursor-pointer space-x-2 md:flex">
        <Icons.Logo className={"h-4 max-w-[200px] sm:h-8 mx-auto text-scale-950 dark:text-scale-25"} />
      </button>
      {items?.length ? (
        <nav className="hidden gap-6 md:flex">
          {items?.map((item, index) => (
            <Link
              key={index}
              to={item.disabled ? "#" : item.href}
              className={cn(
                "flex items-center text-lg font-semibold text-scale-600 dark:text-scale-200 sm:text-sm",
                item.href.startsWith(`/${segment}`) &&
                "text-scale-950 dark:text-scale-25",
                item.disabled && "cursor-not-allowed opacity-80"
              )}
            >
              {item.title}
            </Link>
          ))}
        </nav>
      ) : null}
      <div className="flex items-center">
        <button
          className="flex items-center space-x-2 md:hidden"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <div><Icons.Menu className={"h-8 w-8 text-scale-950 dark:text-scale-25"} /></div>
          <span className="sr-only">Menu</span>
          {showMobileMenu ? <Icons.Close className={"text-scale-950 dark:text-scale-25"} /> :
            <Icons.BrandIcon className={"h-8 w-8 text-scale-950 dark:text-scale-25"} />}
        </button>
        {showMobileMenu && items && (
          <MobileNav items={items}>{children}</MobileNav>
        )}
      </div>

    </div>
  );
}
