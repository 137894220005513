import { Button } from "@/components/ui";
import { cn } from "@/utils";
import React from "react";
import { FlameIcon, SquareUserRound } from "lucide-react";
import { PostFilterEnum } from "@pairprogram/graphql";
import useRequiresAuth from "@/hooks/use-requires-auth";
import { authStore } from "@/store";

export interface ISortFilterItem {
  type: "FILTER" | "SORT";
  icon: any;
  value: string;
  label: string;
  current: boolean;
  requiresAuth: boolean;
}

export default function FilterSortBar({ items, filter, setFilter, sort, setSort }) {
  const { isAuthenticated } = useRequiresAuth();

  return (
    <div className={"py-2"}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          // add on select to set filter
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          // @ts-ignore
          defaultValue={items?.find((filter) => filter.current!).value}
        >
          {items.map((tab) => {
            if (tab.requiresAuth && !isAuthenticated) return null;
            return (
              <option value={tab.value} key={tab.value}>{tab.label}</option>
            );
          })}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {items.map((filter) => {
            const FilterIcon = filter.icon;
            if (filter.requiresAuth && !isAuthenticated) return null;
            return (
              <Button
                // set filter on click
                onClick={() => {
                  setFilter(filter.value);
                }}
                key={filter.value}
                variant={"ghost"}
                size={"sm"}
                className={cn(
                  filter.current ? "bg-gray-200 text-gray-700" : "text-gray-500 hover:text-gray-700",
                  " font-medium"
                )}
                aria-current={filter.current ? "page" : undefined}
              >
                <div className={"flex items-center space-x-2"}>
                  {/*<span><FilterIcon className={"h-3 w-3"} /></span>*/}
                  <span>{filter.label}</span>
                </div>
              </Button>
            );
          })}
        </nav>
      </div>
    </div>
  );
}
