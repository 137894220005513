import { X } from "lucide-react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui";
import { UserAuthForm } from "@/components/user-auth-form";
import React, { useContext } from "react";
import useRequiresAuth from "@/hooks/use-requires-auth";
import { AppContext } from "@/store/context/AppContext";
import useGetUsers from "@/hooks/use-get-users";
import { MAX_PRIVATE_BETA_USERS } from "@/config/site";
// import DefaultBanner from "@/assets/images/backgrounds/flux-gradient-blue.svg";
import DefaultBanner from "@/assets/images/backgrounds/pairprogram-banner-commits-alt.jpg";
import { cn } from "@/utils";
import Balancer from "react-wrap-balancer";

export default function ApplyNowModal() {
  const { count } = useGetUsers(0, 1);
  const { handleShowModal } = useRequiresAuth();
  const { state: app } = useContext(AppContext);
  const spotsRemaining = MAX_PRIVATE_BETA_USERS - count >= 0 ? MAX_PRIVATE_BETA_USERS - count : 0;
  return (
    <AlertDialog
      open={app.displayLoginModal}
    >
    <AlertDialogContent className={"p-0 border border-scale-300 sm:border-4 sm:border-brand-100 w-fit bg-white dark:bg-scale-950 dark:border-scale-700"}>
        <X
          className={"absolute top-0 right-0 h-5 w-5 m-2 cursor-pointer text-scale-900"}
          onClick={() => handleShowModal(false)}/>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <div className={cn(
            // "my-4 outline outline-4 outline-brand-100 rounded-lg"
          )}>
            <div
              style={{
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url(${DefaultBanner})`,
              }}
              className={cn(
                "w-full h-32 items-center inline-block bg-white",
                // "bg-gradient-to-r from-red-500 via-purple-500 to-blue-500"
              )}>
              <div className={'h-full flex items-center justify-center'}>
                {/*<div className="rounded-md bg-gradient-to-r from-red-500 via-purple-500 to-blue-500 py-4 px-2">*/}
                <h1 className="text-2xl sm:text-3xl text-center font-semibold text-scale-800 dark:text-scale-25">Build coding habits<br/><span className={'font-black text-black highlight highlight-amber-300'}>together</span><span className={'pl-2'}>🚀</span></h1>
              </div>
            </div>
          </div>
          <div className="pt-4 flex flex-col">
            <h3 className={'text-center text-base sm:text-lg font-bold text-scale-700 dark:text-scale-100'}>Join a community of like-minded developers who are getting 1% better at their craft everyday.</h3>
          </div>
        </AlertDialogTitle>
        <AlertDialogDescription>
          <div className="space-y-4 p-6 text-xs sm:text-sm text-scale-600">
            {/*<p className={'text-center text-sm sm:text-lg text-scale-500'}>Build practical projects, share your journey, and get helpful feedback from other developers.</p>*/}
            <ul
              className={"text-sm sm:text-lg text-scale-500 font-base gap-y-2 divide-y divide-dashed divide-scale-300"}>
              {/*<li className={"py-1.5"}><span className={"mr-2"}>✅</span>Complete recommended tasks</li>*/}
              <li className={"py-1.5"}><span className={"mr-2"}>✅</span>Discover new tasks and challenges</li>
              <li className={"py-1.5"}><span className={"mr-2"}>📣</span>Share what you're working on</li>
              <li className={"py-1.5"}><span className={"mr-2"}>🤝</span>Get feedback & guidance</li>
              <li className={"py-1.5"}><span className={"mr-2"}>🔧</span>Showcase your projects</li>
              <li className={"py-1.5"}><span className={"mr-2"}>📈</span>Track your progress</li>
            </ul>
          </div>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <div className="p-6 w-full">
          <UserAuthForm className={"w-full h-16 sm:text-lg font-heading text-sm"} size={"default"} text={"Join w/ GitHub"} />
          <div className={"flex justify-center font-mono font-bold pt-4 text-base text-scale-600"}>
            <p><span className={"highlight highlight-variant-9 highlight-amber-300"}>{spotsRemaining}</span> spots
              remaining</p>
          </div>
          <div className={"flex justify-center font-mono font-bold pt-4 text-base text-scale-600"}>
            <a
              type="button"
              href={`/api/v1/auth/github`}>
              <p className={"underline text-xs sm:text-base text-center"}>Already a member? Click here to sign in.</p>
            </a>
          </div>
        </div>
        {/*<AlertDialogCancel*/}
        {/*  className={'w-full'}*/}
        {/*  onClick={() => displayLoginModal(false)}*/}
        {/*>Cancel</AlertDialogCancel>*/}
        {/*<AlertDialogAction onClick={() => console.log("sending...")}>Send</AlertDialogAction>*/}
      </AlertDialogFooter>
    </AlertDialogContent>
    </AlertDialog>
  )
}
