import React, { useEffect, useReducer } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { authStore } from "@/store";
import '@/analytics';
import combineReducers from "@/store/reducers";
import authReducer from "@/store/reducers/auth-reducer";
import {
  AppState,
  AuthState,
  ChatState,
  initialAppState,
  initialAuthState,
  initialChatState,
  initialDashboardState,
  initialSessionState,
  SessionState
} from "@/store/initial-state";
import appReducer from "@/store/reducers/app-reducer";
import { AppContext } from "@/store/context/AppContext";
import { AuthContext } from "@/store/context/AuthContext";
import { SessionContext } from "@/store/context/SessionContext";
import {
  Chat,
  ChatMessage,
  ChatPartsFragmentDoc,
  GetChatDocument,
  GetChatsForUserDocument,
  GetChatsForUserQuery,
  GetLatestPlatformEventsDocument,
  GetLatestPlatformEventsQuery,
  OnChatMessageSubscription,
  OnUserHeartbeatSubscription,
  PlatformEvent,
  useGetCurrentUserLazyQuery,
  useGetHeartbeatQuery,
  useOnChatMessageSubscription,
  useOnPlatformEventSubscription,
  useOnUserHeartbeatSubscription,
  UserPartsFragment,
  UserPartsFragmentDoc,
  useUpdateUserStatusMutation,
  Role
} from "@pairprogram/graphql";
import { AuthActions } from "@/store/actions/auth.actions";
import sessionReducer from "@/store/reducers/session-reducer";
import "@livekit/components-styles";
import chatReducer from "@/store/reducers/chat-reducer";
import { ChatContext } from "@/store/context/ChatContext";
import { ApolloClient, SubscriptionResult } from "@apollo/client";
import "./analytics";
import mixpanel from "./analytics";
import { useGetChatsForUser } from "./hooks/use-get-chats-for-user";
import * as Sentry from "@sentry/react";
import dashboardReducer from "@/store/reducers/dashboard-reducer";
import { DashboardContext } from "@/store/context/DashboardContext";
import { hasUnreadMessagesVar } from "@/store/reactive-vars";
import { AppRoute } from "@/config/routes";

export default function App() {
  const [state, dispatch] = useReducer(
    combineReducers({
      app: appReducer,
      auth: authReducer,
      chat: chatReducer,
      dashboard: dashboardReducer,
      session: sessionReducer
    }),
    {
      app: initialAppState,
      auth: initialAuthState,
      chat: initialChatState,
      dashboard: initialDashboardState,
      session: initialSessionState
    }
  );

  const app: AppState = state.app;
  const auth: AuthState = state.auth;
  const chat: ChatState = state.chat;
  const dashboard = state.dashboard;
  const session: SessionState = state.session;

  const {
    getAccessToken,
    getRefreshToken,
    setAccessToken,
    setRefreshToken,
    setRoles,
  } = authStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  const [getCurrentUser, _] = useGetCurrentUserLazyQuery();
  const { getChatsForUserLazy, checkChatsForUnreadMessages } = useGetChatsForUser();
  useGetHeartbeatQuery({
    onError: (error) => {
      console.log("Error checking heartbeat");
      Sentry.captureException(error);
    },
    onCompleted: (data) => {
      console.log("❤️");
    },
    pollInterval: 1000 * 60, // every minute
    skip: !accessToken || !refreshToken
  });

  const [updateUserStatus] = useUpdateUserStatusMutation({
    variables: {
      input: {
        isOnline: true
      }
    }
  });

  useEffect(() => {
    if (accessToken && refreshToken) {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      updateUserStatus();
      getCurrentUser().then((user) => {
        if (user?.data?.GetCurrentUser.data) {
          getChatsForUserLazy({
            onCompleted: (data) => {
              if (data?.GetChatsForUser?.data?.length) {
                const currentUserId = user?.data?.GetCurrentUser?.data?.id ?? "";
                const chats = data?.GetChatsForUser?.data as Chat[];
                checkChatsForUnreadMessages(chats, currentUserId);
              }
            }
          });
          dispatch({ type: AuthActions.Login });
          dispatch({ type: AuthActions.Account, payload: user.data.GetCurrentUser.data });

          mixpanel.identify(user.data.GetCurrentUser.data.id);
          // user is assumed to be authenticated at this point
          const isNotOnboarded = !user.data.GetCurrentUser.data.isOnboarded;
          const isNotRegistered = !user.data.GetCurrentUser.data.isRegistered;
          const roles = user.data.GetCurrentUser?.data?.roles;
          if (roles) {
            setRoles((roles ?? []) as Role[])
          }

          if (isNotOnboarded) {
            navigate(AppRoute.Apply);
          } else if (isNotRegistered) {
            // make sure user is logged out
            // because they may have been logged in before during the onboarding process
            dispatch({ type: AuthActions.Logout });
            navigate(AppRoute.Home);
          } else {
            navigate(pathname);
          }
        }
      });
    }
  }, [accessToken, refreshToken]);

  useOnChatMessageSubscription({
    skip: !accessToken || !refreshToken,
    onData: ({ client, data }: {
      client: ApolloClient<Object>,
      data: SubscriptionResult<OnChatMessageSubscription, any>,

    }) => {

      const newMessage: ChatMessage = data?.data?.OnChatMessage!;
      const chatsForUser = client.readQuery<GetChatsForUserQuery>({
        query: GetChatsForUserDocument
      });

      const updatedChatsForUser = chatsForUser?.GetChatsForUser?.data?.map((chat) => {
        if (chat?.id === newMessage?.chatId) {
          return {
            ...chat,
            MostRecentMessage: {
              ...chat?.MostRecentMessage,
              data: newMessage
            }
          };
        }
        return chat;
      });

      client.cache.writeQuery({
        query: GetChatsForUserDocument,
        data: {
          GetChatsForUser: {
            ...chatsForUser?.GetChatsForUser,
            data: updatedChatsForUser
          }
        }
      });

      const messageIsFromTheLoggedInUser = newMessage?.Sender?.data?.username === auth?.user?.username;
      if (!messageIsFromTheLoggedInUser) {
        hasUnreadMessagesVar(true);
      }

      const currentChat = client.readFragment({
        id: `Chat:${newMessage?.chatId}`,
        fragment: ChatPartsFragmentDoc
      });

      if (currentChat) {
        client.writeFragment({
          id: `Chat:${newMessage?.chatId}`,
          fragment: ChatPartsFragmentDoc,
          data: {
            ...currentChat?.data?.GetChat.data,
            Messages: {
              ...currentChat?.data?.GetChat?.data?.Messages,
              data: [
                ...(currentChat?.data?.GetChat?.data?.Messages?.data ?? []) as ChatMessage[],
                newMessage
              ],
              error: null
            }
          }
        });

        client.writeQuery({
          query: GetChatDocument,
          variables: {
            input: {
              chatId: newMessage?.chatId
            }
          },
          data: {
            GetChat: {
              ...currentChat?.data?.GetChat,
              data: {
                ...currentChat?.data?.GetChat?.data,
                Messages: {
                  ...currentChat?.data?.GetChat?.data?.Messages,
                  data: [
                    ...(currentChat?.data?.GetChat?.data?.Messages?.data ?? []) as ChatMessage[],
                    newMessage
                  ],
                  error: null
                }
              }
            }
          }
        });
      }
    }
  });

  useOnUserHeartbeatSubscription({
    skip: !accessToken || !refreshToken,
    onData: ({ client, data: heartbeat }: {
      client: ApolloClient<Object>,
      data: SubscriptionResult<OnUserHeartbeatSubscription, any>
    }) => {

      const userInCache = client.readFragment({
        id: `User:${heartbeat?.data?.OnUserHeartbeat?.id}`,
        fragment: UserPartsFragmentDoc
      });

      if (userInCache) {
        client.writeFragment<UserPartsFragment>({
          id: `User:${heartbeat?.data?.OnUserHeartbeat?.id}`,
          fragment: UserPartsFragmentDoc,
          data: {
            ...userInCache,
            isOnline: heartbeat?.data?.OnUserHeartbeat?.isOnline
          }
        });
      }

    }
  });

  useOnPlatformEventSubscription({
    skip: !accessToken || !refreshToken,
    onData: ({ client, data: newPlatformEvent }) => {
      const existingPlatformEvents = client.readQuery<GetLatestPlatformEventsQuery>({ query: GetLatestPlatformEventsDocument });
      const newEvent = newPlatformEvent?.data?.OnPlatformEvent!;

      // FIXME: not currently updating activity feed in real-time
      client.writeQuery<GetLatestPlatformEventsQuery>({
        query: GetLatestPlatformEventsDocument,
        data: {
          GetLatestPlatformEvents: {
            error: null,
            data: {
              platformEvents: [
                newEvent,
                ...(existingPlatformEvents?.GetLatestPlatformEvents?.data?.platformEvents as PlatformEvent[] ?? [] as PlatformEvent[])
              ]
            }
          }
        }
      });
    }
  });

  return (
    <AppContext.Provider value={{ state: app, dispatch }}>
      <AuthContext.Provider value={{ state: auth, dispatch }}>
        <ChatContext.Provider value={{ state: chat, dispatch }}>
          <DashboardContext.Provider value={{ state: dashboard, dispatch }}>
            <SessionContext.Provider value={{ state: session, dispatch }}>
              <Outlet /> {/* Routes used to go here */}
            </SessionContext.Provider>
          </DashboardContext.Provider>
        </ChatContext.Provider>
      </AuthContext.Provider>
    </AppContext.Provider>
  );
}

