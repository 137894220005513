import {
  AlertTriangle,
  Angry,
  Annoyed, ArrowLeft,
  ArrowRight, ArrowUpRight,
  Award,
  Bell, Binary, Blocks, BookOpenCheck,
  BookText,
  Briefcase,
  Bug,
  Building2,
  Calendar,
  CalendarCheck,
  Check, CheckCircle2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsUpDown,
  Circle,
  CircleDot,
  Clock,
  Code,
  Code2,
  Copy,
  CopyCheckIcon,
  CornerUpLeft,
  CreditCard, Cuboid,
  Edit,
  ExternalLink,
  Eye,
  EyeOff,
  File,
  FilePlus,
  FileSignature, FileTerminal,
  FileText,
  FileX,
  Filter,
  FlameIcon,
  Frown,
  GitCompareArrows, GitPullRequestArrow,
  Globe, Hash,
  Heart,
  HelpCircle,
  History,
  Image,
  Laugh,
  Layout,
  Link2,
  Linkedin, ListTodo,
  Loader2,
  LogIn,
  LogOut,
  Mailbox,
  MapPin,
  Megaphone,
  Menu,
  MessagesSquare,
  MoreVertical,
  Network,
  PanelTop, Paperclip, PartyPopper,
  Pizza, PlaySquare,
  Plus, PlusCircle, Presentation,
  Radio,
  Rocket,
  RotateCcw,
  SearchIcon,
  SendIcon,
  Settings, Shapes,
  Smile,
  Star,
  Sticker,
  Tag,
  TerminalSquare, TextIcon, ThumbsUpIcon,
  Timer,
  Trash,
  TrendingUp,
  Twitter, UploadCloud,
  User,
  UserPlus,
  Users2,
  VideoIcon,
  X,
  Zap
} from "lucide-react";
import Logo from "@/assets/logos/logo";
import LogoLight from "@/assets/logos/logo-light";
import OneIcon from "@/assets/icons/one-icon";
import TwoIcon from "@/assets/icons/two-icon";
import ThreeIcon from "@/assets/icons/three-icon";
import PairProgramIcon from "@/assets/icons/pairprogram-icon";
import PairProgramIconLight from "@/assets/icons/pairprogram-icon-light";
import Reddit from "@/assets/icons/reddit";
import GitHub from "@/assets/icons/github";
import Discord from "@/assets/icons/discord";
import DiscordOutlineIcon from "@/assets/icons/discord-outline";

export const Icons = {

  // task types
  TaskType: {
    Default: CopyCheckIcon,
    Article: BookText,
    Exercise: FileTerminal,
    Tutorial: Presentation,
    Project: Cuboid,
    SystemsDesign: Blocks,
    ComputerScience: Binary,
    OpenSource: GitPullRequestArrow,
    Video: PlaySquare,
    DailyUpdate: FlameIcon,
    CodeReview: GitCompareArrows,
  },

  Like: ThumbsUpIcon,
  Upload: UploadCloud,
  Logo: ({ ...props }) => Logo(props),
  LogoLight: ({ ...props }) => LogoLight(props),
  BrandIcon: ({ ...props }) => PairProgramIcon(props),
  BrandIconLight: ({ ...props }) => PairProgramIconLight(props),
  Applied: Sticker,
  Celebration: PartyPopper,
  Title: Hash,
  Edit: Edit,
  Badges: Award,
  Bell: Bell,
  Blog: BookText,
  Difficulty: Shapes,
  Tag: Tag,
  Instructions: BookOpenCheck,
  Attachments: Paperclip,
  LearningObjectives: BookText,
  Prerequisites: ListTodo,
  Description: TextIcon,
  // Feedback: Star,
  Flame: FlameIcon,
  Update: FlameIcon,
  Close: X,
  Remove: X,
  Calendar: Calendar,
  View: Eye,
  Goto: ArrowUpRight,
  Spinner: Loader2,
  ChevronDown: ChevronDown,
  ChevronLeft: ChevronLeft,
  ChevronRight: ChevronRight,
  ChevronUpDown: ChevronsUpDown,
  Copy: Copy,
  Circle: Circle,
  TaskComplete: CheckCircle2,
  Star: Star,
  Link: Link2,
  Activity: Radio,
  Feed: Radio,
  Inbox: Mailbox,
  Posts: Code,
  Time: Clock,
  SendMessage: SendIcon,
  Save: PlusCircle,
  Code: Code,
  Video: VideoIcon,
  Sessions: TerminalSquare,
  Feedback2: GitCompareArrows,
  Stats: TrendingUp,
  Milestones: Award,
  LiveNow: CircleDot,
  Events: CalendarCheck,
  Chat: MessagesSquare,
  Sponsored: Heart,
  LoadMore: RotateCcw,
  Smiley: Smile,
  ExternalLink: ExternalLink,
  Dashboard: Layout,
  Filter: Filter,
  Trash: Trash,
  Publish: Rocket,
  Login: LogIn,
  Logout: LogOut,
  Register: UserPlus,
  Unpublish: EyeOff,
  Post: FileText,
  NewPost: FilePlus,
  UpdatedPost: FileSignature,
  DeletedPost: FileX,
  Search: SearchIcon,
  SessionCreated: Code,
  SessionEnded: Code2,
  SessionJoined: TerminalSquare,
  SessionLeft: CornerUpLeft,
  Page: File,
  Profile: Layout,
  YearsOfExperience: Briefcase,
  Company: Building2,
  JobTitle: Network,
  Media: Image,
  Menu: Menu,
  Settings: Settings,
  Billing: CreditCard,
  Ellipsis: MoreVertical,
  Add: Plus,
  Warning: AlertTriangle,
  User: User,
  Users: Users2,
  ArrowRight: ArrowRight,
  ArrowLeft: ArrowLeft,
  Back: ArrowLeft,
  Website: PanelTop,
  LinkedIn: Linkedin,
  Help: HelpCircle,
  Pizza: Pizza,
  GitHub: GitHub,
  Discord: ({ ...props }) => Discord(props),
  DiscordOutline: ({ ...props }) => DiscordOutlineIcon(props),
  Twitter: Twitter,
  Reddit: Reddit,
  Check: Check,
  Clock: Clock,
  Globe: Globe,
  LastOnline: History,
  Location: MapPin,
  Duration: Timer,
  One: OneIcon,
  Two: TwoIcon,
  Three: ThreeIcon,
  Zap: Zap,
  Feedback: Megaphone,
  Bug: Bug,
  FeedbackFive: Laugh,
  FeedbackFour: Smile,
  FeedbackThree: Annoyed,
  FeedbackTwo: Frown,
  FeedbackOne: Angry,
  Task: CopyCheckIcon,
  Project: Cuboid,
};
