import { cn } from "@/utils";
import React from "react";

export default function ActivityFeedLoader() {
  const skeletonArray = [...Array(5)];
  return (
    <div>
      <div className={"text-md text-center font-semibold text-gray-800 pb-2"}>
        Recent activity
      </div>
      <ul
        className={cn(
          "group hover:outline-brand-100 hover:outline-4 hover:outline",
          "border border-scale-300",
          "ring-1 ring-gray-900/5",
          "py-1 divide-y divide-scale-100 border border-scale-300 overflow-hidden bg-white shadow-sm rounded-xl"
        )}
      >
        {skeletonArray.map((_, i) => {
          return (
            <li
              key={i}
              className="w-full py-1 hover:bg-gray-50 px-4">
              <div className="my-0.5 flex justify-between min-w-0">
                <div className="flex items-center space-x-3">
                  <div className="h-4 w-4 bg-gray-200 rounded-full animate-pulse" />
                  <div className={cn(
                    "h-4 bg-gray-200 rounded-full animate-pulse",
                    i % 2 === 0 ? "w-32" : "w-16"
                  )} />
                </div>
                <div className="h-4 w-8 bg-gray-200 rounded-full animate-pulse" />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  )
}
