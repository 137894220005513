import { SessionCalendarEntry, Tag, TagType } from "@pairprogram/graphql";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icons } from "@/assets/icons";
import { Avatar, AvatarFallback, AvatarImageWithStatus, Button } from "@/components/ui";
import toast from "react-hot-toast";
import { useStartChat } from "@/hooks/use-start-chat";
import { useGetUser } from "@/hooks/use-get-user";
import useRequiresAuth from "@/hooks/use-requires-auth";
import { AppRoute } from "@/config/routes";
import useLocalizedTime from "@/hooks/use-localized-time";
import { getRelativeTime, Timezone } from "@/utils/datetime.utils";
import BannerImage from "@/assets/images/gradients/mesh-gradient-29.jpg";
import TagBadge from "@/components/tag-badge";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import { cn } from "@/utils";
import { Maybe } from "graphql/jsutils/Maybe";
import ProjectCard from "@/components/projects/project-card";
import ActivityCalendar from "@/components/activity-calendar";
import ButtonGroup from "@/components/button-group";
import { handleScrollToElement } from "@/utils/scroll.utils";
import { handleNavigateToExternalUrl } from "@/utils/navigate.utils";

export default function UserPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { startChat } = useStartChat();

  if (!params?.username) {
    navigate(AppRoute.NotFound);
  }

  const { user, loading } = useGetUser(params?.username as string);
  const { withAuth } = useRequiresAuth();

  const [techTagsForUser, setTechTagsForUser] = React.useState<Tag[]>([]);
  const [trackTagsForUser, setTrackTagsForUser] = React.useState<Tag[]>([]);
  const [topicTagsForUser, setTopicTagsForUser] = React.useState<Tag[]>([]);

  useEffect(() => {
    if (user?.tags?.length) {
      user.tags.filter((tag: Maybe<Tag>) => tag?.type === TagType.Technology);
      const techTags = user?.tags?.filter((tag: Maybe<Tag>) => tag?.type === TagType.Technology) ?? [];
      const trackTags = user?.tags?.filter((tag: Maybe<Tag>) => tag?.type === TagType.Career) ?? [];
      const topicTags = user?.tags?.filter((tag: Maybe<Tag>) => tag!.type as TagType === TagType.Topic) ?? [];
      setTechTagsForUser(techTags as Tag[]);
      setTrackTagsForUser(trackTags as Tag[]);
      setTopicTagsForUser(topicTags as Tag[]);
    }
  }, [user?.tags?.length]);

  const localizedTime = useLocalizedTime(user?.timezone as Timezone);
  const handleStartChat = async () => {
    if (user?.id) {
      const chatId = await startChat({ recipientIds: [user?.id] });
      if (chatId) {
        navigate(`${AppRoute.Chat}/${chatId}`);
      }
    } else {
      toast("Error starting chat", {
        icon: "🚨"
      });
    }
  };

  return (
    <div className={"w-full"}>
      <div className={"pb-4"}>
        <div
          className={"outline-4 outline-indigo-100 relative h-32 lg:h-48 w-full"}>
          <div
            className={"inset-0 absolute w-full bg-no-repeat bg-scale-950 bg-cover bg-center"}
            style={{ backgroundImage: `url(${BannerImage})` }}
          >
            <div className="flex flex-col items-center justify-center w-full h-full">
              <Icons.Logo className={"h-10 sm:h-16 text-white"} />
              {/*<p className={"text-white text-lg ml-2"}>Build job-ready skills </p>*/}
            </div>
          </div>
        </div>
        <div className="space-y-12 px-12 max-w-6xl mx-auto">
          <div className={"border-b border-b-scale-200 pb-4"}>
            <div className="py-4 sm:pt-8 sm:pb-0 flex w-full items-center justify-between">
              <DashboardPageHeadingSection />
            </div>
            <div className="grid grid-cols-3 justify-between space-y-4 sm:space-y-0">
              <div
                className={"col-span-3 sm:col-span-2 flex items-center justify-start space-x-6 py-4 divide-x divide-scale-300"}>
                <div className="flex items-center sm:px-2 space-x-8 max-w-5xl">
                  {/*<UserTypeIcon className={"h-5 w-5 text-scale-500"} />*/}
                  <Avatar className={"h-24 w-24 sm:h-32 sm:w-32 ring-4 ring-indigo-100"}>
                    <AvatarImageWithStatus
                      className={"bg-white"}
                      statusSize={"7"}
                      isOnline={user?.isOnline}
                      src={user?.avatarUrl as string}
                      alt={`${user?.username as string ?? "Anonymous User"}'s Avatar`}
                    />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>
                  <div className={"flex flex-col gap-y-2"}>
                    <h1
                      className="w-fit truncate text-3xl sm:text-5xl font-black text-scale-800 dark:text-scale-25">{user?.username}</h1>
                    <p
                      className={"sm:truncate text-scale-800 dark:text-scale-200"}>{user?.profile?.shortBio ? user?.profile?.shortBio : "Let's pair program!"}</p>
                  </div>
                </div>
              </div>


              <div className="col-span-3 lg:col-span-1 flex items-center sm:justify-end w-full sm:space-x-2">

                <div className="grid grid-cols-3 sm:flex items-center gap-x-2 gap-y-4 sm:gap-y-0 w-full sm:w-fit">
                  {/*<Button*/}
                  {/*  className={cn(*/}
                  {/*    "col-span-3 sm:col-span-1 w-full sm:w-fit",*/}
                  {/*    true && "bg-brand-200/80 hover:bg-brand-200 text-brand-700"*/}
                  {/*  )}*/}
                  {/*  variant={true ? "primary" : "outline"}*/}
                  {/*  size={"sm"}*/}
                  {/*  // onClick={() => toggleReaction()}*/}
                  {/*>*/}
                  {/*  <div className="flex items-center space-x-2">*/}
                  {/*    <Icons.Like className={"h-4 w-4"} />*/}
                  {/*    <span>{true ? "Followed" : "Follow"}</span>*/}
                  {/*  </div>*/}
                  {/*</Button>*/}
                  <Button
                    className={"hidden sm:block"}
                    variant={"default"}
                    size={"sm"}
                    onClick={() => handleNavigateToExternalUrl(`https://github.com/${user?.username}`)}
                  >
                    <div className="flex items-center space-x-2">
                      <Icons.GitHub className={"h-4 w-4"} />
                      <span>View GitHub</span>
                    </div>
                  </Button>

                  <Button
                    onClick={() => withAuth(handleStartChat)}
                    variant={"subtle"}
                    size={"sm"}
                    className={"shrink-0 text-sm col-span-3 sm:col-span-1 w-full sm:w-fit"}
                  >
                    <Icons.Chat className="mr-2 h-3 w-3" aria-hidden="true" />
                    <span className="sr-only">Message</span>
                    <span>Message</span>
                  </Button>

                </div>

              </div>

              <div
                className={"col-span-3 grid grid-cols-3 justify-center py-4 gap-y-4 sm:gap-y-0 sm:gap-x-4 sm:divide-x sm:divide-scale-300"}>
                {
                  <div
                    className="col-span-3 sm:col-span-1 flex items-center sm:justify-center space-x-2 sm:pl-4 text-sm">
                    <div className="flex items-center space-x-2">
                      <Icons.LastOnline className={"h-5 w-5 text-scale-500"} />
                      <p className={"text-scale-500"}>Last online {getRelativeTime(user?.lastOnlineAt)}</p>
                    </div>
                  </div>
                }
                {
                  <div
                    className="col-span-3 sm:col-span-1 flex items-center sm:justify-center space-x-2 sm:pl-4 text-sm">
                    <div className="flex items-center space-x-2">
                      <Icons.Time className={"h-5 w-5 text-scale-500"} />
                      <p className={"text-scale-500"}>Local time is {localizedTime}</p>
                    </div>
                    <span
                      className={"font-normal text-scale-500"}></span>
                  </div>
                }
                {
                  Boolean(user?.profile?.location) && (
                    <div className="col-span-3 sm:col-span-1 flex items-center sm:justify-center space-x-2 text-sm">
                      <div className="flex items-center space-x-2">
                        <Icons.Location className={"h-5 w-5 text-scale-500"} />
                        <p className={"text-scale-500"}>Located in {user?.profile?.location!}</p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          <ButtonGroup items={[
            { label: "Activity", onClick: () => handleScrollToElement("#activity") },
            { label: "About", onClick: () => handleScrollToElement("#about") },
            { label: "Projects", onClick: () => handleScrollToElement("#projects") },
            { label: "Skills & Interests", onClick: () => handleScrollToElement("#skills") }
          ]} />
          <main className="space-y-12 sm:space-y-24 divide-scale-200/80 divide-y">
            {/* ACTIVITY */}
            <div id={"activity"} className="py-6 space-y-6">
              <div className={"space-y-2"}>
                <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Activity Calendar</h2>
                <ActivityCalendar
                  sessions={user?.profile?.stats?.sessionCalendarData! as SessionCalendarEntry[] ?? []}
                  loading={false} />
              </div>
            </div>

            <div id={"about"} className="py-6 grid grid-cols-3 h-full space-y-8 sm:space-y-0 gap-x-12 flex-col-reverse">
              {/* ABOUT */}
              <div className="col-span-3 sm:col-span-2 space-y-6">
                <div className={"space-y-2"}>
                  <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>About</h2>
                </div>

                <div className={"space-y-2 items-center"}>
                  <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Bio</h3>
                  <p className={"text-scale-500 dark:text-scale-400"}>{user?.profile?.bio}</p>
                </div>
              </div>

              {/* LINKS */}
              <div className={"col-span-3 md:col-span-1 space-y-1 items-center"}>
                <div
                  className="min-w-fit bg-scale-50 rounded-md pt-2 pb-4 px-4 space-y-4 outline-1 outline-scale-200 outline">
                  {/*<h2 className={"text-lg text-center font-bold text-scale-900 dark:text-scale-100"}>Links</h2>*/}
                  <h3 className={"text-base font-semibold text-center text-scale-700 dark:text-scale-100"}>Links</h3>
                  <div className="grid grid-cols-1 w-full gap-x-12 gap-y-2 sm:gap-y-4 ">

                    {/* WEBSITE */}
                    {
                      Boolean(user?.profile?.personalWebsiteUrl) && (
                        <div className="col-span-1 flex flex-col items-start">
                          <Button
                            variant={"outline"}
                            size={"sm"}
                            onClick={() => handleNavigateToExternalUrl(user?.profile?.personalWebsiteUrl!)}
                            className={cn(
                              "bg-white col-span-3 sm:col-span-1 w-full"
                            )}
                          >
                            <div className="flex items-center space-x-2">
                              <Icons.Website className={"h-4 w-4"} />
                              <span>View personal website</span>
                              <Icons.Goto className={"h-4 w-4"} />
                            </div>
                          </Button>
                        </div>
                      )
                    }

                    {/* BLOG */}
                    {
                      Boolean(user?.profile?.blogUrl) && (
                        <div className="col-span-1 flex flex-col items-start">
                          <Button
                            variant={"outline"}
                            size={"sm"}
                            onClick={() => handleNavigateToExternalUrl(user?.profile?.blogUrl!)}
                            className={cn(
                              "bg-white col-span-3 sm:col-span-1 w-full"
                            )}
                          >
                            <div className="flex items-center space-x-2">
                              <Icons.Blog className={"h-4 w-4"} />
                              <span>View blog</span>
                              <Icons.Goto className={"h-4 w-4"} />
                            </div>
                          </Button>
                        </div>
                      )
                    }


                    {/* LINKEDIN */}
                    {
                      Boolean(user?.profile?.linkedInUrl) && (
                        <div className="col-span-1 flex flex-col items-start">
                          <Button
                            variant={"outline"}
                            size={"sm"}
                            onClick={() => handleNavigateToExternalUrl(user?.profile?.linkedInUrl!)}
                            className={cn(
                              "bg-white col-span-3 sm:col-span-1 w-full"
                            )}
                          >
                            <div className="flex items-center space-x-2">
                              <Icons.LinkedIn className={"h-4 w-4"} />
                              <span>View LinkedIn</span>
                              <Icons.Goto className={"h-4 w-4"} />
                            </div>
                          </Button>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* PROJECTS */}

            <div id={"projects"} className={"space-y-8 py-6"}>
              <div>
                <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Projects</h2>
              </div>
              <div className="h-full space-y-8 sm:space-y-0 gap-x-12">
                <ul className={"gap-y-4 gap-x-4 grid grid-cols-3"}>
                  {
                    user?.Projects?.data?.map((project, key) => (
                      <li className={"col-span-3 md:col-span-1"} key={key}>
                        <Link to={`${AppRoute.Project}/${project?.id}`}>
                          <ProjectCard project={project!} />
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>

            {/* TAGS */}
            <div id={"skills"} className="col-span-2 grid grid-cols-3 space-y-2 py-6">
              <h2 className={"col-span-3 text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Skills &
                Interests</h2>
              <div className="col-span-3 sm:col-span-1 flex flex-col gap-2 flex-wrap">
                <h3 className={"py-4 text-base font-semibold text-scale-600 dark:text-scale-100"}>Technologies</h3>
                {
                  techTagsForUser.map((tag: Tag, key: number) => (
                    <TagBadge key={key} tag={tag} />
                  ))
                }
              </div>
              <div className="col-span-3 sm:col-span-1 flex flex-col gap-2 flex-wrap">
                <h3 className={"py-4 text-base font-semibold text-scale-600 dark:text-scale-100"}>Tracks</h3>
                {
                  trackTagsForUser.map((tag: Tag, key: number) => (
                    <TagBadge key={key} tag={tag} />
                  ))
                }
              </div>
              <div className="col-span-3 sm:col-span-1 flex flex-col gap-2 flex-wrap">
                <h3 className={"py-4 text-base font-semibold text-scale-600 dark:text-scale-100"}>Topics</h3>
                {
                  topicTagsForUser.map((tag: Tag, key: number) => (
                    <TagBadge key={key} tag={tag} />
                  ))
                }
              </div>
            </div>


            {/*{*/}
            {/*  Boolean(user?.profile?.bio) && (*/}
            {/*    <div className="col-span-2 space-y-8 py-6">*/}
            {/*      <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Prerequisites</h2>*/}
            {/*      <MarkdownRenderer markdownText={user?.profile?.bio!} />*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*}*/}

            {/*{*/}
            {/*Boolean(user?.profile?.bio) && (*/}
            {/*    <div className="col-span-2 space-y-8 py-6">*/}
            {/*      <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Learning Objectives</h2>*/}
            {/*      <MarkdownRenderer markdownText={user?.profile?.bio!} />*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*}*/}

            {/*{*/}
            {/*  Boolean(user?.profile?.bio) && (*/}
            {/*    <div className="col-span-2 space-y-8 py-6">*/}
            {/*      <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Instructions</h2>*/}
            {/*      <MarkdownRenderer markdownText={user?.profile?.bio!} />*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*}*/}

          </main>
        </div>
      </div>
      {/*<ViewAttachmentModal attachments={user?.Uploads?.data as Upload[]} initialIndex={selectedImageIndex}*/}
      {/*                     open={isImageModalOpen} setOpen={setIsImageModalOpen} />*/}
      {/*<UserShareUpdateModal task={task!} open={isShareUpdateModalOpen} setOpen={handleOpenShareUpdateModal} />*/}
      {/*<UserFeedbackModal task={task!} open={isUserFeedbackModalOpen} setOpen={setIsUserFeedbackModalOpen} />*/}
    </div>
    // <div className={"w-full"}>
    //   <div className={"pb-4 sm:pb-0"}>
    //     <div
    //       className={"outline-4 outline-indigo-100 relative h-32 lg:h-48 w-full"}>
    //       <div
    //         className={"inset-0 absolute w-full bg-no-repeat bg-scale-950 bg-cover bg-center"}
    //         style={{ backgroundImage: `url(${BannerImage})` }}
    //       >
    //         <div className="flex flex-col items-center justify-center w-full h-full">
    //           <Icons.Logo className={"h-10 sm:h-16 text-white"} />
    //           {/*<p className={"text-white text-lg ml-2"}>Build job-ready skills </p>*/}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="px-12 max-w-6xl mx-auto">
    //       <div>
    //         <div className="space-y-4 sm:space-y-0 -mt-12 sm:-mt-12 sm:flex sm:items-end sm:space-x-5">
    //           <div className="flex">
    //             <Avatar className={"h-24 w-24 sm:h-32 sm:w-32 ring-4 ring-indigo-100"}>
    //               <AvatarImageWithStatus
    //                 className={"bg-white"}
    //                 statusSize={"7"}
    //                 isOnline={user?.isOnline}
    //                 src={user?.avatarUrl as string}
    //                 alt={`${user?.username as string ?? "Anonymous User"}'s Avatar`}
    //               />
    //               <AvatarFallback></AvatarFallback>
    //             </Avatar>
    //           </div>
    //           <div
    //             className="py-2 sm:py-8 min-h-fit h-24 sm:flex sm:min-w-0 sm:flex-1 sm:items-start sm:justify-end sm:space-x-6 sm:pb-1">
    //             <div className="min-w-0 flex-1 sm:hidden md:block">
    //               <h1
    //                 className="w-fit truncate text-3xl sm:text-5xl font-black text-scale-800 dark:text-scale-25">{user?.username}</h1>
    //               <div>
    //                 <p
    //                   className={"sm:truncate text-scale-800 dark:text-scale-200"}>{user?.profile?.shortBio ? user?.profile?.shortBio : "Let's pair program!"}</p>
    //               </div>
    //             </div>
    //             <div className={"py-4 sm:py-0"}>
    //               <Button
    //                 onClick={() => withAuth(handleStartChat)}
    //                 variant={"default"}
    //                 size={"sm"}
    //                 className={"shrink-0 font-mono text-xs"}
    //               >
    //                 <Icons.Chat className="mr-2 h-3 w-3" aria-hidden="true" />
    //                 <span className="sr-only">Message</span>
    //                 <span>Message</span>
    //               </Button>
    //             </div>
    //
    //             {/*<SocialShare configType={"user"} item={user as User} />*/}
    //           </div>
    //         </div>
    //         <div className="mt-2 hidden min-w-0 flex-1 sm:block md:hidden">
    //           <h1 title={user?.username}
    //               className="truncate text-xl font-bold text-scale-900 dark:text-scale-100">{user?.username}</h1>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* End Heading */}
    //
    //   <main className={"px-12 py-12 space-y-12 max-w-6xl mx-auto"}>
    //
    //     {/* Inner Container  */}
    //     <div className="flex flex-col space-y-24">
    //
    //       {/* Activity Calendar */}
    //       <div className="flex">
    //         <div className="space-y-2 flex flex-col w-full">
    //           <h2 className={"text-xl font-bold text-scale-900 dark:text-scale-100"}>Activity Calendar</h2>
    //           <div className="p-4 rounded-md outline-2 outline-indigo-100 outline">
    //
    //             <ActivityCalendar sessions={user?.profile?.stats?.sessionCalendarData! as SessionCalendarEntry[] ?? []}
    //                               loading={false} />
    //           </div>
    //         </div>
    //       </div>
    //
    //       {/* About Me */}
    //       <div className="flex">
    //         <div className="grid grid-cols-3 w-full space-y-4 md:space-y-0">
    //           <div className={"col-span-3 md:col-span-2 space-y-2 max-w-xl"}>
    //             <h2 className={"text-xl font-bold text-scale-900 dark:text-scale-100"}>About Me</h2>
    //             <p className={"text-scale-500 text-sm font-base"}>{user?.profile?.bio}</p>
    //           </div>
    //
    //           <div className={"col-span-3 md:col-span-1 space-y-1"}>
    //             <h2 className={"text-xl font-bold text-scale-900 dark:text-scale-100"}>Details</h2>
    //             <div className="min-w-fit bg-scale-50/50 rounded-md p-4 outline-2 outline-indigo-100 outline">
    //               <div className="grid grid-cols-2 w-full gap-x-12 gap-y-2 sm:gap-y-4">
    //
    //                 {/* Last Online */}
    //                 <div className="col-span-2 sm:col-span-1 flex flex-col items-start">
    //                   <div className="flex items-center text-xs font-bold pb-1">
    //                     <Icons.LastOnline className={"h-4 w-4 mr-2"} />
    //                     <p>Last Online</p>
    //                   </div>
    //                   <p className={"text-xs text-scale-600 py-1 break-all"}>{getRelativeTime(user?.lastOnlineAt)}</p>
    //                 </div>
    //
    //                 {/* Local Time */}
    //                 <div className="col-span-2 sm:col-span-1 flex flex-col items-start">
    //                   <div className="flex items-center text-xs font-bold pb-1">
    //                     <Icons.Clock className={"h-4 w-4 mr-2"} />
    //                     <p>Local Time</p>
    //                   </div>
    //                   <p className={"text-xs text-scale-600 py-1 break-all"}>{localizedTime}</p>
    //                 </div>
    //
    //                 {/* Location */}
    //                 {
    //                   user?.profile?.location && (
    //                     <div className="col-span-2 sm:col-span-1 flex flex-col items-start">
    //                       <div className="flex items-center text-xs font-bold pb-1">
    //                         <Icons.Location className={"h-4 w-4 mr-2"} />
    //                         <p>Location</p>
    //                       </div>
    //                       <p className={"text-xs text-scale-600 py-1 break-all"}>{user?.profile?.location}</p>
    //                     </div>
    //                   )
    //                 }
    //
    //                 {/* Website */}
    //                 {
    //                   user?.profile?.personalWebsiteUrl && (
    //                     <div className="col-span-2 sm:col-span-1 flex flex-col items-start">
    //                       <div className="flex items-center text-xs font-bold pb-1">
    //                         <Icons.Website className={"h-4 w-4 mr-2"} />
    //                         <p>Website</p>
    //                       </div>
    //                       <p className={"text-xs text-scale-600 py-1 break-all"}><a
    //                         href={`${user?.profile?.personalWebsiteUrl}?ref=pairprogram.com`}
    //                         target={"_blank"}>{removeUrlProtocol(user?.profile?.personalWebsiteUrl)}</a>
    //                       </p>
    //                     </div>
    //                   )
    //                 }
    //
    //                 {/* Blog */}
    //                 {
    //                   user?.profile?.blogUrl && (
    //                     <div className="col-span-2 sm:col-span-1 flex flex-col items-start">
    //                       <div className="flex items-center text-xs font-bold pb-1">
    //                         <Icons.Blog className={"h-4 w-4 mr-2"} />
    //                         <p>Blog</p>
    //                       </div>
    //                       <p className={"text-xs text-scale-600 py-1 break-all"}><a
    //                         href={`${user?.profile?.blogUrl}?ref=pairprogram.com`}
    //                         target={"_blank"}>{removeUrlProtocol(user?.profile?.blogUrl)}</a>
    //                       </p>
    //                     </div>
    //                   )
    //                 }
    //
    //                 {/* LinkedIn */}
    //                 {
    //                   user?.profile?.linkedInUrl && (
    //                     <div className="col-span-2 sm:col-span-1 flex flex-col items-start">
    //                       <div className="flex items-center text-xs font-bold pb-1">
    //                         <Icons.LinkedIn className={"h-4 w-4 mr-2"} />
    //                         <p>LinkedIn</p>
    //                       </div>
    //                       <p className={"text-xs text-scale-600 py-1 break-all"}><a
    //                         href={`${user?.profile?.linkedInUrl}?ref=pairprogram.com`}
    //                         target={"_blank"}>{removeUrlProtocol(user?.profile?.linkedInUrl)}</a>
    //                       </p>
    //                     </div>
    //                   )
    //                 }
    //               </div>
    //             </div>
    //
    //           </div>
    //
    //         </div>
    //       </div>
    //
    //
    //       {/* Tags */}
    //       <div className="flex">
    //         <div className="space-y-2 flex flex-col w-full">
    //           <h2 className={"text-xl font-bold text-scale-900 dark:text-scale-100"}>Skills & Interests</h2>
    //           <div className="grid grid-cols-3 w-full gap-y-4 sm:gap-y-0">
    //             <div className="col-span-3 sm:col-span-1 flex items-start">
    //               <div>
    //                 <h3 className={"py-4 text-base font-semibold text-scale-600 dark:text-scale-100"}>Technologies</h3>
    //                 <div className={"flex flex-wrap gap-2"}>
    //                   {
    //                     techTagsForUser.map((item: Tag, key: number) => {
    //                       return (
    //                         <TagBadge key={key} tag={item} />
    //                       );
    //                     })
    //                   }
    //                 </div>
    //               </div>
    //             </div>
    //
    //
    //             <div className="col-span-3 sm:col-span-1 flex items-start">
    //               <div>
    //                 <h3 className={"py-4 text-base font-semibold text-scale-600 dark:text-scale-100"}>Tracks</h3>
    //                 <div className={"flex flex-wrap gap-2"}>
    //                   {
    //                     trackTagsForUser.map((item: Tag, key: number) => {
    //                       return (
    //                         <TagBadge key={key} tag={item} />
    //                       );
    //                     })
    //                   }
    //                 </div>
    //               </div>
    //             </div>
    //
    //             <div className="col-span-3 sm:col-span-1 flex items-start">
    //               <div>
    //                 <h3 className={"py-4 text-base font-semibold text-scale-600 dark:text-scale-100"}>Topics</h3>
    //                 <div className={"flex flex-wrap gap-2"}>
    //                   {
    //                     topicTagsForUser.map((item: Tag, key: number) => {
    //                       return (
    //                         <TagBadge key={key} tag={item} />
    //                       );
    //                     })
    //                   }
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //
    //
    //       {/* Projects  */}
    //       {
    //         Boolean(user?.Projects?.data?.length) && (
    //           <div className="flex">
    //             <div className="w-full space-y-4 md:space-y-0">
    //               <h2 className={"text-xl font-bold text-scale-900 dark:text-scale-100"}>Projects</h2>
    //               <div className={""}>
    //                 <ul className={"gap-y-4 gap-x-4 grid grid-cols-3"}>
    //                   {
    //                     user?.Projects?.data?.map((project, key) => {
    //                       return (
    //                         <li className={"col-span-3 md:col-span-1"} key={key}>
    //                           <Link to={`${AppRoute.Project}/${project?.id}`}>
    //                             <ProjectCard project={project!} />
    //                           </Link>
    //                         </li>
    //                       );
    //                     })
    //                   }
    //                 </ul>
    //               </div>
    //             </div>
    //           </div>
    //         )
    //       }
    //
    //
    //     </div>
    //
    //   </main>
    // </div>
  );
}
