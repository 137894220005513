import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Textarea
} from "@/components/ui";
import { cn } from "@/utils";
import React, { useState } from "react";
import { Icons } from "@/assets/icons";
import { PostTypeEnum, Task, useCreatePostMutation, useSubmitTaskFeedbackMutation } from "@pairprogram/graphql";
import toast from "react-hot-toast";
import useRequiresAuth from "@/hooks/use-requires-auth";
import { useGetPostTypes } from "@/hooks/use-get-post-types";

export default function TaskFeedbackModal({ task, open, setOpen }: {
  task: Task,
  open: boolean,
  setOpen: (open: boolean) => void
}) {
  const [feedback, setFeedback] = useState<string>("");
  const [rating, setRating] = useState<number | null>(null);

  const { postTypes } = useGetPostTypes();
  const progressUpdatePostType = postTypes?.find(postType => postType?.value === PostTypeEnum.Progress);

  const [submitFeedback] = useSubmitTaskFeedbackMutation({
    onCompleted: (data) => {
      if (data?.SubmitTaskFeedback?.error) {
        toast.error("Error submitting feedback");
        return;
      }
      toast.success("Feedback submitted");
    },
    onError: (error) => {
      toast.error("Error submitting feedback");
    },
    variables: {
      input: {
        taskId: task?.id ?? "",
        rating,
        description: feedback
      }
    }
  });

  const { withAuth } = useRequiresAuth();


  return (
    <div>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent className={cn(
          "bg-scale-25 dark:bg-scale-950",
          "border border-scale-200 dark:border-scale-700"
        )}>
          <AlertDialogHeader>
            <AlertDialogTitle className={"text-scale-950 dark:text-scale-25"}>
              <div className="flex flex-col w-full space-y-2">
                <h3 className={"text-xl font-bold"}>What did you think about this task?</h3>
                <p className={"text-base text-scale-500 font-medium"}>
                  Help us improve by sharing your feedback.
                </p>

              </div>
            </AlertDialogTitle>
            <AlertDialogDescription className={"text-scale-700 dark:text-scale-200 space-y-4"}>
              <div className="flex flex-col">
                <div className={"inline-flex w-full items-center justify-between space-x-6 px-4"}>
                  <div>
                    <Icons.FeedbackOne onClick={() => setRating(1)} className={cn(
                      "h-10 w-10 cursor-pointer hover:text-yellow-400",
                      rating === 1 ? "text-yellow-400" : "text-scale-400"
                    )} />
                    <div className={"text-center text-xs py-2"}>
                      1
                    </div>
                  </div>
                  <div>
                    <Icons.FeedbackTwo onClick={() => setRating(2)} className={cn(
                      "h-10 w-10 cursor-pointer hover:text-yellow-400",
                      rating === 2 ? "text-yellow-400" : "text-scale-400"
                    )} />
                    <div className="text-center text-xs py-2">
                      2
                    </div>
                  </div>
                  <div>
                    <Icons.FeedbackThree onClick={() => setRating(3)} className={cn(
                      "h-10 w-10 cursor-pointer hover:text-yellow-400",
                      rating === 3 ? "text-yellow-400" : "text-scale-400"
                    )} />
                    <div className="text-center text-xs py-2">
                      3
                    </div>
                  </div>
                  <div>
                    <Icons.FeedbackFour onClick={() => setRating(4)} className={cn(
                      "h-10 w-10 cursor-pointer hover:text-yellow-400",
                      rating === 4 ? "text-yellow-400" : "text-scale-400"
                    )} />
                    <div className="text-center text-xs py-2">
                      4
                    </div>
                  </div>
                  <div>
                    <Icons.FeedbackFive onClick={() => setRating(5)} className={cn(
                      "h-10 w-10 cursor-pointer hover:text-yellow-400",
                      rating === 5 ? "text-yellow-400" : "text-scale-400"
                    )} />
                    <div className="text-center text-xs py-2">
                      5
                    </div>
                  </div>
                </div>

                <div>
                  <p className={"pt-4 pb-1 text-xs font-normal text-scale-800"}>(Optional) Any additional feedback you'd
                    like to share?</p>
                  <Textarea
                    id="feedback"
                    rows={4}
                    value={feedback}
                    placeholder={"The steps were not very clear and could be worded better."}
                    onChange={(e) => setFeedback(e.target.value)}
                  />
                </div>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            {/*<AlertDialogCancel*/}
            {/*  className={"font-mono text-xs"}*/}
            {/*>Cancel</AlertDialogCancel>*/}
            <AlertDialogCancel
              className={"font-mono text-xs"}
              onClick={() => setOpen(false)}>
              <div className="flex items-center space-x-2">
                <p>Skip</p>
              </div>
            </AlertDialogCancel>
            <AlertDialogAction
              className={"font-mono text-xs"}
              onClick={() => submitFeedback()}>
              <div className="flex items-center space-x-2">
                <Icons.Feedback className={"h-4 w-4"} />
                <p>Share feedback</p>
              </div>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
