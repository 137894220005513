import dayjs from "dayjs";
import { getLocalizedTime, Timezone } from "@/utils/datetime.utils";
import { useEffect, useState } from "react";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

function useLocalizedTime(timezone: Timezone | undefined, initialDate?: Date): string {
  const [currentTime, setCurrentTime] = useState<string>("");  // Initial placeholder value

  useEffect(() => {
    if (!timezone) {
      // If timezone is not available, don't do anything
      return;
    }

    const updateCurrentTime = () => {
      const baseTime = initialDate ? dayjs(initialDate) : dayjs(); // Use the initialDate if provided, otherwise use the current time
      setCurrentTime(getLocalizedTime(timezone, baseTime));
    };

    // Set the time immediately once the timezone is available
    updateCurrentTime();

    // Calculate the delay to start on the dot
    const secondsNow = dayjs.tz(initialDate || dayjs(), timezone).second();
    const delayToNextMinute = (60 - secondsNow) * 1000;

    const timeoutId = setTimeout(() => {
      updateCurrentTime();
      const intervalId = setInterval(updateCurrentTime, 60 * 1000);

      // Clear the interval when the component using the hook is unmounted
      return () => clearInterval(intervalId);
    }, delayToNextMinute);

    // Clear the timeout when the component using the hook is unmounted
    return () => clearTimeout(timeoutId);
  }, [timezone, initialDate]);  // Add initialDate as a dependency to re-run the effect if it changes

  return currentTime;
}

export default useLocalizedTime;
