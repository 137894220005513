import React from "react";
import { Link } from "react-router-dom";
import { Icons } from "@/assets/icons";
import NewPostButton from "@/components/new-post-button";
import { cn } from "@/utils";
import { Button } from "@/components/ui";
import toast from "react-hot-toast";

export default function UsersEmpty() {

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(process.env.REACT_APP_BASE_URL ?? "https://pairprogram.com");
    toast.success("Copied link to clipboard!", { icon: "🙌" });
  }

  return (
    <div
      className={cn(
        "relative block w-full rounded-lg border-2 border-dashed border-scale-300 dark:border-scale-700 p-12",
        "text-center hover:border-scale-400 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
      )}
    >
      <Icons.Post
        className="mx-auto h-12 w-12 dark:text-scale-400 text-scale-600"
      />
      <div className="py-2">
        <span className="mt-2 block text-sm font-medium dark:text-scale-400 text-scale-600">No other users yet!</span>
      </div>
      <div className={'flex w-full py-4 items-center justify-center'}>
        <div className={'w-fit'}>
          <Button
            className={'w-fit font-mono text-xs'}
            onClick={() => handleCopyToClipboard()}
            size={"sm"}
          >
            <Icons.Copy className={"h-4 w-4 mr-2"}/>
            <span>Invite your friends</span>
          </Button>
        </div>
      </div>
    </div>
  )
}
