import {
  Badge,
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@/components/ui";
import { Check, TagIcon, XIcon } from "lucide-react";
import { cn } from "@/utils";
import React, { useState } from "react";
import { capitalizeFirstLetter } from "@/utils/string.utils";
import { TagType } from "@pairprogram/graphql";

export interface ComboBoxItem {
  value: string;
  label: string;
  type?: string;
}

interface ComboBoxProps {
  items: ComboBoxItem[];
  selectedItems: ComboBoxItem[];
  setSelectedItems: Function;
  displayItemsSeparately?: boolean;
  className?: string;
  badgeColor?: "red" | "blue" | "purple" | "primary" | "destructive" | "default" | "outline" | "ghost";
}


export default function MultiTagSelect({
  items,
  selectedItems,
  setSelectedItems,
  displayItemsSeparately = false,
  className = ""
}: ComboBoxProps) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const handleItemSelect = (item: ComboBoxItem) => {
    setSelectedItems(selectedItems.concat(item));
    setDropdownOpen(false);
  };

  const handleItemDeselect = (item: ComboBoxItem) => {
    setSelectedItems(selectedItems.filter(i => i.value !== item.value));
  };

  const availableItems = items.filter(item => !selectedItems.map(i => i.value).includes(item.value));

  return (
    <>
      <Popover open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <PopoverTrigger className={
          cn(className)
        }>
          <Button
            variant={"outline"}
            size={"sm"}
            className={"font-mono text-xs px-1 py-1"}
          >
            <TagIcon
              className={cn(
                "h-5 w-5 text-scale-500"
              )}
              aria-hidden="true"
            />
          </Button>
        </PopoverTrigger>


        <PopoverContent
          className="w-full max-h-64 overflow-y-scroll p-0 bg-scale-25 dark:bg-scale-950 border-scale-200 dark:border-scale-700">
          <Command>
            <CommandInput className={"w-full placeholder:text-scale-400 text-scale-900"}
                          placeholder="Search tags..." />
            <CommandEmpty>No tags found.</CommandEmpty>
            <CommandGroup className={"w-full"}>
              {availableItems.map((tag) => {
                let badgeColor = "default";
                if (tag.type === TagType.Technology) {
                  badgeColor = "red";
                } else if (tag.type === TagType.Career) {
                  badgeColor = "purple";
                } else if (tag.type === TagType.Topic) {
                  badgeColor = "blue";
                }
                return (
                  <CommandItem
                    className={"cursor-pointer"}
                    key={tag.value}
                    onSelect={() => handleItemSelect(tag)}
                  >
                    <Check
                      className={cn("mr-2 h-4 w-4", selectedItems.includes(tag) ? "opacity-100" : "opacity-0")}
                    />
                    <div className="flex items-center justify-between w-full">
                      <p>{tag.label}</p>
                      <p>{tag?.type && <Badge variant={badgeColor as any}>{capitalizeFirstLetter(tag.type)}</Badge>}</p>
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      {
        !displayItemsSeparately &&
        selectedItems.length ? (
          <div className="py-2 flex flex-wrap">
            {selectedItems.map(item => {
              let badgeColor = "default";
              if (item.type === TagType.Technology) {
                badgeColor = "red";
              } else if (item.type === TagType.Career) {
                badgeColor = "purple";
              } else if (item.type === TagType.Topic) {
                badgeColor = "blue";
              }
              const xColor = ["brand", "primary", "red", "blue", "purple"].includes(badgeColor) ? `text-${badgeColor}-700` : "text-scale-600";
              return (
                <Badge key={item.value} className="m-1" variant={badgeColor as any}>
                  {item.label}
                  <XIcon className={cn(
                    xColor,
                    "cursor-pointer ml-1 h-3 w-3"
                  )} onClick={() => handleItemDeselect(item)} />
                </Badge>
              );
            })}
          </div>
        ) : null
      }
    </>
  );
}
