import {
  Chat,
  ChatMessage,
  GetChatsForUserDocument,
  GetChatsForUserQuery,
  useUpdateAllChatMessageStatusesForChatMutation
} from "@pairprogram/graphql";
import toast from "react-hot-toast";
import { hasUnreadMessagesVar } from "@/store/reactive-vars";
import mixpanel from "@/analytics";
import { AnalyticsEvent } from "@/analytics/analytics-event";
import { AuthContext } from "@/store/context/AuthContext";
import { useContext } from "react";
import cloneDeep from "lodash/cloneDeep";

export const useUpdateChatMessageStatus = () => {
  const { state: auth } = useContext(AuthContext);
  const [updateChatMessageStatusMutation] = useUpdateAllChatMessageStatusesForChatMutation();

  const markAllMessagesForChatAsRead = (chatId: string) => {
    updateChatMessageStatusMutation({
      variables: {
        input: {
          chatId
        }
      },
      onCompleted: (data) => {
        mixpanel.track(AnalyticsEvent.ReadChatMessages, {
          user: auth?.user?.username
        });
      },
      onError: (error) => {
        toast.error("Error updating chat message status");
      },
      update: (cache, { data }) => {
        const existingChats: GetChatsForUserQuery | null = cache.readQuery({ query: GetChatsForUserDocument });

        if (existingChats && existingChats.GetChatsForUser) {
          hasUnreadMessagesVar(false);
          const updatedChats = existingChats.GetChatsForUser.data.map(chat => {
            // Create a deep copy of the chat object
            const updatedChat = cloneDeep<Chat | null>(chat);

            if (updatedChat?.id && updatedChat?.id === chatId) {
              // Now, modify the copy as needed
              if (updatedChat?.Messages?.data) {
                updatedChat.Messages.data = updatedChat?.Messages?.data.map((message: ChatMessage | null) => {
                  return {
                    ...message as ChatMessage,
                    Status: {
                      ...message?.Status,
                      data: {
                        ...message?.Status?.data,
                        isRead: true
                      },
                      error: null
                    }
                  };
                }) ?? [] as ChatMessage[];
              }
              return updatedChat;
            }
            return updatedChat;
          });

          cache.writeQuery({
            query: GetChatsForUserDocument,
            data: { GetChatsForUser: { ...existingChats.GetChatsForUser, data: updatedChats } }
          });
        }
      }
    });
  };

  return { markAllMessagesForChatAsRead };
};
