import {
  AddPostReactionError,
  Post,
  PostPartsFragment,
  PostPartsFragmentDoc,
  PostTypeEnum,
  ReactionType,
  RemovePostReactionError,
  useAddPostReactionMutation,
  useRemovePostReactionMutation
} from "@pairprogram/graphql";
import React from "react";
import AvatarHoverCard from "@/components/avatar-hover-card";
import { getRelativeTime } from "@/utils/datetime.utils";
import { BarChart, Cuboid, Flame, HandIcon, TargetIcon, ThumbsUp, Users } from "lucide-react";
import { Button } from "@/components/ui";
import { Link, useNavigate } from "react-router-dom";
import { AppRoute } from "@/config/routes";
import toast from "react-hot-toast";
import ImageWithSkeleton from "@/components/image-with-skeleton";
import useRequiresAuth from "@/hooks/use-requires-auth";
import { cn } from "@/utils";
import TagBadge from "@/components/tag-badge";
import MarkdownRenderer from "@/components/markdown-renderer";

interface PostCardParams {
  post: Post;
}

function mapPostTypeIcon(postTypeLabel: string) {
  switch (postTypeLabel) {
    case PostTypeEnum.Progress:
      return BarChart;
    case PostTypeEnum.Feedback:
      return HandIcon;
    case PostTypeEnum.Collaboration:
      return Users;
    case PostTypeEnum.Milestone:
      return TargetIcon;
    case PostTypeEnum.Project:
      return Cuboid;
    case PostTypeEnum.Resource:
      return Flame;
    default:
      return BarChart;
  }
}

export function PostCard({ post }: PostCardParams) {
  const navigate = useNavigate();
  const { withAuth } = useRequiresAuth();

  const PostTypeIcon = mapPostTypeIcon(post?.PostType?.data?.value!);

  const [addReaction, { data }] = useAddPostReactionMutation({
    variables: {
      input: {
        postId: post?.id!,
        reactionType: ReactionType.Like
      }
    },
    onCompleted: (data) => {
      if (data?.AddPostReaction?.error) {
        if (data?.AddPostReaction?.error === AddPostReactionError.AlreadyExists) {
          toast.error("You have already liked this post", { icon: "😌" });
          return;
        }
        if (data?.AddPostReaction?.error === AddPostReactionError.Forbidden) {
          toast.error("Cannot like your own post", { icon: "😬" });
          return;
        }
      }
      if (data?.AddPostReaction?.data) {
        toast.success("Post liked");
      }
    },
    update: (cache, { data }) => {
      if (data?.AddPostReaction?.data) {
        const existing: PostPartsFragment | null = cache.readFragment({
          id: `Post:${post?.id}`,
          fragment: PostPartsFragmentDoc,
          fragmentName: "PostParts"
        });

        if (existing) {
          const updated = {
            ...existing,
            likedByCurrentUser: true,
            likeCount: (existing?.likeCount ?? 0) + 1
          };

          cache.writeFragment({
            id: `Post:${post?.id}`,
            fragment: PostPartsFragmentDoc,
            fragmentName: "PostParts",
            data: updated
          });
        }
      }
    }
  });

  const [removeReaction, { data: removeReactionData }] = useRemovePostReactionMutation({
    variables: {
      input: {
        postId: post?.id!,
        reactionType: ReactionType.Like
      }
    },
    onCompleted: (data) => {
      if (data?.RemovePostReaction?.error) {
        if (data?.RemovePostReaction?.error === RemovePostReactionError.Forbidden) {
          toast.error("Unable to remove reaction", { icon: "😬" });
          return;
        }
        toast.error("Error unliking post");
        return;
      }
      if (data?.RemovePostReaction?.data) {
        toast.success("Post unliked");
      }
    },
    update: (cache, { data }) => {
      if (data?.RemovePostReaction?.data) {

        const existing: PostPartsFragment | null = cache.readFragment({
          id: `Post:${post?.id}`,
          fragment: PostPartsFragmentDoc,
          fragmentName: "PostParts"
        });

        if (existing) {
          const updated = {
            ...existing,
            likedByCurrentUser: false,
            likeCount: (existing?.likeCount ?? 0) > 0 ? existing?.likeCount! - 1 : 0
          };

          cache.writeFragment({
            id: `Post:${post?.id}`,
            fragment: PostPartsFragmentDoc,
            fragmentName: "PostParts",
            data: updated
          });
        }
      }
    }
  });

  const toggleReaction = () => {
    if (post?.likedByCurrentUser) {
      removeReaction();
    } else {
      addReaction();
    }
  };

  return (
    <li key={post.id} className={cn(
      "hover:outline-brand-100 hover:outline-4 hover:outline",
      "border border-scale-300 hover:border-brand-200",
      "bg-white border border-scale-300 shadow-sm rounded-xl"
    )}>
      <div className={"px-4 pt-4 pb-2"}>
        <article aria-labelledby={"post-title-" + post.title}>
          <div>
            <div className="flex space-x-3">
              <div className="flex-shrink-0">
                <AvatarHoverCard user={post?.Creator?.data!} size={7} statusSize={2} />
              </div>
              <div className="min-w-0 flex-1">
                <p className="text-sm font-medium text-gray-900">
                  <Link to={`${AppRoute.User}/${post?.Creator?.data?.username}`}>
                    {`@${post?.Creator?.data?.username}`}
                  </Link>
                </p>
                <p className="text-sm text-gray-500">
                  <Link to={`${AppRoute.Post}/${post.id}`}>
                    <time dateTime={post?.createdAt}>{getRelativeTime(post?.createdAt)}</time>
                  </Link>
                </p>
              </div>
            </div>
            <h2 id={"post-title-" + post.id} className="mt-2 text-normal font-bold text-gray-900">
              {post?.title}
            </h2>
          </div>

          <div className="mt-1 space-y-2 text-sm text-gray-700 text-wrap break-words">
            <MarkdownRenderer markdownText={post?.description!} />
          </div>

          {
            post?.Uploads?.data?.length && (
              <div className={"mt-2"}>
                <div className="grid">
                  {
                    post?.Uploads?.data?.map((upload, index) => {
                      return (
                        <div className={"rounded-lg"} key={index}>
                          <ImageWithSkeleton
                            className={"rounded-lg w-full"}
                            src={upload?.signedUrl ?? "/images/placeholder.png"}
                            alt={upload?.filename ?? `Uploaded image for post ${post?.title}`}
                          />
                          {/*<img*/}
                          {/*  className={"rounded-lg w-full"}*/}
                          {/*  src={upload?.signedUrl ?? "/images/placeholder.png"}*/}
                          {/*  alt={upload?.filename ?? `Uploaded image for post ${post?.title}`}*/}
                          {/*/>*/}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            )
          }
          <div className={cn(
            // "inline-flex min-h-fit w-fit items-center flex-wrap justify-end pt-2",
            "flex flex-wrap pt-2 gap-1"
          )}>
            {
              post?.tags?.length ? (
                post?.tags?.map(item => <TagBadge tag={item!} />)
              ) : null
            }
          </div>
        </article>
      </div>
      <div
        className="flex items-center justify-between space-x-3 border-t border-scale-200 py-2 px-2">

        {/*<div className="mt-6 flex justify-between space-x-8">*/}
        <div className="flex space-x-6 grow shrink-0 items-center">
          <div className="flex-grow">
            {
              post?.PostType?.data?.label ? (
                <div className={"flex items-center text-xs text-scale-500"}>
                  <div className="flex border border-scale-200 bg-scale-50 rounded-full px-2 py-1 flex-grow-0">
                    <PostTypeIcon className={"h-4 w-4 mr-1"} />
                    <span className={"text-scale-900 hidden sm:block"}>{post?.PostType?.data?.label}</span>
                  </div>
                </div>
              ) : null
            }
          </div>
          {/*<span className="inline-flex items-center text-sm">*/}
          {/*  <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">*/}
          {/*    <ChatBubbleLeftEllipsisIcon className="h-5 w-5" aria-hidden="true" />*/}
          {/*    <span className="font-medium text-gray-900">{10}</span>*/}
          {/*    <span className="sr-only">replies</span>*/}
          {/*  </button>*/}
          {/*</span>*/}
          {/*<span className="inline-flex items-center text-sm">*/}
          {/*  <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">*/}
          {/*    <EyeIcon className="h-5 w-5" aria-hidden="true" />*/}
          {/*    <span className="font-medium text-gray-900">{107}</span>*/}
          {/*    <span className="sr-only">views</span>*/}
          {/*  </button>*/}
          {/*</span>*/}
          <span className={cn(
            "inline-flex items-center justify-between text-sm border border-scale-200 px-2 h-6 rounded rounded-full",
            post?.likedByCurrentUser ? "bg-blue-50 text-blue-300" : "bg-scale-25"
          )}>
            <button
              onClick={() => withAuth(toggleReaction)}
              type="button" className="inline-flex text-gray-400 hover:text-gray-500">
              <div className="flex items-center space-x-2">
              <ThumbsUp className={cn(
                "h-3 w-3 sm:h-3.5 sm:w-3.5",
                post?.likedByCurrentUser ? "text-blue-300" : "text-scale-500"
              )} aria-hidden="true" />
              <div>
                <span className="font-medium text-xs text-gray-900">{post?.likeCount ?? 0}</span>
                <span className="sr-only">{post?.likeCount === 1 ? "like" : "likes"}</span>
              </div>
              </div>
            </button>
          </span>
        </div>
        <div className="flex flex-shrink-0 text-sm space-x-4">
          <div className={"flex space-x-4"}>
            {/*<div className="relative inline-flex items-center text-sm">*/}
            {/*  <ShareOptions itemType={"post"} item={post} />*/}
            {/*</div>*/}
            <div className="flex items-center justify-center">
              <Button
                variant={"outline"}
                size={"sm"}
                className={"font-mono text-xs"}
                onClick={() => navigate(`${AppRoute.Post}/${post?.id}`)}
              >
                Read More
              </Button>
            </div>
          </div>
        </div>
      </div>
    </li>

  );
}
