export const siteConfig = {
  name: "Build job-ready programming skills & habits | pairprogram.com",
  description:
    "Get unstuck and learn to code by collaborating in realtime with other developers over video chat.",
  url: "https://pairprogram.com",
  ogImage: "https://pairprogram.com/og.jpg",
  links: {
    twitter: "https://twitter.com/pairprogram",
    github: "https://github.com/pairprogramhq",
    howItWorks: "/#how-it-works",
  },
}

export const MAX_PRIVATE_BETA_USERS = Number(process.env.REACT_APP_PRIVATE_BETA_SPOTS) ?? 100;
