import { Outlet } from "react-router-dom";
import React from "react";
import BackgroundImage from "@/assets/images/gradients/mesh-gradient-98.jpg";


export default function OnboardingLayout() {
  return (
    <div
      className={"relative h-full min-h-screen w-full bg-gradient-to-br from-purple-500 via-blue-500 to-red-500"}>
      <div
        className={"text-scale-100 overflow-y-auto absolute inset-0 h-full w-full bg-no-repeat bg-center bg-cover py-16"}
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <Outlet />
      </div>
    </div>
  );
}
