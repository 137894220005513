import {
  AddTaskReactionError,
  ReactionType,
  RemoveTaskReactionError,
  Task,
  TaskDifficulty,
  TaskPartsFragment,
  TaskPartsFragmentDoc,
  TaskTypeEnum,
  useAddTaskReactionMutation,
  useRemoveTaskReactionMutation
} from "@pairprogram/graphql";
import React, { useContext } from "react";
import { Icons } from "@/assets/icons";
import { Link, useNavigate } from "react-router-dom";
import ShareOptions from "@/components/ShareOptions";
import { AuthContext } from "@/store/context/AuthContext";
import { useStartChat } from "@/hooks/use-start-chat";
import { ThumbsUp } from "lucide-react";
import toast from "react-hot-toast";
import { cn } from "@/utils";
import useRequiresAuth from "@/hooks/use-requires-auth";
import { getRoundedDisplayMinutes } from "@/utils/datetime.utils";
import { capitalizeFirstLetter } from "@/utils/string.utils";
import { AppRoute } from "@/config/routes";
import BackgroundImage from "@/assets/images/backgrounds/flux-gradient-blue.svg";
import TagBadge from "@/components/tag-badge";
import useTaskReaction from "@/hooks/use-task-reaction";

export function mapTaskTypeIcon(value: TaskTypeEnum) {
  switch (value) {
    case TaskTypeEnum.Article:
      return Icons.TaskType.Article;
    case TaskTypeEnum.Project:
      return Icons.TaskType.Project;
    case TaskTypeEnum.Exercise:
      return Icons.TaskType.Exercise;
    case TaskTypeEnum.Tutorial:
      return Icons.TaskType.Tutorial;
    case TaskTypeEnum.SystemsDesign:
      return Icons.TaskType.SystemsDesign;
    case TaskTypeEnum.ComputerScience:
      return Icons.TaskType.ComputerScience;
    case TaskTypeEnum.OpenSource:
      return Icons.TaskType.OpenSource;
    case TaskTypeEnum.Video:
      return Icons.TaskType.Video;
    case TaskTypeEnum.DailyUpdate:
      return Icons.TaskType.DailyUpdate;
    case TaskTypeEnum.CodeReview:
      return Icons.TaskType.CodeReview;
    default:
      return Icons.TaskType.Default;
  }
}

function getDifficultyColor(difficulty: TaskDifficulty) {
  switch (difficulty) {
    case TaskDifficulty.Beginner:
      return "bg-green-50 text-green-500";
    case TaskDifficulty.Intermediate:
      return "bg-amber-50 text-amber-400";
    case TaskDifficulty.Advanced:
      return "bg-red-50 text-red-400";
    case TaskDifficulty.Expert:
      return "bg-black text-scale-100";
    default:
      return "bg-scale-25 text-scale-500";
  }
}

export default function TaskCard({ task }: { task: Task }) {
  const { withAuth } = useRequiresAuth();

  const TaskTypeIcon = mapTaskTypeIcon(task?.Type?.data?.value as TaskTypeEnum);

  const { toggleReaction } = useTaskReaction(task);

  return (
    <div
      className={cn(
        "group hover:outline-brand-100 hover:outline-4 hover:outline",
        "border border-scale-300 hover:border-brand-200",
        "bg-scale-25 shadow-sm hover:shadow-none transition-shadow duration-300 ease-in-out rounded-xl "
      )}>
      <Link to={`${AppRoute.Task}/${task?.id}`}>
        <div className=" min-h-[200px] flex flex-1 flex-col px-4 py-4">
          <div className="mx-auto w-full">
            <div className="sm:flex sm:space-x-5 items-top">
              <div className="flex space-x-4 pb-2 sm:pb-0 w-full sm:w-fit items-top justify-between sm:justify-normal">
                <div
                  className={cn(
                    "h-10 w-10 outline outline-2 outline-brand-100 rounded-full flex items-center justify-center",
                    "group-hover:bg-brand-100 group-hover:border-brand-300 group-hover:border-2"
                  )}>
                  <TaskTypeIcon className={cn(
                    "h-5 w-5 text-brand group-hover:text-brand-700",
                    // add animation duration
                    "animate duration-300 transition-all ease-in-out"
                  )} />
                </div>
                <div className={"flex sm:hidden"}>
                  <div className="cursor-pointer">
                    <Icons.Goto className="h-6 w-6 text-scale-300 group-hover:text-brand-600" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div className="sm:flex sm:min-w-0 sm:flex-1 sm:items-top sm:justify-end sm:space-x-6 sm:pb-3">
                <div className="min-w-0 flex-1">
                  <h1
                    className="truncate text-lg font-bold dark:text-scale-25">{task.title}</h1>
                  <div className="flex">
                    <div className={"inline-flex items-center space-x-2 text-sm text-scale-600"}>
                      {task?.description}
                    </div>
                  </div>
                </div>
              <div className={"hidden sm:block"}>
                <div className="cursor-pointer">
                  <Icons.Goto className="h-6 w-6 text-scale-300  group-hover:text-brand-600" aria-hidden="true" />
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div
        className="flex flex-wrap items-center justify-between space-x-3 border-t border-scale-200 py-2 pl-4 pr-2">
        <div className="flex space-x-3 items-center justify-center">
          <div className={cn(
            "inline-flex items-center justify-between text-sm border py-0.25 px-1.5 rounded-md",
            task?.likedByCurrentUser ? "bg-brand-100 text-brand-500 border-brand-300" : "bg-scale-25 border-scale-200"
          )}>
            <button
              onClick={() => withAuth(toggleReaction)}
              type="button" className="inline-flex text-gray-400 hover:text-gray-500">
              <div className="flex items-center space-x-2">
                <ThumbsUp className={cn(
                  "h-3.5 w-3.5",
                  task?.likedByCurrentUser ? "text-brand-500" : "text-scale-500"
                )} aria-hidden="true" />
                <div>
                  <span className="font-normal text-xs text-gray-600">{task?.likeCount ?? 0}</span>
                  <span className="sr-only">{task?.likeCount === 1 ? "like" : "likes"}</span>
                </div>
              </div>
            </button>
          </div>

          {
            task?.estimatedMinutes && (
              <div title={`Estimated completion time: ${getRoundedDisplayMinutes(task?.estimatedMinutes)}`}
                   className="inline-flex items-center justify-between text-sm border border-scale-200  py-0.25 px-1.5 rounded-md">
                <div className="flex items-center space-x-2">
                  <Icons.Clock className={"h-3.5 w-3.5 -mr-0.5 text-scale-500"} aria-hidden="true" />
                  <div>
                    <span
                      className="font-normal text-xs text-gray-600">{getRoundedDisplayMinutes(task?.estimatedMinutes)}</span>
                    <span className="sr-only">minutes</span>
                  </div>
                </div>
              </div>
            )
          }

          {
            task?.difficulty && (
              <div title={`Difficulty: ${task?.difficulty}`}
                   className={cn(
                     "inline-flex text-scale-600 items-center justify-between text-sm border border-scale-200 py-0.25 px-1.5 rounded-md"
                   )}>
                <div className="flex items-center space-x-2">
                  <div>
                    <span className={cn(
                      "font-normal text-xs"
                    )}>{capitalizeFirstLetter(task?.difficulty)}</span>
                    <span className="sr-only">difficulty</span>
                  </div>
                </div>
              </div>
            )
          }

          {
            task?.Tags?.data?.map((tag, key) => {
              // only display 2 tags, plus a +1 if there are more
              if (key < 1) {
                return (
                  <TagBadge key={key} tag={tag!} />
                );
              }
              if (key === 1 && task?.Tags?.data?.length! >= 1) {
                const restOfTheTagsStr = [...task?.Tags?.data!]?.splice(1).map(t => t?.label).join(", ");
                return (
                  <div key={key} title={restOfTheTagsStr}
                       className="inline-flex items-center justify-between text-sm border border-scale-200 text-scale-600 py-0.25 px-1.5 rounded-md">
                    <div className="flex items-center space-x-2">
                      <div>
                        <span className={cn(
                          "font-normal text-xs"
                        )}>+{task?.Tags?.data?.length! - 1} more</span>
                        <span className="sr-only">more tags</span>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            }).filter(Boolean)
          }

        </div>
        <div className="flex text-sm space-x-4 justify-end">
          <ShareOptions itemType={"task"} item={task} />
        </div>
      </div>
    </div>

  );
}
