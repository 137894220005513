import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/inter/variable-full.css";
import "@fontsource-variable/manrope";
import App from "./App";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { Provider as BalanceProvider } from "react-wrap-balancer";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  Observable,
  split
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { authStore } from "@/store";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import * as process from "process/browser";
import toast, { Toaster } from "react-hot-toast";
import { cn } from "@/utils";
import * as Sentry from "@sentry/react";
import {
  AuthTokenPair,
  GetPlatformEventOutput,
  GetPlatformEventsInput,
  PlatformEvent,
  Role
} from "@pairprogram/graphql";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { __DEV__ } from "@apollo/client/utilities/globals";
import MarketingLayout from "@/app/marketing/layout";
import { AppRoute } from "@/config/routes";
import PrivacyPolicy from "@/app/marketing/legal/privacy-policy";
import TermsOfService from "@/app/marketing/legal/terms-of-service";
import OnboardingLayout from "@/app/onboarding/layout";
import OnboardingPage from "@/app/onboarding";
import FeedSidebarLayout from "@/app/dashboard/feed-sidebar-layout";
import FeedPage from "@/app/dashboard/home";
import SettingsPage from "@/app/dashboard/settings";
import PostPage from "@/app/dashboard/post";
import SessionsPage from "@/app/dashboard/sessions";
import UsersPage from "@/app/dashboard/users";
import UserPage from "@/app/dashboard/users/user";
import TasksPage from "@/app/dashboard/tasks";
import TaskPage from "@/app/dashboard/tasks/task";
import ChatsPage from "@/app/dashboard/chats";
import ChatPage from "@/app/dashboard/chats/chat";
import SessionsLayout from "@/app/sessions/layout";
import SessionPage from "@/app/sessions/session";
import NotFoundPage from "@/app/marketing/not-found";
import ProtectedRoute from "@/components/nav/protected-route";
import OnboardingThankYou from "@/app/onboarding/thank-you";
import UserProfileLayout from "@/app/dashboard/users/user/layout";
import TasksLayout from "@/app/dashboard/tasks/layout";
import AdminTaskUpdatePage from "@/app/admin/task/update";
import AdminTaskCreatePage from "@/app/admin/task/create";
import ProjectPage from "@/app/dashboard/projects/project";
import ProjectsPage from "./app/dashboard/projects";
import ProjectsLayout from "@/app/dashboard/projects/layout";
import ProjectLayout from "./app/dashboard/projects/project/layout";
import ProjectCreatePage from "@/app/dashboard/projects/project/create";
import TaskLayout from "./app/dashboard/tasks/task/layout";
import ManifestoPage from "@/app/marketing/manifesto";


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "api.pairprogram.com", "pairprogram.com"]
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

export enum Theme {
  Theme = "theme",
  Light = "light",
  Dark = "dark",
  System = "system",
}

/* DARK MODE */
// On page load or when changing themes, best to add inline in `head` to avoid FOUC
// if (!(LocalStorage.Theme in localStorage)) {
//   // if no theme found in localStorage, set initial theme to dark mode
//   localStorage.setItem(LocalStorage.Theme, Theme.Dark);
//   document.documentElement.classList.add(Theme.Dark);
// } else if (localStorage.getItem(Theme.Theme) === Theme.Dark) {
//   // if theme is found and its dark mode, set theme accordingly
//   document.documentElement.classList.add(Theme.Dark);
// } else {
//   document.documentElement.classList.remove(Theme.Dark);
// }

const httpLink = createHttpLink({
  uri: "/graphql"
});

const wsLink = new GraphQLWsLink(createClient({
  url: process.env.REACT_APP_GRAPHQL_WS_URL || "ws://localhost:4000/subscriptions",
  lazy: true,
  connectionParams: async () => ({
    authToken: authStore().getAccessToken()
  }),
  shouldRetry: (errorOrCloseEvent) => {
    const hasAccessToken = authStore().getAccessToken();
    return !!hasAccessToken;
  },
  on: {
    connected: async () => {
      console.log("[WS] CONNECTED");
    },
    closed: async (data) => {
      console.log("[WS] CLOSED:", data);
    }
  }
}));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { getAccessToken } = authStore();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getAccessToken() ? `Bearer ${getAccessToken()}` : ""
    }
  };
});

async function refreshToken(): Promise<AuthTokenPair> {
  const res = await fetch("/api/v1/auth/refresh", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authStore().getRefreshToken()}`
    }
  });
  if (!res.ok) {
    throw new Error("Refresh token expired");
  }
  return res.json();
}

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 1000,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      const isUnauthorizedException = error?.response?.errors?.[0].extensions?.code === "UNAUTHENTICATED";
      if (isUnauthorizedException) {
        return false;
      }
      return true;
    }
  }
});

const errorLink = onError(({ response, forward, operation }: ErrorResponse) => {
  // @ts-ignore
  const isUnauthorizedException: number = response?.errors?.[0].extensions?.code === "UNAUTHENTICATED";
  if (isUnauthorizedException) {
    return new Observable(observer => {
      refreshToken().then((data) => {
        if (data?.accessToken && data?.refreshToken) {
          authStore().setAccessToken(data?.accessToken);
          authStore().setRefreshToken(data.refreshToken);
        }
        operation.setContext(({ headers = {} }) => ({
          headers: {
            // Re-add old headers
            ...headers,
            // Switch out old access token for new one
            authorization: `Bearer ${data.accessToken}` || null
          }
        }));
      }).then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        };

        // Retry last failed request
        forward(operation).subscribe(subscriber);
      }).catch((err) => {
        observer.error(err);
        // if refresh fails, remove all tokens from storage
        authStore().removeAuthState();

        toast.error("Please login", { duration: 5000 });
        // redirect to home page
        window.location.href = AppRoute.Home;
      });
    });
  }

});

const apolloClient = new ApolloClient({
  queryDeduplication: true,
  connectToDevTools: process.env.NODE_ENV === "development",
  cache: new InMemoryCache({
    typePolicies: {
      // UsersEdge: {
      //   fields: {
      //     data: {
      //       merge(existing = [], incoming) {
      //         // Create a Set to track references and avoid duplicates
      //         const mergedRefs = new Set(existing.map(ref => ref.__ref));
      //         console.log(
      //           'UsersEdge MergeData', incoming, existing,
      //         )
      //         // Iterate over incoming references and add them if they're not already present
      //         incoming.forEach(ref => {
      //           mergedRefs.add(ref.__ref);
      //         });
      //
      //         console.log(
      //           'MergeData MergedRefs', Array.from(mergedRefs).map(__ref => ({ __ref })),
      //         )
      //         // Convert the Set back into an array of references
      //         return Array.from(mergedRefs).map(__ref => ({ __ref }));
      //       }
      //     }
      //   }
      // },
      // GetLatestPostsData: {
      //   fields: {
      //     posts: {
      //       merge(existing, incoming) {
      //         return [...(incoming ?? []), ...(existing ?? [])];
      //       }
      //     }
      //   }
      // },
      // Post: {
      //   fields: {
      //     Creator: {
      //       merge(existing, incoming) {
      //         if (!incoming) {
      //           return existing;
      //         }
      //
      //         if (!existing) {
      //           return incoming;
      //         }
      //
      //         if (existing?.data && existing.data?.__ref === incoming?.__ref) {
      //           return existing;
      //         }
      //         return incoming;
      //       }
      //     },
      //     PostType: {
      //       merge(existing, incoming) {
      //         if (!incoming) {
      //           return existing;
      //         }
      //
      //         if (!existing) {
      //           return incoming;
      //         }
      //
      //         if (existing?.data && existing.data?.__ref === incoming?.__ref) {
      //           return existing;
      //         }
      //         return incoming;
      //       }
      //     }
      //   }
      // },
      Query: {
        fields: {
          GetLatestPlatformEvents: {
            keyArgs: false,
            read(existing: PlatformEvent[], { args }) {
              const input = args?.input as GetPlatformEventsInput;
              const offset = input?.offset;
              const limit = input?.limit;
              // A read function should always return undefined if existing is
              // undefined. Returning undefined signals that the field is
              // missing from the cache, which instructs Apollo Client to
              // fetch its value from your GraphQL server.

              // offset can be zero, so cannot check if false, must explicitly check for undefined
              if (!existing || !limit || (offset == undefined || offset == null)) return undefined;

              return existing && existing.slice(offset, offset + limit);
            },
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing: PlatformEvent[] = [], incoming: GetPlatformEventOutput) {
              if (!incoming) return existing;
              return [...existing, ...(incoming?.data?.platformEvents ?? [])];
            }
          },
          GetLatestPosts: {
            keyArgs: ["sort", "filter", "tags"]
          }
        }
      }
    }

  }),
  link: ApolloLink.from([authLink, retryLink, errorLink, splitLink])
});

// apolloClient.clearStore()

const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      <Route element={<App />}>
        <Route path="/" element={<MarketingLayout />}>
          {/*<Route*/}
          {/*  index*/}
          {/*  element={<Home />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/pricing"*/}
          {/*  element={<PricingPage />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path={AppRoute.Login}*/}
          {/*  element={<LoginPage />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path={AppRoutes.Register}*/}
          {/*  element={<RegisterPage />}*/}
          {/*/>*/}
          <Route
            path={AppRoute.PrivacyPolicy}
            element={<PrivacyPolicy />}
          />
          <Route
            path={AppRoute.TermsOfService}
            element={<TermsOfService />}
          />
          {/*<Route*/}
          {/*  path={AppRoute.Join}*/}
          {/*  element={<JoinPage />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/profile/:username"*/}
          {/*  element={<ProfilePage />}*/}
          {/*/>*/}
        </Route>
        {/*<Route*/}
        {/*  path="/"*/}
        {/*  element={*/}
        {/*    <ProtectedRoute*/}
        {/*      isRefreshTokenExpired={isRefreshTokenExpired()}*/}
        {/*    />*/}
        {/*  }*/}
        {/*>*/}

        <Route
          path={AppRoute.Apply}
          element={<OnboardingLayout />}>
          <Route
            index
            element={<OnboardingPage />}
          />
          <Route
            path={`${AppRoute.Apply}/thank-you`}
            element={<OnboardingThankYou />}
          />
        </Route>

        {/* DASHBOARD */}
        <Route path={AppRoute.Home} element={<FeedSidebarLayout />}>
          <Route
            index
            element={<UsersPage/>}
          />
          <Route
            path={`${AppRoute.Post}/:id`}
            element={<PostPage />}
          />

          <Route path={AppRoute.Feed} element={<FeedPage />} />

          {/* PROTECTED APP ROUTES*/}
          <Route element={<ProtectedRoute />}>
            <Route
              path={AppRoute.Settings}
              element={<SettingsPage />}
            />
            <Route path={AppRoute.Session} element={<SessionsPage />} />
            <Route path={AppRoute.Chat} element={<ChatsPage />} />
            <Route path={`${AppRoute.Chat}/:chatId`} element={<ChatPage />} />

            {/* LEGACY */}
            <Route path={"/dashboard/inbox"} element={<ChatsPage />} />
          </Route>
        </Route>

        {/* PROJECTS */}
        <Route path={AppRoute.Project} element={<ProjectsLayout />}>
          <Route path={AppRoute.Project} element={<ProjectsPage />} />
        </Route>
        <Route path={AppRoute.Project} element={<ProjectLayout />}>
          <Route path={`${AppRoute.Project}/new`} element={<ProjectCreatePage />} />
          <Route path={`${AppRoute.Project}/:projectId`} element={<ProjectPage />} />
        </Route>

        {/* TASKS */}
        <Route path={AppRoute.Task} element={<TasksLayout />}>
          <Route
            index
            element={<TasksPage />}
          />
          <Route element={<ProtectedRoute roles={[Role.Admin]} />}>
            <Route
              path={`${AppRoute.Task}/new`}
              element={<AdminTaskCreatePage />}
            />
            <Route
              path={`${AppRoute.Task}/:id/edit`}
              element={<AdminTaskUpdatePage />}
            />
          </Route>
        </Route>
        <Route path={`${AppRoute.Task}/:id`} element={<TaskLayout />}>
          <Route
            path={`${AppRoute.Task}/:id`}
            element={<TaskPage />}
          />
        </Route>

        {/* USER PROFILE */}
        <Route path={`${AppRoute.User}/:username`} element={<UserProfileLayout />}>
          <Route index path={`${AppRoute.User}/:username`} element={<UserPage />} />
        </Route>

        {/* SESSIONS */}
        <Route path={`${AppRoute.Session}/:id`} element={<SessionsLayout />}>
          <Route
            index
            element={<SessionPage />}
          />
        </Route>

        {/* MANIFESTO */}
        <Route path={AppRoute.Manifesto} element={<FeedSidebarLayout />}>
          <Route path={AppRoute.Manifesto} element={<ManifestoPage/>}/>
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Fragment>
  )
);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      {/*<BrowserRouter>*/}
      <BalanceProvider>
        <RouterProvider router={router} />
        <Toaster
          position="top-center"
          toastOptions={{
            className: cn(
              "border border-scale-300 dark:border-scale-700 rounded-md",
              "bg-scale-100 text-scale-600",
              "dark:bg-scale-950 dark:text-scale-25 font-medium text-xs w-fit"
            )
          }}
        />
      </BalanceProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (__DEV__) {  // Adds chats only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}
