import {
  GetLatestPostsDocument,
  GetLatestPostsQuery,
  Post,
  PostFilterEnum,
  PostPartsFragmentDoc,
  PostSortEnum,
  SessionPartsFragmentDoc,
  Tag,
  useGetLatestPostsQuery,
  usePostCreatedSubscription,
  usePostUpdatedSubscription
} from "@pairprogram/graphql";
import { useContext } from "react";
import { authStore } from "@/store";
import { DashboardContext } from "@/store/context/DashboardContext";

export default function useGetPosts(limit: number = 10, offset: number = 0, sort: PostSortEnum = PostSortEnum.Latest, filter: PostFilterEnum = PostFilterEnum.All, tags: Tag[]) {
  const { getAccessToken, getRefreshToken } = authStore();
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const { state: dashboard } = useContext(DashboardContext);

  const {
    data,
    fetchMore,
    loading,
    called,
    refetch
  } = useGetLatestPostsQuery({
    variables: {
      input: {
        limit,
        offset,
        tags: dashboard?.selectedTagFilters?.map((tag: Tag) => tag.value),
        filter: filter as PostFilterEnum,
        sort: sort as PostSortEnum
      }
    },
    fetchPolicy: "cache-and-network",
  });

  usePostCreatedSubscription({
    shouldResubscribe: true,
    onData: ({ client, data: newPostSubscriptionData }) => {
      client.writeFragment({
        id: `Post:${newPostSubscriptionData?.data?.PostCreated?.id}`,
        fragment: PostPartsFragmentDoc,
        data: newPostSubscriptionData?.data?.PostCreated
      });

      const existingPostData = client.readQuery<GetLatestPostsQuery>({
        query: GetLatestPostsDocument,
        variables: {
          input: {
            // TODO: Revisit this as these (essentially) hard coded (defaulted) values need to be dynamic
            // in order to accurately update the cache
            // so this might become problematic in the future
            limit,
            offset,
            tags: dashboard?.selectedTagFilters?.map((tag: Tag) => tag.value),
            filter: filter as PostFilterEnum,
            sort: sort as PostSortEnum
          }
        }
      });


      if (existingPostData) {
        const newPost: Post | undefined = newPostSubscriptionData?.data?.PostCreated;
        client.writeQuery({
          query: GetLatestPostsDocument,
          data: {
            GetLatestPosts: {
              error: null,
              data: {
                count: (existingPostData?.GetLatestPosts?.data?.posts?.length ?? 0) + 1,
                posts: [
                  newPost,
                  ...(existingPostData?.GetLatestPosts?.data?.posts ?? [])
                ]
              }
            }
          }
        });
      }
    },
  });

  usePostUpdatedSubscription({
    skip: !accessToken || !refreshToken,
    shouldResubscribe: true,
    onData: ({ client, data: updatedPostSubscriptionData }) => {
      const updatedPost = updatedPostSubscriptionData?.data?.PostUpdated;
      const existingPost = client.readFragment({
        id: `Post:${updatedPost?.id}`,
        fragment: PostPartsFragmentDoc
      });

      client.writeFragment({
        id: `Post:${updatedPost?.id}`,
        fragment: PostPartsFragmentDoc,
        data: {
          ...existingPost,
          ...updatedPost,
        }
      });

      updatedPost?.sessions?.forEach(session => {
        client.writeFragment({
          id: `Session:${session?.id}`,
          fragment: SessionPartsFragmentDoc,
          fragmentName: "SessionParts",
          data: session
        });
      });
    }
  });

  const posts = data?.GetLatestPosts?.data?.posts as Post[] ?? [];
  const count = data?.GetLatestPosts?.data?.count ?? 0;

  return {
    posts,
    count,
    fetchMore,
    loading,
    called,
    refetch
  };
}
