
export const marketingConfig = {
  mainNav: [
    {
      title: "How it Works",
      href: "/#how-it-works",
      disabled: false,
    },
    // {
    //   title: "Become a Mentor",
    //   href: "/#become-a-mentor",
    //   disabled: false,
    // },
    // {
    //   title: "Pricing",
    //   href: "/pricing",
    // },
    // {
    //   title: "Guides",
    //   href: "/guides",
    // },
    // {
    //   title: "Blog",
    //   href: "/blog",
    // },
    // {
    //   title: "Documentation",
    //   href: "/docs",
    // },
    // {
    //   title: "Contact",
    //   href: "/contact",
    //   disabled: true,
    // },
  ],
}
