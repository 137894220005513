import { cn } from "@/utils";
import { Icons } from "@/assets/icons";
import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Label, Textarea } from "@/components/ui";
import toast from "react-hot-toast";
import ComboBox, { ComboBoxItem } from "@/components/combo-box";
import {
  Tag,
  TagType,
  UpdateTagsForUserMutation,
  useGetCurrentUserQuery,
  User,
  useUpdateProfileMutation,
  useUpdateTagsForUserMutation
} from "@pairprogram/graphql";
import mixpanel from "@/analytics";
import { AnalyticsEvent } from "@/analytics/analytics-event";
import { useNavigate } from "react-router-dom";
import { TimezoneDropdown } from "@/components/timezone-dropdown";
import useGetTags from "@/hooks/use-get-tags";
import { AppRoute } from "@/config/routes";
import { AuthContext } from "@/store/context/AuthContext";
import { authStore } from "@/store";
import { shootConfetti } from "@/utils/confetti.utils";

export default function OnboardingPage() {
  const { dispatch } = useContext(AuthContext);
  const { isAuthenticated } = authStore();
  const navigate = useNavigate();

  if (!isAuthenticated()) {
    navigate(AppRoute.Home);
  }

  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [timezone, setTimezone] = useState<string | undefined>(undefined);
  const [shortBio, setShortBio] = useState("Let's pair program!");
  const [shortBioCharacters, setShortBioCharacters] = useState(0);
  const [displayShortBioError, setDisplayShortBioError] = useState(false);
  const [bio, setBio] = useState("");
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [hopeToGive, setHopeToGive] = useState("");
  const [hopeToGiveCharacters, setHopeToGiveCharacters] = useState(0);
  const [displayHopeToGiveError, setDisplayHopeToGiveError] = useState(false);
  const [hopeToGet, setHopeToGet] = useState("");
  const [hopeToGetCharacters, setHopeToGetCharacters] = useState(0);
  const [displayHopeToGetError, setDisplayHopeToGetError] = useState(false);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const [selectedTechTags, setSelectedTechTags] = useState<Tag[]>([]);
  const [selectedTrackTags, setSelectedTrackTags] = useState<Tag[]>([]);
  const [selectedTopicTags, setSelectedTopicTags] = useState<Tag[]>([]);

  const { techTags, trackTags, topicTags } = useGetTags();

  const initialSteps = [
    { id: "Step 1", name: "About Me", href: "#", status: "current" },
    { id: "Step 2", name: "Skills and Interests", href: "#", status: "upcoming" },
    { id: "Step 3", name: "What are you hoping to get from the community?", href: "#", status: "upcoming" },
    { id: "Step 4", name: "What are you hoping to contribute to the community?", href: "#", status: "upcoming" }
  ];

  const [progress, setProgress] = useState(`${Math.floor((currentStep / initialSteps.length) * 100)}%`);

  useEffect(() => {
    // if onboarding is complete or skipped, update the user's profile
    if (isOnboardingComplete) {
      handleUpdateProfile().then(() => {
        shootConfetti();

        // make sure the user is logged out,
        // so they're not redirected to the feed
        // in a logged in state
        // removeAuthState();
        setTimeout(() => {
          navigate(`${AppRoute.Apply}/thank-you`);
        }, 500);
      });
    }
  }, [isOnboardingComplete]);

  useGetCurrentUserQuery({
    onCompleted: (data) => {
      if (data?.GetCurrentUser?.error) {
        toast("Error fetching user", {
          icon: "🚨"
        });
      } else {
        if (data?.GetCurrentUser?.data) {
          const user: User = data?.GetCurrentUser?.data as User;
          if (user?.timezone) {
            // nullish coalescing doesn't work on empty strings,
            // and we need to set the timezone to undefined if it's empty
            setTimezone(user?.timezone);
          }
          setName(user?.name ?? "");
          setShortBio(user?.profile?.shortBio ?? "");
          setBio(user?.profile?.bio ?? "");
          setLocation(user?.profile?.location ?? "");
          setHopeToGet(user?.profile?.idealCodingPartner ?? "");

          if (user?.tags?.length) {
            setSelectedTags(user?.tags as Tag[]);
          }
        }
      }
    }
  });

  const [updateProfile] = useUpdateProfileMutation({
    variables: {
      input: {
        name: name.trim(),
        location: location.trim(),
        timezone,
        shortBio: shortBio.trim(),
        hopeToGet: hopeToGet.trim(),
        hopeToGive: hopeToGive.trim(),
        isOnboarded: isOnboardingComplete
      }
    },
    onError: (error) => {
      toast.error("Error updating profile");
    },
    onCompleted: (data) => {
      if (data?.UpdateProfile?.error) {
        toast.error("Error updating profile", { icon: "🚨" });
      }
    }
  });

  const handleUpdateProfile = async () => {
    await updateProfile();
    await updateTagsForUser();
  };

  const validateStepOneRequiredFields = () => {
    if (!timezone) {
      toast.error("Timezone is required", { icon: "🚨" });
      return false;
    }
    return true;
  };

  const validateStepTwoRequiredFields = () => {
    if (!selectedTechTags.length && !selectedTrackTags.length && !selectedTopicTags.length) {
      toast.error("Please select at least one tag", { icon: "🚨" });
      return false;
    }
    return true;
  };

  const validateStepThreeRequiredFields = () => {
    if (!hopeToGet) {
      toast.error("Please enter what you hope to get from the community", { icon: "🚨" });
      return false;
    } else if (hopeToGet.trim().length < minHopeToGetLength || hopeToGet.trim().length > maxHopeToGetLength) {
      toast.error(`Must be between ${minHopeToGetLength} and ${maxHopeToGetLength} characters`, { icon: "🚨" });
      return false;
    }
    return true;
  };

  const validateStepFourRequiredFields = () => {
    if (!hopeToGive) {
      toast.error("Please enter what you hope to contribute to the community", { icon: "🚨" });
      return false;
    } else if (hopeToGive.trim().length < minHopeToGiveLength || hopeToGive.trim().length > maxHopeToGiveLength) {
      toast.error(`Must be between ${minHopeToGiveLength} and ${maxHopeToGiveLength} characters`, { icon: "🚨" });
      return false;
    }
    return true;
  };


  const handleCompleteOnboarding = async () => {
    const isValid = validateStepOneRequiredFields()
      && validateStepTwoRequiredFields()
      && validateStepThreeRequiredFields()
      && validateStepFourRequiredFields();

    if (!isValid) {
      return;
    }
    shootConfetti();
    mixpanel.track(AnalyticsEvent.OnboardingCompleted);
    setIsOnboardingComplete(true);
  };

  const handleSkipOnboarding = async () => {
    const isValid = validateStepOneRequiredFields() && validateStepTwoRequiredFields() && validateStepThreeRequiredFields() && validateStepFourRequiredFields();
    if (!isValid) {
      return;
    }
    mixpanel.track(AnalyticsEvent.OnboardingSkipped, {
      onboardingStep: currentStep
    });
    setIsOnboardingComplete(true);
  };

  const [updateTagsForUser] = useUpdateTagsForUserMutation({
    onCompleted: (data: UpdateTagsForUserMutation) => {
      if (data?.UpdateTagsForUser?.error) {
        toast.error("Error updating tags", { icon: "🚨" });
      }
    },
    onError: (error) => {
      toast.error("Error updating tags");
    },
    variables: {
      input: {
        tags: [...selectedTechTags, ...selectedTrackTags, ...selectedTopicTags].map((tag: Tag) => tag.value) as string[]
      }
    }
  });


  const [steps, setSteps] = useState(initialSteps);

  const maxShortBioLength = 100;

  const minHopeToGetLength = 16;
  const maxHopeToGetLength = 512;

  const minHopeToGiveLength = 16;
  const maxHopeToGiveLength = 512;

  const handleSetShortBio = (text: string) => {
    if (text.trim().length > maxShortBioLength) {
      setDisplayShortBioError(true);
      setShortBioCharacters(text.trim().length);
      return toast.error(`Short bio must be ${maxShortBioLength} characters or less`);
    }
    if (displayShortBioError) {
      setDisplayShortBioError(false);
      setShortBioCharacters(0);
    }
    setShortBio(text);
  };

  const handleSetHopeToGet = (text: string) => {
    if (text.trim().length > maxHopeToGetLength || text.trim().length < minHopeToGetLength) {
      setDisplayHopeToGetError(true);
      setHopeToGetCharacters(text.trim().length);
    } else if (displayHopeToGetError) {
      setDisplayHopeToGetError(false);
      setHopeToGetCharacters(0);
    }
    setHopeToGet(text);
  }

  const handleSetHopeToGive = (text: string) => {
    if (text.trim().length > maxHopeToGiveLength || text.trim().length < minHopeToGiveLength) {
      setDisplayHopeToGiveError(true);
      setHopeToGiveCharacters(text.trim().length);
    } else if (displayHopeToGiveError) {
      setDisplayHopeToGiveError(false);
      setHopeToGiveCharacters(0);
    }
    setHopeToGive(text);
  }


  const handleSetPage = async (stepToGoTo: number) => {
    let isPageValid = true;
    if (currentStep === 1 && stepToGoTo === 2) {
      isPageValid = validateStepOneRequiredFields();
    } else if (currentStep === 2 && stepToGoTo === 3) {
      isPageValid = validateStepTwoRequiredFields();
    } else if (currentStep === 3 && stepToGoTo === 4) {
      isPageValid = validateStepThreeRequiredFields();
    }
    // let validation for last page be handled by the complete onboarding function

    if (!isPageValid) {
      return;
    }

    const pageIsOutOfBounds = stepToGoTo > steps.length || stepToGoTo < 1;
    if (!pageIsOutOfBounds) {
      setCurrentStep(stepToGoTo);
      setProgress(`${Math.floor((stepToGoTo / steps.length) * 100)}%`);
      const newSteps = steps.map((currentStepObj, index) => {
        const currentStepNumber = index + 1;
        if (currentStepNumber < stepToGoTo) {
          currentStepObj.status = "complete";
        }
        if (currentStepNumber === stepToGoTo) {
          currentStepObj.status = "current";
        }
        if (currentStepNumber > stepToGoTo) {
          currentStepObj.status = "upcoming";
        }
        return currentStepObj;
      });
      setSteps(newSteps);
      await handleUpdateProfile();
    }
  };

  return (
    <div
      className={"flex flex-col w-auto min-h-screen overflow-scroll"}>
      <div className="grid grid-cols-3 items-center justify-between h-24 flex-shrink-0">
        <div className={"hidden sm:block sm:col-span-1"} />

        <div className="col-span-3 sm:col-span-1 text-center items-center">
          <button>
            <Icons.Logo className="h-12 text-black flex-shrink-0" />
          </button>
        </div>

        <div className={"hidden sm:block sm:col-span-1 text-right px-4"} />
      </div>

      <div className="flex items-center justify-center w-full text-center">
        <p className={"text-sm text-scale-700 max-w-lg"}>
          Complete your application to grow, connect, and learn job-ready skills with our community of developers who
          are dedicated to collaboration and continuous learning.
        </p>
      </div>

      <div className="flex items-center justify-center w-full">
        <div className="max-w-lg w-full flex justify-center items-center py-4 px-4">
          <div className="w-full bg-green-100 rounded-full dark:bg-gray-700 outline outline-4 outline-green-100">
            <div
              className="h-4 items-center flex justify-center bg-green-500 text-xs font-semibold text-green-100 text-center p-0.5 leading-none rounded-full animate animate"
              style={{ width: progress }}>{`${progress}`}</div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center px-4 sm:px-0 py-4">
        <div className="flex flex-1 justify-between max-w-lg">

          <Button
            variant={"default"}
            size={"sm"}
            disabled={currentStep <= 1}
            onClick={async () => await handleSetPage(currentStep - 1)}
            className={cn(
              "text-xs font-mono relative inline-flex items-center",
              "disabled:opacity-60 disabled:cursor-not-allowed cursor-pointer"
            )}
          >
            <Icons.ChevronLeft className="h-4 w-4 -ml-2 mr-1" />
            Prev
          </Button>

          <nav className="flex items-center justify-center" aria-label="Progress">
            <ul role="list" className="flex items-center space-x-5">
              {steps.map((step, index) => (
                <li
                  key={step.name}
                >
                  {step.status === "complete" ? (
                    <button
                      className="cursor-pointer block h-2.5 w-2.5 rounded-full bg-green-600 hover:bg-green-600">
                      <span className="sr-only">{step.name}</span>
                    </button>
                  ) : step.status === "current" ? (
                    <button
                      className="cursor-pointer relative flex items-center justify-center" aria-current="step">
                      <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                        <span className="h-full w-full rounded-full bg-green-500/50" />
                      </span>
                      <span className="relative block h-2.5 w-2.5 rounded-full bg-green-400" aria-hidden="true" />
                      <span className="sr-only">{step.name}</span>
                    </button>
                  ) : (
                    <button
                      disabled={currentStep < index + 1}
                      className="cursor-pointer block h-2.5 w-2.5 rounded-full bg-green-200 hover:bg-scale-400">
                      <span className="sr-only">{step.name}</span>
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          <Button
            variant={"default"}
            size={"sm"}
            disabled={currentStep > steps.length}
            onClick={async () => {
              const isLastStep = currentStep === steps.length;
              if (isLastStep) {
                await handleCompleteOnboarding();
                return;
              }
              await handleSetPage(currentStep + 1);
            }}
            className={cn(
              "text-xs font-mono relative inline-flex items-center",
              "disabled:opacity-60 disabled:cursor-not-allowed cursor-pointer",
              currentStep === steps.length && "bg-green-500 hover:bg-green-600 text-scale-25"
            )}
          >
            {currentStep === steps.length ? "Finish" : "Next"}
            <Icons.ChevronRight className="h-4 w-4 -mr-2 ml-1" />
          </Button>


        </div>
      </div>

      <div className={"flex mx-auto grow px-4 sm:px-0"}>
        <div className="flex-1">

          <div
            className={"px-4 sm:px-8 py-6 border-4 border-scale-50 backdrop-blur-sm bg-white/75 max-w-lg w-[800px] rounded-lg shadow-md"}>

            <div className="flex justify-center items-center w-full">
              <h1 className={"text-lg font-semibold font-mono text-scale-900"}>
                {steps?.[currentStep - 1]?.name}
              </h1>
            </div>

            {/* SECTION 1 */}
            {
              currentStep === 1 && (
                <div className="py-4 flex flex-col mx-auto justify-center items-center shrink-0 space-y-4">
                  <div className="w-full">
                    <Label htmlFor="name"
                           className="block text-sm font-medium leading-6 text-scale-950">
                      Name
                    </Label>
                    <p className="mt-1 text-sm leading-6 text-scale-700">
                      Add your first and last name
                    </p>
                    <div className="mt-2">
                      <Input
                        type="text"
                        placeholder={"Linus Torvalds"}
                        onChange={(e) => setName(e.target.value)}
                        defaultValue={name ?? ""}
                      />
                    </div>
                  </div>


                  <div className="w-full">
                    <Label htmlFor="shortBio"
                           className="block text-sm font-medium leading-6 text-scale-950">
                      Tagline
                    </Label>
                    <p className="mt-1 text-sm leading-6 text-scale-700">
                      Add a tagline to let others know what you're about
                    </p>


                    {/* show error label */}
                    {
                      displayShortBioError && (
                        <p className={"text-xs font-medium text-rose-500 pt-2"}>
                          Tagline must be {maxShortBioLength} characters or less.
                          Currently {shortBioCharacters} characters.
                        </p>
                      )
                    }
                    <div className="mt-2">
                      <Input
                        type="text"
                        placeholder={"Aspiring developer looking for help with interview prep"}
                        onChange={(e) => handleSetShortBio(e.target.value)}
                        defaultValue={shortBio}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <Label htmlFor="location"
                           className="block text-sm font-medium leading-6 text-scale-950">
                      Location
                    </Label>

                    <p className="mt-1 text-sm leading-6 text-scale-700">
                      Add your location to let others know where you're based out of
                    </p>

                    <div className="mt-2">
                      <Input
                        type="text"
                        placeholder={"Boston, MA"}
                        onChange={(e) => setLocation(e.target.value)}
                        defaultValue={location ?? ""}
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <Label htmlFor="timezone"
                           className="w-full block text-sm font-medium leading-6 text-scale-950">
                      Timezone
                    </Label>
                    <p className="mt-1 text-sm leading-6 text-scale-700">
                      Add your timezone so others can see your local time
                    </p>
                    <div className="mt-2">
                      <TimezoneDropdown value={timezone} onChange={setTimezone} />
                    </div>
                  </div>
                </div>
              )
            }
            {
              currentStep === 2 && (
                <div
                  className="py-4 w-full flex flex-col mx-auto justify-center items-center shrink-0 space-y-4">
                  <div className="w-full">
                    <div className={"pb-2"}>
                      <Label htmlFor="tags" className="text-scale-700">
                        Technologies
                      </Label>
                      <p className="mt-1 text-sm leading-6 text-scale-700">
                        Select the technologies you're familiar with or interested in
                      </p>
                    </div>
                    <ComboBox
                      className={"w-full bg-white rounded-md"}
                      items={techTags as ComboBoxItem[]}
                      setSelectedItems={setSelectedTechTags}
                      selectedItems={selectedTechTags as ComboBoxItem[]}
                    />
                  </div>
                  <div className="w-full">
                    <div className={"pb-2"}>
                      <Label htmlFor="tags" className="text-scale-700">
                        Tracks
                      </Label>
                      <p className="mt-1 text-sm leading-6 text-scale-700">
                        Select the tracks you're interested in or have experience with
                      </p>
                    </div>
                    <ComboBox
                      className={"w-full bg-white rounded-md"}
                      items={trackTags as ComboBoxItem[]}
                      setSelectedItems={setSelectedTrackTags}
                      selectedItems={selectedTrackTags as ComboBoxItem[]}
                    />
                  </div>
                  <div className="w-full">
                    <div className={"pb-2"}>
                      <Label htmlFor="tags" className="text-scale-700">
                        Topics
                      </Label>
                      <p className="mt-1 text-sm leading-6 text-scale-700">
                        Select the topics you're interested in or have experience with
                      </p>
                    </div>
                    <ComboBox
                      className={"w-full bg-white rounded-md"}
                      items={topicTags as ComboBoxItem[]}
                      setSelectedItems={setSelectedTopicTags}
                      selectedItems={selectedTopicTags as ComboBoxItem[]}
                    />
                  </div>

                </div>
              )
            }
            {
              currentStep === 3 && (
                <div className="py-4 flex flex-col mx-auto justify-center items-center shrink-0 space-y-4">
                  <div className="w-full">
                    <p className="mt-1 text-sm leading-6 text-scale-700">
                      This helps us determine if we're a good fit for you and vice versa
                    </p>
                    <div className="mt-2">
                      <Textarea
                        rows={7}
                        placeholder={"I'm looking for a community of like-minded developers to help me get past the programming learning curve. I'm also looking for a mentor to help me with interview prep."}
                        onChange={(e) => handleSetHopeToGet(e.target.value)}
                        defaultValue={hopeToGet ?? ""}
                      />
                    </div>
                    {
                      displayHopeToGetError && (
                        <p className={"text-xs font-medium text-rose-500 pt-2"}>
                          Must be between {minHopeToGetLength} and {maxHopeToGetLength} characters.
                          Currently {hopeToGetCharacters} characters.
                        </p>
                      )
                    }
                  </div>

                </div>
              )
            }

            {
              currentStep === 4 && (
                <div className="py-4 flex flex-col mx-auto justify-center items-center shrink-0 space-y-4">
                  <div className="w-full">
                    <p className="mt-1 text-sm leading-6 text-scale-700">
                      Regardless of your experience level, we're all here to help each other grow! What are you hoping to
                      contribute to the community?
                    </p>
                    <div className="mt-2">
                      <Textarea
                        rows={7}
                        placeholder={"I'm looking to regularly share my progress, review other people's code and provide feedback (as best as I can), and share helpful resources that I come across."}
                        onChange={(e) => handleSetHopeToGive(e.target.value)}
                        defaultValue={hopeToGive ?? ""}
                      />
                    </div>
                    {
                      displayHopeToGiveError && (
                        <p className={"text-xs font-medium text-rose-500 pt-2"}>
                          Must be between {minHopeToGiveLength} and {maxHopeToGiveLength} characters.
                          Currently {hopeToGiveCharacters} characters.
                        </p>
                      )
                    }
                  </div>
                </div>
              )
            }

          </div>

        </div>
      </div>


    </div>
  );
}
