import {
  AddTaskReactionError,
  ReactionType, RemoveTaskReactionError,
  Task,
  TaskPartsFragment, TaskPartsFragmentDoc,
  useAddTaskReactionMutation, useRemoveTaskReactionMutation
} from "@pairprogram/graphql";
import toast from "react-hot-toast";

export default function useTaskReaction(task: Task) {
  const [addReaction] = useAddTaskReactionMutation({
    variables: {
      input: {
        taskId: task?.id!,
        reactionType: ReactionType.Like
      }
    },
    onCompleted: (data) => {
      if (data?.AddTaskReaction?.error) {
        if (data?.AddTaskReaction?.error === AddTaskReactionError.AlreadyExists) {
          toast.error("You have already liked this task", { icon: "😌" });
          return;
        }
      }
      if (data?.AddTaskReaction?.data) {
        toast.success("Task liked");
      }
    },
    update: (cache, { data }) => {
      if (data?.AddTaskReaction?.data) {
        const existing: TaskPartsFragment | null = cache.readFragment({
          id: `Task:${task?.id}`,
          fragment: TaskPartsFragmentDoc,
          fragmentName: "TaskParts"
        });

        if (existing) {
          const updated = {
            ...existing,
            likedByCurrentUser: true,
            likeCount: (existing?.likeCount ?? 0) + 1
          };

          cache.writeFragment({
            id: `Task:${task?.id}`,
            fragment: TaskPartsFragmentDoc,
            fragmentName: "TaskParts",
            data: updated
          });
        }
      }
    }
  });

  const [removeReaction] = useRemoveTaskReactionMutation({
    variables: {
      input: {
        taskId: task?.id!,
        reactionType: ReactionType.Like
      }
    },
    onCompleted: (data) => {
      if (data?.RemoveTaskReaction?.error) {
        if (data?.RemoveTaskReaction?.error === RemoveTaskReactionError.Forbidden) {
          toast.error("Unable to remove reaction", { icon: "😬" });
          return;
        }
        toast.error("Error unliking task");
        return;
      }
      if (data?.RemoveTaskReaction?.data) {
        toast.success("Task unliked");
      }
    },
    update: (cache, { data }) => {
      if (data?.RemoveTaskReaction?.data) {

        const existing: TaskPartsFragment | null = cache.readFragment({
          id: `Task:${task?.id}`,
          fragment: TaskPartsFragmentDoc,
          fragmentName: "TaskParts"
        });

        if (existing) {
          const updated = {
            ...existing,
            likedByCurrentUser: false,
            likeCount: (existing?.likeCount ?? 0) > 0 ? existing?.likeCount! - 1 : 0
          };

          cache.writeFragment({
            id: `Task:${task?.id}`,
            fragment: TaskPartsFragmentDoc,
            fragmentName: "TaskParts",
            data: updated
          });
        }
      }
    }
  });

  function toggleReaction() {
    if (task?.likedByCurrentUser) {
      removeReaction();
    } else {
      addReaction();
    }
  }

  return {
    toggleReaction,
  };
}
