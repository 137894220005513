import {
  Badge, badgeVariants,
  Button, buttonVariants,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@/components/ui";
import { Check, ChevronsUpDown, XIcon } from "lucide-react";
import { cn } from "@/utils";
import React, { useState } from "react";
import { capitalizeFirstLetter } from "@/utils/string.utils";
import TagBadge from "@/components/tag-badge";
import { Tag, TagType } from "@pairprogram/graphql";

export interface ComboBoxItem {
  id: string;
  value: string;
  label: string;
  type: string | TagType;
}

interface ComboBoxProps {
  items: ComboBoxItem[];
  selectedItems: ComboBoxItem[];
  setSelectedItems: Function;
  displayItemsSeparately?: boolean;
  className?: string;
  badgeColor?: "red" | "blue" | "purple" | "primary" | "destructive" | "default" | "outline" | "ghost";
}


export default function ComboBox({ items, selectedItems, setSelectedItems, displayItemsSeparately = false, className = "", badgeColor = "default" }: ComboBoxProps) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const handleItemSelect = (item: ComboBoxItem) => {
    setSelectedItems(selectedItems.concat(item));
    setDropdownOpen(false);
  };

  const handleItemDeselect = (item: ComboBoxItem) => {
    setSelectedItems(selectedItems.filter(i => i.value !== item.value));
  };

  const availableItems = items.filter(item => !selectedItems.map(i => i.value).includes(item.value));

  return (
    <>
      <Popover open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <PopoverTrigger className={
          cn(className)
        }>
          <div
            role="combobox"
            aria-expanded={dropdownOpen}
            className={cn(
              buttonVariants({ variant: "outline", size: "sm" }),
              "w-full justify-between",
              "shadow-sm"
            )}
          >
            <span
              className={cn(
                !selectedItems.length && "text-scale-400" // effectively placeholder text
              )}
            >{selectedItems.length ? `${selectedItems.length} selected` : "Select item..."}</span>
            <ChevronsUpDown className="ml-2 min-h-fit w-4 shrink-0 opacity-50" />
          </div>
        </PopoverTrigger>


        <PopoverContent
          className="w-full max-h-64 overflow-y-scroll p-0 bg-scale-25 dark:bg-scale-950 border-scale-200 dark:border-scale-700">
          <Command>
            <CommandInput className={"w-full placeholder:text-scale-400 text-scale-900"} placeholder="Search items..." />
            <CommandEmpty>No tags found.</CommandEmpty>
            <CommandGroup className={'w-full'}>
              {availableItems.map((tag) => {
                return (
                  <CommandItem
                    className={"cursor-pointer"}
                    key={tag.value}
                    onSelect={() => handleItemSelect(tag)}
                  >
                    <Check
                      className={cn("mr-2 h-4 w-4", selectedItems.includes(tag) ? "opacity-100" : "opacity-0")}
                    />
                    <div className="flex items-center justify-between w-full">
                      <p>{tag.label}</p>
                      <p>{ tag?.type && <Badge variant={badgeColor}>{capitalizeFirstLetter(tag.type)}</Badge> }</p>
                    </div>
                  </CommandItem>
                )
              })}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      {
        !displayItemsSeparately &&
        selectedItems.length ? (
          <div className="py-2 flex flex-wrap gap-y-1">
            {selectedItems.map(item => {
              // @ts-ignore
              if (item.__typename === "Tag") {
                return (
                  <TagBadge key={item.value} tag={item as Tag}>
                    {item.label}
                    <XIcon className={cn(
                      "cursor-pointer ml-1 h-3 w-3"
                    )} onClick={() => handleItemDeselect(item)} />
                  </TagBadge>
                )
              }
              const xColor = ["brand", "primary", "red", "blue", "purple"].includes(badgeColor) ? `text-${badgeColor}-700` : "text-scale-600";
              return (
                <Badge key={item.value} className="m-1" variant={badgeColor}>
                  {item.label}
                  <XIcon className={cn(
                    xColor,
                    "cursor-pointer ml-1 h-3 w-3"
                  )} onClick={() => handleItemDeselect(item)} />
                </Badge>
              )
            })}
          </div>
        ) : null
      }

    </>
  );
}
