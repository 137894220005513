"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlatformEventTypeError = exports.PlatformEventTypeEnum = exports.GetUsersError = exports.GetUserTagSubscriptionsError = exports.GetUserRewardsError = exports.GetUserError = exports.GetUserBadgesError = exports.GetTasksError = exports.GetTaskTypesError = exports.GetTaskError = exports.GetTagsError = exports.GetSessionsForUserError = exports.GetSessionsForPostError = exports.GetRewardsError = exports.GetRandomUserError = exports.GetProjectsError = exports.GetProjectError = exports.GetPostsForUserError = exports.GetPostTypesByIdsError = exports.GetPostError = exports.GetPlatformEventTypesByIdsError = exports.GetPlatformEventError = exports.GetLatestPostsError = exports.GetCurrentUserError = exports.GetChatsForUserError = exports.GetChatError = exports.GetBadgesError = exports.GetAllPostTypesError = exports.GetAllPlatformEventTypesError = exports.FollowingEdgeError = exports.FollowersEdgeError = exports.FollowUserError = exports.DeleteProjectError = exports.CreateVideoSessionTokenError = exports.CreateProjectError = exports.CreatePostError = exports.ChatsEdgeError = exports.ChatMessagesEdgeError = exports.ChatMessageStatusEdgeError = exports.ChatMessageEdgeError = exports.ChatEdgeError = exports.BadgesEdgeError = exports.AuthError = exports.AdminUpdateTaskError = exports.AdminDeleteTaskError = exports.AdminCreateTaskError = exports.AddTaskReactionError = exports.AddProjectReactionError = exports.AddPostReactionError = exports.AddPostCommentError = void 0;
exports.UploadEdgeError = exports.UpdateUserTagSubscriptionsError = exports.UpdateUserStatusError = exports.UpdateTagsForUserError = exports.UpdateProjectError = exports.UpdateProfileError = exports.UpdateAllChatMessageStatusesForChatError = exports.TrackPlatformEventError = exports.TaskTypeEnum = exports.TaskTypeEdgeError = exports.TaskSortEnum = exports.TaskResourcesEdgeError = exports.TaskReactionsEdgeError = exports.TaskReactionEdgeError = exports.TaskFilterEnum = exports.TaskEdgeError = exports.TaskDifficulty = exports.TagsEdgeError = exports.TagType = exports.SubmitTaskFeedbackError = exports.SubmitPlatformFeedbackError = exports.StartChatError = exports.SkillLevel = exports.SessionsEdgeError = exports.SendChatMessageError = exports.Role = exports.RewardsEdgeError = exports.RemoveTaskReactionError = exports.RemoveProjectReactionError = exports.RemovePostReactionError = exports.ReactionsEdgeError = exports.ReactionType = exports.ReactionEdgeError = exports.ProjectsEdgeError = exports.ProjectSortEnum = exports.ProjectReactionsEdgeError = exports.ProjectReactionEdgeError = exports.ProjectFilterEnum = exports.ProjectEdgeError = exports.ProfileStatsError = exports.PostsEdgeError = exports.PostTypeEnum = exports.PostTypeEdgeError = exports.PostSortEnum = exports.PostReactionsEdgeError = exports.PostReactionEdgeError = exports.PostFilterEnum = exports.PostEdgeError = exports.PostCommentsEdgeError = exports.PostCommentEdgeError = void 0;
exports.OnChatMessageDocument = exports.useGetChatsForUserLazyQuery = exports.useGetChatsForUserQuery = exports.GetChatsForUserDocument = exports.useGetChatLazyQuery = exports.useGetChatQuery = exports.GetChatDocument = exports.useSendChatMessageMutation = exports.SendChatMessageDocument = exports.useStartChatMutation = exports.StartChatDocument = exports.useGetUserBadgesLazyQuery = exports.useGetUserBadgesQuery = exports.GetUserBadgesDocument = exports.useGetBadgesLazyQuery = exports.useGetBadgesQuery = exports.GetBadgesDocument = exports.AuthUserPartsFragmentDoc = exports.UserTaskPartsFragmentDoc = exports.TaskPartsFragmentDoc = exports.UploadPartsFragmentDoc = exports.TaskTypePartsFragmentDoc = exports.TaskFeedbackPartsFragmentDoc = exports.TagPartsFragmentDoc = exports.SessionPartsFragmentDoc = exports.UserSessionPartsFragmentDoc = exports.UserPartsFragmentDoc = exports.PostPartsFragmentDoc = exports.CreateVideoSessionTokenPartsFragmentDoc = exports.RewardPartsFragmentDoc = exports.ReactionPartsFragmentDoc = exports.ProjectPartsFragmentDoc = exports.ProfileStatsPartsFragmentDoc = exports.ProfilePartsFragmentDoc = exports.PostTypePartsFragmentDoc = exports.PostCommentPartsFragmentDoc = exports.PlatformFeedbackPartsFragmentDoc = exports.PlatformEventPartsFragmentDoc = exports.PlatformEventTypePartsFragmentDoc = exports.MessageStatusPartsFragmentDoc = exports.ChatMessagesEdgePartsFragmentDoc = exports.MessagePartsFragmentDoc = exports.ChatPartsFragmentDoc = exports.BadgePartsFragmentDoc = exports.UsersEdgeError = exports.UserHeartbeatError = exports.UserFeedbackEdgeError = exports.UserEdgeError = exports.UpsertUserTaskError = exports.UploadsEdgeError = void 0;
exports.useGetProjectsLazyQuery = exports.useGetProjectsQuery = exports.GetProjectsDocument = exports.useGetProjectLazyQuery = exports.useGetProjectQuery = exports.GetProjectDocument = exports.useRemoveProjectReactionMutation = exports.RemoveProjectReactionDocument = exports.useAddProjectReactionMutation = exports.AddProjectReactionDocument = exports.useUpdateProfileMutation = exports.UpdateProfileDocument = exports.usePostUpdatedSubscription = exports.PostUpdatedDocument = exports.usePostCreatedSubscription = exports.PostCreatedDocument = exports.useCreatePostMutation = exports.CreatePostDocument = exports.useGetLatestPostsLazyQuery = exports.useGetLatestPostsQuery = exports.GetLatestPostsDocument = exports.useGetPostsForUserLazyQuery = exports.useGetPostsForUserQuery = exports.GetPostsForUserDocument = exports.useGetPostLazyQuery = exports.useGetPostQuery = exports.GetPostDocument = exports.useGetAllPostTypesLazyQuery = exports.useGetAllPostTypesQuery = exports.GetAllPostTypesDocument = exports.useRemovePostReactionMutation = exports.RemovePostReactionDocument = exports.useAddPostReactionMutation = exports.AddPostReactionDocument = exports.useAddPostCommentMutation = exports.AddPostCommentDocument = exports.useSubmitPlatformFeedbackMutation = exports.SubmitPlatformFeedbackDocument = exports.useTrackPlatformEventMutation = exports.TrackPlatformEventDocument = exports.useOnPlatformEventSubscription = exports.OnPlatformEventDocument = exports.useGetLatestPlatformEventsLazyQuery = exports.useGetLatestPlatformEventsQuery = exports.GetLatestPlatformEventsDocument = exports.useFollowUserMutation = exports.FollowUserDocument = exports.useUpdateAllChatMessageStatusesForChatMutation = exports.UpdateAllChatMessageStatusesForChatDocument = exports.useOnChatMessageSubscription = void 0;
exports.useGetTaskTypesQuery = exports.GetTaskTypesDocument = exports.useAdminDeleteTaskMutation = exports.AdminDeleteTaskDocument = exports.useAdminUpdateTaskMutation = exports.AdminUpdateTaskDocument = exports.useAdminCreateTaskMutation = exports.AdminCreateTaskDocument = exports.useGetTasksLazyQuery = exports.useGetTasksQuery = exports.GetTasksDocument = exports.useGetTaskLazyQuery = exports.useGetTaskQuery = exports.GetTaskDocument = exports.useRemoveTaskReactionMutation = exports.RemoveTaskReactionDocument = exports.useAddTaskReactionMutation = exports.AddTaskReactionDocument = exports.useSubmitTaskFeedbackMutation = exports.SubmitTaskFeedbackDocument = exports.useUpdateTagsForUserMutation = exports.UpdateTagsForUserDocument = exports.useGetTagsLazyQuery = exports.useGetTagsQuery = exports.GetTagsDocument = exports.useGetUserTagSubscriptionsLazyQuery = exports.useGetUserTagSubscriptionsQuery = exports.GetUserTagSubscriptionsDocument = exports.useUpdateUserTagSubscriptionsMutation = exports.UpdateUserTagSubscriptionsDocument = exports.useGetSessionsForUserLazyQuery = exports.useGetSessionsForUserQuery = exports.GetSessionsForUserDocument = exports.useGetSessionsForPostLazyQuery = exports.useGetSessionsForPostQuery = exports.GetSessionsForPostDocument = exports.useCreateVideoSessionTokenMutation = exports.CreateVideoSessionTokenDocument = exports.useGetUserRewardsLazyQuery = exports.useGetUserRewardsQuery = exports.GetUserRewardsDocument = exports.useGetRewardsLazyQuery = exports.useGetRewardsQuery = exports.GetRewardsDocument = exports.useDeleteProjectMutation = exports.DeleteProjectDocument = exports.useUpdateProjectMutation = exports.UpdateProjectDocument = exports.useCreateProjectMutation = exports.CreateProjectDocument = void 0;
exports.useOnUserHeartbeatSubscription = exports.OnUserHeartbeatDocument = exports.useUpdateUserStatusMutation = exports.UpdateUserStatusDocument = exports.useGetHeartbeatLazyQuery = exports.useGetHeartbeatQuery = exports.GetHeartbeatDocument = exports.useGetRandomUserLazyQuery = exports.useGetRandomUserQuery = exports.GetRandomUserDocument = exports.useGetUserLazyQuery = exports.useGetUserQuery = exports.GetUserDocument = exports.useGetCurrentUserLazyQuery = exports.useGetCurrentUserQuery = exports.GetCurrentUserDocument = exports.useGetUsersLazyQuery = exports.useGetUsersQuery = exports.GetUsersDocument = exports.useUpsertUserTaskMutation = exports.UpsertUserTaskDocument = exports.useGetTaskTypesLazyQuery = void 0;
const client_1 = require("@apollo/client");
const Apollo = __importStar(require("@apollo/client"));
const defaultOptions = {};
var AddPostCommentError;
(function (AddPostCommentError) {
    AddPostCommentError["BadRequest"] = "BAD_REQUEST";
    AddPostCommentError["NotFound"] = "NOT_FOUND";
    AddPostCommentError["Unauthorized"] = "UNAUTHORIZED";
    AddPostCommentError["Unknown"] = "UNKNOWN";
})(AddPostCommentError || (exports.AddPostCommentError = AddPostCommentError = {}));
var AddPostReactionError;
(function (AddPostReactionError) {
    AddPostReactionError["AlreadyExists"] = "ALREADY_EXISTS";
    AddPostReactionError["BadRequest"] = "BAD_REQUEST";
    AddPostReactionError["Forbidden"] = "FORBIDDEN";
    AddPostReactionError["NotFound"] = "NOT_FOUND";
    AddPostReactionError["Unauthorized"] = "UNAUTHORIZED";
    AddPostReactionError["Unknown"] = "UNKNOWN";
})(AddPostReactionError || (exports.AddPostReactionError = AddPostReactionError = {}));
var AddProjectReactionError;
(function (AddProjectReactionError) {
    AddProjectReactionError["AlreadyExists"] = "ALREADY_EXISTS";
    AddProjectReactionError["BadRequest"] = "BAD_REQUEST";
    AddProjectReactionError["Forbidden"] = "FORBIDDEN";
    AddProjectReactionError["NotFound"] = "NOT_FOUND";
    AddProjectReactionError["Unauthorized"] = "UNAUTHORIZED";
    AddProjectReactionError["Unknown"] = "UNKNOWN";
})(AddProjectReactionError || (exports.AddProjectReactionError = AddProjectReactionError = {}));
var AddTaskReactionError;
(function (AddTaskReactionError) {
    AddTaskReactionError["AlreadyExists"] = "ALREADY_EXISTS";
    AddTaskReactionError["BadRequest"] = "BAD_REQUEST";
    AddTaskReactionError["Forbidden"] = "FORBIDDEN";
    AddTaskReactionError["NotFound"] = "NOT_FOUND";
    AddTaskReactionError["Unauthorized"] = "UNAUTHORIZED";
    AddTaskReactionError["Unknown"] = "UNKNOWN";
})(AddTaskReactionError || (exports.AddTaskReactionError = AddTaskReactionError = {}));
var AdminCreateTaskError;
(function (AdminCreateTaskError) {
    AdminCreateTaskError["BadRequest"] = "BAD_REQUEST";
    AdminCreateTaskError["Unauthorized"] = "UNAUTHORIZED";
    AdminCreateTaskError["Unknown"] = "UNKNOWN";
})(AdminCreateTaskError || (exports.AdminCreateTaskError = AdminCreateTaskError = {}));
var AdminDeleteTaskError;
(function (AdminDeleteTaskError) {
    AdminDeleteTaskError["BadRequest"] = "BAD_REQUEST";
    AdminDeleteTaskError["NotFound"] = "NOT_FOUND";
    AdminDeleteTaskError["Unauthorized"] = "UNAUTHORIZED";
    AdminDeleteTaskError["Unknown"] = "UNKNOWN";
})(AdminDeleteTaskError || (exports.AdminDeleteTaskError = AdminDeleteTaskError = {}));
var AdminUpdateTaskError;
(function (AdminUpdateTaskError) {
    AdminUpdateTaskError["BadRequest"] = "BAD_REQUEST";
    AdminUpdateTaskError["NotFound"] = "NOT_FOUND";
    AdminUpdateTaskError["Unauthorized"] = "UNAUTHORIZED";
    AdminUpdateTaskError["Unknown"] = "UNKNOWN";
})(AdminUpdateTaskError || (exports.AdminUpdateTaskError = AdminUpdateTaskError = {}));
var AuthError;
(function (AuthError) {
    AuthError["ApplicationIncomplete"] = "APPLICATION_INCOMPLETE";
    AuthError["ApplicationPendingApproval"] = "APPLICATION_PENDING_APPROVAL";
    AuthError["IncorrectCredentials"] = "INCORRECT_CREDENTIALS";
    AuthError["InvalidToken"] = "INVALID_TOKEN";
    AuthError["Unknown"] = "UNKNOWN";
    AuthError["UserAlreadyExists"] = "USER_ALREADY_EXISTS";
    AuthError["UserNotFound"] = "USER_NOT_FOUND";
})(AuthError || (exports.AuthError = AuthError = {}));
var BadgesEdgeError;
(function (BadgesEdgeError) {
    BadgesEdgeError["BadRequest"] = "BAD_REQUEST";
    BadgesEdgeError["NotFound"] = "NOT_FOUND";
    BadgesEdgeError["Unknown"] = "UNKNOWN";
})(BadgesEdgeError || (exports.BadgesEdgeError = BadgesEdgeError = {}));
var ChatEdgeError;
(function (ChatEdgeError) {
    ChatEdgeError["BadRequest"] = "BAD_REQUEST";
    ChatEdgeError["NotFound"] = "NOT_FOUND";
    ChatEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ChatEdgeError["Unknown"] = "UNKNOWN";
})(ChatEdgeError || (exports.ChatEdgeError = ChatEdgeError = {}));
var ChatMessageEdgeError;
(function (ChatMessageEdgeError) {
    ChatMessageEdgeError["BadRequest"] = "BAD_REQUEST";
    ChatMessageEdgeError["ChatInitiated"] = "CHAT_INITIATED";
    ChatMessageEdgeError["NotFound"] = "NOT_FOUND";
    ChatMessageEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ChatMessageEdgeError["Unknown"] = "UNKNOWN";
})(ChatMessageEdgeError || (exports.ChatMessageEdgeError = ChatMessageEdgeError = {}));
var ChatMessageStatusEdgeError;
(function (ChatMessageStatusEdgeError) {
    ChatMessageStatusEdgeError["BadRequest"] = "BAD_REQUEST";
    ChatMessageStatusEdgeError["ChatInitiated"] = "CHAT_INITIATED";
    ChatMessageStatusEdgeError["ChatMessageHasNoStatusForSender"] = "CHAT_MESSAGE_HAS_NO_STATUS_FOR_SENDER";
    ChatMessageStatusEdgeError["NotFound"] = "NOT_FOUND";
    ChatMessageStatusEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ChatMessageStatusEdgeError["Unknown"] = "UNKNOWN";
})(ChatMessageStatusEdgeError || (exports.ChatMessageStatusEdgeError = ChatMessageStatusEdgeError = {}));
var ChatMessagesEdgeError;
(function (ChatMessagesEdgeError) {
    ChatMessagesEdgeError["BadRequest"] = "BAD_REQUEST";
    ChatMessagesEdgeError["ChatInitiated"] = "CHAT_INITIATED";
    ChatMessagesEdgeError["NotFound"] = "NOT_FOUND";
    ChatMessagesEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ChatMessagesEdgeError["Unknown"] = "UNKNOWN";
})(ChatMessagesEdgeError || (exports.ChatMessagesEdgeError = ChatMessagesEdgeError = {}));
var ChatsEdgeError;
(function (ChatsEdgeError) {
    ChatsEdgeError["BadRequest"] = "BAD_REQUEST";
    ChatsEdgeError["NotFound"] = "NOT_FOUND";
    ChatsEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ChatsEdgeError["Unknown"] = "UNKNOWN";
})(ChatsEdgeError || (exports.ChatsEdgeError = ChatsEdgeError = {}));
var CreatePostError;
(function (CreatePostError) {
    CreatePostError["BadRequest"] = "BAD_REQUEST";
    CreatePostError["DescriptionLengthExceeded"] = "DESCRIPTION_LENGTH_EXCEEDED";
    CreatePostError["DescriptionLengthUnmet"] = "DESCRIPTION_LENGTH_UNMET";
    CreatePostError["DescriptionRequired"] = "DESCRIPTION_REQUIRED";
    CreatePostError["PostTypeRequired"] = "POST_TYPE_REQUIRED";
    CreatePostError["Profanity"] = "PROFANITY";
    CreatePostError["TagCountExceeded"] = "TAG_COUNT_EXCEEDED";
    CreatePostError["TitleLengthExceeded"] = "TITLE_LENGTH_EXCEEDED";
    CreatePostError["TitleLengthUnmet"] = "TITLE_LENGTH_UNMET";
    CreatePostError["TitleRequired"] = "TITLE_REQUIRED";
    CreatePostError["Unknown"] = "UNKNOWN";
})(CreatePostError || (exports.CreatePostError = CreatePostError = {}));
var CreateProjectError;
(function (CreateProjectError) {
    CreateProjectError["BadRequest"] = "BAD_REQUEST";
    CreateProjectError["Unauthorized"] = "UNAUTHORIZED";
    CreateProjectError["Unknown"] = "UNKNOWN";
})(CreateProjectError || (exports.CreateProjectError = CreateProjectError = {}));
var CreateVideoSessionTokenError;
(function (CreateVideoSessionTokenError) {
    CreateVideoSessionTokenError["BadRequest"] = "BAD_REQUEST";
    CreateVideoSessionTokenError["NotFound"] = "NOT_FOUND";
    CreateVideoSessionTokenError["Unknown"] = "UNKNOWN";
})(CreateVideoSessionTokenError || (exports.CreateVideoSessionTokenError = CreateVideoSessionTokenError = {}));
var DeleteProjectError;
(function (DeleteProjectError) {
    DeleteProjectError["BadRequest"] = "BAD_REQUEST";
    DeleteProjectError["NotFound"] = "NOT_FOUND";
    DeleteProjectError["Unauthorized"] = "UNAUTHORIZED";
    DeleteProjectError["Unknown"] = "UNKNOWN";
})(DeleteProjectError || (exports.DeleteProjectError = DeleteProjectError = {}));
var FollowUserError;
(function (FollowUserError) {
    FollowUserError["BadRequest"] = "BAD_REQUEST";
    FollowUserError["CannotFollowSelf"] = "CANNOT_FOLLOW_SELF";
    FollowUserError["NotFound"] = "NOT_FOUND";
    FollowUserError["Unknown"] = "UNKNOWN";
    FollowUserError["UserAlreadyFollowed"] = "USER_ALREADY_FOLLOWED";
})(FollowUserError || (exports.FollowUserError = FollowUserError = {}));
var FollowersEdgeError;
(function (FollowersEdgeError) {
    FollowersEdgeError["NotFound"] = "NOT_FOUND";
    FollowersEdgeError["Unknown"] = "UNKNOWN";
})(FollowersEdgeError || (exports.FollowersEdgeError = FollowersEdgeError = {}));
var FollowingEdgeError;
(function (FollowingEdgeError) {
    FollowingEdgeError["NotFound"] = "NOT_FOUND";
    FollowingEdgeError["Unknown"] = "UNKNOWN";
})(FollowingEdgeError || (exports.FollowingEdgeError = FollowingEdgeError = {}));
var GetAllPlatformEventTypesError;
(function (GetAllPlatformEventTypesError) {
    GetAllPlatformEventTypesError["BadRequest"] = "BAD_REQUEST";
    GetAllPlatformEventTypesError["NotFound"] = "NOT_FOUND";
    GetAllPlatformEventTypesError["Unknown"] = "UNKNOWN";
})(GetAllPlatformEventTypesError || (exports.GetAllPlatformEventTypesError = GetAllPlatformEventTypesError = {}));
var GetAllPostTypesError;
(function (GetAllPostTypesError) {
    GetAllPostTypesError["BadRequest"] = "BAD_REQUEST";
    GetAllPostTypesError["NotFound"] = "NOT_FOUND";
    GetAllPostTypesError["Unknown"] = "UNKNOWN";
})(GetAllPostTypesError || (exports.GetAllPostTypesError = GetAllPostTypesError = {}));
var GetBadgesError;
(function (GetBadgesError) {
    GetBadgesError["BadRequest"] = "BAD_REQUEST";
    GetBadgesError["NotFound"] = "NOT_FOUND";
    GetBadgesError["Unknown"] = "UNKNOWN";
})(GetBadgesError || (exports.GetBadgesError = GetBadgesError = {}));
var GetChatError;
(function (GetChatError) {
    GetChatError["BadRequest"] = "BAD_REQUEST";
    GetChatError["NotFound"] = "NOT_FOUND";
    GetChatError["Unauthorized"] = "UNAUTHORIZED";
    GetChatError["Unknown"] = "UNKNOWN";
})(GetChatError || (exports.GetChatError = GetChatError = {}));
var GetChatsForUserError;
(function (GetChatsForUserError) {
    GetChatsForUserError["BadRequest"] = "BAD_REQUEST";
    GetChatsForUserError["NotFound"] = "NOT_FOUND";
    GetChatsForUserError["Unauthorized"] = "UNAUTHORIZED";
    GetChatsForUserError["Unknown"] = "UNKNOWN";
})(GetChatsForUserError || (exports.GetChatsForUserError = GetChatsForUserError = {}));
var GetCurrentUserError;
(function (GetCurrentUserError) {
    GetCurrentUserError["Unauthorized"] = "UNAUTHORIZED";
    GetCurrentUserError["Unknown"] = "UNKNOWN";
    GetCurrentUserError["UserNotFound"] = "USER_NOT_FOUND";
})(GetCurrentUserError || (exports.GetCurrentUserError = GetCurrentUserError = {}));
var GetLatestPostsError;
(function (GetLatestPostsError) {
    GetLatestPostsError["NoPostsFound"] = "NO_POSTS_FOUND";
    GetLatestPostsError["Unknown"] = "UNKNOWN";
})(GetLatestPostsError || (exports.GetLatestPostsError = GetLatestPostsError = {}));
var GetPlatformEventError;
(function (GetPlatformEventError) {
    GetPlatformEventError["NotFound"] = "NOT_FOUND";
    GetPlatformEventError["Unknown"] = "UNKNOWN";
})(GetPlatformEventError || (exports.GetPlatformEventError = GetPlatformEventError = {}));
var GetPlatformEventTypesByIdsError;
(function (GetPlatformEventTypesByIdsError) {
    GetPlatformEventTypesByIdsError["BadRequest"] = "BAD_REQUEST";
    GetPlatformEventTypesByIdsError["NotFound"] = "NOT_FOUND";
    GetPlatformEventTypesByIdsError["Unknown"] = "UNKNOWN";
})(GetPlatformEventTypesByIdsError || (exports.GetPlatformEventTypesByIdsError = GetPlatformEventTypesByIdsError = {}));
var GetPostError;
(function (GetPostError) {
    GetPostError["NotFound"] = "NOT_FOUND";
    GetPostError["Unknown"] = "UNKNOWN";
})(GetPostError || (exports.GetPostError = GetPostError = {}));
var GetPostTypesByIdsError;
(function (GetPostTypesByIdsError) {
    GetPostTypesByIdsError["BadRequest"] = "BAD_REQUEST";
    GetPostTypesByIdsError["NotFound"] = "NOT_FOUND";
    GetPostTypesByIdsError["Unknown"] = "UNKNOWN";
})(GetPostTypesByIdsError || (exports.GetPostTypesByIdsError = GetPostTypesByIdsError = {}));
var GetPostsForUserError;
(function (GetPostsForUserError) {
    GetPostsForUserError["BadRequest"] = "BAD_REQUEST";
    GetPostsForUserError["NotFound"] = "NOT_FOUND";
    GetPostsForUserError["Unknown"] = "UNKNOWN";
})(GetPostsForUserError || (exports.GetPostsForUserError = GetPostsForUserError = {}));
var GetProjectError;
(function (GetProjectError) {
    GetProjectError["BadRequest"] = "BAD_REQUEST";
    GetProjectError["NotFound"] = "NOT_FOUND";
    GetProjectError["Unauthorized"] = "UNAUTHORIZED";
    GetProjectError["Unknown"] = "UNKNOWN";
})(GetProjectError || (exports.GetProjectError = GetProjectError = {}));
var GetProjectsError;
(function (GetProjectsError) {
    GetProjectsError["BadRequest"] = "BAD_REQUEST";
    GetProjectsError["Unauthorized"] = "UNAUTHORIZED";
    GetProjectsError["Unknown"] = "UNKNOWN";
})(GetProjectsError || (exports.GetProjectsError = GetProjectsError = {}));
var GetRandomUserError;
(function (GetRandomUserError) {
    GetRandomUserError["NotFound"] = "NOT_FOUND";
    GetRandomUserError["Unauthorized"] = "UNAUTHORIZED";
    GetRandomUserError["Unknown"] = "UNKNOWN";
})(GetRandomUserError || (exports.GetRandomUserError = GetRandomUserError = {}));
var GetRewardsError;
(function (GetRewardsError) {
    GetRewardsError["BadRequest"] = "BAD_REQUEST";
    GetRewardsError["NotFound"] = "NOT_FOUND";
    GetRewardsError["Unknown"] = "UNKNOWN";
})(GetRewardsError || (exports.GetRewardsError = GetRewardsError = {}));
var GetSessionsForPostError;
(function (GetSessionsForPostError) {
    GetSessionsForPostError["BadRequest"] = "BAD_REQUEST";
    GetSessionsForPostError["NotFound"] = "NOT_FOUND";
    GetSessionsForPostError["Unknown"] = "UNKNOWN";
})(GetSessionsForPostError || (exports.GetSessionsForPostError = GetSessionsForPostError = {}));
var GetSessionsForUserError;
(function (GetSessionsForUserError) {
    GetSessionsForUserError["BadRequest"] = "BAD_REQUEST";
    GetSessionsForUserError["NotFound"] = "NOT_FOUND";
    GetSessionsForUserError["Unknown"] = "UNKNOWN";
})(GetSessionsForUserError || (exports.GetSessionsForUserError = GetSessionsForUserError = {}));
var GetTagsError;
(function (GetTagsError) {
    GetTagsError["BadRequest"] = "BAD_REQUEST";
    GetTagsError["NotFound"] = "NOT_FOUND";
    GetTagsError["Unknown"] = "UNKNOWN";
})(GetTagsError || (exports.GetTagsError = GetTagsError = {}));
var GetTaskError;
(function (GetTaskError) {
    GetTaskError["BadRequest"] = "BAD_REQUEST";
    GetTaskError["NotFound"] = "NOT_FOUND";
    GetTaskError["Unauthorized"] = "UNAUTHORIZED";
    GetTaskError["Unknown"] = "UNKNOWN";
})(GetTaskError || (exports.GetTaskError = GetTaskError = {}));
var GetTaskTypesError;
(function (GetTaskTypesError) {
    GetTaskTypesError["BadRequest"] = "BAD_REQUEST";
    GetTaskTypesError["NotFound"] = "NOT_FOUND";
    GetTaskTypesError["Unauthorized"] = "UNAUTHORIZED";
    GetTaskTypesError["Unknown"] = "UNKNOWN";
})(GetTaskTypesError || (exports.GetTaskTypesError = GetTaskTypesError = {}));
var GetTasksError;
(function (GetTasksError) {
    GetTasksError["BadRequest"] = "BAD_REQUEST";
    GetTasksError["NotFound"] = "NOT_FOUND";
    GetTasksError["Unauthorized"] = "UNAUTHORIZED";
    GetTasksError["Unknown"] = "UNKNOWN";
})(GetTasksError || (exports.GetTasksError = GetTasksError = {}));
var GetUserBadgesError;
(function (GetUserBadgesError) {
    GetUserBadgesError["BadRequest"] = "BAD_REQUEST";
    GetUserBadgesError["NotFound"] = "NOT_FOUND";
    GetUserBadgesError["Unknown"] = "UNKNOWN";
})(GetUserBadgesError || (exports.GetUserBadgesError = GetUserBadgesError = {}));
var GetUserError;
(function (GetUserError) {
    GetUserError["BadRequest"] = "BAD_REQUEST";
    GetUserError["Unknown"] = "UNKNOWN";
    GetUserError["UserNotFound"] = "USER_NOT_FOUND";
})(GetUserError || (exports.GetUserError = GetUserError = {}));
var GetUserRewardsError;
(function (GetUserRewardsError) {
    GetUserRewardsError["BadRequest"] = "BAD_REQUEST";
    GetUserRewardsError["NotFound"] = "NOT_FOUND";
    GetUserRewardsError["Unknown"] = "UNKNOWN";
})(GetUserRewardsError || (exports.GetUserRewardsError = GetUserRewardsError = {}));
var GetUserTagSubscriptionsError;
(function (GetUserTagSubscriptionsError) {
    GetUserTagSubscriptionsError["BadRequest"] = "BAD_REQUEST";
    GetUserTagSubscriptionsError["NotFound"] = "NOT_FOUND";
    GetUserTagSubscriptionsError["Unknown"] = "UNKNOWN";
})(GetUserTagSubscriptionsError || (exports.GetUserTagSubscriptionsError = GetUserTagSubscriptionsError = {}));
var GetUsersError;
(function (GetUsersError) {
    GetUsersError["BadRequest"] = "BAD_REQUEST";
    GetUsersError["Unknown"] = "UNKNOWN";
    GetUsersError["UserNotFound"] = "USER_NOT_FOUND";
})(GetUsersError || (exports.GetUsersError = GetUsersError = {}));
var PlatformEventTypeEnum;
(function (PlatformEventTypeEnum) {
    PlatformEventTypeEnum["PostCreated"] = "POST_CREATED";
    PlatformEventTypeEnum["PostDeleted"] = "POST_DELETED";
    PlatformEventTypeEnum["PostLiked"] = "POST_LIKED";
    PlatformEventTypeEnum["PostUpdated"] = "POST_UPDATED";
    PlatformEventTypeEnum["PostViewed"] = "POST_VIEWED";
    PlatformEventTypeEnum["SessionCreated"] = "SESSION_CREATED";
    PlatformEventTypeEnum["SessionEnded"] = "SESSION_ENDED";
    PlatformEventTypeEnum["SessionJoined"] = "SESSION_JOINED";
    PlatformEventTypeEnum["SessionLeft"] = "SESSION_LEFT";
    PlatformEventTypeEnum["TaskCompleted"] = "TASK_COMPLETED";
    PlatformEventTypeEnum["TaskViewed"] = "TASK_VIEWED";
    PlatformEventTypeEnum["UserApplied"] = "USER_APPLIED";
    PlatformEventTypeEnum["UserLogin"] = "USER_LOGIN";
    PlatformEventTypeEnum["UserRegister"] = "USER_REGISTER";
})(PlatformEventTypeEnum || (exports.PlatformEventTypeEnum = PlatformEventTypeEnum = {}));
var PlatformEventTypeError;
(function (PlatformEventTypeError) {
    PlatformEventTypeError["NotFound"] = "NOT_FOUND";
    PlatformEventTypeError["Unknown"] = "UNKNOWN";
})(PlatformEventTypeError || (exports.PlatformEventTypeError = PlatformEventTypeError = {}));
var PostCommentEdgeError;
(function (PostCommentEdgeError) {
    PostCommentEdgeError["BadRequest"] = "BAD_REQUEST";
    PostCommentEdgeError["NotFound"] = "NOT_FOUND";
    PostCommentEdgeError["Unauthorized"] = "UNAUTHORIZED";
    PostCommentEdgeError["Unknown"] = "UNKNOWN";
})(PostCommentEdgeError || (exports.PostCommentEdgeError = PostCommentEdgeError = {}));
var PostCommentsEdgeError;
(function (PostCommentsEdgeError) {
    PostCommentsEdgeError["BadRequest"] = "BAD_REQUEST";
    PostCommentsEdgeError["NotFound"] = "NOT_FOUND";
    PostCommentsEdgeError["Unauthorized"] = "UNAUTHORIZED";
    PostCommentsEdgeError["Unknown"] = "UNKNOWN";
})(PostCommentsEdgeError || (exports.PostCommentsEdgeError = PostCommentsEdgeError = {}));
var PostEdgeError;
(function (PostEdgeError) {
    PostEdgeError["BadRequest"] = "BAD_REQUEST";
    PostEdgeError["NotFound"] = "NOT_FOUND";
    PostEdgeError["Unknown"] = "UNKNOWN";
})(PostEdgeError || (exports.PostEdgeError = PostEdgeError = {}));
var PostFilterEnum;
(function (PostFilterEnum) {
    PostFilterEnum["All"] = "ALL";
    PostFilterEnum["MyPosts"] = "MY_POSTS";
})(PostFilterEnum || (exports.PostFilterEnum = PostFilterEnum = {}));
var PostReactionEdgeError;
(function (PostReactionEdgeError) {
    PostReactionEdgeError["BadRequest"] = "BAD_REQUEST";
    PostReactionEdgeError["NotFound"] = "NOT_FOUND";
    PostReactionEdgeError["Unauthorized"] = "UNAUTHORIZED";
    PostReactionEdgeError["Unknown"] = "UNKNOWN";
})(PostReactionEdgeError || (exports.PostReactionEdgeError = PostReactionEdgeError = {}));
var PostReactionsEdgeError;
(function (PostReactionsEdgeError) {
    PostReactionsEdgeError["BadRequest"] = "BAD_REQUEST";
    PostReactionsEdgeError["NotFound"] = "NOT_FOUND";
    PostReactionsEdgeError["Unauthorized"] = "UNAUTHORIZED";
    PostReactionsEdgeError["Unknown"] = "UNKNOWN";
})(PostReactionsEdgeError || (exports.PostReactionsEdgeError = PostReactionsEdgeError = {}));
var PostSortEnum;
(function (PostSortEnum) {
    PostSortEnum["Latest"] = "LATEST";
    PostSortEnum["Trending"] = "TRENDING";
})(PostSortEnum || (exports.PostSortEnum = PostSortEnum = {}));
var PostTypeEdgeError;
(function (PostTypeEdgeError) {
    PostTypeEdgeError["BadRequest"] = "BAD_REQUEST";
    PostTypeEdgeError["NotFound"] = "NOT_FOUND";
    PostTypeEdgeError["Unknown"] = "UNKNOWN";
})(PostTypeEdgeError || (exports.PostTypeEdgeError = PostTypeEdgeError = {}));
var PostTypeEnum;
(function (PostTypeEnum) {
    PostTypeEnum["Bug"] = "BUG";
    PostTypeEnum["CodeReview"] = "CODE_REVIEW";
    PostTypeEnum["Collaboration"] = "COLLABORATION";
    PostTypeEnum["ComputerScience"] = "COMPUTER_SCIENCE";
    PostTypeEnum["Design"] = "DESIGN";
    PostTypeEnum["Feature"] = "FEATURE";
    PostTypeEnum["Feedback"] = "FEEDBACK";
    PostTypeEnum["Milestone"] = "MILESTONE";
    PostTypeEnum["Other"] = "OTHER";
    PostTypeEnum["Progress"] = "PROGRESS";
    PostTypeEnum["Project"] = "PROJECT";
    PostTypeEnum["Resource"] = "RESOURCE";
    PostTypeEnum["Systems"] = "SYSTEMS";
})(PostTypeEnum || (exports.PostTypeEnum = PostTypeEnum = {}));
var PostsEdgeError;
(function (PostsEdgeError) {
    PostsEdgeError["BadRequest"] = "BAD_REQUEST";
    PostsEdgeError["NotFound"] = "NOT_FOUND";
    PostsEdgeError["Unknown"] = "UNKNOWN";
})(PostsEdgeError || (exports.PostsEdgeError = PostsEdgeError = {}));
var ProfileStatsError;
(function (ProfileStatsError) {
    ProfileStatsError["NotFound"] = "NOT_FOUND";
    ProfileStatsError["Unauthorized"] = "UNAUTHORIZED";
    ProfileStatsError["Unknown"] = "UNKNOWN";
})(ProfileStatsError || (exports.ProfileStatsError = ProfileStatsError = {}));
var ProjectEdgeError;
(function (ProjectEdgeError) {
    ProjectEdgeError["BadRequest"] = "BAD_REQUEST";
    ProjectEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ProjectEdgeError["Unknown"] = "UNKNOWN";
})(ProjectEdgeError || (exports.ProjectEdgeError = ProjectEdgeError = {}));
var ProjectFilterEnum;
(function (ProjectFilterEnum) {
    ProjectFilterEnum["All"] = "ALL";
    ProjectFilterEnum["MyLikes"] = "MY_LIKES";
    ProjectFilterEnum["MyProjects"] = "MY_PROJECTS";
    ProjectFilterEnum["Recommended"] = "RECOMMENDED";
})(ProjectFilterEnum || (exports.ProjectFilterEnum = ProjectFilterEnum = {}));
var ProjectReactionEdgeError;
(function (ProjectReactionEdgeError) {
    ProjectReactionEdgeError["BadRequest"] = "BAD_REQUEST";
    ProjectReactionEdgeError["NotFound"] = "NOT_FOUND";
    ProjectReactionEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ProjectReactionEdgeError["Unknown"] = "UNKNOWN";
})(ProjectReactionEdgeError || (exports.ProjectReactionEdgeError = ProjectReactionEdgeError = {}));
var ProjectReactionsEdgeError;
(function (ProjectReactionsEdgeError) {
    ProjectReactionsEdgeError["BadRequest"] = "BAD_REQUEST";
    ProjectReactionsEdgeError["NotFound"] = "NOT_FOUND";
    ProjectReactionsEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ProjectReactionsEdgeError["Unknown"] = "UNKNOWN";
})(ProjectReactionsEdgeError || (exports.ProjectReactionsEdgeError = ProjectReactionsEdgeError = {}));
var ProjectSortEnum;
(function (ProjectSortEnum) {
    ProjectSortEnum["Alphabetical"] = "ALPHABETICAL";
    ProjectSortEnum["Latest"] = "LATEST";
    ProjectSortEnum["MostPopular"] = "MOST_POPULAR";
})(ProjectSortEnum || (exports.ProjectSortEnum = ProjectSortEnum = {}));
var ProjectsEdgeError;
(function (ProjectsEdgeError) {
    ProjectsEdgeError["BadRequest"] = "BAD_REQUEST";
    ProjectsEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ProjectsEdgeError["Unknown"] = "UNKNOWN";
})(ProjectsEdgeError || (exports.ProjectsEdgeError = ProjectsEdgeError = {}));
var ReactionEdgeError;
(function (ReactionEdgeError) {
    ReactionEdgeError["BadRequest"] = "BAD_REQUEST";
    ReactionEdgeError["NotFound"] = "NOT_FOUND";
    ReactionEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ReactionEdgeError["Unknown"] = "UNKNOWN";
})(ReactionEdgeError || (exports.ReactionEdgeError = ReactionEdgeError = {}));
var ReactionType;
(function (ReactionType) {
    ReactionType["Like"] = "LIKE";
})(ReactionType || (exports.ReactionType = ReactionType = {}));
var ReactionsEdgeError;
(function (ReactionsEdgeError) {
    ReactionsEdgeError["BadRequest"] = "BAD_REQUEST";
    ReactionsEdgeError["NotFound"] = "NOT_FOUND";
    ReactionsEdgeError["Unauthorized"] = "UNAUTHORIZED";
    ReactionsEdgeError["Unknown"] = "UNKNOWN";
})(ReactionsEdgeError || (exports.ReactionsEdgeError = ReactionsEdgeError = {}));
var RemovePostReactionError;
(function (RemovePostReactionError) {
    RemovePostReactionError["BadRequest"] = "BAD_REQUEST";
    RemovePostReactionError["Forbidden"] = "FORBIDDEN";
    RemovePostReactionError["NotFound"] = "NOT_FOUND";
    RemovePostReactionError["Unauthorized"] = "UNAUTHORIZED";
    RemovePostReactionError["Unknown"] = "UNKNOWN";
})(RemovePostReactionError || (exports.RemovePostReactionError = RemovePostReactionError = {}));
var RemoveProjectReactionError;
(function (RemoveProjectReactionError) {
    RemoveProjectReactionError["BadRequest"] = "BAD_REQUEST";
    RemoveProjectReactionError["Forbidden"] = "FORBIDDEN";
    RemoveProjectReactionError["NotFound"] = "NOT_FOUND";
    RemoveProjectReactionError["Unauthorized"] = "UNAUTHORIZED";
    RemoveProjectReactionError["Unknown"] = "UNKNOWN";
})(RemoveProjectReactionError || (exports.RemoveProjectReactionError = RemoveProjectReactionError = {}));
var RemoveTaskReactionError;
(function (RemoveTaskReactionError) {
    RemoveTaskReactionError["BadRequest"] = "BAD_REQUEST";
    RemoveTaskReactionError["Forbidden"] = "FORBIDDEN";
    RemoveTaskReactionError["NotFound"] = "NOT_FOUND";
    RemoveTaskReactionError["Unauthorized"] = "UNAUTHORIZED";
    RemoveTaskReactionError["Unknown"] = "UNKNOWN";
})(RemoveTaskReactionError || (exports.RemoveTaskReactionError = RemoveTaskReactionError = {}));
var RewardsEdgeError;
(function (RewardsEdgeError) {
    RewardsEdgeError["BadRequest"] = "BAD_REQUEST";
    RewardsEdgeError["NotFound"] = "NOT_FOUND";
    RewardsEdgeError["Unknown"] = "UNKNOWN";
})(RewardsEdgeError || (exports.RewardsEdgeError = RewardsEdgeError = {}));
var Role;
(function (Role) {
    Role["Admin"] = "ADMIN";
    Role["User"] = "USER";
})(Role || (exports.Role = Role = {}));
var SendChatMessageError;
(function (SendChatMessageError) {
    SendChatMessageError["BadRequest"] = "BAD_REQUEST";
    SendChatMessageError["ChatNotFound"] = "CHAT_NOT_FOUND";
    SendChatMessageError["Unauthorized"] = "UNAUTHORIZED";
    SendChatMessageError["Unknown"] = "UNKNOWN";
    SendChatMessageError["UserNotFound"] = "USER_NOT_FOUND";
})(SendChatMessageError || (exports.SendChatMessageError = SendChatMessageError = {}));
var SessionsEdgeError;
(function (SessionsEdgeError) {
    SessionsEdgeError["BadRequest"] = "BAD_REQUEST";
    SessionsEdgeError["NotFound"] = "NOT_FOUND";
    SessionsEdgeError["Unknown"] = "UNKNOWN";
})(SessionsEdgeError || (exports.SessionsEdgeError = SessionsEdgeError = {}));
var SkillLevel;
(function (SkillLevel) {
    SkillLevel["Advanced"] = "ADVANCED";
    SkillLevel["Beginner"] = "BEGINNER";
    SkillLevel["Expert"] = "EXPERT";
    SkillLevel["Intermediate"] = "INTERMEDIATE";
})(SkillLevel || (exports.SkillLevel = SkillLevel = {}));
var StartChatError;
(function (StartChatError) {
    StartChatError["BadRequest"] = "BAD_REQUEST";
    StartChatError["CannotStartChatWithSelf"] = "CANNOT_START_CHAT_WITH_SELF";
    StartChatError["NotFound"] = "NOT_FOUND";
    StartChatError["Unauthorized"] = "UNAUTHORIZED";
    StartChatError["Unknown"] = "UNKNOWN";
})(StartChatError || (exports.StartChatError = StartChatError = {}));
var SubmitPlatformFeedbackError;
(function (SubmitPlatformFeedbackError) {
    SubmitPlatformFeedbackError["BadRequest"] = "BAD_REQUEST";
    SubmitPlatformFeedbackError["NotFound"] = "NOT_FOUND";
    SubmitPlatformFeedbackError["Unauthorized"] = "UNAUTHORIZED";
    SubmitPlatformFeedbackError["Unknown"] = "UNKNOWN";
})(SubmitPlatformFeedbackError || (exports.SubmitPlatformFeedbackError = SubmitPlatformFeedbackError = {}));
var SubmitTaskFeedbackError;
(function (SubmitTaskFeedbackError) {
    SubmitTaskFeedbackError["BadRequest"] = "BAD_REQUEST";
    SubmitTaskFeedbackError["NotFound"] = "NOT_FOUND";
    SubmitTaskFeedbackError["Unauthorized"] = "UNAUTHORIZED";
    SubmitTaskFeedbackError["Unknown"] = "UNKNOWN";
})(SubmitTaskFeedbackError || (exports.SubmitTaskFeedbackError = SubmitTaskFeedbackError = {}));
var TagType;
(function (TagType) {
    TagType["Career"] = "CAREER";
    TagType["Technology"] = "TECHNOLOGY";
    TagType["Topic"] = "TOPIC";
})(TagType || (exports.TagType = TagType = {}));
var TagsEdgeError;
(function (TagsEdgeError) {
    TagsEdgeError["BadRequest"] = "BAD_REQUEST";
    TagsEdgeError["NotFound"] = "NOT_FOUND";
    TagsEdgeError["Unknown"] = "UNKNOWN";
})(TagsEdgeError || (exports.TagsEdgeError = TagsEdgeError = {}));
var TaskDifficulty;
(function (TaskDifficulty) {
    TaskDifficulty["Advanced"] = "ADVANCED";
    TaskDifficulty["Beginner"] = "BEGINNER";
    TaskDifficulty["Expert"] = "EXPERT";
    TaskDifficulty["Intermediate"] = "INTERMEDIATE";
})(TaskDifficulty || (exports.TaskDifficulty = TaskDifficulty = {}));
var TaskEdgeError;
(function (TaskEdgeError) {
    TaskEdgeError["NotFound"] = "NOT_FOUND";
    TaskEdgeError["Unauthorized"] = "UNAUTHORIZED";
    TaskEdgeError["Unknown"] = "UNKNOWN";
})(TaskEdgeError || (exports.TaskEdgeError = TaskEdgeError = {}));
var TaskFilterEnum;
(function (TaskFilterEnum) {
    TaskFilterEnum["All"] = "ALL";
    TaskFilterEnum["Complete"] = "COMPLETE";
    TaskFilterEnum["Incomplete"] = "INCOMPLETE";
    TaskFilterEnum["MyTasks"] = "MY_TASKS";
    TaskFilterEnum["Recommended"] = "RECOMMENDED";
})(TaskFilterEnum || (exports.TaskFilterEnum = TaskFilterEnum = {}));
var TaskReactionEdgeError;
(function (TaskReactionEdgeError) {
    TaskReactionEdgeError["BadRequest"] = "BAD_REQUEST";
    TaskReactionEdgeError["NotFound"] = "NOT_FOUND";
    TaskReactionEdgeError["Unauthorized"] = "UNAUTHORIZED";
    TaskReactionEdgeError["Unknown"] = "UNKNOWN";
})(TaskReactionEdgeError || (exports.TaskReactionEdgeError = TaskReactionEdgeError = {}));
var TaskReactionsEdgeError;
(function (TaskReactionsEdgeError) {
    TaskReactionsEdgeError["BadRequest"] = "BAD_REQUEST";
    TaskReactionsEdgeError["NotFound"] = "NOT_FOUND";
    TaskReactionsEdgeError["Unauthorized"] = "UNAUTHORIZED";
    TaskReactionsEdgeError["Unknown"] = "UNKNOWN";
})(TaskReactionsEdgeError || (exports.TaskReactionsEdgeError = TaskReactionsEdgeError = {}));
var TaskResourcesEdgeError;
(function (TaskResourcesEdgeError) {
    TaskResourcesEdgeError["NotFound"] = "NOT_FOUND";
    TaskResourcesEdgeError["Unauthorized"] = "UNAUTHORIZED";
    TaskResourcesEdgeError["Unknown"] = "UNKNOWN";
})(TaskResourcesEdgeError || (exports.TaskResourcesEdgeError = TaskResourcesEdgeError = {}));
var TaskSortEnum;
(function (TaskSortEnum) {
    TaskSortEnum["Latest"] = "LATEST";
    TaskSortEnum["MostPopular"] = "MOST_POPULAR";
    TaskSortEnum["Recommended"] = "RECOMMENDED";
})(TaskSortEnum || (exports.TaskSortEnum = TaskSortEnum = {}));
var TaskTypeEdgeError;
(function (TaskTypeEdgeError) {
    TaskTypeEdgeError["NotFound"] = "NOT_FOUND";
    TaskTypeEdgeError["Unauthorized"] = "UNAUTHORIZED";
    TaskTypeEdgeError["Unknown"] = "UNKNOWN";
})(TaskTypeEdgeError || (exports.TaskTypeEdgeError = TaskTypeEdgeError = {}));
var TaskTypeEnum;
(function (TaskTypeEnum) {
    TaskTypeEnum["Article"] = "ARTICLE";
    TaskTypeEnum["CodeReview"] = "CODE_REVIEW";
    TaskTypeEnum["ComputerScience"] = "COMPUTER_SCIENCE";
    TaskTypeEnum["DailyUpdate"] = "DAILY_UPDATE";
    TaskTypeEnum["Exercise"] = "EXERCISE";
    TaskTypeEnum["OpenSource"] = "OPEN_SOURCE";
    TaskTypeEnum["Other"] = "OTHER";
    TaskTypeEnum["Project"] = "PROJECT";
    TaskTypeEnum["SystemsDesign"] = "SYSTEMS_DESIGN";
    TaskTypeEnum["Tutorial"] = "TUTORIAL";
    TaskTypeEnum["Video"] = "VIDEO";
})(TaskTypeEnum || (exports.TaskTypeEnum = TaskTypeEnum = {}));
var TrackPlatformEventError;
(function (TrackPlatformEventError) {
    TrackPlatformEventError["BadRequest"] = "BAD_REQUEST";
    TrackPlatformEventError["NotFound"] = "NOT_FOUND";
    TrackPlatformEventError["Unknown"] = "UNKNOWN";
})(TrackPlatformEventError || (exports.TrackPlatformEventError = TrackPlatformEventError = {}));
var UpdateAllChatMessageStatusesForChatError;
(function (UpdateAllChatMessageStatusesForChatError) {
    UpdateAllChatMessageStatusesForChatError["BadRequest"] = "BAD_REQUEST";
    UpdateAllChatMessageStatusesForChatError["ChatNotFound"] = "CHAT_NOT_FOUND";
    UpdateAllChatMessageStatusesForChatError["MessageNotFound"] = "MESSAGE_NOT_FOUND";
    UpdateAllChatMessageStatusesForChatError["Unauthorized"] = "UNAUTHORIZED";
    UpdateAllChatMessageStatusesForChatError["Unknown"] = "UNKNOWN";
    UpdateAllChatMessageStatusesForChatError["UserNotFound"] = "USER_NOT_FOUND";
})(UpdateAllChatMessageStatusesForChatError || (exports.UpdateAllChatMessageStatusesForChatError = UpdateAllChatMessageStatusesForChatError = {}));
var UpdateProfileError;
(function (UpdateProfileError) {
    UpdateProfileError["BadRequest"] = "BAD_REQUEST";
    UpdateProfileError["NotFound"] = "NOT_FOUND";
    UpdateProfileError["Profanity"] = "PROFANITY";
    UpdateProfileError["Unauthorized"] = "UNAUTHORIZED";
    UpdateProfileError["Unknown"] = "UNKNOWN";
})(UpdateProfileError || (exports.UpdateProfileError = UpdateProfileError = {}));
var UpdateProjectError;
(function (UpdateProjectError) {
    UpdateProjectError["BadRequest"] = "BAD_REQUEST";
    UpdateProjectError["NotFound"] = "NOT_FOUND";
    UpdateProjectError["Unauthorized"] = "UNAUTHORIZED";
    UpdateProjectError["Unknown"] = "UNKNOWN";
})(UpdateProjectError || (exports.UpdateProjectError = UpdateProjectError = {}));
var UpdateTagsForUserError;
(function (UpdateTagsForUserError) {
    UpdateTagsForUserError["BadRequest"] = "BAD_REQUEST";
    UpdateTagsForUserError["NotFound"] = "NOT_FOUND";
    UpdateTagsForUserError["Unauthorized"] = "UNAUTHORIZED";
    UpdateTagsForUserError["Unknown"] = "UNKNOWN";
})(UpdateTagsForUserError || (exports.UpdateTagsForUserError = UpdateTagsForUserError = {}));
var UpdateUserStatusError;
(function (UpdateUserStatusError) {
    UpdateUserStatusError["BadRequest"] = "BAD_REQUEST";
    UpdateUserStatusError["NotFound"] = "NOT_FOUND";
    UpdateUserStatusError["Unauthorized"] = "UNAUTHORIZED";
    UpdateUserStatusError["Unknown"] = "UNKNOWN";
})(UpdateUserStatusError || (exports.UpdateUserStatusError = UpdateUserStatusError = {}));
var UpdateUserTagSubscriptionsError;
(function (UpdateUserTagSubscriptionsError) {
    UpdateUserTagSubscriptionsError["BadRequest"] = "BAD_REQUEST";
    UpdateUserTagSubscriptionsError["NotFound"] = "NOT_FOUND";
    UpdateUserTagSubscriptionsError["Unknown"] = "UNKNOWN";
})(UpdateUserTagSubscriptionsError || (exports.UpdateUserTagSubscriptionsError = UpdateUserTagSubscriptionsError = {}));
var UploadEdgeError;
(function (UploadEdgeError) {
    UploadEdgeError["BadRequest"] = "BAD_REQUEST";
    UploadEdgeError["NotFound"] = "NOT_FOUND";
    UploadEdgeError["Unauthorized"] = "UNAUTHORIZED";
    UploadEdgeError["Unknown"] = "UNKNOWN";
})(UploadEdgeError || (exports.UploadEdgeError = UploadEdgeError = {}));
var UploadsEdgeError;
(function (UploadsEdgeError) {
    UploadsEdgeError["BadRequest"] = "BAD_REQUEST";
    UploadsEdgeError["NotFound"] = "NOT_FOUND";
    UploadsEdgeError["Unauthorized"] = "UNAUTHORIZED";
    UploadsEdgeError["Unknown"] = "UNKNOWN";
})(UploadsEdgeError || (exports.UploadsEdgeError = UploadsEdgeError = {}));
var UpsertUserTaskError;
(function (UpsertUserTaskError) {
    UpsertUserTaskError["BadRequest"] = "BAD_REQUEST";
    UpsertUserTaskError["NotFound"] = "NOT_FOUND";
    UpsertUserTaskError["Unauthorized"] = "UNAUTHORIZED";
    UpsertUserTaskError["Unknown"] = "UNKNOWN";
})(UpsertUserTaskError || (exports.UpsertUserTaskError = UpsertUserTaskError = {}));
var UserEdgeError;
(function (UserEdgeError) {
    UserEdgeError["BadRequest"] = "BAD_REQUEST";
    UserEdgeError["NotFound"] = "NOT_FOUND";
    UserEdgeError["Unauthorized"] = "UNAUTHORIZED";
    UserEdgeError["Unknown"] = "UNKNOWN";
})(UserEdgeError || (exports.UserEdgeError = UserEdgeError = {}));
var UserFeedbackEdgeError;
(function (UserFeedbackEdgeError) {
    UserFeedbackEdgeError["BadRequest"] = "BAD_REQUEST";
    UserFeedbackEdgeError["NotFound"] = "NOT_FOUND";
    UserFeedbackEdgeError["Unauthorized"] = "UNAUTHORIZED";
    UserFeedbackEdgeError["Unknown"] = "UNKNOWN";
})(UserFeedbackEdgeError || (exports.UserFeedbackEdgeError = UserFeedbackEdgeError = {}));
var UserHeartbeatError;
(function (UserHeartbeatError) {
    UserHeartbeatError["NotFound"] = "NOT_FOUND";
    UserHeartbeatError["Unauthorized"] = "UNAUTHORIZED";
    UserHeartbeatError["Unknown"] = "UNKNOWN";
})(UserHeartbeatError || (exports.UserHeartbeatError = UserHeartbeatError = {}));
var UsersEdgeError;
(function (UsersEdgeError) {
    UsersEdgeError["BadRequest"] = "BAD_REQUEST";
    UsersEdgeError["NotFound"] = "NOT_FOUND";
    UsersEdgeError["Unauthorized"] = "UNAUTHORIZED";
    UsersEdgeError["Unknown"] = "UNKNOWN";
})(UsersEdgeError || (exports.UsersEdgeError = UsersEdgeError = {}));
exports.BadgePartsFragmentDoc = (0, client_1.gql) `
    fragment BadgeParts on Badge {
  id
  name
  description
  imageUrl
  createdAt
  updatedAt
}
    `;
exports.ChatPartsFragmentDoc = (0, client_1.gql) `
    fragment ChatParts on Chat {
  id
  createdAt
  updatedAt
}
    `;
exports.MessagePartsFragmentDoc = (0, client_1.gql) `
    fragment MessageParts on ChatMessage {
  id
  chatId
  userId
  text
  createdAt
  updatedAt
  chatMessageStatusId
}
    `;
exports.ChatMessagesEdgePartsFragmentDoc = (0, client_1.gql) `
    fragment ChatMessagesEdgeParts on ChatMessagesEdge {
  id
  data {
    ...MessageParts
  }
  error
}
    ${exports.MessagePartsFragmentDoc}`;
exports.MessageStatusPartsFragmentDoc = (0, client_1.gql) `
    fragment MessageStatusParts on ChatMessageStatus {
  id
  userId
  chatId
  messageId
  isRead
  readAt
  createdAt
  updatedAt
}
    `;
exports.PlatformEventTypePartsFragmentDoc = (0, client_1.gql) `
    fragment PlatformEventTypeParts on PlatformEventType {
  id
  value
  label
  createdAt
  updatedAt
}
    `;
exports.PlatformEventPartsFragmentDoc = (0, client_1.gql) `
    fragment PlatformEventParts on PlatformEvent {
  id
  userId
  type
  title
  description
  createdAt
  updatedAt
}
    `;
exports.PlatformFeedbackPartsFragmentDoc = (0, client_1.gql) `
    fragment PlatformFeedbackParts on PlatformFeedback {
  id
  userId
  rating
  description
  createdAt
  updatedAt
}
    `;
exports.PostCommentPartsFragmentDoc = (0, client_1.gql) `
    fragment PostCommentParts on PostComment {
  id
  text
  createdAt
  updatedAt
  postId
  userId
  parentId
  childrenIds
}
    `;
exports.PostTypePartsFragmentDoc = (0, client_1.gql) `
    fragment PostTypeParts on PostType {
  id
  value
  label
  createdAt
  updatedAt
}
    `;
exports.ProfilePartsFragmentDoc = (0, client_1.gql) `
    fragment ProfileParts on Profile {
  id
  userId
  githubId
  githubUsername
  discordId
  discordUsername
  twitterId
  twitterUsername
  skillLevel
  bio
  location
  shortBio
  yearsOfExperience
  jobTitle
  company
  personalWebsiteUrl
  blogUrl
  linkedInUrl
  githubUrl
  calendarUrl
  idealCodingPartner
  hopeToGive
  hopeToGet
}
    `;
exports.ProfileStatsPartsFragmentDoc = (0, client_1.gql) `
    fragment ProfileStatsParts on ProfileStats {
  posts
  sessions
  followers
  badges
  rewards
  hoursPaired
  sessionCalendarData {
    date
    count
  }
}
    `;
exports.ProjectPartsFragmentDoc = (0, client_1.gql) `
    fragment ProjectParts on Project {
  id
  name
  description
  features
  implementation
  tradeoffs
  learnings
  challenges
  nextSteps
  imageUrl
  projectUrl
  githubRepoUrl
  demoVideoUrl
  likedByCurrentUser
  likeCount
  userIds
  tagIds
  uploadIds
  createdAt
  updatedAt
}
    `;
exports.ReactionPartsFragmentDoc = (0, client_1.gql) `
    fragment ReactionParts on Reaction {
  id
  value
  label
}
    `;
exports.RewardPartsFragmentDoc = (0, client_1.gql) `
    fragment RewardParts on Reward {
  id
  name
  description
  badgeId
  points
  createdAt
  updatedAt
}
    `;
exports.CreateVideoSessionTokenPartsFragmentDoc = (0, client_1.gql) `
    fragment CreateVideoSessionTokenParts on CreateVideoSessionTokenData {
  token
}
    `;
exports.PostPartsFragmentDoc = (0, client_1.gql) `
    fragment PostParts on Post {
  __typename
  id
  externalId
  creatorId
  title
  description
  type
  likedByCurrentUser
  likeCount
  isPublished
  isApproved
  isComplete
  isCanceled
  createdAt
  updatedAt
}
    `;
exports.UserPartsFragmentDoc = (0, client_1.gql) `
    fragment UserParts on User {
  id
  email
  username
  name
  timezone
  avatarUrl
  isOnline
  lastOnlineAt
  createdAt
}
    `;
exports.UserSessionPartsFragmentDoc = (0, client_1.gql) `
    fragment UserSessionParts on UserSession {
  id
  userId
  sessionId
  joinedAt
  leftAt
  createdAt
  updatedAt
}
    `;
exports.SessionPartsFragmentDoc = (0, client_1.gql) `
    fragment SessionParts on Session {
  id
  externalId
  isActiveNow
  postId
  post {
    ...PostParts
    creator {
      ...UserParts
    }
  }
  userSessions {
    ...UserSessionParts
    user {
      ...UserParts
    }
  }
  startedAt
  endedAt
  createdAt
  updatedAt
}
    ${exports.PostPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.UserSessionPartsFragmentDoc}`;
exports.TagPartsFragmentDoc = (0, client_1.gql) `
    fragment TagParts on Tag {
  id
  value
  label
  type
  description
}
    `;
exports.TaskFeedbackPartsFragmentDoc = (0, client_1.gql) `
    fragment TaskFeedbackParts on TaskFeedback {
  id
  userId
  taskId
  rating
  description
  createdAt
  updatedAt
}
    `;
exports.TaskTypePartsFragmentDoc = (0, client_1.gql) `
    fragment TaskTypeParts on TaskType {
  id
  value
  label
  createdAt
  updatedAt
}
    `;
exports.UploadPartsFragmentDoc = (0, client_1.gql) `
    fragment UploadParts on Upload {
  id
  userId
  key
  signedUrl
  filename
  mimetype
  encoding
  createdAt
  updatedAt
}
    `;
exports.TaskPartsFragmentDoc = (0, client_1.gql) `
    fragment TaskParts on Task {
  id
  title
  description
  url
  estimatedMinutes
  prerequisites
  objectives
  instructions
  difficulty
  likeCount
  likedByCurrentUser
  imageUrl
  imageKey
  createdAt
  updatedAt
  taskTypeId
  resourceIds
  uploadIds
  tagIds
}
    `;
exports.UserTaskPartsFragmentDoc = (0, client_1.gql) `
    fragment UserTaskParts on UserTask {
  id
  taskId
  userId
  isComplete
  createdAt
  updatedAt
  User {
    data {
      ...UserParts
    }
    error
  }
  Task {
    data {
      ...TaskParts
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.TaskPartsFragmentDoc}`;
exports.AuthUserPartsFragmentDoc = (0, client_1.gql) `
    fragment AuthUserParts on User {
  id
  email
  username
  name
  timezone
  avatarUrl
  refreshToken
  roles
  isRegistered
  isBanned
  isAdmin
  isOnboarded
  isOnline
  lastOnlineAt
  createdAt
  updatedAt
}
    `;
exports.GetBadgesDocument = (0, client_1.gql) `
    query GetBadges {
  GetBadges {
    data {
      ...BadgeParts
    }
    error
  }
}
    ${exports.BadgePartsFragmentDoc}`;
/**
 * __useGetBadgesQuery__
 *
 * To run a query within a React component, call `useGetBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgesQuery({
 *   variables: {
 *   },
 * });
 */
function useGetBadgesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetBadgesDocument, options);
}
exports.useGetBadgesQuery = useGetBadgesQuery;
function useGetBadgesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetBadgesDocument, options);
}
exports.useGetBadgesLazyQuery = useGetBadgesLazyQuery;
exports.GetUserBadgesDocument = (0, client_1.gql) `
    query GetUserBadges($input: GetUserBadgesInput!) {
  GetUserBadges(input: $input) {
    data {
      ...BadgeParts
    }
    error
  }
}
    ${exports.BadgePartsFragmentDoc}`;
/**
 * __useGetUserBadgesQuery__
 *
 * To run a query within a React component, call `useGetUserBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBadgesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetUserBadgesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetUserBadgesDocument, options);
}
exports.useGetUserBadgesQuery = useGetUserBadgesQuery;
function useGetUserBadgesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetUserBadgesDocument, options);
}
exports.useGetUserBadgesLazyQuery = useGetUserBadgesLazyQuery;
exports.StartChatDocument = (0, client_1.gql) `
    mutation StartChat($input: StartChatInput!) {
  StartChat(input: $input) {
    data {
      ...ChatParts
      Messages {
        id
        data {
          ...MessageParts
          Sender {
            id
            data {
              ...UserParts
            }
            error
          }
          Status {
            id
            data {
              ...MessageStatusParts
              Recipient {
                id
                data {
                  ...UserParts
                }
                error
              }
            }
            error
          }
        }
        error
      }
      Participants {
        id
        data {
          ...UserParts
        }
        error
      }
    }
    error
  }
}
    ${exports.ChatPartsFragmentDoc}
${exports.MessagePartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.MessageStatusPartsFragmentDoc}`;
/**
 * __useStartChatMutation__
 *
 * To run a mutation, you first call `useStartChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startChatMutation, { data, loading, error }] = useStartChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useStartChatMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.StartChatDocument, options);
}
exports.useStartChatMutation = useStartChatMutation;
exports.SendChatMessageDocument = (0, client_1.gql) `
    mutation SendChatMessage($input: SendChatMessageInput!) {
  SendChatMessage(input: $input) {
    data {
      ...MessageParts
      Sender {
        id
        data {
          ...UserParts
        }
        error
      }
      Status {
        id
        data {
          ...MessageStatusParts
          Recipient {
            id
            data {
              ...UserParts
            }
            error
          }
        }
        error
      }
    }
    error
  }
}
    ${exports.MessagePartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.MessageStatusPartsFragmentDoc}`;
/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useSendChatMessageMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.SendChatMessageDocument, options);
}
exports.useSendChatMessageMutation = useSendChatMessageMutation;
exports.GetChatDocument = (0, client_1.gql) `
    query GetChat($input: GetChatInput!) {
  GetChat(input: $input) {
    data {
      ...ChatParts
      Messages {
        id
        data {
          ...MessageParts
          Status {
            id
            data {
              isRead
              createdAt
              Recipient {
                id
                data {
                  ...UserParts
                }
              }
            }
            error
          }
          Sender {
            id
            data {
              ...UserParts
            }
            error
          }
        }
        error
      }
      Participants {
        id
        data {
          ...UserParts
        }
        error
      }
    }
    error
  }
}
    ${exports.ChatPartsFragmentDoc}
${exports.MessagePartsFragmentDoc}
${exports.UserPartsFragmentDoc}`;
/**
 * __useGetChatQuery__
 *
 * To run a query within a React component, call `useGetChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetChatQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetChatDocument, options);
}
exports.useGetChatQuery = useGetChatQuery;
function useGetChatLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetChatDocument, options);
}
exports.useGetChatLazyQuery = useGetChatLazyQuery;
exports.GetChatsForUserDocument = (0, client_1.gql) `
    query GetChatsForUser {
  GetChatsForUser {
    data {
      ...ChatParts
      MostRecentMessage {
        id
        data {
          ...MessageParts
          Status {
            id
            data {
              isRead
              createdAt
              Recipient {
                data {
                  id
                  ...UserParts
                }
              }
            }
            error
          }
          Sender {
            id
            data {
              ...UserParts
            }
            error
          }
        }
        error
      }
      Participants {
        id
        data {
          id
          ...UserParts
        }
        error
      }
    }
    error
  }
}
    ${exports.ChatPartsFragmentDoc}
${exports.MessagePartsFragmentDoc}
${exports.UserPartsFragmentDoc}`;
/**
 * __useGetChatsForUserQuery__
 *
 * To run a query within a React component, call `useGetChatsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsForUserQuery({
 *   variables: {
 *   },
 * });
 */
function useGetChatsForUserQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetChatsForUserDocument, options);
}
exports.useGetChatsForUserQuery = useGetChatsForUserQuery;
function useGetChatsForUserLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetChatsForUserDocument, options);
}
exports.useGetChatsForUserLazyQuery = useGetChatsForUserLazyQuery;
exports.OnChatMessageDocument = (0, client_1.gql) `
    subscription OnChatMessage {
  OnChatMessage {
    ...MessageParts
    Status {
      id
      data {
        id
        ...MessageStatusParts
        Recipient {
          id
          data {
            ...UserParts
          }
          error
        }
      }
      error
    }
    Sender {
      id
      data {
        ...UserParts
      }
      error
    }
  }
}
    ${exports.MessagePartsFragmentDoc}
${exports.MessageStatusPartsFragmentDoc}
${exports.UserPartsFragmentDoc}`;
/**
 * __useOnChatMessageSubscription__
 *
 * To run a query within a React component, call `useOnChatMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChatMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChatMessageSubscription({
 *   variables: {
 *   },
 * });
 */
function useOnChatMessageSubscription(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription(exports.OnChatMessageDocument, options);
}
exports.useOnChatMessageSubscription = useOnChatMessageSubscription;
exports.UpdateAllChatMessageStatusesForChatDocument = (0, client_1.gql) `
    mutation UpdateAllChatMessageStatusesForChat($input: UpdateAllChatMessageStatusesForChatInput!) {
  UpdateAllChatMessageStatusesForChat(input: $input) {
    data
    error
  }
}
    `;
/**
 * __useUpdateAllChatMessageStatusesForChatMutation__
 *
 * To run a mutation, you first call `useUpdateAllChatMessageStatusesForChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllChatMessageStatusesForChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllChatMessageStatusesForChatMutation, { data, loading, error }] = useUpdateAllChatMessageStatusesForChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateAllChatMessageStatusesForChatMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UpdateAllChatMessageStatusesForChatDocument, options);
}
exports.useUpdateAllChatMessageStatusesForChatMutation = useUpdateAllChatMessageStatusesForChatMutation;
exports.FollowUserDocument = (0, client_1.gql) `
    mutation FollowUser($input: FollowUserInput!) {
  FollowUser(input: $input) {
    data {
      ...UserParts
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}`;
/**
 * __useFollowUserMutation__
 *
 * To run a mutation, you first call `useFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUserMutation, { data, loading, error }] = useFollowUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useFollowUserMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.FollowUserDocument, options);
}
exports.useFollowUserMutation = useFollowUserMutation;
exports.GetLatestPlatformEventsDocument = (0, client_1.gql) `
    query GetLatestPlatformEvents($input: GetPlatformEventsInput!) {
  GetLatestPlatformEvents(input: $input) {
    data {
      platformEvents {
        ...PlatformEventParts
        user {
          ...UserParts
          profile {
            ...ProfileParts
          }
        }
        PlatformEventType {
          id
          data {
            ...PlatformEventTypeParts
          }
          error
        }
      }
    }
    error
  }
}
    ${exports.PlatformEventPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.PlatformEventTypePartsFragmentDoc}`;
/**
 * __useGetLatestPlatformEventsQuery__
 *
 * To run a query within a React component, call `useGetLatestPlatformEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPlatformEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPlatformEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetLatestPlatformEventsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetLatestPlatformEventsDocument, options);
}
exports.useGetLatestPlatformEventsQuery = useGetLatestPlatformEventsQuery;
function useGetLatestPlatformEventsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetLatestPlatformEventsDocument, options);
}
exports.useGetLatestPlatformEventsLazyQuery = useGetLatestPlatformEventsLazyQuery;
exports.OnPlatformEventDocument = (0, client_1.gql) `
    subscription OnPlatformEvent {
  OnPlatformEvent {
    ...PlatformEventParts
    PlatformEventType {
      data {
        ...PlatformEventTypeParts
      }
      error
    }
    user {
      ...UserParts
      profile {
        ...ProfileParts
      }
    }
  }
}
    ${exports.PlatformEventPartsFragmentDoc}
${exports.PlatformEventTypePartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}`;
/**
 * __useOnPlatformEventSubscription__
 *
 * To run a query within a React component, call `useOnPlatformEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPlatformEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPlatformEventSubscription({
 *   variables: {
 *   },
 * });
 */
function useOnPlatformEventSubscription(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription(exports.OnPlatformEventDocument, options);
}
exports.useOnPlatformEventSubscription = useOnPlatformEventSubscription;
exports.TrackPlatformEventDocument = (0, client_1.gql) `
    mutation TrackPlatformEvent($input: TrackPlatformEventInput!) {
  TrackPlatformEvent(input: $input) {
    data {
      ...PlatformEventParts
      user {
        ...UserParts
        profile {
          ...ProfileParts
        }
      }
      User {
        data {
          ...UserParts
          profile {
            ...ProfileParts
          }
        }
        error
      }
      PlatformEventType {
        id
        data {
          ...PlatformEventTypeParts
        }
        error
      }
    }
    error
  }
}
    ${exports.PlatformEventPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.PlatformEventTypePartsFragmentDoc}`;
/**
 * __useTrackPlatformEventMutation__
 *
 * To run a mutation, you first call `useTrackPlatformEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackPlatformEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackPlatformEventMutation, { data, loading, error }] = useTrackPlatformEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useTrackPlatformEventMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.TrackPlatformEventDocument, options);
}
exports.useTrackPlatformEventMutation = useTrackPlatformEventMutation;
exports.SubmitPlatformFeedbackDocument = (0, client_1.gql) `
    mutation SubmitPlatformFeedback($input: SubmitPlatformFeedbackInput!) {
  SubmitPlatformFeedback(input: $input) {
    data {
      feedback {
        ...PlatformFeedbackParts
      }
    }
    error
  }
}
    ${exports.PlatformFeedbackPartsFragmentDoc}`;
/**
 * __useSubmitPlatformFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitPlatformFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPlatformFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPlatformFeedbackMutation, { data, loading, error }] = useSubmitPlatformFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useSubmitPlatformFeedbackMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.SubmitPlatformFeedbackDocument, options);
}
exports.useSubmitPlatformFeedbackMutation = useSubmitPlatformFeedbackMutation;
exports.AddPostCommentDocument = (0, client_1.gql) `
    mutation AddPostComment($input: AddPostCommentInput!) {
  AddPostComment(input: $input) {
    data {
      ...PostCommentParts
      Post {
        data {
          ...PostParts
        }
        error
      }
      User {
        data {
          ...UserParts
        }
        error
      }
    }
    error
  }
}
    ${exports.PostCommentPartsFragmentDoc}
${exports.PostPartsFragmentDoc}
${exports.UserPartsFragmentDoc}`;
/**
 * __useAddPostCommentMutation__
 *
 * To run a mutation, you first call `useAddPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostCommentMutation, { data, loading, error }] = useAddPostCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAddPostCommentMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.AddPostCommentDocument, options);
}
exports.useAddPostCommentMutation = useAddPostCommentMutation;
exports.AddPostReactionDocument = (0, client_1.gql) `
    mutation AddPostReaction($input: AddPostReactionInput!) {
  AddPostReaction(input: $input) {
    data {
      id
      postId
      reactionId
      userId
      updatedAt
      createdAt
      User {
        data {
          ...UserParts
        }
        error
      }
      Post {
        data {
          ...PostParts
        }
        error
      }
      Reaction {
        data {
          ...ReactionParts
        }
        error
      }
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.PostPartsFragmentDoc}
${exports.ReactionPartsFragmentDoc}`;
/**
 * __useAddPostReactionMutation__
 *
 * To run a mutation, you first call `useAddPostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostReactionMutation, { data, loading, error }] = useAddPostReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAddPostReactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.AddPostReactionDocument, options);
}
exports.useAddPostReactionMutation = useAddPostReactionMutation;
exports.RemovePostReactionDocument = (0, client_1.gql) `
    mutation RemovePostReaction($input: RemovePostReactionInput!) {
  RemovePostReaction(input: $input) {
    data {
      id
      postId
      reactionId
      userId
      updatedAt
      createdAt
      User {
        data {
          ...UserParts
        }
        error
      }
      Post {
        data {
          ...PostParts
        }
        error
      }
      Reaction {
        data {
          ...ReactionParts
        }
        error
      }
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.PostPartsFragmentDoc}
${exports.ReactionPartsFragmentDoc}`;
/**
 * __useRemovePostReactionMutation__
 *
 * To run a mutation, you first call `useRemovePostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePostReactionMutation, { data, loading, error }] = useRemovePostReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useRemovePostReactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.RemovePostReactionDocument, options);
}
exports.useRemovePostReactionMutation = useRemovePostReactionMutation;
exports.GetAllPostTypesDocument = (0, client_1.gql) `
    query GetAllPostTypes {
  GetAllPostTypes {
    data {
      id
      value
      label
      createdAt
      updatedAt
    }
    error
  }
}
    `;
/**
 * __useGetAllPostTypesQuery__
 *
 * To run a query within a React component, call `useGetAllPostTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPostTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPostTypesQuery({
 *   variables: {
 *   },
 * });
 */
function useGetAllPostTypesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetAllPostTypesDocument, options);
}
exports.useGetAllPostTypesQuery = useGetAllPostTypesQuery;
function useGetAllPostTypesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetAllPostTypesDocument, options);
}
exports.useGetAllPostTypesLazyQuery = useGetAllPostTypesLazyQuery;
exports.GetPostDocument = (0, client_1.gql) `
    query GetPost($input: GetPostInput!) {
  GetPost(input: $input) {
    data {
      post {
        ...PostParts
        sessions {
          ...SessionParts
        }
        tags {
          ...TagParts
        }
        PostType {
          data {
            ...PostTypeParts
          }
          error
        }
        Creator {
          id
          data {
            ...UserParts
            profile {
              ...ProfileParts
            }
          }
        }
        Uploads {
          id
          data {
            ...UploadParts
          }
          error
        }
      }
    }
    error
  }
}
    ${exports.PostPartsFragmentDoc}
${exports.SessionPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.PostTypePartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useGetPostQuery__
 *
 * To run a query within a React component, call `useGetPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetPostQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetPostDocument, options);
}
exports.useGetPostQuery = useGetPostQuery;
function useGetPostLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetPostDocument, options);
}
exports.useGetPostLazyQuery = useGetPostLazyQuery;
exports.GetPostsForUserDocument = (0, client_1.gql) `
    query GetPostsForUser($input: GetPostsForUserInput!) {
  GetPostsForUser(input: $input) {
    data {
      posts {
        ...PostParts
        sessions {
          ...SessionParts
        }
        tags {
          ...TagParts
        }
        Creator {
          id
          data {
            ...UserParts
            profile {
              ...ProfileParts
            }
          }
        }
        Uploads {
          id
          data {
            ...UploadParts
          }
          error
        }
      }
      count
    }
    error
  }
}
    ${exports.PostPartsFragmentDoc}
${exports.SessionPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useGetPostsForUserQuery__
 *
 * To run a query within a React component, call `useGetPostsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsForUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetPostsForUserQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetPostsForUserDocument, options);
}
exports.useGetPostsForUserQuery = useGetPostsForUserQuery;
function useGetPostsForUserLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetPostsForUserDocument, options);
}
exports.useGetPostsForUserLazyQuery = useGetPostsForUserLazyQuery;
exports.GetLatestPostsDocument = (0, client_1.gql) `
    query GetLatestPosts($input: GetLatestPostsInput!) {
  GetLatestPosts(input: $input) {
    data {
      posts {
        ...PostParts
        sessions {
          ...SessionParts
        }
        tags {
          ...TagParts
        }
        Creator {
          id
          data {
            ...UserParts
            profile {
              ...ProfileParts
            }
          }
        }
        PostType {
          id
          data {
            ...PostTypeParts
          }
        }
        Uploads {
          id
          data {
            ...UploadParts
          }
          error
        }
      }
      count
    }
    error
  }
}
    ${exports.PostPartsFragmentDoc}
${exports.SessionPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.PostTypePartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useGetLatestPostsQuery__
 *
 * To run a query within a React component, call `useGetLatestPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPostsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetLatestPostsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetLatestPostsDocument, options);
}
exports.useGetLatestPostsQuery = useGetLatestPostsQuery;
function useGetLatestPostsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetLatestPostsDocument, options);
}
exports.useGetLatestPostsLazyQuery = useGetLatestPostsLazyQuery;
exports.CreatePostDocument = (0, client_1.gql) `
    mutation CreatePost($input: CreatePostInput!) {
  CreatePost(input: $input) {
    data {
      ...PostParts
      tags {
        ...TagParts
      }
      Uploads {
        id
        data {
          ...UploadParts
        }
        error
      }
      Creator {
        id
        data {
          ...UserParts
          profile {
            ...ProfileParts
          }
        }
        error
      }
      PostType {
        id
        data {
          ...PostTypeParts
        }
        error
      }
    }
    error
  }
}
    ${exports.PostPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.UploadPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.PostTypePartsFragmentDoc}`;
/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useCreatePostMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.CreatePostDocument, options);
}
exports.useCreatePostMutation = useCreatePostMutation;
exports.PostCreatedDocument = (0, client_1.gql) `
    subscription PostCreated {
  PostCreated {
    ...PostParts
    sessions {
      ...SessionParts
    }
    tags {
      ...TagParts
    }
    Uploads {
      id
      data {
        ...UploadParts
      }
      error
    }
    Creator {
      id
      data {
        ...UserParts
        profile {
          ...ProfileParts
        }
      }
      error
    }
    PostType {
      id
      data {
        ...PostTypeParts
      }
      error
    }
    Uploads {
      id
      data {
        ...UploadParts
      }
      error
    }
  }
}
    ${exports.PostPartsFragmentDoc}
${exports.SessionPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.UploadPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.PostTypePartsFragmentDoc}`;
/**
 * __usePostCreatedSubscription__
 *
 * To run a query within a React component, call `usePostCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePostCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
function usePostCreatedSubscription(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription(exports.PostCreatedDocument, options);
}
exports.usePostCreatedSubscription = usePostCreatedSubscription;
exports.PostUpdatedDocument = (0, client_1.gql) `
    subscription PostUpdated {
  PostUpdated {
    ...PostParts
    sessions {
      ...SessionParts
    }
    tags {
      ...TagParts
    }
    PostType {
      data {
        ...PostTypeParts
      }
      error
    }
    Uploads {
      data {
        ...UploadParts
      }
      error
    }
    Creator {
      data {
        ...UserParts
        profile {
          ...ProfileParts
        }
      }
      error
    }
  }
}
    ${exports.PostPartsFragmentDoc}
${exports.SessionPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.PostTypePartsFragmentDoc}
${exports.UploadPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}`;
/**
 * __usePostUpdatedSubscription__
 *
 * To run a query within a React component, call `usePostUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePostUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
function usePostUpdatedSubscription(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription(exports.PostUpdatedDocument, options);
}
exports.usePostUpdatedSubscription = usePostUpdatedSubscription;
exports.UpdateProfileDocument = (0, client_1.gql) `
    mutation UpdateProfile($input: UpdateProfileInput!) {
  UpdateProfile(input: $input) {
    data {
      profile {
        ...ProfileParts
      }
    }
    error
  }
}
    ${exports.ProfilePartsFragmentDoc}`;
/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateProfileMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UpdateProfileDocument, options);
}
exports.useUpdateProfileMutation = useUpdateProfileMutation;
exports.AddProjectReactionDocument = (0, client_1.gql) `
    mutation AddProjectReaction($input: AddProjectReactionInput!) {
  AddProjectReaction(input: $input) {
    data {
      id
      projectId
      reactionId
      userId
      updatedAt
      createdAt
      User {
        data {
          ...UserParts
        }
        error
      }
      Project {
        data {
          ...ProjectParts
        }
        error
      }
      Reaction {
        data {
          ...ReactionParts
        }
        error
      }
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.ProjectPartsFragmentDoc}
${exports.ReactionPartsFragmentDoc}`;
/**
 * __useAddProjectReactionMutation__
 *
 * To run a mutation, you first call `useAddProjectReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectReactionMutation, { data, loading, error }] = useAddProjectReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAddProjectReactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.AddProjectReactionDocument, options);
}
exports.useAddProjectReactionMutation = useAddProjectReactionMutation;
exports.RemoveProjectReactionDocument = (0, client_1.gql) `
    mutation RemoveProjectReaction($input: RemoveProjectReactionInput!) {
  RemoveProjectReaction(input: $input) {
    data {
      id
      projectId
      reactionId
      userId
      updatedAt
      createdAt
      User {
        data {
          ...UserParts
        }
        error
      }
      Project {
        data {
          ...ProjectParts
        }
        error
      }
      Reaction {
        data {
          ...ReactionParts
        }
        error
      }
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.ProjectPartsFragmentDoc}
${exports.ReactionPartsFragmentDoc}`;
/**
 * __useRemoveProjectReactionMutation__
 *
 * To run a mutation, you first call `useRemoveProjectReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectReactionMutation, { data, loading, error }] = useRemoveProjectReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useRemoveProjectReactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.RemoveProjectReactionDocument, options);
}
exports.useRemoveProjectReactionMutation = useRemoveProjectReactionMutation;
exports.GetProjectDocument = (0, client_1.gql) `
    query GetProject($input: GetProjectInput!) {
  GetProject(input: $input) {
    data {
      ...ProjectParts
      Users {
        data {
          ...UserParts
        }
        error
      }
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Uploads {
        data {
          ...UploadParts
        }
        error
      }
    }
    error
  }
}
    ${exports.ProjectPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetProjectQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetProjectDocument, options);
}
exports.useGetProjectQuery = useGetProjectQuery;
function useGetProjectLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetProjectDocument, options);
}
exports.useGetProjectLazyQuery = useGetProjectLazyQuery;
exports.GetProjectsDocument = (0, client_1.gql) `
    query GetProjects($input: GetProjectsInput!) {
  GetProjects(input: $input) {
    data {
      ...ProjectParts
      Users {
        data {
          ...UserParts
        }
        error
      }
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Uploads {
        data {
          ...UploadParts
        }
        error
      }
    }
    error
  }
}
    ${exports.ProjectPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetProjectsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetProjectsDocument, options);
}
exports.useGetProjectsQuery = useGetProjectsQuery;
function useGetProjectsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetProjectsDocument, options);
}
exports.useGetProjectsLazyQuery = useGetProjectsLazyQuery;
exports.CreateProjectDocument = (0, client_1.gql) `
    mutation CreateProject($input: CreateProjectInput!) {
  CreateProject(input: $input) {
    data {
      ...ProjectParts
      Users {
        data {
          ...UserParts
        }
        error
      }
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Uploads {
        data {
          ...UploadParts
        }
        error
      }
    }
    error
  }
}
    ${exports.ProjectPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useCreateProjectMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.CreateProjectDocument, options);
}
exports.useCreateProjectMutation = useCreateProjectMutation;
exports.UpdateProjectDocument = (0, client_1.gql) `
    mutation UpdateProject($input: UpdateProjectInput!) {
  UpdateProject(input: $input) {
    data {
      ...ProjectParts
      Users {
        data {
          ...UserParts
        }
        error
      }
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Uploads {
        data {
          ...UploadParts
        }
        error
      }
    }
    error
  }
}
    ${exports.ProjectPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateProjectMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UpdateProjectDocument, options);
}
exports.useUpdateProjectMutation = useUpdateProjectMutation;
exports.DeleteProjectDocument = (0, client_1.gql) `
    mutation DeleteProject($input: DeleteProjectInput!) {
  DeleteProject(input: $input) {
    data {
      ...ProjectParts
      Users {
        data {
          ...UserParts
        }
        error
      }
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Uploads {
        data {
          ...UploadParts
        }
        error
      }
    }
    error
  }
}
    ${exports.ProjectPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useDeleteProjectMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.DeleteProjectDocument, options);
}
exports.useDeleteProjectMutation = useDeleteProjectMutation;
exports.GetRewardsDocument = (0, client_1.gql) `
    query GetRewards {
  GetRewards {
    data {
      ...RewardParts
    }
    error
  }
}
    ${exports.RewardPartsFragmentDoc}`;
/**
 * __useGetRewardsQuery__
 *
 * To run a query within a React component, call `useGetRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardsQuery({
 *   variables: {
 *   },
 * });
 */
function useGetRewardsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetRewardsDocument, options);
}
exports.useGetRewardsQuery = useGetRewardsQuery;
function useGetRewardsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetRewardsDocument, options);
}
exports.useGetRewardsLazyQuery = useGetRewardsLazyQuery;
exports.GetUserRewardsDocument = (0, client_1.gql) `
    query GetUserRewards($input: GetUserRewardsInput!) {
  GetUserRewards(input: $input) {
    data {
      ...RewardParts
    }
    error
  }
}
    ${exports.RewardPartsFragmentDoc}`;
/**
 * __useGetUserRewardsQuery__
 *
 * To run a query within a React component, call `useGetUserRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRewardsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetUserRewardsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetUserRewardsDocument, options);
}
exports.useGetUserRewardsQuery = useGetUserRewardsQuery;
function useGetUserRewardsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetUserRewardsDocument, options);
}
exports.useGetUserRewardsLazyQuery = useGetUserRewardsLazyQuery;
exports.CreateVideoSessionTokenDocument = (0, client_1.gql) `
    mutation CreateVideoSessionToken($input: CreateVideoSessionTokenInput!) {
  CreateVideoSessionToken(input: $input) {
    data {
      ...CreateVideoSessionTokenParts
    }
    error
  }
}
    ${exports.CreateVideoSessionTokenPartsFragmentDoc}`;
/**
 * __useCreateVideoSessionTokenMutation__
 *
 * To run a mutation, you first call `useCreateVideoSessionTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoSessionTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoSessionTokenMutation, { data, loading, error }] = useCreateVideoSessionTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useCreateVideoSessionTokenMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.CreateVideoSessionTokenDocument, options);
}
exports.useCreateVideoSessionTokenMutation = useCreateVideoSessionTokenMutation;
exports.GetSessionsForPostDocument = (0, client_1.gql) `
    query GetSessionsForPost($input: GetSessionsForPostInput!) {
  GetSessionsForPost(input: $input) {
    data {
      sessions {
        ...SessionParts
      }
    }
    error
  }
}
    ${exports.SessionPartsFragmentDoc}`;
/**
 * __useGetSessionsForPostQuery__
 *
 * To run a query within a React component, call `useGetSessionsForPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsForPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsForPostQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetSessionsForPostQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetSessionsForPostDocument, options);
}
exports.useGetSessionsForPostQuery = useGetSessionsForPostQuery;
function useGetSessionsForPostLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetSessionsForPostDocument, options);
}
exports.useGetSessionsForPostLazyQuery = useGetSessionsForPostLazyQuery;
exports.GetSessionsForUserDocument = (0, client_1.gql) `
    query GetSessionsForUser($input: GetSessionsForUserInput!) {
  GetSessionsForUser(input: $input) {
    data {
      ...SessionParts
      userSessions {
        ...UserSessionParts
        user {
          ...UserParts
        }
      }
    }
    error
  }
}
    ${exports.SessionPartsFragmentDoc}
${exports.UserSessionPartsFragmentDoc}
${exports.UserPartsFragmentDoc}`;
/**
 * __useGetSessionsForUserQuery__
 *
 * To run a query within a React component, call `useGetSessionsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsForUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetSessionsForUserQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetSessionsForUserDocument, options);
}
exports.useGetSessionsForUserQuery = useGetSessionsForUserQuery;
function useGetSessionsForUserLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetSessionsForUserDocument, options);
}
exports.useGetSessionsForUserLazyQuery = useGetSessionsForUserLazyQuery;
exports.UpdateUserTagSubscriptionsDocument = (0, client_1.gql) `
    mutation UpdateUserTagSubscriptions($input: UpdateUserTagSubscriptionsInput!) {
  UpdateUserTagSubscriptions(input: $input) {
    data {
      tags {
        ...TagParts
      }
    }
    error
  }
}
    ${exports.TagPartsFragmentDoc}`;
/**
 * __useUpdateUserTagSubscriptionsMutation__
 *
 * To run a mutation, you first call `useUpdateUserTagSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTagSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTagSubscriptionsMutation, { data, loading, error }] = useUpdateUserTagSubscriptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateUserTagSubscriptionsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UpdateUserTagSubscriptionsDocument, options);
}
exports.useUpdateUserTagSubscriptionsMutation = useUpdateUserTagSubscriptionsMutation;
exports.GetUserTagSubscriptionsDocument = (0, client_1.gql) `
    query GetUserTagSubscriptions {
  GetUserTagSubscriptions {
    data {
      tags {
        ...TagParts
      }
    }
    error
  }
}
    ${exports.TagPartsFragmentDoc}`;
/**
 * __useGetUserTagSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetUserTagSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTagSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTagSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
function useGetUserTagSubscriptionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetUserTagSubscriptionsDocument, options);
}
exports.useGetUserTagSubscriptionsQuery = useGetUserTagSubscriptionsQuery;
function useGetUserTagSubscriptionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetUserTagSubscriptionsDocument, options);
}
exports.useGetUserTagSubscriptionsLazyQuery = useGetUserTagSubscriptionsLazyQuery;
exports.GetTagsDocument = (0, client_1.gql) `
    query GetTags {
  GetTags {
    data {
      ...TagParts
    }
    error
  }
}
    ${exports.TagPartsFragmentDoc}`;
/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
function useGetTagsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetTagsDocument, options);
}
exports.useGetTagsQuery = useGetTagsQuery;
function useGetTagsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetTagsDocument, options);
}
exports.useGetTagsLazyQuery = useGetTagsLazyQuery;
exports.UpdateTagsForUserDocument = (0, client_1.gql) `
    mutation UpdateTagsForUser($input: UpdateTagsForUserInput!) {
  UpdateTagsForUser(input: $input) {
    data {
      ...TagParts
    }
    error
  }
}
    ${exports.TagPartsFragmentDoc}`;
/**
 * __useUpdateTagsForUserMutation__
 *
 * To run a mutation, you first call `useUpdateTagsForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagsForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagsForUserMutation, { data, loading, error }] = useUpdateTagsForUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateTagsForUserMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UpdateTagsForUserDocument, options);
}
exports.useUpdateTagsForUserMutation = useUpdateTagsForUserMutation;
exports.SubmitTaskFeedbackDocument = (0, client_1.gql) `
    mutation SubmitTaskFeedback($input: SubmitTaskFeedbackInput!) {
  SubmitTaskFeedback(input: $input) {
    data {
      ...TaskFeedbackParts
      Task {
        data {
          ...TaskParts
        }
        error
      }
      User {
        data {
          ...UserParts
        }
        error
      }
    }
    error
  }
}
    ${exports.TaskFeedbackPartsFragmentDoc}
${exports.TaskPartsFragmentDoc}
${exports.UserPartsFragmentDoc}`;
/**
 * __useSubmitTaskFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitTaskFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTaskFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTaskFeedbackMutation, { data, loading, error }] = useSubmitTaskFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useSubmitTaskFeedbackMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.SubmitTaskFeedbackDocument, options);
}
exports.useSubmitTaskFeedbackMutation = useSubmitTaskFeedbackMutation;
exports.AddTaskReactionDocument = (0, client_1.gql) `
    mutation AddTaskReaction($input: AddTaskReactionInput!) {
  AddTaskReaction(input: $input) {
    data {
      id
      taskId
      reactionId
      userId
      updatedAt
      createdAt
      User {
        data {
          ...UserParts
        }
        error
      }
      Task {
        data {
          ...TaskParts
        }
        error
      }
      Reaction {
        data {
          ...ReactionParts
        }
        error
      }
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.TaskPartsFragmentDoc}
${exports.ReactionPartsFragmentDoc}`;
/**
 * __useAddTaskReactionMutation__
 *
 * To run a mutation, you first call `useAddTaskReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskReactionMutation, { data, loading, error }] = useAddTaskReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAddTaskReactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.AddTaskReactionDocument, options);
}
exports.useAddTaskReactionMutation = useAddTaskReactionMutation;
exports.RemoveTaskReactionDocument = (0, client_1.gql) `
    mutation RemoveTaskReaction($input: RemoveTaskReactionInput!) {
  RemoveTaskReaction(input: $input) {
    data {
      id
      taskId
      reactionId
      userId
      updatedAt
      createdAt
      User {
        data {
          ...UserParts
        }
        error
      }
      Task {
        data {
          ...TaskParts
        }
        error
      }
      Reaction {
        data {
          ...ReactionParts
        }
        error
      }
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.TaskPartsFragmentDoc}
${exports.ReactionPartsFragmentDoc}`;
/**
 * __useRemoveTaskReactionMutation__
 *
 * To run a mutation, you first call `useRemoveTaskReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskReactionMutation, { data, loading, error }] = useRemoveTaskReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useRemoveTaskReactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.RemoveTaskReactionDocument, options);
}
exports.useRemoveTaskReactionMutation = useRemoveTaskReactionMutation;
exports.GetTaskDocument = (0, client_1.gql) `
    query GetTask($input: GetTaskInput!) {
  GetTask(input: $input) {
    data {
      ...TaskParts
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Type {
        data {
          ...TaskTypeParts
        }
        error
      }
      Uploads {
        data {
          ...UploadParts
        }
        error
      }
    }
    error
  }
}
    ${exports.TaskPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.TaskTypePartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetTaskQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetTaskDocument, options);
}
exports.useGetTaskQuery = useGetTaskQuery;
function useGetTaskLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetTaskDocument, options);
}
exports.useGetTaskLazyQuery = useGetTaskLazyQuery;
exports.GetTasksDocument = (0, client_1.gql) `
    query GetTasks($input: GetTasksInput!) {
  GetTasks(input: $input) {
    data {
      ...TaskParts
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Type {
        data {
          ...TaskTypeParts
        }
        error
      }
      Uploads {
        data {
          ...UploadParts
        }
        error
      }
    }
    error
  }
}
    ${exports.TaskPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.TaskTypePartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetTasksQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetTasksDocument, options);
}
exports.useGetTasksQuery = useGetTasksQuery;
function useGetTasksLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetTasksDocument, options);
}
exports.useGetTasksLazyQuery = useGetTasksLazyQuery;
exports.AdminCreateTaskDocument = (0, client_1.gql) `
    mutation AdminCreateTask($input: AdminCreateTaskInput!) {
  AdminCreateTask(input: $input) {
    data {
      ...TaskParts
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Type {
        data {
          ...TaskTypeParts
        }
        error
      }
      Uploads {
        data {
          ...UploadParts
        }
        error
      }
    }
    error
  }
}
    ${exports.TaskPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.TaskTypePartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useAdminCreateTaskMutation__
 *
 * To run a mutation, you first call `useAdminCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateTaskMutation, { data, loading, error }] = useAdminCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAdminCreateTaskMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.AdminCreateTaskDocument, options);
}
exports.useAdminCreateTaskMutation = useAdminCreateTaskMutation;
exports.AdminUpdateTaskDocument = (0, client_1.gql) `
    mutation AdminUpdateTask($input: AdminUpdateTaskInput!) {
  AdminUpdateTask(input: $input) {
    data {
      ...TaskParts
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Type {
        data {
          ...TaskTypeParts
        }
        error
      }
      Uploads {
        data {
          ...UploadParts
        }
        error
      }
    }
    error
  }
}
    ${exports.TaskPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.TaskTypePartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useAdminUpdateTaskMutation__
 *
 * To run a mutation, you first call `useAdminUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateTaskMutation, { data, loading, error }] = useAdminUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAdminUpdateTaskMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.AdminUpdateTaskDocument, options);
}
exports.useAdminUpdateTaskMutation = useAdminUpdateTaskMutation;
exports.AdminDeleteTaskDocument = (0, client_1.gql) `
    mutation AdminDeleteTask($input: AdminDeleteTaskInput!) {
  AdminDeleteTask(input: $input) {
    data {
      ...TaskParts
      Tags {
        data {
          ...TagParts
        }
        error
      }
      Type {
        data {
          ...TaskTypeParts
        }
        error
      }
    }
    error
  }
}
    ${exports.TaskPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.TaskTypePartsFragmentDoc}`;
/**
 * __useAdminDeleteTaskMutation__
 *
 * To run a mutation, you first call `useAdminDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteTaskMutation, { data, loading, error }] = useAdminDeleteTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAdminDeleteTaskMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.AdminDeleteTaskDocument, options);
}
exports.useAdminDeleteTaskMutation = useAdminDeleteTaskMutation;
exports.GetTaskTypesDocument = (0, client_1.gql) `
    query GetTaskTypes {
  GetTaskTypes {
    data {
      ...TaskTypeParts
    }
    error
  }
}
    ${exports.TaskTypePartsFragmentDoc}`;
/**
 * __useGetTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTypesQuery({
 *   variables: {
 *   },
 * });
 */
function useGetTaskTypesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetTaskTypesDocument, options);
}
exports.useGetTaskTypesQuery = useGetTaskTypesQuery;
function useGetTaskTypesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetTaskTypesDocument, options);
}
exports.useGetTaskTypesLazyQuery = useGetTaskTypesLazyQuery;
exports.UpsertUserTaskDocument = (0, client_1.gql) `
    mutation UpsertUserTask($input: UpsertUserTaskInput!) {
  UpsertUserTask(input: $input) {
    data {
      ...UserTaskParts
    }
    error
  }
}
    ${exports.UserTaskPartsFragmentDoc}`;
/**
 * __useUpsertUserTaskMutation__
 *
 * To run a mutation, you first call `useUpsertUserTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserTaskMutation, { data, loading, error }] = useUpsertUserTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpsertUserTaskMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UpsertUserTaskDocument, options);
}
exports.useUpsertUserTaskMutation = useUpsertUserTaskMutation;
exports.GetUsersDocument = (0, client_1.gql) `
    query GetUsers($input: GetUsersInput!) {
  GetUsers(input: $input) {
    data {
      users {
        ...UserParts
        profile {
          ...ProfileParts
          stats {
            ...ProfileStatsParts
          }
        }
        posts {
          ...PostParts
        }
        followers {
          ...UserParts
        }
        following {
          ...UserParts
        }
        badges {
          ...BadgeParts
        }
        rewards {
          badge {
            ...BadgeParts
          }
          ...RewardParts
        }
        tags {
          ...TagParts
        }
        Posts {
          id
          data {
            ...PostParts
          }
          error
        }
      }
      count
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.ProfileStatsPartsFragmentDoc}
${exports.PostPartsFragmentDoc}
${exports.BadgePartsFragmentDoc}
${exports.RewardPartsFragmentDoc}
${exports.TagPartsFragmentDoc}`;
/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetUsersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetUsersDocument, options);
}
exports.useGetUsersQuery = useGetUsersQuery;
function useGetUsersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetUsersDocument, options);
}
exports.useGetUsersLazyQuery = useGetUsersLazyQuery;
exports.GetCurrentUserDocument = (0, client_1.gql) `
    query GetCurrentUser {
  GetCurrentUser {
    data {
      ...AuthUserParts
      profile {
        ...ProfileParts
        stats {
          ...ProfileStatsParts
        }
      }
      posts {
        ...PostParts
      }
      followers {
        ...UserParts
      }
      following {
        ...UserParts
      }
      badges {
        ...BadgeParts
      }
      rewards {
        badge {
          ...BadgeParts
        }
        ...RewardParts
      }
      tags {
        ...TagParts
      }
      Posts {
        id
        data {
          ...PostParts
        }
        error
      }
      Chats {
        id
        data {
          ...ChatParts
          Messages {
            id
            data {
              ...MessageParts
              Status {
                id
                data {
                  ...MessageStatusParts
                }
                error
              }
            }
            error
          }
          Participants {
            id
            data {
              ...UserParts
            }
            error
          }
        }
        error
      }
    }
    error
  }
}
    ${exports.AuthUserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.ProfileStatsPartsFragmentDoc}
${exports.PostPartsFragmentDoc}
${exports.UserPartsFragmentDoc}
${exports.BadgePartsFragmentDoc}
${exports.RewardPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.ChatPartsFragmentDoc}
${exports.MessagePartsFragmentDoc}
${exports.MessageStatusPartsFragmentDoc}`;
/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
function useGetCurrentUserQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetCurrentUserDocument, options);
}
exports.useGetCurrentUserQuery = useGetCurrentUserQuery;
function useGetCurrentUserLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetCurrentUserDocument, options);
}
exports.useGetCurrentUserLazyQuery = useGetCurrentUserLazyQuery;
exports.GetUserDocument = (0, client_1.gql) `
    query GetUser($input: GetUserInput) {
  GetUser(input: $input) {
    data {
      ...UserParts
      profile {
        ...ProfileParts
        stats {
          ...ProfileStatsParts
        }
      }
      posts {
        ...PostParts
        sessions {
          ...SessionParts
        }
      }
      followers {
        ...UserParts
      }
      following {
        ...UserParts
      }
      badges {
        ...BadgeParts
      }
      rewards {
        badge {
          ...BadgeParts
        }
        ...RewardParts
      }
      tags {
        ...TagParts
      }
      Posts {
        data {
          ...PostParts
        }
        error
      }
      Projects {
        data {
          ...ProjectParts
          Users {
            data {
              ...UserParts
            }
            error
          }
          Uploads {
            data {
              ...UploadParts
            }
            error
          }
        }
        error
      }
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.ProfileStatsPartsFragmentDoc}
${exports.PostPartsFragmentDoc}
${exports.SessionPartsFragmentDoc}
${exports.BadgePartsFragmentDoc}
${exports.RewardPartsFragmentDoc}
${exports.TagPartsFragmentDoc}
${exports.ProjectPartsFragmentDoc}
${exports.UploadPartsFragmentDoc}`;
/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useGetUserQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetUserDocument, options);
}
exports.useGetUserQuery = useGetUserQuery;
function useGetUserLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetUserDocument, options);
}
exports.useGetUserLazyQuery = useGetUserLazyQuery;
exports.GetRandomUserDocument = (0, client_1.gql) `
    query GetRandomUser {
  GetRandomUser {
    data {
      ...UserParts
      profile {
        ...ProfileParts
        stats {
          ...ProfileStatsParts
        }
      }
      tags {
        ...TagParts
      }
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}
${exports.ProfilePartsFragmentDoc}
${exports.ProfileStatsPartsFragmentDoc}
${exports.TagPartsFragmentDoc}`;
/**
 * __useGetRandomUserQuery__
 *
 * To run a query within a React component, call `useGetRandomUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomUserQuery({
 *   variables: {
 *   },
 * });
 */
function useGetRandomUserQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetRandomUserDocument, options);
}
exports.useGetRandomUserQuery = useGetRandomUserQuery;
function useGetRandomUserLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetRandomUserDocument, options);
}
exports.useGetRandomUserLazyQuery = useGetRandomUserLazyQuery;
exports.GetHeartbeatDocument = (0, client_1.gql) `
    query GetHeartbeat {
  GetHeartbeat {
    data {
      ...UserParts
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}`;
/**
 * __useGetHeartbeatQuery__
 *
 * To run a query within a React component, call `useGetHeartbeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeartbeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeartbeatQuery({
 *   variables: {
 *   },
 * });
 */
function useGetHeartbeatQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetHeartbeatDocument, options);
}
exports.useGetHeartbeatQuery = useGetHeartbeatQuery;
function useGetHeartbeatLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetHeartbeatDocument, options);
}
exports.useGetHeartbeatLazyQuery = useGetHeartbeatLazyQuery;
exports.UpdateUserStatusDocument = (0, client_1.gql) `
    mutation UpdateUserStatus($input: UpdateUserStatusInput!) {
  UpdateUserStatus(input: $input) {
    data {
      ...UserParts
    }
    error
  }
}
    ${exports.UserPartsFragmentDoc}`;
/**
 * __useUpdateUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStatusMutation, { data, loading, error }] = useUpdateUserStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateUserStatusMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UpdateUserStatusDocument, options);
}
exports.useUpdateUserStatusMutation = useUpdateUserStatusMutation;
exports.OnUserHeartbeatDocument = (0, client_1.gql) `
    subscription OnUserHeartbeat {
  OnUserHeartbeat {
    ...UserParts
  }
}
    ${exports.UserPartsFragmentDoc}`;
/**
 * __useOnUserHeartbeatSubscription__
 *
 * To run a query within a React component, call `useOnUserHeartbeatSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUserHeartbeatSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserHeartbeatSubscription({
 *   variables: {
 *   },
 * });
 */
function useOnUserHeartbeatSubscription(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription(exports.OnUserHeartbeatDocument, options);
}
exports.useOnUserHeartbeatSubscription = useOnUserHeartbeatSubscription;
