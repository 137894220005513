import * as React from "react";
import { useContext } from "react";
// import Link from "next/link"
import { useLockBody } from "@/hooks/use-lock-body";

// import { MainNavItem } from "types"
import { cn, navigateAccordingly } from "@/utils";
import { Icons } from "@/assets/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { authStore } from "@/store";
import { AuthContext } from "@/store/context/AuthContext";
import { Avatar, AvatarFallback, AvatarImage, buttonVariants, DropdownMenuItem } from "@/components/ui";
import { Github, LifeBuoy, LogOut, Settings, User } from "lucide-react";
import { AuthActions } from "@/store/actions/auth.actions";
import { AppRoute } from "@/config/routes";

interface MobileNavProps {
  items: any; //MainNavItem[]
  children?: React.ReactNode;
}

export function MobileNav({ items, children }: MobileNavProps) {
  useLockBody();

  const { isRefreshTokenExpired } = authStore();
  const { state: auth, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    dispatch({ type: AuthActions.Logout });
  };

  const userLoggedIn = auth?.isAuthenticated && !isRefreshTokenExpired();

  return (
    <div
      className={cn(
        "fixed inset-0 top-10 z-50 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto",
        "py-6 pb-32 shadow-md animate-in slide-in-from-bottom-80 md:hidden"
      )}
    >
      <div
        className="border border-scale-100 dark:border-scale-700 relative z-20 grid gap-6 rounded-b-lg bg-scale-100 p-4 text-scale-950 shadow-md dark:bg-scale-950 dark:text-scale-200">
        <button onClick={() => navigateAccordingly({ userLoggedIn, navigate, location })}
                className="items-center self-start space-x-2 cursor-pointer">
          <Icons.Logo className={"h-8 mt-2 text-scale-950 dark:text-scale-25"} />
        </button>
        <nav className="grid grid-flow-row auto-rows-max text-sm">
          <div className={'p-2 font-bold'}>
            Menu
          </div>
          {items.map((item, index) => (
            <Link
              key={index}
              to={item.disabled ? "#" : item.href}
              className={cn(
                "flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline",
                item.disabled && "cursor-not-allowed opacity-60"
              )}
            >
              {item.title}
            </Link>
          ))}
          {
            userLoggedIn ? (
              <div className={'p-2'}>
                <hr className={'my-4 border-scale-100 dark:border-scale-700'} />
              <div className={'space-y-4'}>
                <div className="inline-flex items-center">
                  <Avatar className={"h-8 w-8 cursor-pointer"}>
                    <AvatarImage src={auth?.user?.avatarUrl}
                                 alt={`${auth?.user?.name ?? "Anonymous User"}'s Avatar`} />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>
                  <div className={'ml-4 font-bold'}>
                    { auth?.user?.email }
                  </div>
                </div>

                <div>
                  <Link to={`${AppRoute.User}/${auth?.user?.username}`}>
                    <div className={"inline-flex cursor-pointer hover:bg-scale-100 dark:hover:bg-scale-800"}>
                      <User className="mr-2 h-4 w-4" />
                      <span>My Profile</span>
                      {/*<DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>*/}
                    </div>
                  </Link>
                </div>

                <div>
                  <Link to={AppRoute.Settings}>
                    <div className={"inline-flex cursor-pointer hover:bg-scale-100 dark:hover:bg-scale-800"}>
                      <Settings className="mr-2 h-4 w-4" />
                      <span>Settings</span>
                      {/*<DropdownMenuShortcut>⌘S</DropdownMenuShortcut>*/}
                    </div>
                  </Link>
                </div>

                <div>
                  <a href={`https://github.com/${auth?.user?.profile?.githubUsername}`}
                     target={"_blank"} rel={"noreferrer"}>
                    <div className={"inline-flex cursor-pointer hover:bg-scale-100 dark:hover:bg-scale-800"}>
                      <Github className="mr-2 h-4 w-4" />
                      <span>My GitHub</span>
                    </div>
                  </a>
                </div>

                <div>
                  <Link to={"/dashboard/support"}>
                    <div className={"inline-flex cursor-pointer hover:bg-scale-100 dark:hover:bg-scale-800"}>
                      <LifeBuoy className="mr-2 h-4 w-4" />
                      <span>Support</span>
                    </div>
                  </Link>
                </div>

                <div>
                  <div className={"inline-flex cursor-pointer hover:bg-scale-100 dark:hover:bg-scale-800"} onClick={handleLogout}>
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Log out</span>
                    {/*<DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>*/}
                  </div>
                </div>

              </div>
              </div>
            ) : (
              <div className={'flex flex-col space-y-4'}>
                <hr className={'border-scale-100 dark:border-scale-700'} />
                <Link
                  to={AppRoute.Login}
                  className={cn(
                    buttonVariants({ size: "sm", variant: "default" }),
                    "shrink-0",
                    "px-4"
                  )}
                >
                  Log in
                </Link>
                <Link
                  to={AppRoute.Register}
                  className={cn(buttonVariants({ size: "sm", variant: "primary" }))}
                >
                  Register
                </Link>
              </div>
            )
          }
        </nav>
        {children}
      </div>
    </div>
  );
}
