import { Avatar, AvatarImageWithStatus, Button, HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui";
import { cn } from "@/utils";
import { CalendarDays } from "lucide-react";
import { getRelativeTime } from "@/utils/datetime.utils";
import React, { useContext } from "react";
import { User } from "@pairprogram/graphql";
import StartChatModal from "@/components/start-chat-modal";
import { useNavigate } from "react-router-dom";
import { useStartChat } from "@/hooks/use-start-chat";
import { AuthContext } from "@/store/context/AuthContext";
import { AppRoute } from "@/config/routes";

interface AvatarHoverCardProps {
  user: User;
  size?: number;
  statusSize?: number;
}

export default function AvatarHoverCard({ user, size = 4, statusSize = 1 }: AvatarHoverCardProps) {
  const [open, setOpen] = React.useState(false);
  const { state: auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { startChat } = useStartChat();

  const handleStartChat = async () => {
    const newChatId = await startChat({ recipientIds: [user?.id] });

    setTimeout(() => {
      if (newChatId) {
        navigate(`${AppRoute.Chat}/${newChatId}`);
      }
    }, 1);
  };

  return (
    <div className={"cursor-pointer inline-flex items-center z-10"}>
      <HoverCard key={`hover-card-${user?.id}`}>
        <HoverCardTrigger onClick={() => navigate(`${AppRoute.User}/${user?.username}`)} className={"cursor-pointer"} asChild>
          <Avatar className={`h-${size} w-${size}`}>
            <AvatarImageWithStatus
              className={`h-${size} w-${size} rounded-full ring-1 ring-scale-100 dark:ring-scale-700`}
              statusSize={statusSize}
              src={user?.avatarUrl ?? "/images/avatar.png"}
              alt={`@${user?.username}'s avatar`}
              isOnline={user?.isOnline}
            />
          </Avatar>
        </HoverCardTrigger>
        <HoverCardContent
          side={"right"}
          className={
          cn(
            "w-80 bg-scale-25 dark:bg-scale-950",
            "border border-scale-100 dark:border-scale-800"
          )
        }>
          <div className="flex space-x-4">
            <Avatar>
              <AvatarImageWithStatus
                className="rounded-full ring-1 ring-scale-100 dark:ring-scale-700"
                isOnline={user?.isOnline}
                src={user?.avatarUrl ?? "/images/avatar.png"}
              />
            </Avatar>
            <div className="space-y-1">
              <h3
                className="text-sm font-semibold leading-6 text-scale-950 dark:text-scale-25">{user?.username}</h3>
              <p className="text-sm text-scale-800 dark:text-scale-200">
                {user?.profile?.bio}
              </p>
              <div className="flex items-center pt-2">
                <CalendarDays className="mr-2 h-4 w-4 text-scale-800/70 dark:text-scale-200/70" />{" "}
                <span className="text-xs text-scale-800/70 dark:text-scale-200/70">
                            Joined {getRelativeTime(user?.createdAt)}
                          </span>
              </div>
            </div>
          </div>
          {/* section with 'send message' button*/}
          <div className="flex pt-6 justify-between space-x-2 text-center">
            <Button
              onClick={() => navigate(`${AppRoute.User}/${user?.username}`)}
              type="button"
              variant={"outline"}
              size={"sm"}
              className={"w-full justify-center font-mono text-xs"}
              // className="justify-center w-full inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-scale-25"
            >
              View Profile
            </Button>
            <Button
              onClick={() => handleStartChat()}
              type="button"
              size={"sm"}
              className={"w-full justify-center font-mono text-xs"}
            >
              Send Message
            </Button>

          </div>
          <StartChatModal user={user} open={open} setOpen={setOpen} />
        </HoverCardContent>

      </HoverCard>
    </div>
  );

}
