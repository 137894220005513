import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import React, { useState } from "react";
import FilterSortBar, { ISortFilterItem } from "@/components/filter-sort-bar";
import { SquareUserRound } from "lucide-react";
import { Project, ProjectFilterEnum, ProjectSortEnum } from "@pairprogram/graphql";
import { Icons } from "@/assets/icons";
import { Avatar, AvatarImageWithStatus, Button } from "@/components/ui";
import DefaultBanner from "@/assets/images/gradients/mesh-gradient-29.jpg";
import { Link, useNavigate } from "react-router-dom";
import { AppRoute } from "@/config/routes";
import useGetProjects from "@/hooks/use-get-projects";
import ProjectCard from "@/components/projects/project-card";

export default function ProjectsPage() {
  const navigate = useNavigate();
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [sort, setSort] = useState<ProjectSortEnum>(ProjectSortEnum.Latest);
  const [filter, setFilter] = useState<ProjectFilterEnum>(ProjectFilterEnum.All);

  const { projects, loading, fetchMore } = useGetProjects({ limit, offset, sort, filter });

  const sortAndFilterItems: ISortFilterItem[] = [
    {
      type: "FILTER",
      icon: Icons.Project,
      value: ProjectFilterEnum.All,
      label: "All",
      current: filter === ProjectFilterEnum.All,
      requiresAuth: true
    },
    {
      type: "FILTER",
      icon: SquareUserRound,
      value: ProjectFilterEnum.MyProjects,
      label: "My Projects",
      current: filter === ProjectFilterEnum.MyProjects,
      requiresAuth: true
    }
  ];

  console.log('projects', projects);

  return (
    <div>
      <DashboardPageHeadingSection title={"Projects"} description={'Browse and share projects'}>
        <div className="inline-flex items-center justify-end space-x-2">

          <Button
            onClick={() => navigate(`${AppRoute.Project}/new`)}
            variant={"default"}
            size={"sm"}
            className={"shrink-0 font-mono text-xs"}
          >
            <span className="sr-only">Add project</span>
            <Icons.Add className="h-4 w-4 mr-2" aria-hidden="true" />
            <span>Add Project</span>
          </Button>
        </div>
      </DashboardPageHeadingSection>
      <FilterSortBar items={sortAndFilterItems} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} />

      <div className={"py-2 "}>
        <ul className={"gap-y-8 gap-x-4 grid grid-cols-3"}>
          {projects?.map((project, key) => {
            return (
              <li className={"col-span-3 md:col-span-1"} key={key}>
                <Link to={`${AppRoute.Project}/${project?.id}`}>
                  <ProjectCard project={project!} />
                </Link>
              </li>
            )
          })}
          {
            offset + limit < 0 ? (
              <li key={"load-more-projects"} className="px-4 py-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-center">
                  <Button
                    className={"font-mono text-xs"}
                    size={"sm"} variant={"ghost"}
                    onClick={() => {
                      setOffset(offset + limit);
                      fetchMore({
                        variables: {
                          input: {
                            limit,
                            offset: offset + limit
                          }
                        }
                      });
                    }}
                  >
                    <Icons.LoadMore className={"h-3 w-3 mr-2"} />
                    Load more
                  </Button>
                </div>
              </li>
            ) : null
          }
        </ul>
      </div>
    </div>
  );
}
