import { Icons } from "@/assets/icons";
import { Avatar, AvatarImageWithStatus, Button } from "@/components/ui";
import TagBadge from "@/components/tag-badge";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoute } from "@/config/routes";
import toast from "react-hot-toast";
import useGetProject from "@/hooks/use-get-project";
import BannerImage from "@/assets/images/gradients/mesh-gradient-29.jpg";
import MainImagePlaceholder from "@/assets/images/gradients/mesh-gradient-29.jpg";
import { cn } from "@/utils";
import useProjectReaction from "@/hooks/use-project-reaction";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import MarkdownRenderer from "@/components/markdown-renderer";
import { handleNavigateToExternalUrl } from "@/utils/navigate.utils";

export default function ProjectPage() {
  const navigate = useNavigate();
  const { projectId } = useParams();

  if (!projectId) {
    toast.error("Project not found", { id: "project-not-found" });
    navigate(AppRoute.Project);
  }

  const { project, loading } = useGetProject(projectId!);
  const { toggleReaction } = useProjectReaction(project!);

  if (!project && !loading) {
    toast.error("Project not found", { id: "project-not-found" });
    navigate(AppRoute.Project);
  }

  return (
    <div className={"w-full"}>
      <div className={"pb-4"}>
        <div
          className={"outline-4 outline-indigo-100 relative h-32 lg:h-48 w-full"}>
          <div
            className={"inset-0 absolute w-full bg-no-repeat bg-scale-950 bg-cover bg-center"}
            style={{ backgroundImage: `url(${BannerImage})` }}
          >
            <div className="flex flex-col items-center justify-center w-full h-full">
              <Icons.Logo className={"h-10 sm:h-16 text-white"} />
            </div>
          </div>
        </div>
        <div className="space-y-12 px-12 max-w-6xl mx-auto">
          <div className={"border-b border-b-scale-200 pb-4"}>
            <div className="py-4 sm:pt-8 flex w-full items-center justify-between">
              <DashboardPageHeadingSection title={project?.name!}/>
            </div>
            <div className="flex justify-between">
              <div className={"flex items-center space-x-2"}>
                {
                  project?.Users?.data?.map((user, key) => (
                    <div className="flex items-center space-x-4">
                      <Avatar key={key} className={"h-12 w-12 outline outline-2 outline-white/80 shadow-lg"}>
                        <AvatarImageWithStatus
                          statusSize={"3"}
                          src={user?.avatarUrl!}>
                        </AvatarImageWithStatus>
                      </Avatar>
                      <div className={"font-medium text-lg text-scale-800"}>@{user?.username}</div>
                    </div>
                  ))
                }
              </div>


              <div className="flex items-center space-x-2 pr-5">
                <div className="flex items-center space-x-2">
                  {
                    Boolean(project?.githubRepoUrl) && (
                      <Button
                        className={"hidden sm:block"}
                        variant={"default"}
                        size={"sm"}
                        onClick={() => handleNavigateToExternalUrl(project?.githubRepoUrl!)}
                      >
                        <div className="flex items-center space-x-2">
                          <Icons.GitHub className={"h-4 w-4"} />
                          <span>View on GitHub</span>
                        </div>
                      </Button>
                    )
                  }

                  {
                    Boolean(project?.projectUrl) && (
                      <Button
                        className={"hidden sm:block"}
                        variant={"outline"}
                        size={"sm"}
                        onClick={() => handleNavigateToExternalUrl(project?.projectUrl!)}
                      >
                        <div className="flex items-center space-x-2">
                          <Icons.Goto className={"h-4 w-4"} />
                          <span>View project</span>
                        </div>
                      </Button>
                    )
                  }
                </div>

                {/*<span className="h-full w-[1px] border border-scale-300 px-4">--</span>*/}

                <Button
                  className={cn(
                    project?.likedByCurrentUser && "bg-brand-200/80 hover:bg-brand-200 text-brand-700"
                  )}
                  variant={project?.likedByCurrentUser ? "primary" : "outline"}
                  size={"sm"}
                  onClick={() => toggleReaction()}
                >
                  <div className="flex items-center space-x-2">
                    <Icons.Like className={"h-4 w-4"} />
                    <span>{project?.likedByCurrentUser ? "Liked" : "Like"}</span>
                  </div>
                </Button>


              </div>
            </div>
          </div>

          <main className="space-y-12 sm:space-y-32 divide-scale-200/80 divide-y">
            <div className="grid grid-cols-2 h-full space-y-8 sm:space-y-0 gap-x-12 flex-col-reverse">
              <div className="col-span-2 sm:col-span-1 space-y-6">

                <div className={"space-y-2"}>
                  <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Project Overview</h2>
                </div>

                <div className={"space-y-2"}>
                  <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Description</h3>
                  <MarkdownRenderer markdownText={project?.description!} />
                </div>

                {
                  Boolean(project?.features?.length) && (
                    <div className={"space-y-2"}>
                      <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Key Features</h3>
                      {
                        project?.features?.split(",").map((feature, key) => {
                          if (feature.trim().length) {
                            return (
                              <div key={key} className={"flex items-center space-x-2"}>
                                <Icons.Check className={"h-4 w-4 text-brand"} />
                                <p className={"text-scale-500 dark:text-scale-400"}>{feature}</p>
                              </div>
                            );
                          }
                          return null;
                        })
                      }
                    </div>
                  )
                }

              </div>
              <div className="relative col-span-2 sm:col-span-1 order-first sm:order-last">
                <img className={"sticky rounded-lg w-full aspect-auto mb-4"} src={project?.imageUrl || MainImagePlaceholder}
                     alt={`${project?.name} Screenshot`} />
                {
                  Boolean(!project?.imageUrl) && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Icons.BrandIcon className={"h-16 sm:h-32 text-white"} />
                    </div>
                  )
                }
              </div>
            </div>

            {
              Boolean(project?.Tags?.data?.length || project?.implementation || project?.tradeoffs) && (
                <div className="col-span-2 space-y-8 py-6">
                  <div>
                    <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Technical Details</h2>
                  </div>
                  <div className="grid grid-cols-2 h-full space-y-8 gap-x-12">
                    {
                      Boolean(project?.Tags?.data?.length) && (
                        <div className={"col-span-2 space-y-2"}>
                          <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Tech Stack</h3>
                          {/* @ts-ignore */}

                          <div className="flex gap-2 items-center flex-wrap">
                            {
                              // @ts-ignore
                              project?.Tags?.data?.map((tag: Tag, key: number) => (
                                <TagBadge key={key} tag={tag} />
                              ))
                            }
                          </div>
                        </div>
                      )
                    }
                    {
                      Boolean(project?.implementation) && (
                        <div className="col-span-2 sm:col-span-1">
                          <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Implementation
                            Details</h3>
                          <MarkdownRenderer markdownText={project?.implementation!} />
                        </div>
                      )
                    }
                    {
                      Boolean(project?.tradeoffs) && (
                        <div className="col-span-2 sm:col-span-1">
                          <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Tradeoffs</h3>
                          <MarkdownRenderer markdownText={project?.tradeoffs!} />
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }

            {
              Boolean(project?.learnings || project?.challenges || project?.nextSteps) && (
                <div className="col-span-2 space-y-8 py-6">
                  <div>
                    <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Takeaways</h2>
                  </div>
                  <div className="grid grid-cols-2 h-full space-y-8 sm:space-y-0 gap-x-12">
                    {
                      Boolean(project?.learnings) && (
                        <div className="col-span-2 sm:col-span-1">
                          <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Key Learnings</h3>
                          <MarkdownRenderer markdownText={project?.learnings!} />
                        </div>
                      )
                    }
                    {
                      Boolean(project?.challenges) && (
                        <div className="col-span-2 sm:col-span-1">
                          <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Biggest
                            Challenges</h3>
                          <MarkdownRenderer markdownText={project?.challenges!} />
                        </div>
                      )
                    }
                    {
                      Boolean(project?.nextSteps) && (
                        <div className="col-span-2 sm:col-span-1">
                          <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Next Steps</h3>
                          <MarkdownRenderer markdownText={project?.nextSteps!} />
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }

            {
              Boolean(project?.demoVideoUrl) && (
                <div className={"space-y-8 py-6"}>
                  <div className={"space-y-8"}>
                    <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Demo Video</h2>
                    <iframe width="100%" height="1024" src={project?.demoVideoUrl!}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                  </div>
                </div>
              )
            }

            {
              Boolean(project?.Uploads?.data && project?.Uploads?.data?.length > 0) && (
                <div className={"space-y-8 py-6"}>
                  <div>
                    <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Screenshots</h2>
                  </div>
                  <div className="grid grid-cols-2 h-full space-y-8 sm:space-y-0 gap-x-12">
                    {
                      project?.Uploads?.data?.map((upload, key) => (
                        <div key={key} className="col-span-2 sm:col-span-1">
                          <img className={"rounded-lg w-full aspect-auto mb-4"} src={upload?.signedUrl!}
                               alt={`Screenshot ${key}`} />
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
            }
          </main>
        </div>
      </div>
    </div>
  );
}
