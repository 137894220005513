// ... (same imports for Apollo hooks, GraphQL queries, etc.)

import { useGetUserQuery, User } from "@pairprogram/graphql";
import { useContext, useEffect } from "react";
import toast from "react-hot-toast";
import mixpanel from "@/analytics";
import { AnalyticsEvent } from "@/analytics/analytics-event";
import { AuthContext } from "@/store/context/AuthContext";

export const useGetUser = (username: string) => {
  const { state: auth } = useContext(AuthContext);
  const loggedInUser = auth?.user?.username;

  const { data, loading, error } = useGetUserQuery({
    onCompleted: (data) => {
      if (data?.GetUser?.error) {
        return toast("Error fetching user", {
          icon: "🚨"
        });
      }

      const user = data?.GetUser?.data as User;

      if (user?.id) {
        mixpanel.track(AnalyticsEvent.ViewedPublicProfile, {
          profileOwner: user?.username,
          profileViewer: loggedInUser
        });
      }
    },
    variables: {
      input: {
        username: username
      }
    },
    skip: !username
  });

  useEffect(() => {
    if (data?.GetUser?.error) {
      toast("Error getting user", { icon: "🚨" });
    }
  }, [data]);

  const getUser = (username: string) => {
    // Trigger the query with the new postId
    // You could add additional logic here if needed
    useGetUserQuery({
      variables: {
        input: {
          username: username
        }
      }
    });
  };

  const user = data?.GetUser?.data as User;
  return {
    getUser,
    user,
    loading,
    error
  };
};
