import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import breaks from 'remark-breaks';
import { cn } from "@/utils";

interface MarkdownRendererProps {
  markdownText: string;
  className?: string;
  [key: string]: any;
}
const MarkdownRenderer = ({ markdownText, className, ...props }: MarkdownRendererProps) => {
  return (
    <ReactMarkdown
      className={cn(
        // headings
        'prose-headings:text-scale-950 prose-headings:bold dark:prose-headings:text-scale-25',
        'prose dark:prose-invert prose-',

        // h1
        'prose-h1:text-xl',

        // h2
        'prose-h2:text-lg',

        // h3
        'prose-h3:text-base',

        // h4
        'prose-h4:text-base',

        // h5
        'prose-h5:text-xs',

        // h6
        'prose-h6:text-[11px]',

        // p
        'prose-p:text-base prose-p:text-scale-600 prose-p:bold dark:prose-p:text-scale-300',

        // ul
        'prose-ul:text-base prose-ul:text-scale-950 prose-ul:bold dark:prose-ul:text-scale-25',

        // ol
        'prose-ol:text-base prose-ol:text-scale-950 prose-ol:bold dark:prose-ol:text-scale-25',

        // li
        'prose-li:text-base prose-li:text-scale-600 prose-li:bold dark:prose-li:text-scale-25',

        // blockquote
        'prose-blockquote:text-base prose-blockquote:text-scale-950 prose-blockquote:bold dark:prose-blockquote:text-scale-25',

        // table
        'prose-table:text-base prose-table:text-scale-950 prose-table:bold dark:prose-table:text-scale-25',

        // thead
        'prose-thead:text-base prose-thead:text-scale-950 prose-thead:bold dark:prose-thead:text-scale-25',

        // tbody
        'prose-tbody:text-base prose-tbody:text-scale-950 prose-tbody:bold dark:prose-tbody:text-scale-25',

        // tr
        'prose-tr:text-base prose-tr:text-scale-950 prose-tr:bold dark:prose-tr:text-scale-25',

        // th
        'prose-th:text-base prose-th:text-scale-950 prose-th:bold dark:prose-th:text-scale-25',

        // td
        'prose-td:text-base prose-td:text-scale-950 prose-td:bold dark:prose-td:text-scale-25',

        // a
        'prose-a:text-base prose-a:text-scale-950 prose-a:bold dark:prose-a:text-scale-25',

        // pre
        'prose-pre:text-base prose-pre:text-scale-950 prose-pre:bold dark:prose-pre:text-scale-25',

        // code
        'prose-code:text-base prose-code:text-green-500 prose-code:bold dark:prose-code:text-green-500',

        // hr
        'prose-hr:text-base prose-hr:text-scale-950 prose-hr:bold dark:prose-hr:text-scale-25',

        // img
        'prose-img:text-base prose-img:text-scale-950 prose-img:bold dark:prose-img:text-scale-25',

        // em
        'prose-em:text-base prose-em:text-scale-600 prose-em:bold dark:prose-em:text-scale-300',

        // strong
        'prose-strong:text-base prose-strong:text-scale-950 prose-strong:bold dark:prose-strong:text-scale-25',

        // del
        'prose-del:text-base prose-del:text-scale-950 prose-del:bold dark:prose-del:text-scale-25',

        // inlineCode
        'prose-inlineCode:text-base prose-inlineCode:text-scale-950 prose-inlineCode:bold dark:prose-inlineCode:text-scale-25',

        // br
        'prose-br:text-base prose-br:text-scale-950 prose-br:bold dark:prose-br:text-scale-25',

        // add custom classes
        className,
      )}
      remarkPlugins={[remarkGfm, breaks]}
    >
      {markdownText}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;
