export default function PairProgramIcon(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
         style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
            fill: 'currentColor',
         }}
         {...props}
    >
    <g id="Icon" transform="matrix(2.40939,0,0,2.40939,-95.6154,15.0621)">
        <g transform="matrix(0.144304,0,0,0.144304,-27.229,-73.1653)">
            <path
              style={{ fill: 'currentColor'}}
              d="M1573.95,944.614C1567.23,962.683 1568.17,962.892 1546.32,962.892L1215.41,962.916C1081.34,962.922 1034.36,982.318 963.739,1105.78C939.928,1147.41 916.001,1189.18 892.567,1230.68L820.882,1357.17C717.422,1357.66 788.899,1265.2 812.056,1226.51C841.153,1177.9 870.522,1129.19 899.044,1079.28C997.158,907.575 1023.38,886.988 1238.32,886.883L1505.59,886.901C1533.17,886.827 1563.42,889.154 1574.95,908.884C1581.59,920.244 1578.12,933.415 1573.95,944.614ZM1286.68,1039.31C1362.78,1039.33 1448.08,1035.68 1523.05,1039.71C1507.7,1077.51 1459.6,1146.85 1424.22,1174.3C1366.21,1219.31 1305.63,1208.22 1220.5,1208.19C1123.55,1208.15 1112.88,1233.77 1074.15,1295.15C1028.8,1367.02 1037.07,1365.17 905.854,1361.24C907.012,1351.55 979.182,1234.21 990.861,1214.36C1094.98,1037.39 1082.47,1039.27 1286.68,1039.31ZM1482.77,1227.35C1497.22,1211.35 1507.98,1203.06 1522.79,1181.21C1550.72,1140 1596.87,1059.6 1622.47,1016.17C1644.61,978.602 1668.19,934.874 1647.23,882.388C1631.34,842.593 1593.44,817.94 1540.25,811.399C1506.07,807.5 1286.51,810.226 1233.23,810.208C1124.79,810.169 1050.57,807.009 971.624,859.134C906.987,901.811 880.337,960.277 836.55,1034.61C798.054,1099.97 759.599,1163.78 720.919,1229.5C701.991,1261.65 679.385,1301.34 691.733,1349.12C717.9,1450.39 840.672,1435.31 915.044,1435.36C951.005,1435.38 1035.76,1440.24 1062.47,1428.58C1095.65,1414.1 1111.68,1375.61 1131.37,1342.13C1155.24,1301.56 1166.88,1284.5 1228.12,1284.55C1322.71,1284.64 1380.99,1296.59 1459.02,1244.71C1467.58,1239.02 1474.82,1231.99 1482.77,1227.35Z"/>
        </g>
      <g transform="matrix(0.144304,0,0,0.144304,-27.229,-73.1653)">
            <path
              style={{ fill: 'currentColor'}}
              d="M1658.55,1098.08C1645.09,1118.98 1632.24,1139.69 1618.91,1160.25C1596.11,1195.42 1562.29,1254.04 1536.11,1277.97C1524.47,1297.52 1492.6,1323.95 1468.39,1338.07C1371.93,1394.37 1242.79,1335.36 1189.98,1382.98C1175.9,1395.68 1172.46,1404.77 1162.38,1421.56C1106.42,1514.74 1128.71,1512.51 996.499,1512.44C924.156,1512.4 811.093,1524.14 765.533,1485.52C763.774,1525.78 798.101,1559.51 824.465,1572.73C864.883,1593 908.787,1589.02 958.317,1589.09C999.103,1589.14 1070.71,1593.27 1107.97,1588.12C1197.77,1575.71 1187.48,1448.13 1259.11,1442.14C1306.29,1438.2 1360.3,1446.02 1407.64,1440.47C1476.63,1432.38 1536.73,1399.24 1577.54,1347.97C1612.9,1303.57 1707.77,1157.83 1711.33,1113.17C1713.97,1080.15 1705.15,1043.65 1686.32,1027.41C1679.48,1041.23 1683.32,1059.6 1658.55,1098.08Z"/>
        </g>
    </g>
</svg>
  )
}
