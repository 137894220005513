import { isTokenExpired } from "@/utils/jwt.utils";
import { LocalStorage } from "@pairprogram/common";
import { Role } from "@pairprogram/graphql";

export enum EventType {
  AccessTokenUpdated = 'AccessTokenUpdated',
  RefreshTokenUpdated = 'RefreshTokenUpdated',
}

function dispatchEvent(e: EventType) {
  window.dispatchEvent(new Event(e));
}

export function subscribeToEvent(e: EventType, callback: () => void) {
  window.addEventListener(e, callback);
}

export function unsubscribeFromEvent(e: EventType, callback: () => void) {
  window.removeEventListener(e, callback);
}

export function authStore() {
  return {
    getAccessToken: () => localStorage.getItem(LocalStorage.AccessToken),
    setAccessToken: (accessToken: string): void => {
      if (accessToken) {
        localStorage.setItem(LocalStorage.AccessToken, accessToken);
        dispatchEvent(EventType.AccessTokenUpdated);
      }
    },
    removeAccessToken: (): void => {
      localStorage.removeItem(LocalStorage.AccessToken);
      dispatchEvent(EventType.RefreshTokenUpdated);
    },
    isAccessTokenExpired: (): boolean => {
      return isTokenExpired(localStorage?.getItem(LocalStorage.AccessToken));
    },
    getRefreshToken: () => localStorage.getItem(LocalStorage.RefreshToken),
    setRefreshToken: (refreshToken: string): void => {
      if (refreshToken) {
        localStorage.setItem(LocalStorage.RefreshToken, refreshToken);
        dispatchEvent(EventType.RefreshTokenUpdated);
      }
    },
    removeRefreshToken: (): void => {
      localStorage.removeItem(LocalStorage.RefreshToken);
      dispatchEvent(EventType.RefreshTokenUpdated);
    },
    isRefreshTokenExpired: (): boolean => {
      return isTokenExpired(localStorage.getItem(LocalStorage.RefreshToken));
    },
    isAdmin: (): boolean => {
      return !!localStorage.getItem(LocalStorage.Roles)?.includes(Role.Admin)
    },
    isAuthenticated: (): boolean => {
      return !!authStore().getAccessToken() && !!authStore().getRefreshToken();
    },
    getRoles: () => {
      const roles = localStorage.getItem(LocalStorage.Roles)
      return roles ? JSON.parse(roles) : [];
    },
    setRoles: (roles: Role[]) => {
      localStorage.setItem(LocalStorage.Roles, JSON.stringify(roles));
    },
    removeRoles: () => localStorage.removeItem(LocalStorage.Roles),
    removeIsAdmin: () => localStorage.removeItem(LocalStorage.IsAdmin),
    removeAuthState: () => {
      authStore().removeAccessToken();
      authStore().removeRefreshToken();
      authStore().removeIsAdmin();
      authStore().removeRoles();
      dispatchEvent(EventType.RefreshTokenUpdated);
      dispatchEvent(EventType.AccessTokenUpdated);
    }
  };
}
