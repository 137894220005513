export enum AnalyticsEvent {
  OnboardingSkipped = 'Onboarding Skipped',
  OnboardingCompleted = 'Onboarding Completed',
  ViewedProfile = 'Viewed Profile',
  ViewedPublicProfile = 'Viewed Public Profile',
  StartedChat = 'Started Chat',
  SentChatMessage = 'Sent Chat Message',
  ReadChatMessages = 'Read Chat Message(s)',
  PostCreated = 'Post Created',
  TagsUpdated = 'Tags Updated',
  TagSubscriptionsUpdated = 'Tag Subscriptions Updated',
  ProfileUpdated = 'Profile Updated',
}
