import { Button, buttonVariants } from "@/components/ui";
import { cn } from "@/utils";
import XTwitterIcon from "@/assets/icons/x-twitter";
import { Icons } from "@/assets/icons";
import { AppRoute } from "@/config/routes";
import { useNavigate } from "react-router-dom";
import { AuthActions } from "@/store/actions/auth.actions";
import { useContext, useEffect } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import { authStore } from "@/store";

export default function OnboardingThankYou() {
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const { removeAuthState } = authStore();

  // ensure user is logged out
  useEffect(() => {
    removeAuthState();
  }, []);

  return (
    <div className={"flex flex-col items-center justify-center h-full space-y-8"}>
      <h1 className={"text-6xl font-bold text-center text-foreground"}>
        <span className={'highlight highlight-amber-300 text-scale-600'}>Thank you</span> for applying!
      </h1>
      <p className={"text-center text-2xl text-foreground"}>
        We'll be in touch soon.
      </p>
      {/* buttons for twitter and reddit*/}
      <div className={"flex flex-row items-center justify-between space-x-4"}>
        <a href={"https://x.com/pairprogram"} target={"_blank"}>
          <div
            className={cn(
              buttonVariants({ variant: "default", size: "lg" }),
              "font-mono text-sm"
            )}
          >
            <div className="flex items-center justify-center">
              <p>Follow us on</p>
              <XTwitterIcon className={"ml-2 text-white h-3 w-3"} />
            </div>
          </div>
        </a>
        {/* use regular link to purposely refresh app to remove any stale auth state */}
        <a href={"/"}
          onClick={() => navigate(AppRoute.Home)}
          className={cn(
            buttonVariants({ variant: "default", size: "lg" }),
            "font-mono text-sm",
          )}>
          <div className="flex items-center justify-center">
            <Icons.BrandIcon className={"mr-2 text-white h-6 w-6"} />
            <p>View feed</p>
          </div>
        </a>
      </div>
    </div>
  );
}
