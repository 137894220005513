import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils";
import { TimezoneMap, Timezones } from "@/utils/datetime.utils";
import { Input, Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger } from "@/components/ui";

interface TimezoneDropdownProps {
  value: string | undefined;
  onChange: (value: string) => void;
}
export function TimezoneDropdown({ value, onChange }: TimezoneDropdownProps) {

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Assume Timezones is your array of timezone objects
  const filteredTimezones = Timezones.filter((timezone) => {
    const labelMatch = timezone.label.toLowerCase().includes(searchTerm.toLowerCase());
    const nameMatch = timezone.name.toLowerCase().includes(searchTerm.toLowerCase());
    return (labelMatch || nameMatch);
  });

  const handleValueChange = (selectedLabel) => {
    const selectedTimezone = Timezones.find((timezone) => timezone.label === selectedLabel);
    if (selectedTimezone) {
      onChange(selectedTimezone.value);
    }
  };

  const inputRef = useRef(null);
  useEffect(() => {
    if (open) {
      // @ts-ignore
      inputRef?.current?.focus();
    }
  }, [open]);

  return (
    <Select open={open} onOpenChange={setOpen} onValueChange={handleValueChange}>
      <SelectTrigger
        className={cn(
          "shadow-sm border-scale-200 dark:border-scale-800 text-scale-800 dark:text-scale-100 bg-scale-100 dark:bg-scale-950",
        )}
      >
        {value
          ? Timezones.find((timezone) => timezone.value === value)?.label
          : "Select timezone..."}
      </SelectTrigger>
      <SelectContent
        className={cn(
          "max-h-64 overflow-y-auto border-scale-100 bg-scale-100 text-scale-800 dark:bg-scale-950 dark:text-scale-100"
        )}
      >
        <Input
          ref={inputRef}
          autoFocus={open}
          type="text"
          placeholder="Search timezones..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          // className={cn("py-2 border-scale-100 bg-scale-100 text-scale-800 dark:bg-scale-950 dark:text-scale-100")}
        />
        <SelectGroup>
          <SelectLabel>Timezones</SelectLabel>
          {filteredTimezones.map((timezone) => {
            return (
              <SelectItem className={cn('cursor-pointer')} value={timezone.label} key={timezone.value}>
                {timezone.label}
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}


// export function TimezoneDropdown({ value, onChange }) {
//   const [open, setOpen] = React.useState(false);
//
//   return (
//     <Select open={open} onOpenChange={setOpen} onValueChange={onChange}>
//       <SelectTrigger
//         className={cn(
//           'border-scale-100 bg-scale-100 text-scale-800 dark:bg-scale-950 dark:text-scale-100'
//         )}
//       >
//         {value
//           ? timezones.find((timezone) => timezone.value === value)?.label
//           : "Select timezone..."}
//       </SelectTrigger>
//       <SelectContent
//         className={cn(
//           'max-h-64 overflow-y-auto border-scale-100 bg-scale-100 text-scale-800 dark:bg-scale-950 dark:text-scale-100'
//         )}>
//         <SelectGroup
//
//         >
//           <SelectLabel>Timezones</SelectLabel>
//           {timezones.map((timezone) => {
//             return (
//               <SelectItem
//                 value={timezone.value}
//                 key={timezone.value}
//               >
//                 {timezone.label}
//               </SelectItem>
//             );
//           })}
//         </SelectGroup>
//       </SelectContent>
//     </Select>
//   );
// }
