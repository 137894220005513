import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Textarea
} from "@/components/ui";
import { cn } from "@/utils";
import React, { useState } from "react";
import { Icons } from "@/assets/icons";
import { CreatePostError, PostTypeEnum, Task, useCreatePostMutation } from "@pairprogram/graphql";
import toast from "react-hot-toast";
import useRequiresAuth from "@/hooks/use-requires-auth";
import { useGetPostTypes } from "@/hooks/use-get-post-types";
import { handleCreatePostError } from "@/components/feed/create-post-card";

const minDescriptionLength = 16;
const maxDescriptionLength = 2048;

export default function TaskShareUpdateModal({ task, open, setOpen }: {
  task: Task,
  open: boolean,
  // set open dispatch function
  setOpen: any,
}) {
  const { postTypes } = useGetPostTypes();
  const progressUpdatePostType = postTypes?.find(postType => postType?.value === PostTypeEnum.Progress);
  const [takeaways, setTakeaways] = useState<string>("");

  const [createProgressUpdatePost] = useCreatePostMutation({
    onCompleted: (data) => {
      if (data?.CreatePost?.error) {
        handleCreatePostError(data?.CreatePost?.error);
        return;
      }
      toast.success("Progress update shared");
    },
    variables: {
      input: {
        title: `Takeaways from ${task?.title}`,
        description: takeaways,
        tags: [],
        postTypeId: progressUpdatePostType?.id
      }
    }
  });

  const handleSubmitProgressUpdateAndFeedback = async () => {
    if (takeaways) {
      await createProgressUpdatePost();
    }
    setTakeaways("");
  };

  const { withAuth } = useRequiresAuth();

  return (
    <div>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent className={cn(
          "bg-scale-25 dark:bg-scale-950",
          "border border-scale-200 dark:border-scale-700"
        )}>
          <AlertDialogHeader className={'space-y-6'}>
            <div
              className={cn(
                "inline-flex space-x-2 items-center justify-center bg-green-100 border border-green-300 rounded-lg py-2",
                // "outline outline-2 outline-green-200"
              )}>
              <div className={cn(
                "rounded-full bg-green-100 h-8 w-8 flex items-center justify-center"
              )}>
                <Icons.TaskComplete className={"h-6 w-6 shrink-0 text-green-500"} />
              </div>
              <h3 className={"font-bold text-base text-scale-700"}>Task complete!</h3>
            </div>

            <AlertDialogTitle className={"text-scale-950 dark:text-scale-25 mb-6 mt-4"}>
              <div className="flex flex-col w-full space-y-2">
                <h3 className={"text-xl font-bold"}><span
                  className={"font-mono highlight highlight-amber-300"}>Share</span> your takeaways with the community
                </h3>
                <p className={"text-base text-scale-500 font-medium"}>Inspire others and track your growth
                  by
                  sharing your progress and learnings from this task with the community.</p>

              </div>
            </AlertDialogTitle>
            <AlertDialogDescription className={"text-scale-700 dark:text-scale-200 space-y-8"}>
              <div className="flex flex-col">
                <div>
                  <Textarea
                    id="takeaways"
                    rows={7}
                    value={takeaways}
                    placeholder={"Example: Overall this task was a great learning experience. I learned a lot about how to use React Query and how to use it to fetch data from a GraphQL API. I also learned how to use the useMutation hook to update data on the server. I had some trouble with the useMutation hook because I was not passing the correct variables to the mutation. I was able to figure it out by reading the documentation and looking at the examples."}
                    onChange={(e) => setTakeaways(e.target.value)}
                  />
                  {/*<p className={"text-scale-500 text-xs py-2"}>Sharing an update will also contribute to your streaks*/}
                  {/*  and can earn you badges & rewards!</p>*/}
                </div>
                <p className={"text-scale-500 text-xs pt-2"}>Must be between {minDescriptionLength} and {maxDescriptionLength} characters.</p>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              className={"font-mono text-xs"}
              onClick={() => setOpen(false)}>
              <div className="flex items-center space-x-2">
                <p>Skip</p>
              </div>
            </AlertDialogCancel>
            <AlertDialogAction
              disabled={takeaways.length < minDescriptionLength || takeaways.length > maxDescriptionLength}
              className={"font-mono text-xs disabled:opacity-20 disabled:cursor-not-allowed"}
              onClick={handleSubmitProgressUpdateAndFeedback}>
              <div className="flex items-center space-x-2">
                <Icons.Flame className={"h-4 w-4"} />
                <p>Share update</p>
              </div>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
