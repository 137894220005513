import * as React from "react"

import { cn } from "@/utils"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex min-h-fit h-10 w-full shadow-xs rounded-md px-3 py-1.5 text-sm",
          'bg-scale-50 dark:bg-scale-950',
          'border border-scale-300 dark:border-scale-700',
          'text-scale-950 dark:text-scale-25',
          // 'ring-offset-background bg-transparent',
          'ring-0 ring-inset ring-scale-25/10 focus:ring-1 focus:ring-inset focus:ring-primary',
          'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1',
          'file:border-0 file:bg-transparent file:text-sm file:font-medium',
          'disabled:cursor-not-allowed disabled:opacity-50',
          'placeholder:text-scale-400 dark:placeholder:text-scale-600',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
