import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import FeedNavigation from "@/components/nav/feed-navigation";
import useRequiresAuth from "@/hooks/use-requires-auth";
import ApplyNowModal from "@/components/apply-now-modal";

export default function ProjectLayout() {
  const { showModal } = useRequiresAuth();
  return (
    <div className={"bg-transparent min-h-screen"}>
      <FeedNavigation />
      <div className="w-full flex justify-center">
        <div className="justify-center w-full">
          <div className="grid grid-cols-1 sm:grid-cols-12">
            <div className="col-span-12 pb-8">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {
        showModal && (
          <ApplyNowModal />
        )
      }
    </div>
  );
}
