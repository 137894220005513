import React, { useState } from "react";
import {
  AddPostReactionError,
  Post,
  PostPartsFragmentDoc,
  PostTypeEnum,
  ReactionType,
  RemovePostReactionError,
  useAddPostReactionMutation,
  useRemovePostReactionMutation
} from "@pairprogram/graphql";
import { useNavigate, useParams } from "react-router-dom";
import MarkdownRenderer from "@/components/markdown-renderer";
import { AlertDialog, Avatar, AvatarFallback, AvatarImageWithStatus, Button } from "@/components/ui";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import { Icons } from "@/assets/icons";
import { cn } from "@/utils";
import { useStartChat } from "@/hooks/use-start-chat";
import { useGetPost } from "@/hooks/use-get-post";
import ShareOptions from "@/components/ShareOptions";
import { AppRoute } from "@/config/routes";
import useRequiresAuth from "@/hooks/use-requires-auth";
import { BarChart, Cuboid, Flame, HandIcon, TargetIcon, ThumbsUp, Users } from "lucide-react";
import toast from "react-hot-toast";
import BackgroundImage from "@/assets/images/backgrounds/flux-gradient-blue.svg";

export interface VideoSessionState {
  username: string,
  videoEnabled: boolean,
  videoDeviceId: string,
  audioEnabled: boolean,
  audioDeviceId: string,
  videoSessionToken?: string,
}

function mapPostTypeIcon(postTypeLabel: string) {
  switch (postTypeLabel) {
    case PostTypeEnum.Progress:
      return BarChart;
    case PostTypeEnum.Feedback:
      return HandIcon;
    case PostTypeEnum.Collaboration:
      return Users;
    case PostTypeEnum.Milestone:
      return TargetIcon;
    case PostTypeEnum.Project:
      return Cuboid;
    case PostTypeEnum.Resource:
      return Flame;
    default:
      return BarChart;
  }
}

export default function PostPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { post, getPost } = useGetPost(params?.id as string);
  const PostTypeIcon = mapPostTypeIcon(post?.PostType?.data?.value!);

  async function handleKeyPress(e: React.KeyboardEvent<HTMLButtonElement | HTMLInputElement>) {
    const ENTER_KEY = "Enter";
    if (e.key === ENTER_KEY) {
      await handleStartChat();
    }
  }

  const [sendChatMessageModalOpen, setSendChatMessageModalOpen] = useState<boolean>(false);
  const { startChat } = useStartChat();
  const { withAuth } = useRequiresAuth();

  const [addReaction, { data }] = useAddPostReactionMutation({
    variables: {
      input: {
        postId: post?.id!,
        reactionType: ReactionType.Like
      }
    },
    onCompleted: (data) => {
      if (data?.AddPostReaction?.error) {
        if (data?.AddPostReaction?.error === AddPostReactionError.AlreadyExists) {
          toast.error("You have already liked this post", { icon: "😌", id: "reaction" });
          return;
        }
        if (data?.AddPostReaction?.error === AddPostReactionError.Forbidden) {
          toast.error("Cannot like your own post", { icon: "😬", id: "reaction" });
          return;
        }
      }
      if (data?.AddPostReaction?.data) {
        toast.success("Post liked", { id: "reaction" });
      }
    },
    update: (cache, { data }) => {
      if (data?.AddPostReaction?.data) {
        const existingPost: Post | null = cache.readFragment({
          id: `Post:${post?.id}`,
          fragment: PostPartsFragmentDoc
        });

        if (existingPost) {
          const updatedLikeCount: number = (post?.likeCount ?? 0) + 1;
          const updatedPost = {
            ...existingPost,
            likeCount: updatedLikeCount,
            likedByCurrentUser: true
          };
          cache.writeFragment({
            fragment: PostPartsFragmentDoc,
            data: updatedPost
          });
        }
      }
    }
  });

  const [removeReaction, { data: removeReactionData }] = useRemovePostReactionMutation({
    variables: {
      input: {
        postId: post?.id!,
        reactionType: ReactionType.Like
      }
    },
    onCompleted: (data) => {
      if (data?.RemovePostReaction?.error) {
        if (data?.RemovePostReaction?.error === RemovePostReactionError.Forbidden) {
          toast.error("Unable to remove reaction", { icon: "😬", id: "reaction" });
          return;
        }
        toast.error("Error unliking post", { id: "reaction"});
        return;
      }
      if (data?.RemovePostReaction?.data) {
        toast.success("Post unliked", { id: "reaction"});
      }
    },
    update: (cache, { data }) => {
      if (data?.RemovePostReaction?.data) {
        const existingPost: Post | null = cache.readFragment({
          id: `Post:${post?.id}`,
          fragment: PostPartsFragmentDoc
        });

        if (existingPost) {
          let updatedLikeCount: number = 0;
          if (post?.likeCount && post?.likeCount > 0) {
            updatedLikeCount = post?.likeCount! - 1;
          }

          const updatedPost = {
            ...existingPost,
            likeCount: updatedLikeCount,
            likedByCurrentUser: false
          };
          cache.writeFragment({
            fragment: PostPartsFragmentDoc,
            data: updatedPost
          });
        }
      }
    }
  });

  const toggleReaction = () => {
    if (post?.likedByCurrentUser) {
      removeReaction();
    } else {
      addReaction();
    }
  };


  const handleStartChat = async () => {
    const newChatId = await startChat({ recipientIds: [post?.Creator?.data?.id] });
    setTimeout(() => {
      navigate(`${AppRoute.Chat}/${newChatId}`);
    }, 1);
  };

  return (
    <div className={cn(
      "min-h-screen",
    )}>
      <AlertDialog
        open={sendChatMessageModalOpen}
        defaultOpen={false}
      >
        <main>
          <DashboardPageHeadingSection title={"Post"} />
          <div
            className={cn(
              "group hover:outline-brand-100 hover:outline-4 hover:outline",
              "border border-scale-300",
              "sm:items-center bg-scale-25 rounded-lg border border-scale-300"
            )}>
            <div className={""}>
              <div className="flex items-center justify-between p-4">
                <div className={"inline-flex items-center gap-x-3 flex-1"}>
                  <Avatar className={"h-8 w-8"}>
                    <AvatarImageWithStatus
                      isOnline={post?.Creator?.data?.isOnline}
                      src={post?.Creator?.data?.avatarUrl as string}
                      alt={`${post?.Creator?.data?.username as string ?? "Anonymous User"}'s Avatar`}
                    />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>

                  <div className="flex justify-between items-center gap-x-3 w-full">
                    <h4 title={post?.title || undefined}
                        className="whitespace-nowrap text-scale-600 dark:text-scale-100 truncate font-bold">{post?.Creator?.data?.username}</h4>
                    <ShareOptions itemType={"post"} item={post as Post} />
                  </div>
                </div>
              </div>

              <div className={"flex items-center justify-between px-4"}>
                <h1 title={post?.title!}
                    className={"text-2xl text-scale-900 dark:text-scale-100 font-extrabold truncate text-wrap break-words"}>{post?.title}</h1>
              </div>
              <div className={"p-4 text-wrap break-words"}>
                <MarkdownRenderer markdownText={post?.description!} />
              </div>
              {
                post?.Uploads?.data?.length && (
                  <div className={"p-4"}>
                    <div className="grid">
                      {
                        post?.Uploads?.data?.map((upload, index) => {
                          return (
                            <div key={index} className={"relative"}>
                              <img
                                className={"rounded-lg w-full"}
                                src={upload?.signedUrl ?? "/images/placeholder.png"}
                                alt={upload?.filename ?? `Uploaded image for post ${post?.title}`}
                              />
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                )
              }
              <div
                className="flex items-center justify-between space-x-3 border-t border-scale-200 py-2 px-2">

                {/*<div className="mt-6 flex justify-between space-x-8">*/}
                <div className="flex space-x-6 w-full">
                  <div className="flex-grow">
                    {
                      post?.PostType?.data?.label ? (
                        <div className={"flex items-center text-xs text-scale-500"}>
                          <div className="flex border border-scale-200 bg-scale-50 rounded-full px-2 py-1 flex-grow-0">
                            <PostTypeIcon className={"h-4 w-4 mr-1"} />
                            <span className={"text-scale-900"}>{post?.PostType?.data?.label}</span>
                          </div>
                        </div>
                      ) : null
                    }
                  </div>

                  <span className={cn(
                    "inline-flex items-center justify-between text-sm border border-scale-200 px-2 rounded-full",
                    post?.likedByCurrentUser ? "bg-blue-50 text-blue-300" : "bg-scale-25"
                  )}>
                    <button
                      onClick={() => withAuth(toggleReaction)}
                      type="button" className="inline-flex text-gray-400 hover:text-gray-500">
                      <div className="flex items-center space-x-2">
                      <ThumbsUp className={cn(
                        "h-3.5 w-3.5",
                        post?.likedByCurrentUser ? "text-blue-300" : "text-scale-500"
                      )} aria-hidden="true" />
                      <div>
                        <span className="font-medium text-xs text-gray-900">{post?.likeCount}</span>
                        <span className="sr-only">{post?.likeCount === 1 ? "like" : "likes"}</span>
                      </div>
                      </div>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="py-4">
            <div
              style={{
                backgroundImage: `url(${BackgroundImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className="w-full mt-auto py-3 px-4 bg-brand-100 bg-gradient-to-br from-brand-400 to-brand-700 dark:bg-brand-800 rounded-lg outline outline-4 outline-brand-100">
              <div className="flex items-center space-x-4 justify-between">
                <div className="grid grid-cols-3 w-full space-y-2 sm:space-y-0">
                  <div className={"col-span-3 sm:col-span-2 inline-flex items-center"}>
                    <div className={"mr-4"}>
                      <Avatar className={"h-8 w-8"}>
                        <AvatarImageWithStatus
                          isOnline={post?.Creator?.data?.isOnline}
                          statusSize={"2"}
                          className="rounded-full ring-2 ring-scale-100 dark:ring-scale-700"
                          src={post?.Creator?.data?.avatarUrl ?? "/icon.png"}
                          alt={`${post?.Creator?.data?.username}'s Avatar` ?? "User Avatar"}
                        />
                      </Avatar>
                    </div>
                    <p className={"text-white dark:text-scale-100"}>Want to collaborate with <span
                      className={"font-semibold"}>@{post?.Creator?.data?.username}</span>?</p>
                  </div>
                  <div className={"flex items-center justify-end col-span-3 sm:col-span-1 w-full"}>
                    <Button
                      variant={"default"}
                      size={"sm"}
                      onClick={() => withAuth(handleStartChat)}
                      className="px-4 shrink-0 w-full sm:w-fit flex-wrap font-mono text-xs">
                      <Icons.Chat className="mr-2 h-4 w-4" aria-hidden="true" />
                      <span>Start chat</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </main>
      </AlertDialog>
    </div>
  );
}
