import { Action } from "@/store/reducers/index";
import { DashboardState } from "@/store/initial-state";
import { DashboardActions } from "@/store/actions/dashboard.actions";


const dashboardReducer = (state: DashboardState, action: Action) => {
  switch (action.type) {
    case DashboardActions.SetSelectedTagFilters:
      return {
        ...state,
        selectedTagFilters: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
