import React from "react";
import { UserAuthForm } from "@/components/user-auth-form";
import useGetUsers from "@/hooks/use-get-users";
import { MAX_PRIVATE_BETA_USERS } from "@/config/site";
// import DefaultBanner from "@/assets/images/backgrounds/flux-gradient-blue.svg";
// import DefaultBanner from "@/assets/images/gradients/mesh-gradient-99.jpg";
// import DefaultBanner from "@/assets/images/backgrounds/pairprogram-banner-commits.jpg";
import DefaultBanner from "@/assets/images/backgrounds/pairprogram-banner-commits-alt.jpg";
import { cn } from "@/utils";
import Balancer from "react-wrap-balancer";

export default function FeedSidebarLogin() {

  const { count } = useGetUsers(0, 1);
  const spotsRemaining = MAX_PRIVATE_BETA_USERS - count >= 0 ? MAX_PRIVATE_BETA_USERS - count : 0;

  return (
    <div
      className={cn(
        "group hover:outline-brand-100 hover:outline-4 hover:outline",
        "border border-scale-300 hover:border-brand-200",
        "shadow-sm inline-block rounded-lg bg-white",
        // "animate-border bg-gradient-to-r from-indigo-700 via-purple-500 to-sky-500 bg-[length:400%_400%] p-1"
      )}>
      <div className={"rounded-lg bg-white"}>
        <div
          className={cn(
            "relative flex items-center justify-center rounded-md rounded-b-none h-32 py-4 px-2 bg-gradient-to-r from-scale-25 to-scale-200",
            "border-b border-b-scale-300"
            // "outline outline-4 outline-brand-100"
            // "bg-gradient-to-r from-red-500 via-purple-500 to-blue-500",
          )}>
          <div
            className={'rounded-t-md text-scale-100 overflow-y-auto absolute inset-0 h-full w-full bg-no-repeat bg-center bg-cover py-16'}
            style={{ backgroundImage: `url(${DefaultBanner})` }}
          >
          <div
            className="flex items-center justify-center h-full text-center font-extrabold text-lg lg:text-2xl text-black">
            <h2 className={'font-semibold'}>Build coding habits<br/><span className={"font-black highlight highlight-amber-300"}>together</span><span className={'ml-2'}>🚀</span></h2>
          </div>
          </div>
        </div>
        <div className="mx-auto px-5">
          <div className="mt-2 min-w-0 flex-1 pb-8">

            {/*<h1 className="font-mono text-lg font-semibold text-scale-900">*/}
            {/*  <Balancer>Find your coding tribe</Balancer>*/}
            {/*</h1>*/}
            <div className="mt-2 mb-4">
              <div>
                <div
                  className="py-2 flex justify-center text-center font-medium text-sm text-scale-600">
                  {/*<p>Skill up through real-world software projects and real-world feedback.</p>*/}
                  <p>Join a community of like-minded developers who are getting 1% better at their craft everyday.</p>
                </div>
                {/*<p className={"text-xs lg:text-sm text-scale-400 text-center"}>*/}
                {/*  /!*Solidify your programming knowledge with a community of developers who learn by doing.*!/*/}
                {/*  /!*Complete real-world projects and collaborate to build your portfolio and become a better developer.*!/*/}
                {/*  Learn new technologies and skills by working on real-world projects and tasks with other developers.*/}
                {/*</p>*/}
                <ul className={"py-2 text-xs lg:text-sm text-scale-500"}>
                  <li className={"py-1.5"}><span className={"mr-2"}>✅</span>Discover new tasks and challenges</li>
                  <li className={"py-1.5"}><span className={"mr-2"}>📣</span>Share what you're working on</li>
                  <li className={"py-1.5"}><span className={"mr-2"}>🤝</span>Get feedback, guidance, and kudos</li>
                  <li className={"py-1.5"}><span className={"mr-2"}>🔧</span>Showcase your projects</li>
                  <li className={"py-1.5"}><span className={"mr-2"}>📈</span>Track your progress</li>
                </ul>
              </div>
            </div>
            <div className="">
              {/*<div className="flex justify-center font-mono font-bold py-2 space-x-1 text-xs text-scale-600">*/}
              {/*  <p>Currently in beta</p>*/}
              {/*</div>*/}
              <UserAuthForm className={"h-12 font-heading text-sm"} variant={"secondary"}
                            text={"Join with GitHub"} />
              <div className="flex justify-center font-mono font-bold pt-4 text-sm text-scale-600">
                <p>
                  <span className={"highlight highlight-variant-9 highlight-amber-300"}>{spotsRemaining}</span> spots remaining
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
