import { User } from "@pairprogram/graphql";
import { Avatar, AvatarImageWithStatus, Button } from "@/components/ui";
import React, { useContext } from "react";
import { getRelativeTime, Timezone } from "@/utils/datetime.utils";
import { Icons } from "@/assets/icons";
import useLocalizedTime from "@/hooks/use-localized-time";
import { Link, useNavigate } from "react-router-dom";
import ShareOptions from "@/components/ShareOptions";
import { removeUrlProtocol } from "@/utils/string.utils";
import LinkedInIcon from "@/assets/icons/linkedin";
import toast from "react-hot-toast";
import { AuthContext } from "@/store/context/AuthContext";
import { useStartChat } from "@/hooks/use-start-chat";
import { AppRoute } from "@/config/routes";
import useRequiresAuth from "@/hooks/use-requires-auth";
import TagBadge from "@/components/tag-badge";
import { cn } from "@/utils";
import { useTailwindBreakpoint } from "@/hooks/use-tailwind-breakpoint";

export default function UserCard({ user }: { user: User }) {
  const localizedTime = useLocalizedTime(user?.timezone as Timezone ?? "" as Timezone);
  const navigate = useNavigate();
  const { state: auth } = useContext(AuthContext);
  const { startChat } = useStartChat();

  const { withAuth } = useRequiresAuth();

  const handleStartChat = async () => {
    if (user?.id && user?.id === auth?.user?.id) {
      toast.error("You can't start a chat with yourself.");
      navigate(AppRoute.Home);
      return;
    }

    const chatId = await startChat({ recipientIds: [user?.id] });
    setTimeout(() => {
      navigate(`${AppRoute.Chat}/${chatId}`);
    }, 250);
  };

  const breakpoint = useTailwindBreakpoint();

  return (
    <div
      className={cn(
        "group hover:outline-brand-100 hover:outline-4 hover:outline",
        "border border-scale-300 hover:border-brand-200",
        "bg-scale-25 shadow-sm hover:shadow-none transition-shadow duration-300 ease-in-out rounded-xl"
      )}>
      <div className="flex flex-1 flex-col px-4 py-4">
        <div className="mx-auto w-full">
          <div className="items-top sm:flex sm:space-x-5">
            <div>
              <Link to={`${AppRoute.User}/${user?.username}`}>
                <Avatar
                  className={"h-12 w-12"}
                >
                  <AvatarImageWithStatus
                    isOnline={user?.isOnline}
                    statusSize={3}
                    className="rounded-full ring-2 ring-scale-100 dark:ring-scale-700"
                    src={user?.avatarUrl ?? "/icon.png"}
                    alt={`${user?.username}'s Avatar` ?? "User Avatar"}
                  />
                </Avatar>
              </Link>
            </div>
            <div className="flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-3">
              <div className="min-w-0 flex-1">
                <h1 className="truncate text-2xl font-bold text-scale-950 dark:text-scale-25">{user?.username}</h1>
                <div className="flex">
                  <div className={"grid grid-cols-3 items-center sp sm:gap-x-2 gap-y-1"}>
                    <p className={"col-span-3 sm:col-span-1 py-1 text-xs text-scale-500 dark:text-scale-200"}>Last
                      online {getRelativeTime(user?.lastOnlineAt)}</p>

                    {
                      user?.timezone && (
                        <div className={"col-span-3 sm:col-span-1"}>
                          <div className="flex items-center">
                            <div title={"Local Time"}
                                 className="inline-flex items-center space-x-2 text-scale-500 dark:text-scale-100">
                              <Icons.Time className={"h-3 w-3"} />
                              <p className={"text-xs"}>Local
                                time: {localizedTime}</p>
                            </div>
                          </div>
                        </div>
                      )
                    }

                    {
                      user?.profile?.location && (
                        <div className={"col-span-3 sm:col-span-1"}>
                          <div className="flex items-center">
                            <div title={"Personal Website"}
                                 className="inline-flex items-center space-x-2 text-scale-500 dark:text-scale-100">
                              <Icons.Location className={"h-3 w-3"} />
                              <p className={"text-xs"}>{user?.profile?.location}</p>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
                {/*<div className="flex">*/}
                {/*  <div className={"inline-flex items-center space-x-2"}>*/}
                {/*    */}
                {/*  </div>*/}
                {/*</div>*/}

              </div>
            </div>
          </div>
          <div className="hidden min-w-0 flex-1 sm:hidden">
            <Link className={"cursor-pointer"} to={`${AppRoute.User}/${user?.username}`}>
              <h1 className="truncate text-2xl font-bold text-scale-900 dark:text-scale-100">{user?.username}</h1>
            </Link>
          </div>
        </div>
        {
          user?.profile?.bio && (
            <div className="">
              <div className="flex py-4 sm:py-2 text-sm text-scale-600 dark:text-scale-100">
                {user?.profile?.bio}
              </div>
            </div>
          )
        }

        {
          user?.tags && user?.tags?.length > 0 && (
            <div className="mt-3 flex flex-wrap gap-y-1">
              {
                user?.tags?.map((tag, index) => <TagBadge tag={tag!} />)
              }
            </div>
          )
        }
      </div>
      <div
        className="flex flex-wrap items-center justify-between space-y-4 sm:space-y-0 border-t border-scale-200 py-2 px-2">
        {/*<div className="mt-3 flex justify-between space-x-8">*/}
        <div className="flex space-x-3 items-center gap-y-2 sm:gap-y-0 justify-center sm:justify-start w-full sm:w-fit">

          {
            user?.profile?.githubUrl && (
              <div>
                <div className="flex items-center">
                  <div title={"GitHub URL"}
                       className="inline-flex items-center space-x-2 text-scale-400 dark:text-scale-100">
                    <a href={user?.profile?.githubUrl ?? ""} target={"_blank"} className={"text-xs"}>
                      <Icons.GitHub className={"h-5 w-5"} />
                    </a>
                  </div>
                </div>
              </div>
            )
          }

          {
            user?.profile?.linkedInUrl && (
              <div>
                <div className="flex items-center">
                  <div title={"LinkedIn"}
                       className="inline-flex items-center space-x-2 text-scale-500 dark:text-scale-100">
                    <a href={user?.profile?.linkedInUrl ?? ""} target={"_blank"} className={"text-xs"}>
                      <LinkedInIcon className={"text-scale-400 h-5 w-5"} />
                    </a>
                  </div>
                </div>
              </div>
            )
          }
          {
            user?.profile?.personalWebsiteUrl && (
              <div>
                <a href={user?.profile?.personalWebsiteUrl ?? ""} target={"_blank"}
                   className={"text-xs"}>
                  <Button
                    variant={"ghost"}
                    size={"xs"}
                    className={"font-mono text-xs"}
                  >
                    <div className="flex items-center">
                      <div title={"Personal Website"}
                           className="inline-flex items-center text-scale-400 dark:text-scale-100">
                        <Icons.Website className={"h-5 w-5 sm:mr-2 sm:-ml-1"} />
                        <span className={"hidden sm:block"}>{removeUrlProtocol(user?.profile?.personalWebsiteUrl)}</span>
                      </div>
                    </div>
                  </Button>
                </a>
              </div>
            )
          }
          <div className="justify-self-end">
            <ShareOptions itemType={"user"} item={user} />
          </div>
        </div>
        {/*<div className="relative inline-flex justify-end items-center text-sm">*/}
        {/*</div>*/}
        <div className="flex text-sm space-x-4 w-full sm:w-fit sm:justify-end">
          <div className="w-full sm:w-fit items-center">
            <Button
              variant={"outline"}
              size={"sm"}
              className={"w-full sm:w-fit shrink-0 font-mono text-xs"}
              onClick={() => navigate(`${AppRoute.User}/${user?.username}`)}
            >
              <span className={"hidden sm:block"}>View Profile</span>
              <span className={"block sm:hidden"}>View</span>
            </Button>
          </div>
          <div className="w-full sm:w-fit items-center">
            <Button
              onClick={() => withAuth(handleStartChat)}
              variant={"default"}
              size={"sm"}
              className={"w-full sm:w-fit shrink-0 font-mono text-xs"}
            >
              <Icons.Chat className="hidden sm:block sm:mr-2 h-3 w-3" aria-hidden="true" />
              <span className="sr-only">Message</span>
              <span className={"hidden sm:block"}>Message</span>
              <span className={"block sm:hidden"}>Chat</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
