import { Link, LinkProps } from "react-router-dom";
import * as React from "react";
import useRequiresAuth from "@/hooks/use-requires-auth";

interface IMaybeAuthLinkProps extends LinkProps {
  requiresAuth: boolean;
}
export const MaybeAuthLink = ({ requiresAuth, ...rest }: IMaybeAuthLinkProps) => {
  const { isAuthenticated, withAuth } = useRequiresAuth();

  function handleMaybeRequiresAuth() {
    if (requiresAuth) {
      if (!isAuthenticated) {
        withAuth();
      }
    }
  }

  return rest?.children ? (
    <Link onClick={handleMaybeRequiresAuth} {...rest}>
      {rest.children}
    </Link>
  ): (
    <Link onClick={handleMaybeRequiresAuth} {...rest} />
  )
}
