import * as React from "react";

import { cn } from "@/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ rows = 5, className, ...props }, ref) => {
    return (
      <textarea
        rows={rows}
        // className={cn(
        //   "flex w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm",
        //   "ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2",
        //   "focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        //   "placeholder:text-scale-300 dark:placeholder:text-scale-300",
        //   className
        // )}
        className={cn(
          "flex w-full min-h-fit shadow-sm rounded-md px-3 py-1.5 text-sm",
          'bg-scale-100 dark:bg-scale-950',
          'border border-scale-300 dark:border-scale-700',
          'text-scale-950 dark:text-scale-25',
          // 'ring-offset-background bg-transparent',
          'ring-0 ring-inset ring-scale-25/10 focus:ring-2 focus:ring-inset focus:ring-primary',
          'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          'file:border-0 file:bg-transparent file:text-sm file:font-medium',
          'disabled:cursor-not-allowed disabled:opacity-50',
          'placeholder:text-scale-400 dark:placeholder:text-scale-600',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
