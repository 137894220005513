import { formatDate, getLocalizedTime } from "@/utils/datetime.utils";
import React, { Fragment, useContext, useEffect, useRef } from "react";
import { cn } from "@/utils";
import { Button, Textarea } from "@/components/ui";
import { Icons } from "@/assets/icons";
import { useSendChatMessage } from "@/hooks/use-send-chat-message";
import { Chat } from "@pairprogram/graphql";
import { AuthContext } from "@/store/context/AuthContext";

export default function ChatBox({ chat, height, maxHeight, className = "" }: { chat: Chat; className?: string; height?: number | string; maxHeight?: number | string; }) {
  const { state: auth } = useContext(AuthContext);
  const { sendMessage, setMessage, message } = useSendChatMessage();

  function handleKeyPress(e: React.KeyboardEvent<HTMLButtonElement | HTMLTextAreaElement>) {
    const ENTER_KEY = "Enter";
    if (e.key === ENTER_KEY) {
      sendMessage({
        chatId: chat?.id,
        msg: message
      });
    }
  }
  const chatBoxContainerRef = useRef(null);
  const messagesEndRef = useRef(null);
  useEffect(() => {
    if (messagesEndRef.current) {
      // @ts-ignore
      const containerScrollHeight = chatBoxContainerRef.current.scrollHeight;
      // @ts-ignore
      const containerHeight = chatBoxContainerRef.current.clientHeight;
      // @ts-ignore
      chatBoxContainerRef.current.scrollTop = containerScrollHeight - containerHeight;
    }
  }, [chat?.id, chat?.Messages?.data?.length]);

  return (
    <div
      style={{ height, maxHeight }}
      className={"flex flex-col h-full max-h-[70vh] overflow-y-scroll"}
    >
      <div
        ref={chatBoxContainerRef}
        className={cn(
          "h-full border border-scale-300 border-b-0 overflow-y-auto flex-col pt-5 pb-5 px-4",
          className ? className : ""
        )}>

        {
          chat?.Messages?.data?.map((message, index, messagesArray) => {
            if (!message) {
              return null;
            }

            const messageUser = message?.Sender?.data;

            const messageSender = messageUser?.username;
            const messageSentByLoggedInUser = messageSender && messageSender === auth?.user?.username;

            let showDateSeparator = false;

            if (index === 0) {
              // Always show separator for the first message
              showDateSeparator = true;
            } else if (messagesArray[index - 1]) {
              const previousMessageDate = messagesArray?.[index - 1]?.createdAt;
              const currentMessageDate = message?.createdAt;

              if (formatDate(previousMessageDate) !== formatDate(currentMessageDate)) {
                showDateSeparator = true;
              }
            }

            return (
              <div className={'flex flex-col'} key={index}>
                {showDateSeparator && (
                  <div className="text-center my-6 font-medium text-xs text-scale-400 dark:text-scale-600">
                    {formatDate(message?.createdAt)}
                  </div>
                )}
                <div key={index} className={cn(
                  "flex mb-4",
                  messageSentByLoggedInUser ? "ml-auto" : ""
                )}>
                  <div className={'flex flex-col justify-end'}>
                    <div className={cn(
                      "text-[10px] font-semibold text-scale-400 dark:text-scale-600 pb-1",
                      messageSentByLoggedInUser ? "" : ""
                    )}>
                      {getLocalizedTime(auth?.user?.timezone, message?.createdAt)}
                    </div>
                    <div className={"max-w-sm flex justify-end"}>
                      <div className={cn(
                        "rounded-xl justify-end px-3 py-2 max-w-fit text-xs",
                        messageSentByLoggedInUser ? "bg-blue-500 text-scale-25" : "bg-scale-100 dark:bg-scale-700 dark:text-scale-100"
                      )}>
                        {message?.text}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }).filter(Boolean)
        }
        <div ref={messagesEndRef}></div>
      </div>

      <div className="bg-scale-200 border border-scale-300 rounded-b-md mt-auto dark:border-scale-700 py-3">
        <div className="items-center px-4 space-y-2">
          <Textarea
            rows={3}
            className={"bg-white w-full"}
            onKeyUp={handleKeyPress}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..." />
          <div className={"flex justify-end"}>
            <Button
              variant={"default"}
              size={"sm"}
              onClick={() => {
                if (chat?.id) {
                  sendMessage({
                    chatId: chat?.id,
                    msg: message
                  });
                }
              }}
              className={cn(
                "font-mono text-xs flex-shrink"
              )}>
              <Icons.SendMessage className={"h-3 w-3 mr-2"} />
              <span>Send</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
