import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import CreatePostCard from "@/components/feed/create-post-card";
import FeedNavigation from "@/components/nav/feed-navigation";
import FeedSidebar from "@/components/feed/feed-sidebar";
import { AppRoute } from "@/config/routes";
import useRequiresAuth from "@/hooks/use-requires-auth";
import ApplyNowModal from "@/components/apply-now-modal";
import { cn } from "@/utils";
import { authStore } from "@/store";
import FeedSidebarLogin from "@/components/feed/feed-sidebar-login";

export default function FeedSidebarLayout() {
  const { pathname } = useLocation();
  const isDashboardHome = pathname === AppRoute.Feed;
  const { showModal } = useRequiresAuth();
  const { isAuthenticated } = authStore();

  return (

    <div>
      <FeedNavigation />
      <div className="w-full flex justify-center">
        <div className="justify-center max-w-6xl w-full">
          <div className="grid grid-cols-1 sm:grid-cols-12">
            <div className="col-span-12 md:col-span-8 px-8 py-8">
              {
                isDashboardHome && (
                  <div className={'space-y-8'}>
                    <CreatePostCard />
                    <div className={"col-span-12 md:hidden"}>
                      <FeedSidebarLogin />
                    </div>
                  </div>
                )
              }
              <div>
                <Outlet />
              </div>
            </div>
            <div className={cn(
              "px-8 py-8 hidden md:block md:col-span-4",
            )}>
              <FeedSidebar />
            </div>
          </div>
        </div>
      </div>
      {
        showModal && (
          <ApplyNowModal />
        )
      }
    </div>
  );
}
