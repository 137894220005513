import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import React, { useState } from "react";
import { Button } from "@/components/ui";
import { Icons } from "@/assets/icons";
import { Link, useNavigate } from "react-router-dom";
import { TaskFilterEnum, TaskSortEnum } from "@pairprogram/graphql";
import useGetTasks from "@/hooks/use-get-tasks";
import FilterSortBar, { ISortFilterItem } from "@/components/filter-sort-bar";
import { FlameIcon, SquareUserRound, ZapIcon } from "lucide-react";
import { AppRoute } from "@/config/routes";
import DefaultBanner from "@/assets/images/gradients/mesh-gradient-29.jpg";
import TagBadge from "@/components/tag-badge";
import { cn } from "@/utils";

export default function TasksPage() {
  const navigate = useNavigate();
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [sort, setSort] = useState<TaskSortEnum>(TaskSortEnum.Latest);
  const [filter, setFilter] = useState<TaskFilterEnum>(TaskFilterEnum.All);
  const { tasks, fetchMore } = useGetTasks({
    limit,
    offset,
    sort,
    filter
  });

  const sortAndFilterItems: ISortFilterItem[] = [
    {
      type: "FILTER",
      icon: FlameIcon,
      value: TaskFilterEnum.All,
      label: "All",
      current: filter === TaskFilterEnum.All,
      requiresAuth: false
    },
    {
      type: "FILTER",
      icon: ZapIcon,
      value: TaskFilterEnum.Recommended,
      label: "Recommended",
      current: filter === TaskFilterEnum.Recommended,
      requiresAuth: true
    },
    {
      type: "FILTER",
      icon: SquareUserRound,
      value: TaskFilterEnum.MyTasks,
      label: "My Tasks",
      current: filter === TaskFilterEnum.MyTasks,
      requiresAuth: true
    },
    // { type: 'SORT', value: PostSortEnum.Trending, label: "Trending", current: sort === PostSortEnum.Trending },
    // { type: 'SORT', value: PostSortEnum.Latest, label: "Latest", current: sort === PostSortEnum.Latest },
  ];

  return (
    <div>
      <DashboardPageHeadingSection title={"Tasks"} description={'Discover practical tasks to build your habit'} />
      <FilterSortBar items={sortAndFilterItems} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} />
      <div className={"py-2 "}>
        <ul className={"gap-y-8 gap-x-4 grid grid-cols-3"}>
          {tasks?.map((task, key) => (
            <li className={"col-span-3 sm:col-span-1"} key={key}>
              {/*<TaskCard task={task as Task} />*/}
              {/*<li className={"col-span-3 md:col-span-1"} key={key}>*/}
                <Link to={`${AppRoute.Task}/${task?.id}`}>

                  <div className="flex flex-col w-full space-y-2">
                    {/* top of card */}
                    <div className="border border-brand-100 bg-white p-4 rounded-lg shadow-md hover:shadow-sm transition-shadow duration-300">
                      <div className="relative flex w-full items-center justify-center">
                        <img className="bg-scale-800 rounded-lg h-24 w-full object-cover"
                             src={task?.imageUrl || DefaultBanner}
                             alt="Default banner" />
                        {/*<h3 className="absolute text-lg font-semibold text-white text-center justify-center">{project.name}</h3>*/}
                        { !task?.imageUrl && <Icons.BrandIcon className={"absolute h-12 text-white"} /> }
                      </div>
                      <div className="flex flex-col py-4 space-y-2">
                        <h3 className="text-lg font-semibold text-scale-900 line-clamp-1">{task?.title}</h3>
                        <p className="text-sm text-scale-500 line-clamp-2 h-10">{task?.description}</p>
                      </div>
                    </div>
                    {/* card footer */}
                    <div className="flex w-full justify-between items-center">
                      <div className="flex items-center space-x-2">
                        {/*<Avatar className={"h-6 w-6 outline outline-2 outline-white/80 shadow-lg"}>*/}
                        {/*  <AvatarImageWithStatus*/}
                        {/*    statusSize={"1.5"}*/}
                        {/*    src={avatarUrl}>*/}
                        {/*  </AvatarImageWithStatus>*/}
                        {/*</Avatar>*/}
                        <div className="flex flex-col space-y-1">
                          <div className="inline-flex text-xs text-scale-700 tracking-wide font-mono">
                            {
                              task?.Tags?.data?.map((tag, key) => {
                                // only display 2 tags, plus a +1 if there are more
                                if (key < 1) {
                                  return (
                                    <TagBadge key={key} tag={tag!} />
                                  );
                                }
                                if (key === 1 && task?.Tags?.data?.length! >= 1) {
                                  const restOfTheTagsStr = [...task?.Tags?.data!]?.splice(1).map(t => t?.label).join(", ");
                                  return (
                                    <div key={key} title={restOfTheTagsStr}
                                         className="inline-flex items-center bg-white/75 justify-between text-sm border border-scale-200 text-scale-500 py-0.25 px-1.5 rounded-md">
                                      <div className="flex items-center space-x-2">
                                        <div>
                                          <span className={cn("font-semibold text-xs")}>
                                            +{task?.Tags?.data?.length! - 1} more
                                          </span>
                                          <span className="sr-only">more tags</span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                                return null;
                              }).filter(Boolean)
                            }
                          </div>
                        </div>
                      </div>
                      <Link to={`${AppRoute.Task}/${task?.id}`}>
                        <Icons.Goto className={"h-6 w-6 text-brand"} />
                      </Link>
                    </div>
                  </div>
                </Link>
              {/*</li>*/}
            </li>
          ))}
          {
            offset + limit < 0 ? (
              <li key={"load-more-posts"} className="px-4 py-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-center">
                  <Button
                    className={"font-mono text-xs"}
                    size={"sm"} variant={"ghost"}
                    onClick={() => {
                      setOffset(offset + limit);
                      fetchMore({
                        variables: {
                          input: {
                            limit,
                            offset: offset + limit
                          }
                        }
                      });
                    }}
                  >
                    <Icons.LoadMore className={"h-3 w-3 mr-2"} />
                    Load more
                  </Button>
                </div>
              </li>
            ) : null
          }
        </ul>
      </div>

    </div>
  );
}
