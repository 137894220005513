import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Chat, GetChatError, useGetChatQuery } from "@pairprogram/graphql";
import { useNavigate } from "react-router-dom";

export const useGetChat = (chatId: string) => {
  const [chat, setChat] = useState<Chat | null>(null);
  const navigate = useNavigate();
  const { data, loading, error, refetch } = useGetChatQuery({
    variables: {
      input: {
        chatId: chatId,
      }
    }
  });

  useEffect(() => {
    if (chatId) {
      refetch();
    }
  }, [chatId]);


  useEffect(() => {
    if (data?.GetChat?.error) {
      switch (data.GetChat.error) {
        case GetChatError.Unauthorized:
          toast("You are not authorized to view this chat", { icon: "🚨" });
          navigate("/dashboard/chat");
          break;
        default:
          toast("Error fetching chat", { icon: "🚨" });
          navigate("/dashboard/chat");
          break;
      }
    } else {
      setChat(data?.GetChat?.data as Chat);
    }
  }, [data, chatId]);

  const getChat = (chatId: string) => {
    // Trigger the query with the new chatId
    // You could add additional logic here if needed
    useGetChatQuery({
      variables: {
        input: {
          chatId
        }
      }
    });
  };

  return {
    getChat,
    chat,
    loading,
    error
  };
};
