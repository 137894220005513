import React from "react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui";
import { cn } from "@/utils";
import { Icons } from "@/assets/icons";
import { Upload } from "@pairprogram/graphql";

interface ViewAttachmentModalProps {
  attachments: Upload[];
  open: boolean;
  setOpen: (open: boolean) => void;
  initialIndex: number;
}

export default function ViewAttachmentModal({ attachments = [], initialIndex, open, setOpen }: ViewAttachmentModalProps) {
  const [currentIndex, setCurrentIndex] = React.useState<number>(initialIndex);
  function getNextAttachment() {
    if (currentIndex === attachments?.length - 1) {
      setCurrentIndex(0);
      return attachments?.[0];
    }
    setCurrentIndex(currentIndex + 1);
    return attachments?.[currentIndex + 1];
  }

  function getPreviousAttachment() {
    if (currentIndex === 0) {
      setCurrentIndex(attachments?.length - 1);
      return attachments[attachments?.length - 1];
    }
    setCurrentIndex(currentIndex - 1);
    return attachments?.[currentIndex - 1];
  }

  return (
    <AlertDialog
      open={open}
    >
      <AlertDialogContent className={cn(
        "max-w-7xl",
        "group outline-brand-100 outline-4 outline",
        "border border-scale-300",
        "bg-white dark:bg-scale-950 dark:border-scale-700")}>
        <AlertDialogHeader>
          {/*<AlertDialogTitle className={"text-scale-900 dark:text-scale-100"}>*/}
          {/*  View Attachment*/}
          {/*</AlertDialogTitle>*/}
          <div>
            <div className="flex items-center justify-between w-full space-x-4">
              {/* previous button */}
              <div className={'p-2 border border-scale-300 rounded-lg'}>
                <button
                  disabled={attachments?.length <= 1}
                  onClick={() => getPreviousAttachment()}
                  className={"flex items-center justify-center w-full cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"}>
                  <Icons.ArrowLeft className={"w-6 h-6 text-scale-500 dark:text-scale-100"} />
                </button>
              </div>
              <div className={"rounded-md"}>
                <img
                  src={attachments?.[currentIndex]?.signedUrl!} alt={"Image"}
                  className={"w-full h-full max-h-[60vh] object-cover rounded-md border border-scale-400"} />
              </div>
              {/*  next button */}
              <div className={'p-2 border border-scale-300 rounded-lg'}>
                <button
                  onClick={() => getNextAttachment()}
                  disabled={attachments?.length <= 1}
                  className={"flex items-center justify-center w-full cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"}>
                  <Icons.ArrowRight className={"w-6 h-6 text-scale-500 dark:text-scale-100"} />
                </button>
              </div>
            </div>
            <div className="flex w-full justify-center text-center pt-2">
              <p className={"text-scale-700 text-sm font-bold dark:text-scale-100"}>{currentIndex + 1} / {attachments?.length}</p>
            </div>
            <div className="flex items-center justify-end py-2 space-x-2">
              <AlertDialogCancel onClick={() => setOpen(false)}>Close</AlertDialogCancel>
            </div>

          </div>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );

}
