import { Navigate, Outlet, useLocation } from "react-router-dom";
import { authStore } from "@/store";
import { AppRoute } from "@/config/routes";
import { Role } from "@pairprogram/graphql";
import toast from "react-hot-toast";

interface ProtectedRouteProps {
  navigateTo?: string;
  roles?: Role[];
  children?: React.ReactNode;
}

const ProtectedRoute = ({
  navigateTo = AppRoute.Home,
  roles = [],
  children = null
}: ProtectedRouteProps) => {
  const location = useLocation();
  const { isRefreshTokenExpired, removeAuthState, getRoles } = authStore();

  if (isRefreshTokenExpired()) {
    removeAuthState();
    return <Navigate to={navigateTo} replace={true} />;
  }

  const routeRequiresSpecificRole = roles.length > 0;
  const userRoles = getRoles();
  const userHasRequiredRole = userRoles?.some((role: Role) => roles?.includes(role));

  if (routeRequiresSpecificRole && !userHasRequiredRole) {
    toast.error("You do not have permission to access this page.", { id: "access-denied" });
    return <Navigate to={navigateTo} replace={true} />;
  }

  return children ? <Navigate to={location.pathname} /> : <Outlet />;
};

export default ProtectedRoute;
