import toast from "react-hot-toast";
import { ChatActions } from "@/store/actions/chat.actions";
import { useNavigate } from "react-router-dom";
import { User } from "@pairprogram/graphql";
import React, { useContext, useState } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import { useStartChat } from "@/hooks/use-start-chat";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Textarea
} from "@/components/ui";
import { cn } from "@/utils";
import { AppRoute } from "@/config/routes";

interface StartChatButtonProps {
  user: User;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function StartChatModal({ user, open, setOpen }: StartChatButtonProps) {
  const { state: auth, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { startChat } = useStartChat();

  const handleStartChat = async () => {
    if (user?.id && user?.id === auth?.user?.id) {
      toast.error("You can't start a chat with yourself.");
      navigate(AppRoute.Home);
      return;
    }

    await startChat({ recipientIds: [user?.id] });
    dispatch({ type: ChatActions.SetSelectedChatIndex, payload: 0 });
    setTimeout(() => {
      navigate(AppRoute.Chat);
    }, 250);
  };

  return (
    <AlertDialog
      open={open}
    >
      <AlertDialogContent className={"bg-scale-100 dark:bg-scale-950 border-scale-300 dark:border-scale-700"}>
        <AlertDialogHeader>
          <AlertDialogTitle className={"text-scale-900 dark:text-scale-100"}>
            <p>Send <span className={"font-bold"}>@{user?.username}</span> a message</p>
          </AlertDialogTitle>
          <AlertDialogDescription className={"text-scale-700 dark:text-scale-300"}>
            Reach out to @{user?.username} and let them know you're interested in pairing with them!
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => setOpen(false)}
          >Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={async () => await handleStartChat()}>Send</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

}
