import React, { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { MainNav } from "@/components/nav/main-nav";
import { sessionConfig } from "@/config/session";
import { Badge, buttonVariants, DarkModeToggle } from "@/components/ui";
import { AvatarDropdown } from "@/components/nav/avatar-dropdown";
import { cn } from "@/utils";
import { SiteFooter } from "@/components/nav/site-footer";
import { AuthContext } from "@/store/context/AuthContext";
import { AppRoute } from "@/config/routes";

export default function SessionsLayout() {
  const { state: auth } = useContext(AuthContext);
  return (

    <div className="flex min-h-screen flex-col bg-scale-100 dark:bg-scale-950">
      <header
        className="sticky top-0 z-40 backdrop-blur-xl bg-scale-25 dark:bg-scale-950/50 border-b border-b-scale-200 dark:border-b-scale-800">
        <div className="container flex h-16 items-center justify-between py-4">
          <MainNav items={sessionConfig.mainNav} />
          <nav className={"flex items-center space-x-4"}>
            <div>
              <Badge variant={"default"}>
                BETA
              </Badge>
            </div>
            {
              auth?.isAuthenticated ? (
                <AvatarDropdown />
              ) : (
                <Link
                  to={AppRoute.Login}
                  className={cn(
                    buttonVariants({ size: "sm", variant: "default" }),
                    "px-4",
                    "shrink-0",
                  )}
                >
                  Log in
                </Link>
              )
            }
            {/*<DarkModeToggle />*/}
          </nav>
        </div>
      </header>
      <main className="flex-1">
        <Outlet />
      </main>
      <SiteFooter />
    </div>
  );
};
