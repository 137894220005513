import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { VariantProps, cva } from "class-variance-authority"

import { cn } from "@/utils"

const buttonVariants = cva(
  "inline-flex items-center text-sm justify-center rounded-md font-medium transition-colors focus-visible:border-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-enums-none ring-offset-background",
  {
    variants: {
      variant: {
        primary:
          "border border-transparent bg-brand-600 text-scale-25 hover:bg-brand-600/80 dark:bg-brand-500 dark:text-scale-25",
        secondary:
          "border border-transparent bg-secondary-500 text-scale-25 hover:bg-secondary-400 dark:bg-secondary-700 dark:text-scale-25",
        default:
          "border border-transparent bg-scale-950 text-scale-25 hover:bg-scale-700 dark:bg-scale-50 dark:text-scale-950",
        mono:
          "font-mono text-xm border border-transparent bg-scale-950 text-scale-25 hover:bg-scale-700 dark:bg-scale-50 dark:text-scale-950",
        inverse:
          "border border-transparent bg-scale-50 text-scale-950 hover:bg-scale-200 dark:bg-scale-950 dark:text-scale-25",
        destructive:
          "border border-transparent bg-red-500 text-scale-25 hover:bg-red-600 dark:hover:bg-red-600",
        outline:
          "bg-transparent border border-scale-300 dark:hover:bg-scale-800 hover:bg-scale-200 dark:border-scale-700 text-scale-700 dark:text-scale-25",
        subtle:
          "border border-transparent bg-scale-200 text-scale-950 hover:bg-scale-300 dark:bg-scale-700 dark:text-scale-100",
        ghost:
          "border border-transparent bg-transparent hover:bg-scale-200 dark:hover:bg-scale-800 dark:text-scale-25 dark:hover:text-scale-25 data-[state=open]:bg-transparent dark:data-[state=open]:bg-transparent",
        link: "border border-transparent bg-transparent underline-offset-4 hover:underline text-scale-950 dark:text-scale-100 hover:bg-transparent dark:hover:bg-transparent",
      },
      size: {
        default: "min-w-fit h-10 py-2 px-4",
        xxs: "min-w-fit h-fit py-1.5 px-2 rounded-md text-[10px]",
        xs: "min-w-fit h-fit py-1 px-2 rounded-md text-xs",
        sm: "min-w-fit h-fit py-1.5 px-3 rounded-md",
        lg: "min-w-fit h-11 py-4 px-8 rounded-md text-lg",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
