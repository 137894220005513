import { GetTagsQuery, Tag, TagType, useGetTagsQuery } from "@pairprogram/graphql";
import toast from "react-hot-toast";
import { useState } from "react";

export default function useGetTags() {
  const [tags, setTags] = useState<Tag[]>([]);
  const [techTags, setTechTags] = useState<Tag[]>([]);
  const [trackTags, setTrackTags] = useState<Tag[]>([]);
  const [topicTags, setTopicTags] = useState<Tag[]>([]);
  useGetTagsQuery({
    onCompleted: (data: GetTagsQuery) => {
      if (data?.GetTags?.error) {
        toast("Error fetching tags", {
          icon: "🚨"
        });
      } else {
        setTags(data?.GetTags?.data as Tag[] || []);
        const techTags = (data?.GetTags?.data?.filter(tag => tag?.type === TagType.Technology) ?? []) as Tag[];
        const trackTags = (data?.GetTags?.data?.filter(tag => tag?.type === TagType.Career) ?? []) as Tag[];
        const topicTags = (data?.GetTags?.data?.filter(tag => tag?.type === TagType.Topic) ?? []) as Tag[];
        setTechTags(techTags);
        setTrackTags(trackTags);
        setTopicTags(topicTags);
      }
    }
  });



  return {
    tags,
    techTags,
    trackTags,
    topicTags
  }
}
