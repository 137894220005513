import { GetProjectError, useGetProjectQuery, useGetTaskQuery } from "@pairprogram/graphql";

export default function useGetProject(id: string) {
  const { data, loading, error, refetch } = useGetProjectQuery({
    variables: {
      input: {
        id
      }
    }
  });

  return {
    project: data?.GetProject?.data,
    refetch,
    loading,
    error
  };
}
