import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui";
import { Icons } from "@/assets/icons";
import React, { useState } from "react";
import useGetTask from "@/hooks/use-get-task";
import { AppRoute } from "@/config/routes";
import toast from "react-hot-toast";
import TagBadge from "@/components/tag-badge";
import { mapTaskTypeIcon } from "@/components/tasks/task-card";
import { TaskTypeEnum, Upload, useUpsertUserTaskMutation } from "@pairprogram/graphql";
import BannerImage from "@/assets/images/gradients/mesh-gradient-29.jpg";
import MainImagePlaceholder from "@/assets/images/gradients/mesh-gradient-29.jpg";
import { shootConfetti } from "@/utils/confetti.utils";
import MarkdownRenderer from "@/components/markdown-renderer";
import useTaskReaction from "@/hooks/use-task-reaction";
import { cn } from "@/utils";
import ViewAttachmentModal from "@/components/view-attachment-modal";
import TaskShareUpdateModal from "@/components/tasks/task-share-update-modal";
import TaskFeedbackModal from "@/components/tasks/task-feedback-modal";
import { getRoundedDisplayMinutes } from "@/utils/datetime.utils";
import { capitalizeFirstLetter } from "@/utils/string.utils";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import { handleScrollToElement } from "@/utils/scroll.utils";
import ButtonGroup from "@/components/button-group";


export default function TaskPage() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { task, loading } = useGetTask(id!);
  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);
  const [isShareUpdateModalOpen, setIsShareUpdateModalOpen] = useState<boolean>(false);
  const [isTaskFeedbackModalOpen, setIsTaskFeedbackModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndexIndex] = React.useState<number>(0);

  const handleOpenShareUpdateModal = (val: boolean) => {
    if (val === false) {
      setIsTaskFeedbackModalOpen(true);
    }
    setIsShareUpdateModalOpen(val);
  };

  const [completeTask, { loading: isCompleteTaskLoading }] = useUpsertUserTaskMutation({
    onCompleted: (data) => {
      if (data.UpsertUserTask?.error) {
        toast.error("Failed to complete task");
        return;
      }
      shootConfetti();
      setIsShareUpdateModalOpen(true);
    },
    onError: () => {
      toast.error("Failed to complete task", { id: "task-completed-failed" });
    },
    variables: {
      input: {
        taskId: task?.id,
        isComplete: true
      }
    }
  });

  function handleNavigateToTask() {
    if (task?.url) {
      window.open(task?.url!, "_blank");
    }
  }

  const { toggleReaction } = useTaskReaction(task!);

  const handleCompleteTask = () => {
    setIsLoading(true);

    setTimeout(() => {
      completeTask();
      setIsLoading(false);
    }, 1000);
  };

  // TODO: update loader to match new design
  // if (loading) {
  //   return (
  //     <TaskPageLoading />
  //   );
  // }

  function handleImageClick(index: number) {
    setIsImageModalOpen(true);
    setSelectedImageIndexIndex(index);
  }

  if (!task && !loading) {
    toast.error("Task not found", { id: "task-not-found" });
    navigate(AppRoute.Task);
  }

  const TaskTypeIcon = mapTaskTypeIcon(task?.Type?.data?.value as TaskTypeEnum);

  return (
    <div className={"w-full"}>
      <div className={"pb-4"}>
        <div
          className={"outline-4 outline-indigo-100 relative h-32 lg:h-48 w-full"}>
          <div
            className={"inset-0 absolute w-full bg-no-repeat bg-scale-950 bg-cover bg-center"}
            style={{ backgroundImage: `url(${BannerImage})` }}
          >
            <div className="flex flex-col items-center justify-center w-full h-full">
              <Icons.Logo className={"h-10 sm:h-16 text-white"} />
              {/*<p className={"text-white text-lg ml-2"}>Build job-ready skills </p>*/}
            </div>
          </div>
        </div>
        <div className="space-y-12 px-12 max-w-6xl mx-auto">
          <div className={"border-b border-b-scale-200 pb-4"}>
            <div className="py-4 sm:pt-8 sm:pb-0 flex w-full items-center justify-between">
              <DashboardPageHeadingSection title={task?.title!} />
            </div>
            <div className="grid grid-cols-2 justify-between space-y-4 sm:space-y-0">

              <div
                className={"col-span-2 sm:col-span-1 flex items-center justify-start space-x-6 py-4 divide-x divide-scale-300"}>
                <div className="flex items-center sm:px-2 space-x-2">
                  <TaskTypeIcon className={"h-5 w-5 text-scale-500"} />
                  <div className={"font-normal text-sm sm:text-lg text-scale-500"}>{task?.Type?.data?.label}</div>
                </div>

                {
                  task?.estimatedMinutes && (
                    <div className="flex items-center space-x-2 pl-4">
                      <Icons.Time className={"h-5 w-5 text-scale-500"} />
                      <span
                        className={"font-normal text-sm sm:text-lg text-scale-500"}>{getRoundedDisplayMinutes(task?.estimatedMinutes!)}</span>
                    </div>
                  )
                }
                {
                  task?.difficulty && (
                    <div className="flex items-center space-x-2 pl-4">
                      <Icons.Difficulty className={"h-5 w-5 text-scale-500"} />
                      <span
                        className={"font-normal text-sm sm:text-lg text-scale-500"}>{capitalizeFirstLetter(task?.difficulty!)}</span>
                    </div>
                  )
                }
              </div>


              <div className="col-span-2 sm:col-span-1 flex items-center sm:justify-end w-full sm:space-x-2">
                <div className="grid grid-cols-3 sm:flex items-center gap-x-2 gap-y-4 sm:gap-y-0 w-full sm:w-fit">
                  <Button
                    className={cn(
                      "col-span-3 sm:col-span-1 w-full sm:w-fit",
                      task?.likedByCurrentUser && "bg-brand-200/80 hover:bg-brand-200 text-brand-700"
                    )}
                    variant={task?.likedByCurrentUser ? "primary" : "outline"}
                    size={"sm"}
                    onClick={() => toggleReaction()}
                  >
                    <div className="flex items-center space-x-2">
                      <Icons.Like className={"h-4 w-4"} />
                      <span>{task?.likedByCurrentUser ? "Liked" : "Like"}</span>
                    </div>
                  </Button>

                  <Button
                    className={cn(
                      "col-span-3 sm:col-span-1 w-full sm:w-fit"
                    )}
                    variant={"subtle"}
                    size={"sm"}
                    onClick={() => handleCompleteTask()}>
                    {isLoading ? (
                      <Icons.Spinner className="mr-2 h-4 w-4  animate-spin" />
                    ) : (
                      <Icons.Check className={"h-4 w-4 mr-2"} />

                    )}{" "}
                    <span>Mark Complete</span>
                  </Button>

                  {
                    task?.url && (
                      <Button
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => handleNavigateToTask()}
                        className={cn(
                          "col-span-3 sm:col-span-1 w-full sm:w-fit"
                        )}
                      >
                        <div className="flex items-center space-x-2">
                          <Icons.Goto className={"h-4 w-4"} />
                          <span>Start task</span>
                        </div>
                      </Button>
                    )
                  }

                </div>

              </div>
            </div>


          </div>

          <ButtonGroup items={[
            { label: "Overview", onClick: () => handleScrollToElement("#overview") },
            { label: "Prerequisites", onClick: () => handleScrollToElement("#prerequisites") },
            { label: "Objectives", onClick: () => handleScrollToElement("#objectives") },
            { label: "Instructions", onClick: () => handleScrollToElement("#instructions") },
            { label: "Screenshots", onClick: () => handleScrollToElement("#screenshots") }
          ]} />
          <main className="space-y-12 sm:space-y-24 divide-scale-200/80 divide-y">
            <div className="grid grid-cols-2 h-full space-y-8 sm:space-y-0 gap-x-12 flex-col-reverse">
              <div id={'overview'} className="col-span-2 sm:col-span-1 space-y-6">

                <div className={"space-y-2"}>
                  <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Task Overview</h2>
                </div>

                <div className={"space-y-2"}>
                  <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Description</h3>
                  <p className={"text-scale-500 dark:text-scale-400"}>{task?.description}</p>
                </div>

                <div className="grid grid-cols-2 h-full space-y-8 gap-x-12">
                  {
                    Boolean(task?.Tags?.data?.length) && (
                      <div className={"col-span-2 space-y-2"}>
                        <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Related Tags</h3>
                        {/* @ts-ignore */}

                        <div className="flex gap-2 items-center flex-wrap">
                          {
                            // @ts-ignore
                            task?.Tags?.data?.map((tag: Tag, key: number) => (
                              <TagBadge key={key} tag={tag} />
                            ))
                          }
                        </div>
                      </div>
                    )
                  }
                </div>

                {/*{*/}
                {/*  task?.features?.length && (*/}
                {/*    <div className={"space-y-2"}>*/}
                {/*      <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Key Features</h3>*/}
                {/*      {*/}
                {/*        project?.features?.split(",").map((feature, key) => {*/}
                {/*          if (feature.trim().length) {*/}
                {/*            return (*/}
                {/*              <div key={key} className={"flex items-center space-x-2"}>*/}
                {/*                <Icons.Check className={"h-4 w-4 text-brand"} />*/}
                {/*                <p className={"text-scale-500 dark:text-scale-400"}>{feature}</p>*/}
                {/*              </div>*/}
                {/*            );*/}
                {/*          }*/}
                {/*          return null;*/}
                {/*        })*/}
                {/*      }*/}
                {/*    </div>*/}
                {/*  )*/}
                {/*}*/}

              </div>
              <div className="relative col-span-2 sm:col-span-1 order-first sm:order-last">
                <img className={"sticky rounded-lg w-full aspect-auto mb-4"}
                     src={task?.imageUrl || MainImagePlaceholder}
                     alt={`${task?.title} Screenshot`} />
                {
                  !task?.imageUrl && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Icons.BrandIcon className={"h-16 sm:h-32 text-white"} />
                    </div>
                  )
                }
              </div>
            </div>

            {
              Boolean(task?.prerequisites) && (
                <div id={'prerequisites'} className="col-span-2 space-y-8 py-6">
                  <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Prerequisites</h2>
                  <MarkdownRenderer markdownText={task?.prerequisites!} />
                </div>
              )
            }

            {
              Boolean(task?.objectives) && (
                <div id={'objectives'} className="col-span-2 space-y-8 py-6">
                  <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Learning Objectives</h2>
                  <MarkdownRenderer markdownText={task?.objectives!} />
                </div>
              )
            }

            {
              Boolean(task?.instructions) && (
                <div id={'instructions'} className="col-span-2 space-y-8 py-6">
                  <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Instructions</h2>
                  <MarkdownRenderer markdownText={task?.instructions!} />
                </div>
              )
            }

            {
              Boolean(task?.Uploads?.data && task?.Uploads?.data?.length > 0) && (
                <div id={'screenshots'} className={"space-y-8 py-6"}>
                  <div>
                    <h2 className={"text-2xl font-semibold text-scale-900 dark:text-scale-100"}>Screenshots</h2>
                  </div>
                  <div className="grid grid-cols-2 h-full space-y-8 sm:space-y-0 gap-x-12">
                    {
                      task?.Uploads?.data?.map((upload, key) => (
                        <div
                          onClick={() => handleImageClick(key)}
                          key={key}
                          className="col-span-2 sm:col-span-1 cursor-pointer"
                        >
                          <img className={"rounded-lg w-full aspect-auto mb-4"} src={upload?.signedUrl!}
                               alt={`Screenshot ${key}`} />
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
            }
          </main>
        </div>
      </div>
      <ViewAttachmentModal attachments={task?.Uploads?.data as Upload[]} initialIndex={selectedImageIndex}
                           open={isImageModalOpen} setOpen={setIsImageModalOpen} />
      <TaskShareUpdateModal task={task!} open={isShareUpdateModalOpen} setOpen={handleOpenShareUpdateModal} />
      <TaskFeedbackModal task={task!} open={isTaskFeedbackModalOpen} setOpen={setIsTaskFeedbackModalOpen} />
    </div>
  );

  // return (
  //   <div>
  //     <div className={cn(
  //       "rounded-lg mb-4"
  //     )}>
  //       <div className="grid grid-cols-2 items-center gap-x-2 gap-y-2 sm:gap-y-0">
  //         <div className="col-span-2 sm:col-span-1">
  //           <Breadcrumbs />
  //         </div>
  //         <div className="col-span-2 sm:col-span-1 sm:justify-self-end">
  //           <Button
  //             className={"font-mono bg-white"}
  //             variant={"outline"}
  //             size={"sm"}
  //             onClick={() => navigate(AppRoute.Task)}>
  //             <Icons.ArrowLeft className={"h-4 w-4 mr-2"} />
  //             <span className={"text-xs font-mono"}>Back</span>
  //           </Button>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="grid grid-cols-3 gap-12">
  //       <div
  //         className={cn(
  //           "col-span-3 lg:col-span-2 flex flex-col"
  //         )}>
  //         <div
  //           className={cn(
  //             "group hover:outline-brand-100 hover:outline-4 hover:outline",
  //             "border border-scale-300",
  //             "bg-white shadow-sm rounded-lg"
  //           )}
  //         >
  //
  //
  //           <div className={cn(
  //             // "p-4",
  //             // "space-y-4",
  //           )}>
  //
  //
  //             {/*<div className="hidden w-full lg:flex items-center space-x-4 px-4">*/}
  //             {/*  <TaskTypeIcon className={"h-8 w-8 text-scale-900 dark:text-scale-50 shrink-0"} />*/}
  //             {/*  <h1 className={"font-bold text-lg lg:text-2xl text-scale-800 py-6"}>*/}
  //             {/*    <Balancer>{task?.title}</Balancer>*/}
  //             {/*  </h1>*/}
  //             {/*</div>*/}
  //
  //             <div
  //               className={cn(
  //                 // "bg-gradient-to-r from-red-500 via-purple-500 to-blue-500",
  //                 // "bg-scale-900",
  //                 // "group hover:outline-brand-100 hover:outline-4 hover:outline",
  //                 // "border border-scale-300",
  //                 // "justify-center",
  //                 // "lg:hidden",
  //                 "flex items-center rounded-lg min-h-fit max-h-96"
  //               )}>
  //
  //               <div
  //                 className={cn(
  //                   "flex w-full items-center bg-no-repeat bg-center bg-cover rounded-t-md",
  //                   // "outline outline-4 outline-scale-100",
  //                   // "h-48"
  //                   "min-h-[12rem]"
  //                 )}
  //                 style={{ backgroundImage: `url(${task?.imageUrl ?? BackgroundImage})` }}
  //               >
  //
  //                 <div className="grow grid grid-cols-3 w-full h-full min-h-[12rem] p-4">
  //
  //                   {/* Top Left */}
  //                   <div className="col-span-1 justify-self-start items-start">
  //                     <div className={cn(
  //                       // "items-center justify-center",
  //                       "h-full w-full flex")}>
  //                       <Icons.BrandIcon className={"h-12 text-scale-600 w-fit"} />
  //                     </div>
  //                   </div>
  //
  //                   {/* Top Center */}
  //                   <div className="col-span-1">
  //                     {/* Blank */}
  //                   </div>
  //
  //                   {/* Top Right*/}
  //                   <div className="col-span-1 justify-self-end items-start">
  //                     <div className="flex w-full gap-2">
  //
  //                       <div className={cn(
  //                         "rounded-md bg-brand-100",
  //                         "outline outline-2 outline-brand-200",
  //                         "px-2 py-1",
  //                         "flex items-center justify-center"
  //                       )}>
  //                         <TaskTypeIcon className={"h-3 w-3 text-brand-700 mr-1"} />
  //                         <h3
  //                           className={"text-brand-700 text-xs font-semibold tracking-wide"}>{capitalizeFirstLetter(task?.Type?.data?.value!)}</h3>
  //                       </div>
  //
  //                       <div className={cn(
  //                         "rounded-md bg-brand-100",
  //                         "outline outline-2 outline-brand-200",
  //                         "px-2 py-1",
  //                         "flex items-center justify-center"
  //                       )}>
  //                         <Icons.Difficulty className={"h-3 w-3 text-brand-700 mr-1"} />
  //                         <h3
  //                           className={"text-brand-700 text-xs font-semibold tracking-wide"}>{capitalizeFirstLetter(task?.difficulty!)}</h3>
  //                       </div>
  //
  //                       <div className={cn(
  //                         "rounded-md bg-brand-100",
  //                         "outline outline-2 outline-brand-200",
  //                         "px-2 py-1",
  //                         "flex items-center justify-center"
  //                       )}>
  //                         <Icons.Time className={"h-3 w-3 text-brand-700 mr-1"} />
  //                         <h3
  //                           className={"text-brand-700 text-xs font-semibold tracking-wide"}>{getRoundedDisplayMinutes(task?.estimatedMinutes!)}</h3>
  //                       </div>
  //                     </div>
  //                   </div>
  //
  //                   {/* Middle Left*/}
  //                   {/*<div className="col-span-3 lg:col-span-1">*/}
  //                   {/*  /!*<div className={cn(*!/*/}
  //                   {/*  /!*  // "items-center justify-center",*!/*/}
  //                   {/*  /!*  "h-full w-full flex")}>*!/*/}
  //                   {/*  /!*  <Icons.BrandIcon className={"h-12 text-white w-fit"} />*!/*/}
  //                   {/*  /!*</div>*!/*/}
  //                   {/*</div>*/}
  //
  //                   {/* Middle Center*/}
  //                   <div className="col-span-3">
  //                     {/* Blank */}
  //                     <h1
  //                       className={"block font-bold text-center items-center justify-center text-2xl text-scale-100"}>
  //                       <Balancer>{task?.title}</Balancer>
  //                     </h1>
  //                   </div>
  //
  //                   {/* Middle Right */}
  //                   {/*<div className="col-span-3 lg:col-span-1">*/}
  //                   {/*  /!*<div className="flex items-center justify-center h-full w-full">*!/*/}
  //                   {/*  /!*  <TaskTypeIcon className={"h-8 text-white w-fit"} />*!/*/}
  //                   {/*  /!*</div>*!/*/}
  //                   {/*</div>*/}
  //
  //                   {/* Bottom Left */}
  //                   <div className="col-span-1 py-4">
  //                     {/*<div className={cn(*/}
  //                     {/*  // "items-center justify-center",*/}
  //                     {/*  "h-full w-full flex")}>*/}
  //                     {/*  <Icons.BrandIcon className={"h-12 text-white w-fit"} />*/}
  //                     {/*</div>*/}
  //                   </div>
  //
  //                   {/* Bottom Center */}
  //                   <div className="col-span-1 py-4">
  //                     {/* Blank */}
  //                   </div>
  //
  //                   {/* Bottom Right*/}
  //                   <div className="col-span-1 py-4">
  //                     {/*<div className="flex items-center justify-center h-full w-full">*/}
  //                     {/*  <TaskTypeIcon className={"h-8 text-white w-fit"} />*/}
  //                     {/*</div>*/}
  //                   </div>
  //
  //
  //                 </div>
  //
  //
  //               </div>
  //             </div>
  //           </div>
  //
  //           <div className={"h-full flex flex-col px-4 pb-4 space-y-4"}>
  //             <div className="space-y-8 pb-4">
  //
  //               {/*<h1*/}
  //               {/*  className={"block font-bold text-center lg:text-left items-center justify-center pt-6 text-2xl text-scale-800"}>*/}
  //               {/*  <Balancer>{task?.title}</Balancer>*/}
  //               {/*</h1>*/}
  //
  //               <div className="lg:hidden pt-6 grid grid-cols-3 gap-y-4 lg:gap-y-0 gap-x-4">
  //
  //                 <div className="col-span-3 lg:col-span-1">
  //                   <div>
  //                     <div className="flex items-center justify-start">
  //                       <TaskTypeIcon className={"bg-white h-4 w-4 text-scale-600 dark:text-scale-50 mr-2"} />
  //                       <h3
  //                         className="pr-2 bg-white text-sm font-bold text-scale-800 dark:text-scale-50 shrink-0">Task
  //                         Type</h3>
  //                       <div className="w-full border-t border-scale-200" />
  //                     </div>
  //                   </div>
  //                   <div className="space-y-2">
  //                     <p className={"text-scale-600 text-sm"}>
  //                       {capitalizeFirstLetter(task?.Type?.data?.value ?? "")}
  //                     </p>
  //                   </div>
  //                 </div>
  //
  //                 <div className="col-span-3 lg:col-span-1">
  //                   <div>
  //                     <div className="flex items-center justify-start">
  //                       <Icons.Time className={"bg-white h-4 w-4 text-scale-600 dark:text-scale-50 mr-2"} />
  //                       <h3
  //                         className="pr-2 bg-white text-sm font-bold text-scale-800 dark:text-scale-50 shrink-0">Estimated
  //                         Time</h3>
  //                       <div className="w-full border-t border-scale-200" />
  //                     </div>
  //                   </div>
  //                   <div className="space-y-2">
  //                     <p className={"text-scale-600 text-sm"}>
  //                       {getRoundedDisplayMinutes(task?.estimatedMinutes!)}
  //                     </p>
  //                   </div>
  //                 </div>
  //
  //                 <div className="col-span-3 lg:col-span-1">
  //                   <div>
  //                     <div className="flex items-center justify-start">
  //                       <Icons.Difficulty className={"bg-white h-4 w-4 text-scale-600 dark:text-scale-50 mr-2"} />
  //                       <h3
  //                         className="pr-2 bg-white text-sm font-bold text-scale-800 dark:text-scale-50 shrink-0">Difficulty</h3>
  //                       <div className="w-full border-t border-scale-200" />
  //                     </div>
  //                   </div>
  //                   <div className="space-y-2">
  //                     <p className={"text-scale-600 text-sm"}>
  //                       {capitalizeFirstLetter(task?.difficulty!)}
  //                     </p>
  //                   </div>
  //                 </div>
  //               </div>
  //
  //               {/* Description */}
  //               <div>
  //                 <div className={"pb-2"}>
  //                   <div className="flex items-center justify-start">
  //                     <Icons.Description className={"bg-white h-4 w-4 text-scale-600 dark:text-scale-50 mr-2"} />
  //                     <h3
  //                       className="pr-2 bg-white text-sm font-bold text-scale-800 dark:text-scale-50 shrink-0">Description</h3>
  //                     <div className="w-full border-t border-scale-200" />
  //                   </div>
  //                 </div>
  //                 <div className="space-y-2">
  //                   <p className={"text-scale-600 text-sm"}>
  //                     <MarkdownRenderer markdownText={task?.description!} />
  //                   </p>
  //                 </div>
  //               </div>
  //
  //               {/* Related Tags */}
  //               {
  //                 task?.Tags?.data?.length ? (
  //                   <div>
  //                     <div className={'pb-2'}>
  //                       <div className="flex items-center justify-start">
  //                         <Icons.Tag className={"bg-white h-4 w-4 text-scale-600 dark:text-scale-50 mr-2"} />
  //                         <h3
  //                           className="pr-2 bg-white text-sm font-bold text-scale-800 dark:text-scale-50 shrink-0">Related
  //                           Tags</h3>
  //                         <div className="w-full border-t border-scale-200" />
  //                       </div>
  //                     </div>
  //                     <div className="flex flex-col">
  //
  //                       <p className={"text-sm text-scale-600"}>
  //                         <div className={"flex w-full flex-wrap gap-y-2 gap-x-2"}>
  //                           {
  //                             task?.Tags?.data?.map((tag) => <TagBadge tag={tag!} />)
  //                           }
  //                         </div>
  //                       </p>
  //                     </div>
  //                   </div>
  //                 ) : null
  //               }
  //
  //               {/* Prerequisites */}
  //               {
  //                 task?.prerequisites && (
  //                   <div>
  //                     <div className={"pb-2"}>
  //                       <div className="flex items-center justify-start">
  //                         <Icons.Prerequisites className={"bg-white h-4 w-4 text-scale-600 dark:text-scale-50 mr-2"} />
  //                         <h3
  //                           className="pr-2 bg-white text-sm font-bold text-scale-800 dark:text-scale-50 shrink-0">Prerequisites</h3>
  //                         <div className="w-full border-t border-scale-200" />
  //                       </div>
  //                     </div>
  //
  //                     <div className="space-y-2">
  //                       <p className={"text-scale-600 text-sm"}>
  //                         <MarkdownRenderer markdownText={task?.prerequisites!} />
  //                       </p>
  //                     </div>
  //                   </div>
  //                 )
  //               }
  //
  //
  //               {/* Learning objectives */}
  //               {
  //                 task?.objectives && (
  //                   <div>
  //                     <div className={"pb-2"}>
  //                       <div className="flex items-center justify-start">
  //                         <Icons.LearningObjectives
  //                           className={"bg-white h-4 w-4 text-scale-600 dark:text-scale-50 mr-2"} />
  //                         <h3
  //                           className="pr-2 bg-white text-sm font-bold text-scale-800 dark:text-scale-50 shrink-0">Learning
  //                           Objectives</h3>
  //                         <div className="w-full border-t border-scale-200" />
  //                       </div>
  //                     </div>
  //                     <div className="space-y-2">
  //                       <p className={"text-scale-600 text-sm"}>
  //                         <MarkdownRenderer markdownText={task?.objectives!} />
  //                       </p>
  //                     </div>
  //                   </div>
  //                 )
  //               }
  //
  //
  //               {
  //                 task?.instructions && (
  //                   <div>
  //                     <div className={"pb-2"}>
  //                       <div className="flex items-center justify-start">
  //                         <Icons.Instructions className={"bg-white h-4 w-4 text-scale-600 dark:text-scale-50 mr-2"} />
  //                         <h3
  //                           className="pr-2 bg-white text-sm font-bold text-scale-800 dark:text-scale-50 shrink-0">Instructions</h3>
  //                         <div className="w-full border-t border-scale-200" />
  //                       </div>
  //                     </div>
  //                     <div className="space-y-2">
  //                       <p className={"text-scale-600 text-sm"}>
  //                         <MarkdownRenderer markdownText={task?.instructions!} />
  //                       </p>
  //                     </div>
  //                   </div>
  //                 )
  //               }
  //
  //
  //               {
  //                 task?.Uploads?.data?.length && (
  //                   <div>
  //                     <div className={"pb-2"}>
  //                       <div className="flex items-center justify-start">
  //                         <Icons.Attachments className={"bg-white h-4 w-4 text-scale-600 dark:text-scale-50 mr-2"} />
  //                         <h3
  //                           className="pr-2 bg-white text-sm font-bold text-scale-800 dark:text-scale-50 shrink-0">Attachments</h3>
  //                         <div className="w-full border-t border-scale-200" />
  //                       </div>
  //                     </div>
  //                     <div className="flex flex-col space-y-2 py-4">
  //                       <div>
  //                         <div className="flex w-full flex-wrap gap-4">
  //                           {
  //                             task?.Uploads?.data?.map((upload, index) => {
  //                               return (
  //                                 <div
  //                                   onClick={() => {
  //                                     setSelectedImageIndexIndex(index);
  //                                     setIsImageModalOpen(true);
  //                                   }}
  //                                   key={index}
  //                                   className={cn(
  //                                     "relative cursor-pointer",
  //                                     "outline outline-4 outline-brand-100 rounded-lg"
  //                                   )}>
  //                                   <img
  //                                     className={"rounded-lg max-h-24 aspect-auto border border-scale-300"}
  //                                     src={upload?.signedUrl ?? "/images/placeholder.png"}
  //                                     alt={upload?.filename ?? `Uploaded image for task ${task?.title}`}
  //                                   />
  //                                 </div>
  //                               );
  //                             })
  //                           }
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 )
  //               }
  //
  //
  //             </div>
  //
  //             {/* link section */}
  //             <div className={cn(
  //               // "group outline-brand-100 outline",
  //               // "p-4 rounded-lg bg-brand-50",
  //               "gap-y-4 gap-x-4 grid grid-cols-2")}
  //             >
  //               {
  //                 task?.url && (
  //                   <div className="col-span-2 sm:col-span-1">
  //                     <a href={task?.url!} target="_blank" rel="noreferrer">
  //                       <Button
  //                         className={"w-full flex items-center text-xs font-mono"}
  //                         variant={"subtle"}
  //                         size={"lg"}
  //                       >
  //                         <Icons.ExternalLink className={"h-4 w-4 mr-2"} />
  //                         Start Task
  //                       </Button>
  //                     </a>
  //                   </div>
  //                 )
  //               }
  //               <div className={"col-span-2 sm:col-span-1"}>
  //                 <Button
  //                   className={"w-full border-0 text-xs font-mono"}
  //                   variant={"default"}
  //                   size={"lg"}
  //                   onClick={() => handleCompleteTask()}>
  //                   {isLoading ? (
  //                     <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
  //                   ) : (
  //                     <Icons.Check className={"h-4 w-4 mr-2"} />
  //
  //                   )}{" "}
  //                   <span>Complete Task</span>
  //                 </Button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //
  //
  //       <TaskSidebar task={task!} loading={isLoading} onComplete={handleCompleteTask} />
  //     </div>
  //
  //     <ViewAttachmentModal attachments={task?.Uploads?.data as Upload[]} initialIndex={selectedImageIndex}
  //                          open={isImageModalOpen} setOpen={setIsImageModalOpen} />
  //     <TaskShareUpdateModal task={task!} open={isShareUpdateModalOpen} setOpen={handleOpenShareUpdateModal} />
  //     <TaskFeedbackModal task={task!} open={isTaskFeedbackModalOpen} setOpen={setIsTaskFeedbackModalOpen} />
  //   </div>
  // );
}
