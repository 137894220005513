import { Action } from "@/store/reducers/index";
import { AppState } from "@/store/initial-state";
import { LocalStorage } from "@pairprogram/common";
import { AppActions } from "@/store/actions/app.actions";


const appReducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case AppActions.Theme:
      localStorage.setItem(LocalStorage.Theme, action.payload);
      return {
        ...state,
        theme: action.payload,
      };
    case AppActions.IsOnboardingModalOpen:
      return {
        ...state,
        isOnboardingModalOpen: action.payload,
      }
    case AppActions.DisplayLoginModal:
      return {
        ...state,
        displayLoginModal: action.payload,
      }
    default:
      return state;
  }
};

export default appReducer;
