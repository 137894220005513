import React, { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui";
import { authStore } from "@/store";
import toast from "react-hot-toast";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { Icons } from "@/assets/icons";

interface FileUploadModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setFileUploadData: Function;
  filePrefix: "post" | "profile" | "task" | "project";
}

export default function FileUploadModal({ open, setOpen, setFileUploadData, filePrefix }: FileUploadModalProps) {
  const { getAccessToken } = authStore();
  const accessToken = getAccessToken();
  const fileInputRef = useRef<any | null>(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const fileReader = new FileReader();
  const handleFileUpload = async () => {
    if (!fileObject) {
      toast.error("No file selected");
      return;
    }

    setIsFileUploading(true);

    const formData = new FormData();
    formData.append("file", fileObject);
    formData.set('prefix', filePrefix);

    const req = await fetch("/api/v1/upload", {
      method: "POST",
      body: formData,
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    const res = await req.json();

    if (res.error) {
      toast.error("Error uploading image");
      setIsFileUploading(false);
      setOpen(false);
      return;
    }
    setFileUploadData(res.data);
    setOpen(false);
    setIsFileUploading(false);
  };

  const handleFileChange = (event: any) => {
    const file = event?.target?.files?.[0];
    fileReader.onloadend = () => {
      // @ts-ignore
      setFilePreview({ name: file.name, type: file.type, content: fileReader.result });
      setFileObject(file);
    };

    if (file.type.startsWith("image/")) {
      fileReader.readAsDataURL(file);
    } else {
      // Handle other file types or skip
      toast.error("File type not supported");
      return;
    }
  };

  return (
    <AlertDialog
      open={open}
    >
      <AlertDialogContent className={"bg-scale-100 dark:bg-scale-950 border-scale-300 dark:border-scale-700"}>
        <AlertDialogHeader>
          <AlertDialogTitle className={"text-scale-900 dark:text-scale-100"}>
            File upload
          </AlertDialogTitle>
          <AlertDialogDescription className={"text-scale-700 dark:text-scale-300"}>
            Add an image to your post
          </AlertDialogDescription>
          <div className={"pt-2"}>

            <div className="flex items-center justify-center w-full">
              {
                !filePreview ? (
                  <label htmlFor="dropzone-file"
                         className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <input
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        id="dropzone-file" type="file" className="hidden"
                      />
                      <CloudArrowUpIcon className={"w-8 h-8 text-gray-500 dark:text-gray-400"} />
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span>
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, WEBP, or GIF (Up to 5MB)</p>
                    </div>
                  </label>
                ) : (
                  <div className={"rounded-md"}>
                    {/* @ts-ignore */}
                    <img src={filePreview?.content} alt={filePreview?.name}
                         className={"w-full h-full object-cover rounded-md border border-scale-400"} />
                  </div>
                )
              }

            </div>

            <div className="flex items-center justify-end py-2 space-x-2">
              <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
              <div>
                {filePreview && (
                  <AlertDialogAction onClick={async () => await handleFileUpload()}>
                    {
                      isFileUploading ? (
                        <Icons.Spinner className="h-4 w-4 animate-spin" />
                      ) : (
                        <span>Upload</span>
                      )
                    }
                  </AlertDialogAction>
                )}
              </div>
            </div>

          </div>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );

}
