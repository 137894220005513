import DefaultBanner from "@/assets/images/gradients/mesh-gradient-29.jpg";
import { Icons } from "@/assets/icons";
import { Avatar, AvatarImageWithStatus } from "@/components/ui";
import { Link } from "react-router-dom";
import { AppRoute } from "@/config/routes";
import React from "react";
import { Project } from "@pairprogram/graphql";

export default function ProjectCard({ project}: { project: Project }) {
  const projectCreator = project?.Users?.data?.[0];
  return (
    <div className="flex flex-col w-full space-y-2">
      {/* top of card */}
      <div className="bg-white border border-brand-100 p-4 rounded-lg shadow-md hover:shadow-sm transition-shadow duration-300">
        <div className="relative flex w-full items-center justify-center">
          <img className="bg-scale-800 rounded-lg h-24 w-full object-cover"
               src={project?.imageUrl || DefaultBanner}
               alt="Default banner" />
          {/*<h3 className="absolute text-lg font-semibold text-white text-center justify-center">{project.name}</h3>*/}
          {!project?.imageUrl && <Icons.BrandIcon className={"absolute h-12 text-white"} />}
        </div>
        <div className="flex flex-col py-4 space-y-2">
          <h3 className="text-lg font-semibold text-scale-900 line-clamp-1">{project?.name}</h3>
          <p className="text-sm text-scale-500 line-clamp-3 h-16">{project?.description}</p>
        </div>
      </div>
      {/* card footer */}
      <div className="flex w-full justify-between items-center">
        <div className="flex items-center space-x-2">
          <Avatar className={"h-6 w-6 outline outline-2 outline-white/80 shadow-lg"}>
            <AvatarImageWithStatus
              statusSize={"1.5"}
              src={projectCreator?.avatarUrl}>
            </AvatarImageWithStatus>
          </Avatar>
          <div className="flex flex-col space-y-1">
            <p className="text-xs font-semibold text-scale-700 tracking-wide font-mono">{projectCreator?.username}</p>
          </div>
        </div>
        <Link to={`${AppRoute.Project}/${project?.id}`}>
          <Icons.Goto className={"h-6 w-6 text-brand"} />
        </Link>
      </div>
    </div>
  )
}
