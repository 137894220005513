import React from "react";
import { useGetChatsForUser } from "@/hooks/use-get-chats-for-user";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import ChatsEmpty from "@/app/dashboard/chats/empty";
import { cn } from "@/utils";
import ChatsSkeleton from "@/app/dashboard/chats/skeleton";
import ChatItem from "@/app/dashboard/chats/chat/chat-item";

export default function ChatsPage() {
  const { chats, loading: isLoadingChats } = useGetChatsForUser();

  if (isLoadingChats) {
    return (
      <ChatsSkeleton />
    );
  }

  if (!isLoadingChats && !chats.length) {
    return (
      <div>
        <DashboardPageHeadingSection title={"Chats"} description={'Text, video chat, and screen-share with others'} />
        <div>
          <ChatsEmpty />
        </div>
      </div>
    );
  }

  return (
    <div>
      <DashboardPageHeadingSection title={"Chats"} description={'Text, video chat, and screen-share with others'} />
      <div className="">
        <ul
          role="list"
          className={cn(
            chats?.length && "ring-1 ring-gray-900/5",
            "hover:outline-brand-100 hover:outline-4 hover:outline",
            "border border-scale-300 hover:border-brand-200",
            "divide-y divide-scale-200 border border-scale-200 overflow-hidden bg-white shadow-xs rounded-xl"
          )}
        >
          {chats.map((chat, i) => (
            <li key={i}>
              <ChatItem chat={chat} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
