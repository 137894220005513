import { siteConfig } from "@/config/site"
import { Icons } from "@/assets/icons"
import * as React from "react"

export function SiteFooter() {
  return (
    <footer className="bg-scale-100 text-scale-600 dark:bg-scale-950 border-t border-t-scale-950/10 dark:border-t-scale-25/10">
      <div className={'container'}>
      <div className="flex flex-col items-center justify-between gap-4 py-10 md:h-24 md:flex-row md:py-0">
        <div className="flex flex-col items-center gap-4 px-8 md:flex-row md:gap-2 md:px-0">
          <Icons.Logo className={"h-8 w-full text-scale-950 dark:text-scale-25"} />
          {/*<p className="text-center text-sm leading-loose md:text-left">*/}
          {/*  Built by{" "}*/}
          {/*  <a*/}
          {/*    href={siteConfig.links.twitter}*/}
          {/*    target="_blank"*/}
          {/*    rel="noreferrer"*/}
          {/*    className="font-medium underline underline-offset-4"*/}
          {/*  >*/}
          {/*    shadcn*/}
          {/*  </a>*/}
          {/*  . Hosted on{" "}*/}
          {/*  <a*/}
          {/*    href="https://vercel.com"*/}
          {/*    target="_blank"*/}
          {/*    rel="noreferrer"*/}
          {/*    className="font-medium underline underline-offset-4"*/}
          {/*  >*/}
          {/*    Vercel*/}
          {/*  </a>*/}
          {/*  . Illustrations by{" "}*/}
          {/*  <a*/}
          {/*    href="https://popsy.co"*/}
          {/*    target="_blank"*/}
          {/*    rel="noreferrer"*/}
          {/*    className="font-medium underline underline-offset-4"*/}
          {/*  >*/}
          {/*    Popsy*/}
          {/*  </a>*/}
          {/*  .*/}
          {/*</p>*/}
        </div>
        {/*<p className="text-center text-sm md:text-left">*/}
        {/*  The source code is available on{" "}*/}
        {/*  <a*/}
        {/*    href={siteConfig.links.github}*/}
        {/*    target="_blank"*/}
        {/*    rel="noreferrer"*/}
        {/*    className="font-medium underline underline-offset-4"*/}
        {/*  >*/}
        {/*    GitHub*/}
        {/*  </a>*/}
        {/*  .*/}
        {/*</p>*/}
      </div>
    </div>
    </footer>
  )
}
