import React from "react";
import { Link } from "react-router-dom";
import { Icons } from "@/assets/icons";
import NewPostButton from "@/components/new-post-button";
import { Button } from "@/components/ui";
import { AppRoute } from "@/config/routes";

export default function SessionsEmpty() {
  return (
    <Link
      to={AppRoute.Home}
      type="button"
      className="relative block w-full rounded-lg border-2 border-dashed border-scale-300 dark:border-scale-700 p-12 text-center hover:border-scale-400 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
    >
      <Icons.Sessions
        className="mx-auto h-12 w-12 dark:text-scale-400 text-scale-600"
      />
      <div className="py-2">
        <p className="mt-2 block text-sm font-medium dark:text-scale-400 text-scale-600">Join or start a session</p>
      </div>
      <div className={'flex w-full py-4 items-center justify-center'}>
        <div className={'w-fit'}>
          <Link to={AppRoute.Home}>
            <Button className={'w-full shrink-0'} size={"sm"} variant={"default"}>
              <Icons.Feed className={"hidden sm:block h-4 w-4 text-scale-25 dark:text-scale-950 mr-1"} />
              <span className={'text-xs sm:text-sm shrink-0'}>View Feed</span>
            </Button>
          </Link>
        </div>
      </div>
    </Link>
  )
}
