import { Chat,
  ChatMessage, GetChatDocument, GetChatQuery, GetChatsForUserDocument, GetChatsForUserQuery, useSendChatMessageMutation } from "@pairprogram/graphql";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import mixpanel from "@/analytics";
import { AnalyticsEvent } from "@/analytics/analytics-event";
import { AuthContext } from "@/store/context/AuthContext";

export const useSendChatMessage = () => {
  const { state: auth } = useContext(AuthContext);
  const [message, setMessage] = useState("");

  const [sendChatMessageMutation, { data }] = useSendChatMessageMutation({
    onCompleted: (data) => {
      setMessage("");
      mixpanel.track(AnalyticsEvent.SentChatMessage, {
        sender: auth?.user?.username,
        chatId: data?.SendChatMessage?.data?.chatId
      });
    },
    variables: {
      input: {
        chatId: "",  // Placeholder, to be set when function is called
        text: message
      }
    }
  });

  const sendMessage = ({ chatId, msg }: { chatId: string, msg: string }) => {

    if (!msg) {
      toast.error("Please enter a message");
      return;
    }

    setMessage(msg);
    sendChatMessageMutation({
      variables: {
        input: {
          chatId,
          text: msg
        }
      },
    });
  };

  return {
    sendMessage,
    message,
    setMessage
  };
};
