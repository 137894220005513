export default function OneIcon(props) {
  return (
    <svg className={"text-scale-700 dark:text-scale-300 h-8 w-8"} {...props} width="100%" height="100%" viewBox="0 0 800 800"
         version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
         style={{
           fillRule: "evenodd",
           clipRule: "evenodd",
           strokeLinejoin: "round",
           strokeMiterlimit: 2
         }}>
      <path
        d="M400.005,0.005C179.088,0.005 0,179.074 0,400.01C0,620.907 179.088,799.995 400.005,799.995C620.912,799.995 800,620.907 800,400.01C800,179.074 620.912,0.005 400.005,0.005ZM400.005,25.005C607.105,25.005 775,192.882 775,400.01C775,607.101 607.105,774.995 400.005,774.995C192.896,774.995 25,607.101 25,400.01C25,192.882 192.895,25.005 400.005,25.005Z"
        style={{ fill: "currentColor" }} />
      <g transform="matrix(1.51899,0,0,1.51899,36.962,-209.873)">
        <g transform="matrix(288,0,0,288,160,501.455)">
          <path
            d="M0.346,-0.727L0.346,-0L0.236,-0L0.236,-0.617L0.232,-0.617L0.058,-0.504L0.058,-0.609L0.239,-0.727L0.346,-0.727Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }} />
        </g>
      </g>
    </svg>

  );
};
