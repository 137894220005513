export default function TermsOfService(){
  return (
    <div className="mx-auto max-w-3xl px-4 py-8">
      <main>
        <h1 className="mb-4 text-2xl font-bold">
          <a href="https://pairprogram.com" rel="noreferrer" target="_blank">
            PairProgram.com
          </a>{' '}
          Terms of Service
        </h1>

        <h2 className="mb-2 text-lg font-bold">Use of the Service</h2>
        <p className="mb-6">
          PairProgram.com allows users to collaboratively write and edit code in
          real-time. You may use the Service only for lawful purposes and in
          accordance with these Terms. You agree not to use the Service:
        </p>
        <ul className="ml-6 mb-6 list-disc">
          <li className="mb-2">
            In any way that violates any applicable federal, state, local, or
            international law or regulation.
          </li>
          <li className="mb-2">
            To transmit, or procure the sending of, any advertising or
            promotional material, including any "junk mail," "chain letter,"
            "spam," or any other similar solicitation.
          </li>
          <li className="mb-2">
            To impersonate or attempt to impersonate PairProgram.com, a
            PairProgram.com employee, another user, or any other person or
            entity.
          </li>
          <li className="mb-2">
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the Service, or which, as determined by
            PairProgram.com, may harm PairProgram.com or users of the Service,
            or expose them to liability.
          </li>
        </ul>

        <h2 className="mb-2 text-lg font-bold">User Content</h2>
        <p className="mb-6">
          The Service allows you to collaborate with other users by creating,
          editing, and sharing code. Any content you upload, post, or otherwise
          make available on the Service is referred to as "User Content". You
          retain all rights in, and are solely responsible for, the User Content
          you make available on the Service.
        </p>
        <p className="mb-6">
          By making any User Content available on or through the Service, you
          grant to PairProgram.com a non-exclusive, transferable,
          sub-licensable, royalty-free, worldwide license to use, copy, modify,
          create derivative works based on, distribute, publicly display,
          publicly perform, and otherwise exploit in any manner such User
          Content in all formats and distribution channels now known or
          hereafter devised (including in connection with the Service and
          PairProgram.com's business and on third-party sites and services),
          without further notice to or consent from you, and without the
          requirement of payment to you or any other person or entity.
        </p>
        <p className="mb-6">
          You represent and warrant that: (i) you either are the sole and
          exclusive owner of all User Content or you have all rights, licenses,
          consents, and releases that are necessary to grant to PairProgram.com
          the rights in such User Content, as contemplated under these Terms;
          and (ii) neither the User Content, nor your submission, uploading,
          publishing, or otherwise making available of such User Content, nor
          PairProgram.com's use of the User Content as permitted herein will
          infringe, misappropriate, or violate a third party's patent,
          copyright, trademark, trade secret, moral rights, or other proprietary
          or intellectual property rights, or rights of publicity or privacy, or
          result in the violation of any applicable law or regulation.
        </p>

        <h2 className="mb-2 text-lg font-bold">Intellectual Property</h2>
        <p className="mb-6">
          The Service and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof), are owned by PairProgram.com, its licensors, or
          other providers of such material and are protected by United States
          and international copyright, trademark, patent, trade secret, and
          other intellectual property or proprietary rights laws.
        </p>
        <p className="mb-6">
          These Terms permit you to use the Service for your personal,
          non-commercial use only. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store, or transmit any of the material on our
          Service, except as follows:
        </p>
        <ul className="ml-6 mb-6 list-disc">
          <li className="mb-2">
            Your computer may temporarily store copies of such materials in RAM
            incidental to your accessing and viewing those materials.
          </li>
          <li className="mb-2">
            You may store files that are automatically cached by your Web
            browser for display enhancement purposes.
          </li>
          <li className="mb-2">
            You may print or download one copy of a reasonable number of pages
            of the Service for your own personal, non-commercial use and not for
            further reproduction, publication, or distribution.
          </li>
          <li className="mb-2">
            If we provide desktop, mobile, or other applications for download,
            you may download a single copy to your computer or mobile device
            solely for your own personal, non-commercial use, and not for
            further reproduction, publication, or distribution. If you use such
            applications, you must also comply with any applicable end-user
            license agreement for such applications.
          </li>
        </ul>

        <h2 className="mb-2 text-lg font-bold">Third-Party Services</h2>
        <p className="mb-6">
          PairProgram.com integrates with various third-party services such as
          GitHub, Slack, Discord, Twitter, and others. Your use of such services
          is governed by their respective terms of service and privacy policies.
          PairProgram.com is not responsible for the availability or
          functionality of such third-party services and makes no guarantees
          regarding their security, reliability, or accuracy.
        </p>

        <h2 className="mb-2 text-lg font-bold">Disclaimer of Warranties</h2>
        <p className="mb-6">
          THE SERVICE AND ALL CONTENT, PRODUCTS, AND SERVICES AVAILABLE THROUGH
          THE SERVICE ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY
          OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
          THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, AND NON-INFRINGEMENT.
        </p>
        <p className="mb-6">
          PAIRPROGRAM.COM MAKES NO WARRANTY THAT THE SERVICE WILL MEET YOUR
          REQUIREMENTS, BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, NOR
          DOES PAIRPROGRAM.COM MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
          OBTAINED FROM THE USE OF THE SERVICE, OR AS TO THE ACCURACY OR
          RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE SERVICE.
        </p>

        <h2 className="mb-2 text-lg font-bold">Limitation of Liability</h2>
        <p className="mb-6">
          IN NO EVENT WILL PAIRPROGRAM.COM, ITS AFFILIATES, OR THEIR LICENSORS,
          SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE
          FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE, INCLUDING
          ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
          DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
          SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
          OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
          LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
          BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
        </p>

        <h2 className="mb-2 text-lg font-bold">Indemnification</h2>
        <p className="mb-6">
          You agree to defend, indemnify, and hold harmless PairProgram.com, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys' fees) arising out
          of or relating to your violation of these Terms or your use of the
          Service, including, but not limited to, any use of the Service's
          content, services, and products other than as expressly authorized in
          these Terms or your use of any information obtained from the Service.
        </p>

        <h2 className="mb-2 text-lg font-bold">Termination</h2>
        <p className="mb-6">
          PairProgram.com may terminate or suspend your access to all or any
          part of the Service at any time, with or without cause, with or
          without notice, effective immediately. If you wish to terminate these
          Terms, you may simply discontinue using the Service.
        </p>

        <h2 className="mb-2 text-lg font-bold">Governing Law</h2>
        <p className="mb-6">
          These Terms and any action related thereto will be governed by the
          laws of the State of Rhode Island without regard to its conflict of
          law provisions. Any dispute arising out of or related to these Terms
          or the Service shall be brought exclusively in the state or federal
          courts located in East Providence, Rhode Island, and you hereby
          consent to the jurisdiction of and venue in such courts and waive any
          objection as to inconvenient forum.
        </p>

        <h2 className="mb-2 text-lg font-bold">Changes to Terms of Service</h2>
        <p className="mb-6">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms of Service at any time. If a revision is material, we will
          provide at least 30 days' notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p className="mb-6">
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </p>

        <h2 className="mb-2 text-lg font-bold">Contact Us</h2>
        <p className="mb-6">
          If you have any questions about these Terms, please contact us at
          {' '}
          <span className="mb-4 underline">
            <a
              href={
                'mailto:privacy@pairprogram.com?subject=Privacy - PairProgram.com'
              }
            >
              support@pairprogram.com
            </a>
          </span>
          .
        </p>

        <p className="mb-4 font-bold">Last Updated: February 21, 2023.</p>
      </main>
    </div>
  );
};
