import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "@/assets/logos/logo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cn } from "@/utils";
import {
  Avatar,
  AvatarImage,
  Button,
  buttonVariants,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from "@/components/ui";
import FeedbackForm from "@/components/feedback-form";
import { AvatarDropdown } from "@/components/nav/avatar-dropdown";
import React, { useContext, useRef } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import { Flame } from "lucide-react";
import { Icons } from "@/assets/icons";
import { AuthActions } from "@/store/actions/auth.actions";
import { useReactiveVar } from "@apollo/client";
import { hasUnreadMessagesVar } from "@/store/reactive-vars";
import { AppRoute } from "@/config/routes";
import useRequiresAuth from "@/hooks/use-requires-auth";
import { AppContext } from "@/store/context/AppContext";
import { UserAuthForm } from "@/components/user-auth-form";
import { MaybeAuthLink } from "@/components/maybe-auth-link";

enum RouteNames {
  Chats = "Chats",
  Dashboard = "Dashboard",
  Feed = "Feed",
  Projects = "Projects",
  Tasks = "Tasks",
  Users = "Users",
  Sessions = "Sessions",
  Settings = "Settings",
}

interface INavigationItem {
  name: RouteNames;
  href: string;
  icon: any;
  requiresAuth?: boolean;
  description: string;
  current: boolean;
}

const navigation: INavigationItem[] = [
  {
    name: RouteNames.Users,
    href: AppRoute.Home,
    icon: Icons.Users,
    current: false,
    requiresAuth: false,
    description: "Find other users to connect and build with",
  },
  {
    name: RouteNames.Feed,
    href: AppRoute.Feed,
    icon: Flame,
    current: true,
    requiresAuth: false,
    description: "Browse the latest updates from other users",
  },
  {
    name: RouteNames.Chats,
    href: AppRoute.Chat,
    icon: Icons.Chat,
    current: false,
    requiresAuth: true,
    description: "Text and video chat with others",
  },
  {
    name: RouteNames.Projects,
    href: AppRoute.Project,
    icon: Icons.Project,
    current: true,
    requiresAuth: true,
    description: "Showcase your projects and get feedback",
  },
  {
    name: RouteNames.Tasks,
    href: AppRoute.Task,
    icon: Icons.Task,
    current: true,
    requiresAuth: true,
    description: "Discover practical tasks to build your habit",
  }
];

const routeNamesThatDisplayNotificationIcon = [
  RouteNames.Chats
  // RouteNames.Dashboard, // TODO: wire up
  // RouteNames.Tasks // TODO: wire up
];

// function that takes current route and returns the current navigation item

function getNavigation(currentRoute: string) {
  return navigation.map((item) => {
    return {
      ...item,
      current: item.href === currentRoute
    };
  });
}

const userNavigation = [
  { name: RouteNames.Settings, href: AppRoute.Settings, icon: Icons.Settings }
];

export default function FeedNavigation() {
  const { state: auth } = useContext(AuthContext);
  const { state: app, dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch({ type: AuthActions.Logout });
  };

  function focusCreatePostContainer() {
    const createPostContainerTitleInput = document.getElementById("create-post-container-title");
    if (createPostContainerTitleInput) {
      createPostContainerTitleInput.focus();
    }
  }

  const { withAuth, isAuthenticated } = useRequiresAuth();
  const location = useLocation();
  const navigation = getNavigation(location.pathname);

  const triggerRef = useRef();
  const timeOutRef = useRef();

  const handleEnter = (isOpen) => {
    clearTimeout(timeOutRef.current);
    // @ts-ignore
    !isOpen && triggerRef.current?.click();
  };

  const handleLeave = (isOpen) => {
    // @ts-ignore
    timeOutRef.current = setTimeout(() => {
      // @ts-ignore
      isOpen && triggerRef.current?.click();
      // @ts-ignore
    }, 120);
  };

  return (
    <Disclosure as="nav"
                className="top-0 sticky z-50 bg-scale-25 border-b border-b-scale-300 shadow w-full items-center justify-center">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-5xl px-4">
            <div className="flex h-12 justify-center">
              <div className="flex items-center justify-between w-full">
                <div className="-ml-2 mr-2 lg:hidden justify-start">
                  {/* Mobile menu button */}
                  <Disclosure.Button
                    className="relative inline-flex items-center justify-center rounded-md p-2 text-scale-600 hover:bg-scale-200 hover:text-scale-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 justify-center md:justify-start">
                  <Link to={AppRoute.Home}>
                    <div className={"h-7 -ml-4 sm:-ml-8 md:-ml-0 "}>
                      <Logo />
                      {/*<img className={'h-6 w-full shrink-0'} src={'/codesnippet.png'}/>*/}
                    </div>
                  </Link>
                </div>
                <div className="lg:hidden justify-end">
                  <AvatarDropdown />
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                  {
                    navigation.map((item, key) => {
                      const CurrentIcon = item.icon;
                      const shouldAddNotificationIconIfHasUnreadMessages = routeNamesThatDisplayNotificationIcon.includes(item.name);
                      const hasUnreadMessages: boolean = useReactiveVar(hasUnreadMessagesVar);

                      return (
                        <MaybeAuthLink
                          requiresAuth={item.requiresAuth!!}
                          key={key}
                          to={item.href}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <HoverCard>
                            <Disclosure.Button>
                              <HoverCardTrigger>
                                <div
                                  key={key}
                                  className={cn(
                                    buttonVariants({ variant: "ghost", size: "sm" }),
                                    "text-xs font-mono font-medium",
                                    item.current ? "bg-scale-100 text-scale-700" : "text-scale-500 hover:bg-scale-100/80 hover:text-scale-900"
                                  )}
                                >
                                  <div className="relative flex">
                                    {shouldAddNotificationIconIfHasUnreadMessages && hasUnreadMessages && (
                                      <div className={"absolute -top-1 -left-0"}>
                                        <div className={"w-2 h-2 bg-red-500 rounded-full"} />
                                      </div>
                                    )}
                                    <CurrentIcon className={cn(
                                      "h-4 w-4 text-scale-400"
                                      // "mr-2"
                                    )} aria-hidden="true" />
                                    <span className={'text-xs ml-1'}>{item.name}</span>
                                  </div>
                                </div>
                              </HoverCardTrigger>
                            </Disclosure.Button>
                            <HoverCardContent className="bg-white min-w-fit w-48 p-2">
                              <div
                                className="flex items-center justify-center space-x-2 w-full bg-scale-100 p-2 rounded-lg">
                                <CurrentIcon className={cn(
                                  "h-4 w-4 text-scale-400"
                                )} aria-hidden="true" />
                                <span className={"text-sm font-semibold font-mono text-scale-600"}>{item.name}</span>
                              </div>
                              <div className="w-full py-2">
                                <p className={"text-sm text-scale-400 text-center"}>{item.description}</p>
                              </div>
                            </HoverCardContent>
                          </HoverCard>
                        </MaybeAuthLink>
                      );
                    })}
                </div>
              </div>
              <div className="hidden lg:flex items-center justify-end w-full">

                <div className="hidden lg:ml-4 lg:flex lg:items-center">

                  <div className={cn(
                    "flex space-x-4 items-center",
                    isAuthenticated ? "lg:space-x-6" : "lg:space-x-3",
                  )}>
                    <div className="">
                      <Button
                        onClick={() => withAuth(() => {
                          navigate(AppRoute.Home);
                          setTimeout(() => {
                            focusCreatePostContainer();
                          }, 100);
                        })}
                        variant={"default"}
                        size={"sm"}
                        className={"font-mono text-xs flex-shrink-0"}
                      >
                        <div className="flex items-center">
                          {/*<Icons.Add className={"mr-1 h-4 w-4"} />*/}
                          <Flame className="md:mr-1 h-3 w-3" aria-hidden="true" />
                          <span className={"hidden md:block"}>Share</span>
                        </div>
                      </Button>
                    </div>
                    {/*<DarkModeToggle />*/}
                    {
                      auth?.isAuthenticated && (
                        <FeedbackForm />
                      )
                    }
                    {
                      auth?.isAuthenticated ? (
                          <AvatarDropdown />
                      ) : (
                        <UserAuthForm variant={'outline'} className={'bg-white'} text={"Login"} />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item, key) => {
                const CurrentIcon = item.icon;
                const shouldAddNotificationIconIfHasUnreadMessages = routeNamesThatDisplayNotificationIcon.includes(item.name);
                const hasUnreadMessages: boolean = useReactiveVar(hasUnreadMessagesVar);
                return (
                  <MaybeAuthLink
                    requiresAuth={item.requiresAuth!!}
                    key={key}
                    to={item.href}>
                    <Disclosure.Button
                      key={item.name}
                      as="button"
                      className={cn(
                        item.current ? "bg-scale-200 text-scale-600" : "text-scale-500 hover:bg-scale-100 hover:text-scale-600",
                        "block rounded-md px-3 py-2 text-sm font-mono font-medium w-full"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <div className="flex space-x-1">
                        <CurrentIcon className="mr-3 h-4 w-4 text-scale-400" aria-hidden="true" />
                        <span>{item.name}</span>
                      </div>
                    </Disclosure.Button>
                  </MaybeAuthLink>
                );
              })}
            </div>
            {isAuthenticated ? (
              <div className="border-t border-scale-200 pb-3 pt-4">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <Avatar className={"flex items-center"}>
                      <AvatarImage
                        className="items-center h-8 w-8 rounded-full"
                        src={auth?.user?.avatarUrl}
                        alt={`${auth?.user?.username}'s Avatar` ?? "User Avatar"} />
                    </Avatar>
                  </div>
                  <div className="ml-2">
                    <div className="text-sm font-semibold text-scale-600 font-mono">{auth?.user?.email}</div>
                  </div>
                </div>

                <div className="mt-3 space-y-1 px-2 sm:px-3">
                  {userNavigation.map((item, key) => {
                    const CurrentIcon = item.icon;
                    return (
                      <Link
                        key={key}
                        to={item.href}
                        className={cn(
                          "text-scale-500 hover:bg-scale-100 hover:text-scale-600",
                          "block rounded-md px-3 py-2 text-sm font-mono font-medium"
                        )}
                      >
                        <Disclosure.Button>
                          <div className="flex space-x-1">
                            <CurrentIcon className="mr-3 h-4 w-4 text-scale-400" aria-hidden="true" />
                            <span>{item.name}</span>
                          </div>
                        </Disclosure.Button>
                      </Link>
                    );
                  })}
                  <div
                    onClick={() => handleLogout()}
                    className={cn(
                      "cursor-pointer text-scale-500 hover:bg-scale-100 hover:text-scale-600",
                      "block rounded-md px-3 py-2 text-sm font-mono font-medium"
                    )}
                  >
                    <div className="flex space-x-1">
                      <Icons.Logout className={"mr-2 h-4 w-4"} />
                      <span>Logout</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="border-t border-scale-200 pb-3 pt-4">
                <div className="mt-3 space-y-4 px-2 sm:px-3">
                  <UserAuthForm size={'lg'} variant={'outline'} className={'text-lg font-heading'} text={"Login"} />
                  <MaybeAuthLink
                    // make sure modal is always displayed
                    // when user is not authenticated,
                    // and they click on the join for access button
                    requiresAuth={true}
                    to={"/"}
                    className={cn(
                      "w-full",
                      buttonVariants({ variant: "secondary", size: "lg" }),
                    )}
                  >
                    <div className="flex space-x-1 items-center">
                      <Icons.GitHub className={"mr-2 h-4 w-4"} />
                      <h3>Join with GitHub</h3>
                    </div>
                  </MaybeAuthLink>
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
