import { Session } from "@pairprogram/graphql";
import { Link } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage, Badge } from "@/components/ui";
import { getDifferenceInMinutes, getRelativeTime } from "@/utils/datetime.utils";
import { cn } from "@/utils";
import React from "react";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Icons } from "@/assets/icons";
import { AppRoute } from "@/config/routes";

dayjs.extend(duration);
dayjs.extend(utc);


interface SessionItemProps {
  session: Session;
}

export default function SessionItem({ session }: SessionItemProps) {

  const sessionDurationInMinutes = getDifferenceInMinutes(session?.startedAt, session?.endedAt);
  return (
    // <div>
    //   <p>{session?.id}</p>
    //   <p>{session?.post?.title}</p>
    //   {
    //     session?.userSessions?.map((userSession, i) => {
    //       console.log('userSession', userSession);
    //       return (
    //         <div key={i}>
    //           <p>{userSession?.user?.id}</p>
    //           <p>{userSession?.user?.username}</p>
    //           <p>{userSession?.user?.avatarUrl}</p>
    //           <p>{userSession?.joinedAt}</p>
    //           <p>{userSession?.leftAt}</p>
    //         </div>
    //       )
    //   })}
    //   <br/>
    //   {/*<p>{session?.post?.creator?.avatarUrl}</p>*/}
    //   {/*<p>{session}</p>*/}
    // </div>
    <Link className={"cursor-pointer"} key={`link-${session.id}`} to={`${AppRoute.Post}/${session?.post?.id}`}>
      <li key={session.id}
          className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 border-b border-b-scale-950/5 dark:border-b-scale-25/5">
        {/*<LivePing isLive={isLiveNow} />*/}

        <div className="min-w-0 flex-auto">


          {/* mobile title display */}
          {/*<div className="flex sm:hidden items-center gap-x-3">*/}
          {/*  <div className="min-w-0 text-sm font-extrabold leading-6 text-scale-950 dark:text-scale-25">*/}

          {/*    <div>*/}
          {/*      <h2 title={post?.title || undefined} className="truncate font-bold">{post.title}</h2>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*/!* end mobile title display *!/*/}

          {/* Desktop title display */}
          <div className="flex items-center gap-x-3">
            <div className="min-w-0 text-sm leading-6 text-scale-950 dark:text-scale-25">
              <div className="flex gap-x-2">
                <h2 title={session?.post?.title || undefined}
                    className="whitespace-nowrap truncate font-bold">{session?.post?.title}</h2>
              </div>
            </div>
          </div>
          {/* end desktop title display */}


          <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-scale-400">
            {
              session?.userSessions?.map((userSession, i) => {
                return (
                  <div className="inline-flex items-center gap-x-2">
                    <Link to={`${AppRoute.User}/${userSession?.user?.username}`} className="flex gap-x-2">
                      <Avatar className={"h-4 w-4"}>
                        <AvatarImage src={userSession?.user?.avatarUrl as string}
                                     alt={`${userSession?.user?.username as string ?? "Anonymous User"}'s Avatar`} />
                        <AvatarFallback></AvatarFallback>
                      </Avatar>
                    </Link>
                    <h3
                      className="shrink-0 font-bold text-scale-600 dark:text-scale-200">{userSession?.user?.username}</h3>
                  </div>
                );
              })
            }
            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-scale-300">
              <circle cx={1} cy={1} r={1} />
            </svg>
            <p className="shrink-0 min-w-fit">{getRelativeTime(session?.post?.createdAt)}</p>
            {/*<svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-scale-300">*/}
            {/*  <circle cx={1} cy={1} r={1} />*/}
            {/*</svg>*/}
            {/*<p className="max-w-xs grow truncate">{session?.post?.description}</p>*/}
          </div>
        </div>

        <div className="inline-flex items-center space-x-1 text-scale-950 dark:text-scale-25 text-xs">
          <Icons.Users className={'h-4 w-4 '} />
          <div>
          {`${session?.userSessions?.length} ${session?.userSessions?.length! === 1 ? "participant" : "participants"}`}
          </div>
        </div>

        <div className={'inline-flex items-center space-x-1 text-scale-950 dark:text-scale-25 text-xs'}>
          <div>
            <Icons.Duration className={'h-4 w-4'} />
          </div>
          <div>{`${sessionDurationInMinutes} minute session`}</div>
        </div>

        <div
          className={cn(
            "text-brand-400 bg-brand-400/10 ring-brand-400/30",
            "rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset"
          )}
        >
          {getRelativeTime(session?.endedAt)}
        </div>

        <ChevronRightIcon className="h-5 w-5 flex-none text-scale-400" aria-hidden="true" />
      </li>
    </Link>
  );
}
