import { Chat, useGetChatsForUserLazyQuery, useGetChatsForUserQuery } from "@pairprogram/graphql";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { authStore } from "@/store";
import { hasUnreadMessagesVar } from "@/store/reactive-vars";


function checkChatsForUnreadMessages(chats: Chat[], currentUserId: string) {
  let hasUnreadMessages = false;
  hasUnreadMessages = chats?.some((chat) => {
    const mostRecentMessageIsUnread = chat?.MostRecentMessage?.data?.Status?.data?.isRead === false;
    const mostRecentMessageIsSentToLoggedInUser = (chat?.MostRecentMessage?.data?.Status?.data?.Recipient?.data?.id === currentUserId) && currentUserId;
    return mostRecentMessageIsUnread && mostRecentMessageIsSentToLoggedInUser;
  });
  hasUnreadMessagesVar(hasUnreadMessages);
}


export const useGetChatsForUser = () => {
  const { getAccessToken, getRefreshToken } = authStore();
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  const { data, error, loading } = useGetChatsForUserQuery({
    fetchPolicy: "cache-and-network",
    skip: !accessToken || !refreshToken
  });

  const [getChatsForUserLazy, { data: lazyData }] = useGetChatsForUserLazyQuery({
    onCompleted: (data) => {
      if (data?.GetChatsForUser?.error) {
        toast.error("Error getting chats");
      }
    }
  });

  useEffect(() => {
    // checking `data` will properly update cache in real-time
    if (data?.GetChatsForUser?.error) {
      toast.error("Error getting chats");
    }
  }, [data]);

  let chats: Chat[] = [];
  if (data?.GetChatsForUser?.data) {
    chats = data.GetChatsForUser.data.map((chat) => chat) as Chat[];
  }
  // if (lazyData?.GetChatsForUser?.data) {
  //   console.log('adding lazy chat data');
  //   chats = lazyData.GetChatsForUser.data.map((chat) => chat) as Chat[];
  // }

  return {
    chats,
    loading,
    error,
    getChatsForUserLazy,
    checkChatsForUnreadMessages
  };
};
