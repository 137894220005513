import { Moon, Sun } from "lucide-react";
import { Toggle } from "@/components/ui/toggle";
import * as React from "react";
import { useContext, useState } from "react";
import { Theme } from "@/index";
import { AppContext } from "@/store/context/AppContext";
import { AppActions } from "@/store/actions/app.actions";

export const DarkModeToggle = () => {
  const { dispatch } = useContext(AppContext);
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    const currentTheme = localStorage.getItem(Theme.Theme);
    if (currentTheme === Theme.Light) {
      localStorage.setItem(Theme.Theme, Theme.Dark);
      document.documentElement.classList.add(Theme.Dark);
      setTheme(Theme.Dark)
      dispatch({ type: AppActions.Theme, payload: Theme.Dark })
    } else {
      dispatch({ type: AppActions.Theme, payload: Theme.Light })
      localStorage.setItem(Theme.Theme, Theme.Light);
      document.documentElement.classList.remove(Theme.Dark);
      setTheme(Theme.Light)
    }
  };

  return (
    <Toggle
      className={'border border-scale-300 dark:hover:bg-scale-800 hover:bg-scale-100 dark:border-scale-700 '}
      onClick={toggleTheme}
      variant="outline"
      size="sm"
      aria-label="Toggle dark mode"
    >
      {theme === "dark" ? (
        <Sun className={"h-4 w-4 text-scale-500 dark:text-scale-200"} />
      ) : (
        <Moon className={"h-4 w-4 text-scale-500 dark:text-scale-200"} />
      )}
    </Toggle>
  );
};
