import { useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Input, Label, Textarea } from "@/components/ui";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import useGetTags from "@/hooks/use-get-tags";
import toast from "react-hot-toast";
import { Tag, TaskDifficulty, TaskType, TaskTypeEnum, useAdminCreateTaskMutation, useAdminUpdateTaskMutation } from "@pairprogram/graphql";
import { Icons } from "@/assets/icons";
import useGetTaskTypes from "@/hooks/use-get-task-types";
import { Listbox, Transition } from "@headlessui/react";
import { cn } from "@/utils";
import { mapTaskTypeIcon } from "@/components/tasks/task-card";
import { ImageIcon, XCircle } from "lucide-react";
import FileUploadModal from "@/components/file-upload-modal";
import { capitalizeFirstLetter } from "@/utils/string.utils";
import useGetTask from "@/hooks/use-get-task";
import { AppRoute } from "@/config/routes";
import { TagType, Upload } from "@pairprogram/graphql";

export default function AdminTaskUpdatePage() {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) {
    navigate(AppRoute.Home);
  }

  const { task, loading, refetch, } = useGetTask(id!);

  if (!task && !loading) {
    toast("Task not found", { id: "task-not-found" })
    navigate(AppRoute.Home)
  }

  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [estimatedMinutes, setEstimatedMinutes] = useState<number>();
  const [url, setUrl] = useState<string>();
  const [prerequisites, setPrerequisites] = useState<string>();
  const [objectives, setObjectives] = useState<string>();
  const [instructions, setInstructions] = useState<string>();
  const [taskType, setTaskType] = useState<TaskType>();
  const [difficulty, setDifficulty] = useState<TaskDifficulty>();
  const [selectedTechTags, setSelectedTechTags] = useState<Tag[]>();
  const [selectedTrackTags, setSelectedTrackTags] = useState<Tag[]>();
  const [selectedTopicTags, setSelectedTopicTags] = useState<Tag[]>();
  const [uploads, setUploads] = useState<Upload[]>();
  const [uploadedFileData, setUploadedFileData] = useState<{ key: string; url: string; } | null>(null);
  const [uploadKeys, setUploadKeys] = useState<string[]>();

  const { techTags, trackTags, topicTags } = useGetTags();
  const { taskTypes } = useGetTaskTypes();

  useEffect(() => {
    if (task?.title) {
      setTitle(task?.title);
    }

    if (task?.description) {
      setDescription(task?.description);
    }

    if (task?.estimatedMinutes) {
      setEstimatedMinutes(task?.estimatedMinutes);
    }

    if (task?.url) {
      setUrl(task?.url);
    }

    if (task?.prerequisites) {
      setPrerequisites(task?.prerequisites);
    }

    if (task?.objectives) {
      setObjectives(task?.objectives);
    }

    if (task?.instructions) {
      setInstructions(task?.instructions);
    }

    if (task?.Type) {
      setTaskType(task?.Type?.data as TaskType);
    }

    if (task?.difficulty) {
      setDifficulty(task?.difficulty as TaskDifficulty);
    }


    if (task?.Tags?.data) {
      setSelectedTechTags(task?.Tags?.data?.filter(tag => tag?.type === TagType.Technology) as Tag[]);
      setSelectedTrackTags(task?.Tags?.data?.filter(tag => tag?.type === TagType.Career) as Tag[]);
      setSelectedTopicTags(task?.Tags?.data?.filter(tag => tag?.type === TagType.Topic) as Tag[]);
    }

    if (task?.Uploads?.data) {
      setUploads(task?.Uploads?.data as Upload[])
      setUploadKeys(task?.Uploads?.data?.map(upload => upload?.key!));
    }

  }, [task?.id]);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [updateTask] = useAdminUpdateTaskMutation({
    onCompleted: (data) => {
      if (data?.AdminUpdateTask?.error) {
        console.error(data?.AdminUpdateTask?.error)
        toast.error("Error updating task", { id: "task-updated" });
        return;
      }
      if (data?.AdminUpdateTask?.data) {
        toast.success("Task updated", { id: "task-updated" });
        navigate(`/task/${data?.AdminUpdateTask?.data?.id}`);
      }
    },
    variables: {
      input: {
        id: id!,
        title: title!,
        description,
        estimatedMinutes,
        url,
        prerequisites,
        objectives,
        instructions,
        difficulty,
        taskTypeId: taskType?.id,
        uploadKeys,
        tagIds: [
          ...(selectedTechTags?.map(tag => tag?.id!) ?? []),
          ...(selectedTrackTags?.map(tag => tag?.id!) ?? []),
          ...(selectedTopicTags?.map(tag => tag?.id!) ?? [])
        ]
      }
    }
  });

  const taskDifficulties = Object.values(TaskDifficulty);

  function removeUpload(uploadKey: string) {
    setUploads(uploads?.filter((upload) => upload?.key !== uploadKey));
    setUploadKeys(uploadKeys?.filter((key) => key !== uploadKey));
  }

  useEffect(() => {
    if (uploadedFileData?.key) {
      if (uploadKeys?.length) {
        setUploadKeys([...uploadKeys, uploadedFileData.key]);
      } else {
        setUploadKeys([uploadedFileData.key]);
      }
      refetch();
    }
  }, [uploadedFileData?.key])

  return (
    <div>
      <DashboardPageHeadingSection title={"Create Task"} />
      <div className="flex flex-col space-y-4">
        <div>
          <Label htmlFor="title">Title</Label>
          <Input onChange={(e) => setTitle(e.currentTarget.value)} value={title!} id="title" />
        </div>

        <div>
          <Label htmlFor="description">Description</Label>
          <Textarea className={'bg-white'} onChange={(e) => setDescription(e.currentTarget.value)} value={description!} id="description" />
        </div>
        {/* estimated minutes*/}
        <div>
          <Label htmlFor="estimatedMinutes">Estimated Minutes</Label>
          <Input type={"number"} onChange={(e) => setEstimatedMinutes(Number(e.currentTarget.value))}
                 value={estimatedMinutes!} id="estimatedMinutes" />
        </div>

        {/* task type */}
        <div>
          <Label htmlFor="taskTypes">Task Type</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            <Listbox as="div" value={taskType} onChange={(taskTypeValue) => {
              const taskTypeObj = taskTypes.find((item) => {
                return item.value === taskTypeValue;
              });
              setTaskType(taskTypeObj!);
            }} className="flex-shrink-0">
              {({ open }) => {
                const TaskTypeIcon = mapTaskTypeIcon(taskType?.value as TaskTypeEnum);
                return (
                  <>
                    <Listbox.Label className="sr-only">Select a task type</Listbox.Label>
                    <div className="relative">
                      <Listbox.Button
                        className="relative inline-flex items-center whitespace-nowrap border border-scale-200 bg-scale-50 px-1 py-1 text-lg rounded-md font-medium text-scale-400 hover:bg-scale-100 sm:px-3">
                        <TaskTypeIcon
                          className={cn(
                            !taskType?.value ? "text-scale-300" : "text-scale-500",
                            "h-4 w-4 flex-shrink-0 sm:-ml-1"
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={cn(
                            !taskType?.value ? "" : "text-scale-900",
                            "hidden truncate sm:ml-2 sm:block"
                          )}
                        >
                      {!taskType?.value ? "Select task type" : taskType?.label}
                    </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          className="p-2 text-scale-600 absolute left-0 z-50 mt-1 max-h-64 w-60 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {taskTypes?.map((taskType: TaskType) => {
                            const CurrentPostTypeIcon = mapTaskTypeIcon(taskType.value as TaskTypeEnum);
                            return (
                              <Listbox.Option
                                key={taskType.value}
                                className={({ active }) =>
                                  cn(
                                    active ? "bg-scale-100 rounded-md" : "bg-white",
                                    "cursor-default select-none px-3 py-2"
                                  )
                                }
                                value={taskType.value}
                              >
                                <div className="flex space-x-2 items-center">
                                  <CurrentPostTypeIcon
                                    className={cn(
                                      !taskType?.value ? "text-scale-300" : "text-scale-500",
                                      "h-4 w-4 flex-shrink-0 sm:-ml-1"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="block truncate font-medium">{taskType.label}</span>
                                </div>
                              </Listbox.Option>
                            );
                          })}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                );
              }}
            </Listbox>
          </div>
        </div>

        {/* task difficulty */}
        <div>
          <Label htmlFor="difficulty">Difficulty</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            <Listbox as="div" value={difficulty} onChange={(difficulty) => {
              const taskDifficultyObj = taskDifficulties.find((item) => {
                return item === difficulty;
              });
              setDifficulty(taskDifficultyObj!);
            }} className="flex-shrink-0">
              {({ open }) => {
                return (
                  <>
                    <Listbox.Label className="sr-only">Select a task difficulty</Listbox.Label>
                    <div className="relative">
                      <Listbox.Button
                        className="relative inline-flex items-center whitespace-nowrap border border-scale-200 bg-scale-50 px-1 py-1 text-lg rounded-md font-medium text-scale-400 hover:bg-scale-100 sm:px-3">
                        <span
                          className={cn(
                            !difficulty ? "" : "text-scale-900",
                            "hidden truncate sm:block"
                          )}
                        >
                      {!difficulty ? "Select task difficulty" : capitalizeFirstLetter(difficulty)}
                    </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          className="p-2 text-scale-600 absolute left-0 z-50 mt-1 max-h-64 w-60 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {taskDifficulties?.map((difficulty: TaskDifficulty) => {
                            return (
                              <Listbox.Option
                                key={difficulty}
                                className={({ active }) =>
                                  cn(
                                    active ? "bg-scale-100 rounded-md" : "bg-white",
                                    "cursor-default select-none px-3 py-2"
                                  )
                                }
                                value={difficulty}
                              >
                                <div className="flex space-x-2 items-center">
                                  <span
                                    className="block truncate font-medium">{capitalizeFirstLetter(difficulty)}</span>
                                </div>
                              </Listbox.Option>
                            );
                          })}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                );
              }}
            </Listbox>
          </div>
        </div>


        <div>
          <Label htmlFor="techTags">Tech Tags</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            {techTags?.map((tag) => {
              return (
                <Button
                  size={"xs"}
                  className={"text-xs font-mono"}
                  key={tag.id}
                  variant={selectedTechTags?.map(t => t?.id).includes(tag.id) ? "primary" : "default"}
                  onClick={() => {
                    if (selectedTechTags?.includes(tag)) {
                      setSelectedTechTags(selectedTechTags?.filter((t) => t.id !== tag.id));
                    } else {
                      if (selectedTechTags?.length) {
                        setSelectedTechTags([...selectedTechTags, tag]);
                      } else {
                        setSelectedTechTags([tag]);
                      }
                    }
                  }}
                >
                  {tag.label}
                </Button>
              )
            })}
          </div>
        </div>

        <div>
          <Label htmlFor="trackTags">Track Tags</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            {trackTags?.map((tag) => (
              <Button
                size={"xs"}
                className={"text-xs font-mono"}
                key={tag.id}
                variant={selectedTrackTags?.map(t => t?.id)?.includes(tag.id) ? "primary" : "default"}
                onClick={() => {
                  if (selectedTrackTags?.includes(tag)) {
                    setSelectedTrackTags(selectedTrackTags?.filter((t) => t.id !== tag.id));
                  } else {
                    if (selectedTrackTags?.length) {
                      setSelectedTrackTags([...selectedTrackTags, tag]);
                    } else {
                      setSelectedTrackTags([tag]);
                    }
                  }
                }}
              >
                {tag.label}
              </Button>
            ))}
          </div>
        </div>

        <div>
          <Label htmlFor="topicTags">Topic Tags</Label>
          <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
            {topicTags?.map((tag) => (
              <Button
                size={"xs"}
                className={"text-xs font-mono"}
                key={tag.id}
                variant={selectedTopicTags?.map(t => t?.id)?.includes(tag.id) ? "primary" : "default"}
                onClick={() => {
                  if (selectedTopicTags?.includes(tag)) {
                    setSelectedTopicTags(selectedTopicTags?.filter((t) => t.id !== tag.id));
                  } else {
                    if (selectedTopicTags?.length) {
                      setSelectedTopicTags([...selectedTopicTags, tag]);
                    } else {
                      setSelectedTopicTags([tag]);
                    }
                  }
                }}
              >
                {tag.label}
              </Button>
            ))}
          </div>
        </div>

        <div>
          <Label htmlFor="file">Existing Files</Label>
          <div className={'flex gap-4 flex-wrap'}>
            {
              uploads?.map((upload, i) => {
                console.log(`upload ${i}`, upload)
                return (
                  <div className={"relative w-fit"}>
                    <div className="relative">
                      <XCircle
                        className={"cursor-pointer bg-scale-100 rounded-full absolute -top-1 -right-1 h-4 w-4 text-scale-400"}
                        aria-hidden="true" onClick={() => removeUpload(upload?.key!)} />
                      <img src={upload?.signedUrl!} alt={"Uploaded file"}
                           className={"rounded-md max-h-12 max-w-[140px] object-contain h-full border-2 border-scale-100/90 shadow"} />
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div>
          <Label htmlFor="file">File</Label>
          <div>
            {
              !uploadedFileData ? (
                <Button
                  onClick={() => setIsUploadModalOpen(true)}
                  variant={"outline"}
                  size={"sm"}
                  className={"font-mono text-xs px-1 py-1"}
                >
                  <ImageIcon className={"h-5 w-5 text-scale-500"} aria-hidden="true" />
                </Button>
              ) : (
                <div className={"relative w-fit"}>
                  <div className="relative">
                    <XCircle
                      className={"cursor-pointer bg-scale-100 rounded-full absolute -top-1 -right-1 h-4 w-4 text-scale-400"}
                      aria-hidden="true" onClick={() => {
                        removeUpload(uploadedFileData.key);
                        setUploadedFileData(null)
                      }} />
                    <img src={uploadedFileData.url} alt={"uploaded file"}
                         className={"rounded-md max-h-12 max-w-[140px] object-contain h-full border-2 border-scale-100/90 shadow "} />
                  </div>
                </div>
              )
            }
          </div>
        </div>

        <div>
          <Label htmlFor="url">URL</Label>
          <Input onChange={(e) => setUrl(e.currentTarget.value)} value={url!} id="url" />
        </div>
        <div>
          <Label htmlFor="prerequisites">Prerequisites</Label>
          <Textarea className={"bg-white"} onChange={(e) => setPrerequisites(e.currentTarget.value)}
                    value={prerequisites!} id="prerequisites" />
        </div>
        <div>
          <Label htmlFor="objective">Objectives</Label>
          <Textarea className={"bg-white"} onChange={(e) => setObjectives(e.currentTarget.value)} value={objectives!}
                    id="objectives" />
        </div>
        <div>
          <Label htmlFor="instructions">Instructions</Label>
          <Textarea className={"bg-white"} onChange={(e) => setInstructions(e.currentTarget.value)}
                    value={instructions!}
                    id="instructions" />
        </div>
        <Button
          onClick={() => updateTask()}
          className={"flex items-center text-xs font-mono space-x-2"}
          variant={"default"}
          size={"lg"}
        >
          <Icons.Edit className={"w-4 h-4"} />
          <span>Update task</span>
        </Button>
      </div>
      {
        isUploadModalOpen && (
          <FileUploadModal
            filePrefix={"task"}
            setFileUploadData={setUploadedFileData}
            open={isUploadModalOpen}
            setOpen={setIsUploadModalOpen}
          />
        )
      }
    </div>
  );
}
