import { TaskFilterEnum, TaskSortEnum, useGetTasksQuery } from "@pairprogram/graphql";

export default function useGetTasks({ limit = 10, offset = 0, sort = TaskSortEnum.Latest, filter = TaskFilterEnum.All }) {
  const { data, loading, error, fetchMore } = useGetTasksQuery({
    variables: {
      input: {
        limit,
        offset,
        sort,
        filter
      }
    }
  });

  return {
    tasks: data?.GetTasks?.data,
    loading,
    error,
    fetchMore,
  };
}
