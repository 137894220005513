import { AuthActions } from "@/store/actions/auth.actions";
import { Action } from "@/store/reducers/index";
import { authStore } from "@/store";
import { AuthState } from "@/store/initial-state";
import { AppRoute } from "@/config/routes";

const { setAccessToken, setRefreshToken, removeAuthState } = authStore();
const authReducer = (state: AuthState, action: Action) => {
  switch (action.type) {
    case AuthActions.Login:
      // sentryLoginUser({ email: state?.user?.email });
      // sentrySetAuthContext(state);
      return {
        ...state,
        isAuthenticated: true,
      };
    case AuthActions.Logout:
      // sentryLogoutUser();
      removeAuthState();
      window.location.href = AppRoute.Home;
      window.location.reload();
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    case AuthActions.Account:
      return {
        ...state,
        user: action.payload,
      };
    // case USER_ADMIN:
    //   return {
    //     ...state,
    //     isAdmin: true,
    //   };
    // case USER_ACCESS_TOKEN:
    //   setAccessToken(action.payload);
    //   return {
    //     ...state,
    //     accessToken: action.payload,
    //   };
    // case USER_REFRESH_TOKEN:
    //   setRefreshToken(action.payload);
    //   return {
    //     ...state,
    //     refreshToken: action.payload,
    //   };
    default:
      return state;
  }
};

export default authReducer;
