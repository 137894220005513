import { ReactNode } from "react";
import Breadcrumbs from "@/components/nav/breadcrumbs";
import { cn } from "@/lib/utils";
import { LucideIcon } from "lucide-react";

interface DashboardPageHeadingProps {
  title?: string;
  description?: string;
  Icon?: LucideIcon | null;
  children?: ReactNode;
}

export default function DashboardPageHeadingSection({ title = "", description, Icon = null, children }: DashboardPageHeadingProps) {
  return (
    <div className={cn(
      'bg-transparent max-w-full overflow-x-hidden',
    )}>
      <div>
        <Breadcrumbs />
      </div>
      <div className="my-2 md:flex md:items-center md:justify-between">
        <div className="space-x-2 inline-flex flex-row justify-center items-center">
          {
            Icon && (
              <div className="flex-shrink-0">
                <Icon className="h-10 w-10 text-scale-900 dark:text-scale-50" />
              </div>
            )
          }
          {
            Boolean(title) && (
              <h2 title={title}
                  className="max-h-fit min-h-[2.5rem] shrink-1 sm:shrink-0 max-w-4xl text-2xl font-bold leading-7 text-scale-950 dark:text-scale-25 sm:truncate sm:text-3xl sm:tracking-tight">
                {title}
              </h2>
            )
          }
          {
            description && (
              <div
                className={"flex space-x-2 text-sm sm:text-lg text-scale-500 dark:text-scale-400 divide-x divide-scale-300"}>
                <div>
                  {/*  keep this for the divider */}
                </div>
                <p className="pl-4 max-w-2xl">
                  {description}
                </p>
              </div>
            )
          }
        </div>
        {
          children && (
            <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
            { children }
            </div>
          )
        }
      </div>
    </div>
  );
}
