import { TaskType, useGetTaskTypesQuery } from "@pairprogram/graphql";
import { useState } from "react";
import toast from "react-hot-toast";

export default function useGetTaskTypes() {
  const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
  useGetTaskTypesQuery({
    onCompleted: (data) => {
      if (data?.GetTaskTypes?.error) {
        toast.error("Error getting task types", { id: "get-task-types" });
      }
      if (data?.GetTaskTypes?.data) {
        setTaskTypes(data?.GetTaskTypes?.data as TaskType[]);
      }
    }
  });

  return {
    taskTypes
  };
}
