import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Location, NavigateFunction } from "react-router-dom";
import { AppRoute } from "@/config/routes";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

interface NavigateParams {
  userLoggedIn: boolean,
  navigate: NavigateFunction,
  location: Location
}

export function navigateAccordingly({ userLoggedIn, navigate, location }: NavigateParams): void {
  if (userLoggedIn) {
    if (location?.pathname.startsWith(AppRoute.Home)) {
      // display marketing page
      // when user clicks on logo
      // and is already on dashboard page
      return navigate(AppRoute.Home);
    } else {
      // display dashboard
      // when user clicks on logo
      // and is already on marketing page
      return navigate(AppRoute.Home);
    }
  } else {
    return navigate(AppRoute.Home);
  }
}
