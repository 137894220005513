import React, { useState } from "react";

const ImageWithSkeleton = ({ src, alt, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      {...rest}
    >
      {isLoading && (
        <div className={"rounded-lg"} style={{ width: "100%", height: "100%", backgroundColor: "#ccc" }}>
          {/* Skeleton Placeholder */}
          <div
            className="rounded-lg animate-pulse bg-gradient-to-br from-scale-100 via-scale-300 to-scale-200 w-full h-full"></div>
        </div>
      )}
      <img
        className={"rounded-lg"}
        src={src}
        alt={alt}
        onLoad={() => setIsLoading(false)}
        style={{
          display: isLoading ? "none" : "block",
          width: "100%",
          height: "auto",
          objectFit: "cover" // Optional, for better control over image scaling
        }}
      />
    </div>
  );
};

export default ImageWithSkeleton;
