import Balancer from "react-wrap-balancer";
import MarkdownRenderer from "@/components/markdown-renderer";
import { Icons } from "@/assets/icons";
import DefaultBanner from "@/assets/images/backgrounds/pairprogram-banner-commits.jpg";
import { UserAuthForm } from "@/components/user-auth-form";
import React from "react";

export default function ManifestoPage() {
  const beliefs = [
    {
      title: "1% better every day.",
      description: "Big goals are achieved through small wins. Whether you shipped a new feature, read an article, or pushed a commit to a README to keep your streak alive -- we celebrate every win."
    },
    {
      title: "To go far, go together.",
      description: "We believe in the transformative power of collaboration. By connecting developers, we're not just building code; we're building careers, confidence, and a community."
    },
    {
      title: "We learn by doing.",
      description: "We believe that the best way to learn is by doing. Whether you're building a new project, learning a new technology, or working on a new skill, working on real-world projects is the best way to apply what you learn."
    },
    {
      title: "A growth mindset will always win.",
      description: "We believe that if we don't know something, we can absolutely learn it. We're not afraid to ask questions, and we're not afraid to fail. We're here to help one another grow, and to build the habits we need to succeed."
    }
    // {
    //   title: "Habits drive success.",
    //   description: "We believe that the best way to achieve our goals is to build habits that support them. We're here to help you build the habits you need to succeed.",
    // },
    // {
    //   title: "Lifelong learning is the game.",
    //   description: "Technology is ever-changing. Seek enjoyment in the process of learning, and you'll never stop growing. We're here to help you find the joy in learning, and to help you build the habits you need to succeed.",
    // },
    // {
    //   title: "Showcase more than just code.",
    //   description: "The work you do as a developer is far more than just code. What about the countless articles, videos, tutorials, projects, and exercises along the way? We believe that you deserve to showcase all of it to the world.",
    // },
    // {
    //   title: "Showing up is ~~half~~ the battle.",
    //   description: "To build a habit, you have to show up. We believe that the hardest part of learning is just getting started. We're here to help you get started, and to keep you going when you feel like giving up.",
    // },
  ];
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="bg-white shadow-lg rounded-lg pb-8">
        <div>
          <div
            className="relative flex items-center justify-center rounded-md rounded-b-none h-48 py-4 px-2 bg-gradient-to-r from-scale-25 to-scale-200">
            <div
              className={"rounded-t-md text-scale-100 overflow-y-auto absolute inset-0 h-full w-full bg-no-repeat bg-center bg-cover py-16"}
              style={{ backgroundImage: `url(${DefaultBanner})` }}
            >
              <div className="flex flex-col justify-center items-center w-full h-full">
                <div>
                  <Icons.BrandIcon className={"h-12 w-full text-black"} />
                </div>
                <h1 className="text-3xl tracking-widest uppercase font-black text-scale-700 text-center"><Balancer>The
                  Pairprogram<br/><span
                    className={"highlight highlight-variant-1 highlight-amber-200"}>Manifesto</span></Balancer>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className={"p-8 w-full"}>
          <ul className={"py-12 space-y-12"}>
            {
              beliefs.map((belief, index) => {
                return (
                  <li key={index} className="space-y-2">
                    {/*<h2 className="text-2xl font-bold text-scale-600">{belief.title}</h2>*/}
                    <MarkdownRenderer
                      className={"prose-h1:text-2xl prose-h1:text-scale-600/90 prose-h1:font-mono prose-h1:font-semibold"}
                      markdownText={`# ${belief.title}`} />
                    <p className="text-scale-500/80 text-lg">{belief.description}</p>
                  </li>
                );
              })
            }
          </ul>
        </div>
        <div className="px-8 pb-12 w-full">
          <UserAuthForm className={"h-12 w-full font-heading text-sm"} variant={"secondary"}
                        text={"Join our community"} />
        </div>
        <div>
          <Icons.Logo className={"h-12 w-full text-black"} />
        </div>
      </div>
    </div>
  );
}
