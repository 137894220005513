import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <main className="grid min-h-screen h-full place-items-center bg-scale-100 dark:bg-scale-950 px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-brand-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight dark:text-scale-100 text-scale-900 sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-scale-600 dark:text-scale-300">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-brand-600 px-3.5 py-2.5 text-sm font-semibold text-scale-25 shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
          >
            Go back home
          </Link>
          <Link to="/support" className="text-sm font-semibold dark:text-scale-100 text-scale-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </main>
  )
}
