import React, { useCallback, useContext } from "react";
import { LiveKitRoom, useLiveKitRoom, VideoConference } from "@livekit/components-react";
import * as process from "process";
import { SessionContext } from "@/store/context/SessionContext";
import { SessionState } from "@/store/initial-state";
import { useBeforeUnload, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AuthContext } from "@/store/context/AuthContext";
import ChatBox from "@/app/dashboard/chats/chat/chat-box";
import { User } from "@pairprogram/graphql";
import { Avatar, AvatarImage } from "@/components/ui";
import { useGetChat } from "@/hooks/use-get-chat";
import { AppRoute } from "@/config/routes";


export default function SessionPage() {
  const { state: session } = useContext(SessionContext);
  const { state: auth } = useContext(AuthContext);
  const location = useLocation();
  const videoSession: SessionState = location.state;
  const navigate = useNavigate();

  const { chat } = useGetChat(videoSession?.chat?.id ?? "");

  if (!videoSession?.sessionToken) {
    toast.error("Unable to join session");
    navigate(AppRoute.Home);
  }

  const wsURL = process.env.REACT_APP_VIDEO_SESSION_WS_URL;


  // join chat participant usernames if more than two, otherwise use User1 & User2 syntax
  // concat date in MM/DD/YYYY format at end
  let roomTitle: string = (chat?.Participants?.data && chat?.Participants?.data?.length > 2 ?
    chat?.Participants?.data?.map(p => p?.username).join(", ") :
    chat?.Participants?.data?.map(p => p?.username).join(" & ")) ?? "";

  roomTitle = roomTitle ? `${roomTitle} - ${new Date().toLocaleDateString()}` : `Pair Programming Session - ${new Date().toLocaleDateString()}`;


  const { room, htmlProps } = useLiveKitRoom({
    token: videoSession?.sessionToken ?? "",
    serverUrl: wsURL,
    title: roomTitle ?? "",
    options: {
      dynacast: true,
      adaptiveStream: true,
      disconnectOnPageLeave: true,
      publishDefaults: {
        stopMicTrackOnMute: true
      },
      videoCaptureDefaults: {
        facingMode: "user"
      }
    }
  });

  const chatParticipant: User = videoSession?.chat?.Participants?.data?.[0] as User;

  const handleDisconnect = async () => {
    toast(`Disconnected from session`, { icon: "👋" });
    room?.disconnect().catch(err => console.log("Error disconnecting from room", err));
    await room?.localParticipant.setCameraEnabled(false);
    await room?.localParticipant.setMicrophoneEnabled(false);
    navigate(AppRoute.Home);
  };

  const handleConnect = () => {
    toast(`Connected to session`, { icon: "✅" });
  };

  return (
    <div className={"min-h-screen h-full overflow-auto bg-scale-100 dark:bg-rose-500"}>
      <div className="">
        <div className="grid grid-cols-12 rounded-lg h-full">
          <LiveKitRoom
            room={room}
            data-lk-theme="default"
            onConnected={handleConnect}
            onDisconnected={async () => await handleDisconnect()}
            token={videoSession?.sessionToken ?? ""}
            serverUrl={wsURL}
            className={"col-span-12 md:col-span-9 h-full"}
            // style={{ height: "70vh" }}
            connect={!!videoSession?.sessionToken}
            options={{
              dynacast: true,
              adaptiveStream: true,
              disconnectOnPageLeave: false,
              publishDefaults: {
                stopMicTrackOnMute: true
              },
              videoCaptureDefaults: {
                facingMode: "user"
              }
            }}
          >
            <VideoConference />
            {/*<Chat/>*/}
            <RoomMetadata />
          </LiveKitRoom>
          <div
            className="col-span-12 md:col-span-3 bg-white">
            {/*<h1 className={"text-2xl font-bold text-center text-scale-900 dark:text-scale-100 my-4"}>*/}
            {/*  {`Pair programming with ${chatParticipant?.username}`}*/}
            {/*</h1>*/}
            <div className={"flex space-x-2 items-center px-4 py-2 bg-scale-200 border border-scale-300 border-b-0"}>
              <a className={"cursor-pointer"} target={"_blank"} href={`${AppRoute.User}/${chatParticipant?.username}`}>
                <Avatar>
                  <AvatarImage src={chatParticipant?.avatarUrl!} />
                </Avatar>
              </a>
              <div className="grayscale ml-2 font-semibold text-scale-950 dark:text-scale-25">
                {chatParticipant?.username}
              </div>
            </div>
            <ChatBox
              height={"calc(100vh - 64px)"}
              className={"rounded-bl-none"}
              chat={chat!} />
          </div>
        </div>
      </div>
      <div className={"max-w-7xl justify-center w-full px-8 py-4 space-y-2"}>
        <div className="flex items-center justify-center space-x-2">
          <a className={"cursor-pointer"} target={"_blank"} href={`${AppRoute.User}/${chatParticipant?.username}`}>
            <Avatar>
              <AvatarImage src={chatParticipant?.avatarUrl!} />
            </Avatar>
          </a>
          <h1 className={"text-2xl font-bold text-center text-scale-900 dark:text-scale-100 my-4"}>
            {`Pair programming with ${chatParticipant?.username}`}
          </h1>
        </div>
        <div className="grid grid-cols-3 gap-4 py-4">
          <div className="col-span-3 sm:col-span-2">
            <p className={"font-bold py-2 text-scale-950 dark:text-scale-25"}>
              General Guidelines
            </p>
            <ul className={"list-disc list-inside space-y-2"}>
              <li className={"text-scale-950 dark:text-scale-25"}>
                <span className={"font-semibold"}>Set a goal:</span> What do you want to accomplish in this session?
              </li>
              <li className={"text-scale-950 dark:text-scale-25"}>
                <span className={"font-semibold"}>Be respectful:</span> Be kind and respectful to your pair programming
                partner.
              </li>
              <li className={"text-scale-950 dark:text-scale-25"}>
                <span className={"font-semibold"}>Be patient:</span> Be patient with your pair programming partner.
              </li>
              <li className={"text-scale-950 dark:text-scale-25"}>
                <span className={"font-semibold"}>Be present:</span> Avoid distractions and focus on the session.
              </li>
            </ul>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <div className="flex flex-col space-y-4">
              <div className={"font-bold py-2 text-scale-950 dark:text-scale-25"}>
                Participants
              </div>
              <div className={"flex space-x-2 items-center"}>
                <a className={"cursor-pointer"} target={"_blank"} href={`${AppRoute.User}/${chatParticipant?.username}`}>
                  <Avatar>
                    <AvatarImage src={chatParticipant?.avatarUrl!} />
                  </Avatar>
                </a>
                <div className="grayscale ml-2 text-scale-950 dark:text-scale-25">
                  {chatParticipant?.username}
                </div>
              </div>
              <div className={"flex space-x-2 items-center"}>
                <a className={"cursor-pointer"} target={"_blank"} href={`${AppRoute.User}/${auth?.user?.username}`}>
                  <Avatar>
                    <AvatarImage src={auth?.user?.avatarUrl!} />
                  </Avatar>
                </a>
                <div className="ml-2 text-scale-950 dark:text-scale-25">
                  {auth?.user?.username}
                </div>
              </div>
            </div>
            {/*<div className="ml-2 text-scale-950 dark:text-scale-25">*/}
            {/*  Test*/}
            {/*</div>*/}
          </div>
        </div>

      </div>
    </div>
  );
};

function RoomMetadata() {
  // const roomInfo = useRoomInfo()
  // const roomCtx = useRoomContext();
  // console.log('roomInfo', roomInfo);
  // console.log('roomCtx', roomCtx);
  //
  // useEffect(() => {
  //   console.log('numParticipants', roomCtx.numParticipants);
  // }, [roomCtx.numParticipants])

  return (
    <div></div>
  );
}
