import { Post, useGetPostQuery } from "@pairprogram/graphql";
import { useEffect } from "react";
import toast from "react-hot-toast";

export const useGetPost = (postId: string) => {
  const { data, loading, error } = useGetPostQuery({
    variables: {
      input: {
        id: postId
      }
    }
  });

  useEffect(() => {
    if (data?.GetPost?.error) {
      toast("Error fetching post", { icon: "🚨" });
    }
  }, [data]);

  const getPost = (postId: string) => {
    // Trigger the query with the new postId
    // You could add additional logic here if needed
    useGetPostQuery({
      variables: {
        input: {
          id: postId
        }
      }
    });
  };

  const post = data?.GetPost?.data?.post as Post;
  return {
    getPost,
    post,
    loading,
    error
  };
};
