import { PostType, useGetAllPostTypesQuery } from "@pairprogram/graphql";
import { useState } from "react";
import toast from "react-hot-toast";

export const useGetPostTypes = () => {
  const [postTypes, setPostTypes] = useState<PostType[]>([] as PostType[]);

  useGetAllPostTypesQuery({
    onCompleted: (data) => {
      if (data?.GetAllPostTypes?.error) {
        toast("Error fetching post types", {
          icon: "🚨"
        });
      } else {
        setPostTypes(data?.GetAllPostTypes?.data as PostType[]);
      }
    }
  });

  return {
    postTypes
  };
};
