import { useContext } from "react";
import { Chat, GetChatsForUserDocument, GetChatsForUserQuery, useStartChatMutation } from "@pairprogram/graphql";
import { toast } from "react-hot-toast";
import { hasUnreadMessagesVar } from "@/store/reactive-vars";
import { AuthContext } from "@/store/context/AuthContext";
import mixpanel from "@/analytics";
import { AnalyticsEvent } from "@/analytics/analytics-event";


export function useStartChat(): { startChat: ({ recipientIds }) => Promise<string | undefined>; } {
  const { state: auth } = useContext(AuthContext);
  const [startChat] = useStartChatMutation();

  // Return a function that will invoke the mutation with the recipient ID
  const startChatWithRecipient = async ({ recipientIds }: {
    recipientIds: string[]
  }) => {

    const messageSenderIsAlsoTheMessageRecipient = recipientIds[0] === auth?.user?.id;

    if (messageSenderIsAlsoTheMessageRecipient) {
      toast.error("You can't start a chat with yourself.");
      return;
    }

    const chat = await startChat({
      variables: {
        input: {
          recipientIds,
        }
      },
      onCompleted: (data) => {
        mixpanel.track(AnalyticsEvent.StartedChat, {
          sender: auth?.user?.username,
          recipient: recipientIds[0] // hardcode for now
        });
      },
    });
    return chat.data?.StartChat?.data?.id;
  };

  return {
    startChat: startChatWithRecipient
  };
}
