export default function PrivacyPolicy() {
  return (
    <div className="mx-auto max-w-3xl px-4 py-8">
      <main className="container mx-auto my-8">
        <h2 className="mb-4 text-2xl font-bold">Privacy Policy</h2>
        <p className="mb-4">
          This privacy policy outlines how PairProgram.com ("we," "us," or
          "our") collects, uses, and protects your personal information. By
          using our website or any of our services, you agree to the terms
          outlined in this privacy policy.
        </p>

        <h3 className="mb-2 text-xl font-bold">Information We Collect</h3>
        <p className="mb-4">
          When you use PairProgram.com, we may collect the following
          information:
        </p>
        <ul className="ml-8 mb-4 list-disc">
          <li>
            Personal Information: We collect personal information that you
            provide to us, such as your name, email address, and any other
            information you provide when you create an account, update your
            profile, or communicate with us.
          </li>
          <li>
            Usage Information: We collect information about your use of our
            website and services, such as your IP address, browser type,
            operating system, device information, and the pages you visit.
          </li>
          <li>
            Bots Integration: We use various bots like GitHub, Slack, Discord,
            and Twitter to improve the functionality of PairProgram.com. These
            bots may collect information such as your usage data, account
            information, and any other data necessary to provide their services.
          </li>
        </ul>

        <h3 className="mb-2 text-xl font-bold">How We Use Your Information</h3>
        <p className="mb-4">
          We may use the information we collect for the following purposes:
        </p>
        <ul className="ml-8 mb-4 list-disc">
          <li>
            To provide our services and improve the functionality of
            PairProgram.com.
          </li>
          <li>
            To personalize your experience on our website and provide relevant
            content.
          </li>
          <li>
            To communicate with you about our services and respond to your
            inquiries.
          </li>
          <li>
            To protect the security and integrity of our website and prevent
            fraud or illegal activity.
          </li>
          <li>To comply with legal and regulatory requirements.</li>
        </ul>

        <h3 className="mb-2 text-xl font-bold">
          How We Protect Your Information
        </h3>
        <p className="mb-4">
          We take appropriate measures to ensure the security and
          confidentiality of your personal information. We use encryption,
          firewalls, and other security measures to protect your data from
          unauthorized access, disclosure, or alteration.
        </p>
        <p className="mb-4">
          However, please be aware that no data transmission over the internet
          or any wireless network can be guaranteed to be 100% secure.
          Therefore, we cannot guarantee the security of any information you
          transmit to us or that we store on our servers.
        </p>

        <h3 className="mb-2 text-xl font-bold">
          How We Share Your Information
        </h3>
        <p className="mb-4">
          We do not share your personal information with third parties except as
          follows:
        </p>
        <ul className="ml-8 mb-4 list-disc">
          <li>
            With service providers or business partners who help us operate our
            website or provide our services. These third-party service providers
            are contractually obligated to protect the confidentiality and
            security of your personal information and may not use it for any
            other purpose.
          </li>
          <li>
            With your consent or at your direction, such as when you authorize
            us to share your information with other users of our services.
          </li>
          <li>
            To comply with legal or regulatory requirements, such as in response
            to a court order or subpoena.
          </li>
          <li>
            In connection with a merger, acquisition, or sale of our business.
          </li>
        </ul>

        <h3 className="mb-2 text-xl font-bold">Your Rights and Choices</h3>
        <p className="mb-4">
          You may have certain rights and choices regarding the personal
          information we collect about you. These may include the right to
          access, correct, or delete your personal information, or to object to
          or restrict certain types of processing.
        </p>
        <p className="mb-4">
          To exercise any of these rights, or if you have any questions or
          concerns about our privacy practices, please contact us at the address
          provided below.
        </p>
        <p className="mb-4">
          Changes to this Privacy Policy We may update this privacy policy from
          time to time. If we make any material changes, we will provide notice
          by email or through our website. Your continued use of our website or
          services after any such changes indicates your acceptance of the
          updated privacy policy.
        </p>

        <h3 className="mb-2 text-xl font-bold">Contact Us</h3>
        <p className="mb-4">
          If you have any questions or concerns about our privacy practices or
          this privacy policy, please contact us at:
        </p>

        <p className="mb-4 font-bold">PairProgram.com</p>
        <p className="mb-4">35 Roslyn Ave</p>
        <p className="mb-4">East Providence, RI 02914</p>
        <p className="mb-4 underline">
          <a
            href={
              'mailto:privacy@pairprogram.com?subject=Privacy - PairProgram.com'
            }
          >
            privacy@pairprogram.com
          </a>
        </p>
        <p className="mb-4 font-bold">Last Updated: February 21, 2023.</p>
      </main>
    </div>
  );
};
