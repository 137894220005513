import { Post, PostFilterEnum, PostSortEnum } from "@pairprogram/graphql";
import { Button } from "@/components/ui";
import React, { useContext, useEffect, useState } from "react";
import { Icons } from "@/assets/icons";
import PostItemSkeleton from "@/components/post/post-item-skeleton";
import FeedEmpty from "@/app/dashboard/home/feed-empty";
import { DashboardContext } from "@/store/context/DashboardContext";
import { cn } from "@/utils";
import { PostCard } from "@/components/post/post-card";
import FilterSortBar, { ISortFilterItem } from "@/components/filter-sort-bar";
import useGetPosts from "@/hooks/use-get-posts";
import { FlameIcon, SquareUserRound } from "lucide-react";

export default function DashboardHome() {
  const skeletonPosts = Array.from({ length: 20 }).map((_, i) => (<PostItemSkeleton key={i} />));
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [filter, setFilter] = useState<PostFilterEnum>(PostFilterEnum.All);
  const [sort, setSort] = useState<PostSortEnum>(PostSortEnum.Latest);
  const { state: dashboard } = useContext(DashboardContext);

  const sortAndFilterItems: ISortFilterItem[] = [
    {
      type: "FILTER",
      icon: FlameIcon,
      value: PostFilterEnum.All,
      label: "All",
      current: filter === PostFilterEnum.All,
      requiresAuth: false
    },
    {
      type: "FILTER",
      icon: SquareUserRound,
      value: PostFilterEnum.MyPosts,
      label: "My Posts",
      current: filter === PostFilterEnum.MyPosts,
      requiresAuth: true
    }
    // { type: 'SORT', value: PostSortEnum.Trending, label: "Trending", current: sort === PostSortEnum.Trending },
    // { type: 'SORT', value: PostSortEnum.Latest, label: "Latest", current: sort === PostSortEnum.Latest },
  ];

  const {
    posts,
    refetch: refetchPosts,
    loading: loadingInitialPosts,
    count: postCount,
    fetchMore: fetchOlderPosts
  } = useGetPosts(limit, offset, sort, filter, dashboard.selectedTagFilters);

  useEffect(() => {
    refetchPosts();
  }, [filter, sort]);

  if (loadingInitialPosts) {
    return (
      <div className={"py-4"}>
        <FilterSortBar items={sortAndFilterItems} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} />
        <ul role="list" className={cn(
          "space-y-4"
        )}>
          {skeletonPosts}
        </ul>
      </div>
    );
  }

  if (!loadingInitialPosts && !postCount) {
    return (
      <div className={"py-4"}>
        <FilterSortBar items={sortAndFilterItems} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} />
        <FeedEmpty />
      </div>
    );
  }

  return (
    <div className={"py-4"}>
      <FilterSortBar items={sortAndFilterItems} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} />
      <ul role="list" className={cn(
        "space-y-4"
      )}>
        {
          posts?.map((post: Post, index: number) => {
            return (
              <PostCard key={index} post={post} />
            );
          })
        }
        {
          offset + limit < postCount ? (
            <li key={"load-more-posts"} className="px-4 py-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-center">
                <Button
                  className={"font-mono text-xs"}
                  size={"sm"} variant={"ghost"}
                  onClick={() => {
                    setOffset(offset + limit);
                    fetchOlderPosts({
                      variables: {
                        input: {
                          limit,
                          offset: offset + limit
                        }
                      }
                    });
                  }}
                >
                  <Icons.LoadMore className={"h-3 w-3 mr-2"} />
                  Load more
                </Button>
              </div>
            </li>
          ) : null
        }
      </ul>
    </div>
  );
}
