export default function TwoIcon(props) {
  return (

    <svg className={"text-scale-700 dark:text-scale-300 h-8 w-8"} {...props} width="100%" height="100%" viewBox="0 0 800 800"
         version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
         style={{
           fillRule: "evenodd",
           clipRule: "evenodd",
           strokeLinejoin: "round",
           strokeMiterlimit: 2
         }}>
      <path
        d="M400.005,0.005C179.088,0.005 0,179.074 0,400.01C0,620.907 179.088,799.995 400.005,799.995C620.912,799.995 800,620.907 800,400.01C800,179.074 620.912,0.005 400.005,0.005ZM400.005,25.005C607.105,25.005 775,192.882 775,400.01C775,607.101 607.105,774.995 400.005,774.995C192.896,774.995 25,607.101 25,400.01C25,192.882 192.895,25.005 400.005,25.005Z"
        style={{ fill: "currentColor"}} />
      <g transform="matrix(1.51899,0,0,1.51899,36.962,-209.873)">
        <g transform="matrix(288,0,0,288,160,501.455)">
          <path
            d="M0.07,-0L0.07,-0.08L0.316,-0.335C0.342,-0.362 0.364,-0.387 0.381,-0.407C0.398,-0.428 0.411,-0.448 0.42,-0.467C0.428,-0.486 0.432,-0.506 0.432,-0.528C0.432,-0.552 0.427,-0.573 0.415,-0.59C0.404,-0.608 0.389,-0.621 0.369,-0.631C0.349,-0.641 0.327,-0.645 0.302,-0.645C0.276,-0.645 0.254,-0.64 0.234,-0.629C0.215,-0.619 0.2,-0.604 0.19,-0.585C0.179,-0.565 0.174,-0.542 0.174,-0.516L0.069,-0.516C0.069,-0.561 0.079,-0.599 0.1,-0.632C0.12,-0.666 0.148,-0.691 0.184,-0.71C0.219,-0.728 0.26,-0.737 0.305,-0.737C0.35,-0.737 0.391,-0.728 0.426,-0.71C0.46,-0.692 0.488,-0.667 0.507,-0.636C0.527,-0.605 0.537,-0.57 0.537,-0.531C0.537,-0.504 0.532,-0.478 0.522,-0.452C0.512,-0.426 0.494,-0.397 0.47,-0.365C0.445,-0.334 0.41,-0.296 0.366,-0.251L0.221,-0.099L0.221,-0.094L0.549,-0.094L0.549,-0L0.07,-0Z"
            style={{ fillRule: "nonzero", fill: "currentColor" }} />
        </g>
      </g>
    </svg>

  );
};
