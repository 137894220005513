// import DefaultBanner from "@/assets/images/abstract.webp";
// import DefaultBanner from "@/assets/images/backgrounds/flux-gradient-blue.svg";
import DefaultBanner from "@/assets/images/gradients/mesh-gradient-29.jpg";
import { Button } from "@/components/ui";
import { Icons } from "@/assets/icons";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { set } from "react-hook-form";
import { cn } from "@/utils";

export default function FeedSidebarProfile() {
  const { state: auth } = useContext(AuthContext);
  const { pathname } = useLocation();
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    if (auth?.user?.id && loading) {
      setLoading(false);
    }
  }, [auth && auth?.user && auth?.user?.id]);

  return (
    <div className={cn(
      "group hover:outline-brand-100 hover:outline-4 hover:outline",
      "border border-scale-300",
      "rounded-lg bg-white border border-scale-300 shadow-sm")}>
      <div>
        {
          loading ? (
            <div
              className="rounded-t-lg h-24 bg-gradient-to-br from-scale-100 to-scale-300 animate-pulse dark:bg-scale-700"/>
            ) : (
            <img className="bg-scale-800 rounded-t-lg h-24 w-full object-cover"
                 src={DefaultBanner}
                 alt="Default banner" />
          )
        }
        <Icons.Logo className={cn(
          "absolute top-0 left-0 hidden lg:block lg:h-8 lg:mt-8",
          loading ? "text-scale-400": "text-white"
        )} />
        <Icons.BrandIcon className={"absolute top-0 left-0 lg:hidden h-12 mt-6 text-white"} />
      </div>
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div
          style={{ zIndex: 1 }}
            className="flex">
            {
              loading ? (
                <div className="h-24 w-24 rounded-full ring-4 ring-white sm:h-14 sm:w-14 bg-scale-200 dark:bg-scale-700 animate-pulse"></div>
              ): (
                <img
                  className="h-24 w-24 rounded-full ring-4 ring-white shadow-lg sm:h-14 sm:w-14"
                  src={auth?.user?.avatarUrl}
                  alt=""
                />
              )
            }
          </div>
          <div
            className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div
              className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
              <div className="h-8"></div>
            </div>
          </div>
        </div>
        <div className="mt-2 hidden min-w-0 flex-1 sm:block pb-8">
          {
            loading ? (
              <div
                className="rounded-md bg-scale-300 animate-pulse h-8 w-48"/>
            ): (
              <h1
                className="truncate font-mono text-2xl font-semibold text-scale-900">{auth?.user?.username}</h1>
            )
          }
          <div className="mt-2">
            {
              loading ? (
                <div className="flex space-x-1 animate-pulse h-4 w-32 text-sm bg-scale-300 rounded-md"/>
              ): (
                <div className="flex space-x-1 text-sm text-scale-500">
                  {auth?.user?.profile?.bio}
                </div>
              )
            }
          </div>

          {
            loading ? (
              <div className="pt-4 flex flex-col justify-center items-center space-y-2">

                <div className="bg-scale-200 rounded-md h-8 animate-pulse w-full" />
                <div className="bg-scale-300 rounded-md h-8 animate-pulse w-full" />
                <div className="bg-scale-100 rounded-md h-8 animate-pulse w-full" />
                <div className="bg-scale-200 rounded-md h-8 animate-pulse w-full" />
              </div>
            ) : (
              <div className="pt-4 flex flex-col justify-center items-center space-y-2">
                {
                  auth?.user?.profile?.githubUrl && (
                    <Button
                      onClick={() => window.open(auth?.user?.profile?.githubUrl)}
                      variant={"outline"}
                      size={"sm"}
                      className={"shrink-0 w-full font-mono text-xs"}
                    >
                      <Icons.GitHub className="mr-1 h-3 w-3" aria-hidden="true" />
                      <span className={""}>GitHub</span>
                    </Button>
                  )
                }

                {
                  auth?.user?.profile?.linkedInUrl && (
                    <Button
                      onClick={() => window.open(auth?.user?.profile?.linkedInUrl)}
                      variant={"outline"}
                      size={"sm"}
                      className={"shrink-0 w-full font-mono text-xs"}
                    >
                      <Icons.LinkedIn className="mr-1 h-3 w-3" aria-hidden="true" />
                      <span className={""}>LinkedIn</span>
                    </Button>
                  )
                }

                {
                  auth?.user?.profile?.personalWebsiteUrl && (
                    <Button
                      onClick={() => window.open(auth?.user?.profile?.personalWebsiteUrl)}
                      variant={"outline"}
                      size={"sm"}
                      className={"shrink-0 w-full font-mono text-xs"}
                    >
                      <Icons.Website className="mr-1 h-3 w-3" aria-hidden="true" />
                      <span className={""}>Website</span>
                    </Button>
                  )
                }

                {
                  auth?.user?.profile?.blogUrl && (
                    <Button
                      onClick={() => window.open(auth?.user?.profile?.blogUrl)}
                      variant={"outline"}
                      size={"sm"}
                      className={"shrink-0 w-full font-mono text-xs"}
                    >
                      <Icons.Blog className="mr-1 h-3 w-3" aria-hidden="true" />
                      <span className={""}>Blog</span>
                    </Button>
                  )
                }
              </div>
            )
          }
        </div>


      </div>
    </div>
  )
}
