import { useGetTaskQuery } from "@pairprogram/graphql";

export default function useGetTask(id: string) {
  const { data, loading, error, refetch } = useGetTaskQuery({
    variables: {
      input: {
        id
      }
    }
  });

  return {
    task: data?.GetTask?.data,
    refetch,
    loading,
    error
  };
}
