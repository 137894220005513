import {
  AddProjectReactionError,
  Project,
  ProjectPartsFragment,
  ProjectPartsFragmentDoc,
  ReactionType,
  RemoveProjectReactionError,
  useAddProjectReactionMutation,
  useRemoveProjectReactionMutation
} from "@pairprogram/graphql";
import toast from "react-hot-toast";

export default function useProjectReaction(project: Project) {
  const [addReaction] = useAddProjectReactionMutation({
    variables: {
      input: {
        projectId: project?.id!,
        reactionType: ReactionType.Like
      }
    },
    onCompleted: (data) => {
      if (data?.AddProjectReaction?.error) {
        if (data?.AddProjectReaction?.error === AddProjectReactionError.AlreadyExists) {
          toast.error("You have already liked this project", { icon: "😌" });
          return;
        }
      }
      if (data?.AddProjectReaction?.data) {
        toast.success("Project liked");
      }
    },
    update: (cache, { data }) => {
      if (data?.AddProjectReaction?.data) {
        const existing: ProjectPartsFragment | null = cache.readFragment({
          id: `Project:${project?.id}`,
          fragment: ProjectPartsFragmentDoc,
          fragmentName: "ProjectParts"
        });

        if (existing) {
          const updated = {
            ...existing,
            likedByCurrentUser: true,
            likeCount: (existing?.likeCount ?? 0) + 1
          };

          cache.writeFragment({
            id: `Project:${project?.id}`,
            fragment: ProjectPartsFragmentDoc,
            fragmentName: "ProjectParts",
            data: updated
          });
        }
      }
    }
  });

  const [removeReaction] = useRemoveProjectReactionMutation({
    variables: {
      input: {
        projectId: project?.id!,
        reactionType: ReactionType.Like
      }
    },
    onCompleted: (data) => {
      if (data?.RemoveProjectReaction?.error) {
        if (data?.RemoveProjectReaction?.error === RemoveProjectReactionError.Forbidden) {
          toast.error("Unable to remove reaction", { icon: "😬" });
          return;
        }
        toast.error("Error unliking project");
        return;
      }
      if (data?.RemoveProjectReaction?.data) {
        toast.success("Project unliked");
      }
    },
    update: (cache, { data }) => {
      if (data?.RemoveProjectReaction?.data) {

        const existing: ProjectPartsFragment | null = cache.readFragment({
          id: `Project:${project?.id}`,
          fragment: ProjectPartsFragmentDoc,
          fragmentName: "ProjectParts"
        });

        if (existing) {
          const updated = {
            ...existing,
            likedByCurrentUser: false,
            likeCount: (existing?.likeCount ?? 0) > 0 ? existing?.likeCount! - 1 : 0
          };

          cache.writeFragment({
            id: `Project:${project?.id}`,
            fragment: ProjectPartsFragmentDoc,
            fragmentName: "ProjectParts",
            data: updated
          });
        }
      }
    }
  });

  function toggleReaction() {
    if (project?.likedByCurrentUser) {
      removeReaction();
    } else {
      addReaction();
    }
  }

  return {
    toggleReaction
  };
}
