import React from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import { cn } from "@/utils";

export default function ChatsSkeleton() {
  return (
    <div>
      <DashboardPageHeadingSection title={"Chats"} />
      <div className="">
        <ul
          role="list"
          className={cn(
            "ring-1 ring-gray-900/5",
            "divide-y divide-scale-300 border border-scale-300 overflow-hidden bg-white shadow-sm rounded-xl"
          )}
        >
          {Array.from({ length: 5 }).map((_, i) => {
            return (
              <li
                key={i}
                className="cursor-pointer relative flex justify-between gap-x-6 px-4 py-4 hover:bg-gray-50 sm:px-6">
                <div className="flex min-w-0 gap-x-4">
                  <div className="h-12 w-12 flex-none rounded-full bg-gray-200 animate-pulse" />
                  <div className="min-w-0 flex-auto items-center flex">
                    <div className="flex flex-col space-y-2">
                      <div className="h-3 bg-gray-200 animate-pulse w-36 sm:w-72 rounded-lg" />
                      <div className="h-3 bg-gray-200 animate-pulse w-12 sm:w-32 rounded-lg" />
                    </div>
                  </div>
                </div>
                <div className="flex shrink-0 items-center gap-x-2">
                  <div className="h-4 w-4 sm:w-12 rounded-lg bg-gray-200 animate-pulse" />
                  <ChevronRightIcon className="h-5 w-5 flex-none text-gray-600" aria-hidden="true" />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
