import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import React, { useContext, useState } from "react";
import { Button, Input, Label, Textarea } from "@/components/ui";
import { Icons } from "@/assets/icons";
import ComboBox, { ComboBoxItem } from "@/components/combo-box";
import useGetTags from "@/hooks/use-get-tags";
import FileUploadModal from "@/components/file-upload-modal";
import { ImageIcon, XCircle } from "lucide-react";
import { CreateProjectError, useCreateProjectMutation } from "@pairprogram/graphql";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/config/routes";
import { AuthContext } from "@/store/context/AuthContext";

export default function ProjectCreatePage() {
  const { state: auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [implementation, setImplementation] = React.useState<string>("");
  const [tradeoffs, setTradeoffs] = React.useState<string>("");
  const [learnings, setLearnings] = React.useState<string>("");
  const [challenges, setChallenges] = React.useState<string>("");
  const [nextSteps, setNextSteps] = React.useState<string>("");
  const [projectUrl, setProjectUrl] = React.useState<string>("");
  const [githubRepoUrl, setGithubRepoUrl] = React.useState<string>("");
  const [demoVideoUrl, setDemoVideoUrl] = React.useState<string>("");

  // comma separated list of features
  const [features, setFeatures] = React.useState<string[]>([]);
  const [feature, setFeature] = React.useState<string>("");

  const { techTags } = useGetTags();
  const [selectedTechTags, setSelectedTechTags] = React.useState<ComboBoxItem[]>([]);

  const [uploadedFileData, setUploadedFileData] = useState<{ key: string; url: string; } | null>(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [uploadKeys, setUploadKeys] = useState<string[]>([]);

  function handleAddFeature() {
    if (!feature) {
      toast.error("Please enter a feature", { id: "add-feature-error"});
      return;
    }
    setFeatures([...features, feature]);
    setFeature("");
  }

  function handleRemoveFeature(index: number) {
    const newFeatures = [...features];
    newFeatures.splice(index, 1);
    setFeatures(newFeatures);
  }

  const [createProject] = useCreateProjectMutation({
    onCompleted: (data) => {
      if (data.CreateProject?.error) {
        if (data.CreateProject.error === CreateProjectError.BadRequest) {
          toast.error("Project name and description are required");
          return;
        }
        toast.error("Error creating project");
        return;
      }
      toast("Project created successfully");
      navigate(`${AppRoute.Project}/${data.CreateProject?.data?.id}`);
    },
    variables: {
      input: {
        name,
        description,
        features: features.map(f => f.trim()).join(","),
        implementation,
        tradeoffs,
        learnings,
        challenges,
        nextSteps,
        projectUrl,
        githubRepoUrl,
        demoVideoUrl,
        imageKey: uploadedFileData?.key ? uploadedFileData.key : "",
        tagIds: selectedTechTags.map(t => t.id),
        userIds: [auth?.user?.id],
        uploadKeys,
      }
    }
  })


  return (
    <div className={"py-8 max-w-5xl mx-auto"}>
      <DashboardPageHeadingSection title={"Add Project"} />
      <div className="grid grid-cols-3 space-y-8">
        <div className="col-span-3 py-12 max-w-2xl">
          <main>

            {/* Project details */}
            <div className="flex flex-col space-y-12">
              <div className={"space-y-6"}>
                <div>
                  <h2 className={"text-2xl font-semibold text-scale-700 dark:text-scale-100"}>Project Overview</h2>
                </div>

                <div className={"space-y-2"}>
                  <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Main Image</h3>
                  <p className="mt-1 text-sm leading-6 text-scale-500">
                    Images should be at least 1280x720px (16:9) to display properly on the project page.
                  </p>
                  <div>
                    {
                      !uploadedFileData ? (
                        <Button
                          onClick={() => setIsUploadModalOpen(true)}
                          variant={"outline"}
                          size={"sm"}
                          className={"font-mono text-xs px-1 py-1 bg-white"}
                        >
                          <ImageIcon className={"h-5 w-5 text-scale-500"} aria-hidden="true" />
                        </Button>
                      ) : (
                        <div className={"relative w-fit"}>
                          <div className="relative">
                            <XCircle
                              className={"cursor-pointer bg-scale-100 rounded-full absolute -top-1 -right-1 h-4 w-4 text-scale-400"}
                              aria-hidden="true" onClick={() => setUploadedFileData(null)} />
                            <img src={uploadedFileData.url} alt={"uploaded file"}
                                 className={"rounded-md max-h-12 max-w-[140px] object-contain h-full border-2 border-scale-100/90 shadow "} />
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>

                {/* Project Name */}
                <div className={"space-y-2"}>
                  <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Name (required)</h3>
                  <Input onChange={(e) => setName(e.target.value)} value={name} className={"w-full"}
                         placeholder={"GitHub API Tutorial"} />
                </div>

                {/* Project Description */}
                <div className={"space-y-2"}>
                  <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Description
                    (required)</h3>
                  <Textarea onChange={(e) => setDescription(e.target.value)} value={description}
                            className={"w-full bg-white"}
                            placeholder={"👨‍💻⚡️🛠 A Quick Tutorial on Building a Simple Web App w/ the GitHub API"} />
                </div>

                <div className={"space-y-2"}>
                  <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Project URL</h3>
                  <Input onChange={(e) => setProjectUrl(e.target.value)} value={projectUrl} className={"w-full"}
                         placeholder={"https://codesnippet.io"} />
                </div>

                {/* Github Repo Url*/}
                <div className={"space-y-2"}>
                  <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>GitHub Repo URL</h3>
                  <Input onChange={(e) => setGithubRepoUrl(e.target.value)} value={githubRepoUrl} className={"w-full"}
                         placeholder={"https://github.com/timmywheels/github-api-tutorial"} />
                </div>

                {/* Project Features */}
                <div className="flex flex-col space-y-2">
                  <h3 className={"text-base font-semibold text-scale-700 dark:text-scale-100"}>Key Features</h3>
                  <div className={"flex flex-col"}>
                    <ul>
                      {
                        features.map((feature, key) => (
                          <li
                            key={key}
                            className={"flex items-center space-x-2 rounded-lg py-1"}
                          >
                            <span className={"text-scale-600 dark:text-scale-400"}>{feature}</span>
                            <Button
                              onClick={() => handleRemoveFeature(key)}
                              variant={"ghost"}
                              size={"sm"}
                              className={"shrink-0 rounded-lg text-scale-900 px-1 py-1"}
                            >
                              <Icons.Remove className="h-4 w-4" aria-hidden="true" />
                            </Button>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                  <div className={"inline-flex space-x-2"}>

                    <Input value={feature} onChange={(e) => setFeature(e.target.value)} className={"w-full"}
                           placeholder={"Add a feature"} />
                    <Button
                      onClick={() => handleAddFeature()}
                      variant={"subtle"}
                      size={"sm"}
                      className={"shrink-0 rounded-lg text-scale-400"}
                    >
                      <Icons.Add className="h-6 w-6" aria-hidden="true" />
                    </Button>
                  </div>
                </div>
              </div>


              <div className="flex flex-col space-y-12">
                <div className={"space-y-6"}>
                  <div>
                    <h2 className={"text-2xl font-semibold text-scale-700 dark:text-scale-100"}>Technical Details</h2>
                  </div>

                  {/* Tech Stack */}
                  <div className={"space-y-2"}>
                    <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Tech Stack</h3>
                    <div className="w-full">
                      <div className={"pb-2"}>
                        <p className="mt-1 text-sm leading-6 text-scale-500">
                          Select the technologies and tools used in this project.
                        </p>
                      </div>
                      <ComboBox
                        badgeColor={"red"}
                        className={"w-full bg-white rounded-md"}
                        items={techTags as ComboBoxItem[]}
                        setSelectedItems={setSelectedTechTags}
                        selectedItems={selectedTechTags as ComboBoxItem[]}
                      />
                    </div>
                  </div>

                  <div className={"space-y-2"}>
                    <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Implementation
                      Details</h3>
                    <Textarea onChange={(e) => setImplementation(e.target.value)} value={implementation} className={"w-full bg-white"} placeholder={"Uses vanilla JS, no frameworks. Simple client-side data fetching that retrieves data from the GitHub API."} />
                  </div>

                  <div className={"space-y-2"}>
                    <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Tradeoffs</h3>
                    <Textarea onChange={(e) => setTradeoffs(e.target.value)} value={tradeoffs} className={"w-full bg-white"} placeholder={"For the sake of simplicity, the request to GitHub's API is made directly on the client, as opposed to fetching the data from our own server, which proxies the request to GitHub."} />
                  </div>


                </div>
              </div>

              <div className="flex flex-col space-y-12">
                <div className={"space-y-6"}>
                  <div>
                    <h2 className={"text-2xl font-semibold text-scale-700 dark:text-scale-100"}>Takeaways</h2>
                  </div>

                  {/* Key Learnings */}
                  <div className={"space-y-2"}>
                    <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Key Learnings</h3>
                    <Textarea onChange={(e) => setLearnings(e.target.value)} value={learnings}
                              className={"w-full bg-white"}
                              placeholder={"I learned quite about about GitHub's API throughout this project."} />
                  </div>

                  {/* Biggest Challenges */}
                  <div className={"space-y-2"}>
                    <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Biggest Challenges</h3>
                    <Textarea onChange={(e) => setChallenges(e.target.value)} value={challenges}
                              className={"w-full bg-white"}
                              placeholder={"The biggest challenge was learning how to dynamically pass user input to the API request."} />
                  </div>

                  <div className={"space-y-2"}>
                    <h3 className={"text-base font-semibold text-scale-600 dark:text-scale-100"}>Next Steps</h3>
                    <Textarea onChange={(e) => setNextSteps(e.target.value)} value={nextSteps}
                              className={"w-full bg-white"}
                              placeholder={"Looking to build a more complex app using more advanced GitHub API features."} />
                  </div>

                </div>
              </div>
            </div>
            <div className="flex py-4">
              <Button
                onClick={() => createProject()}
                variant={"default"}
                size={"lg"}
                className={"shrink-0 font-mono text-xs"}
              >
                <div className="flex items-center rounded-lg">
                  <Icons.Add className="h-5 w-5 mr-1" aria-hidden="true" />
                  <span>Add project</span>
                </div>
              </Button>
            </div>
          </main>
        </div>
      </div>
      {
        isUploadModalOpen && (
          <FileUploadModal
            filePrefix={"project"}
            setFileUploadData={setUploadedFileData}
            open={isUploadModalOpen}
            setOpen={setIsUploadModalOpen}
          />
        )
      }
    </div>
  );
}
