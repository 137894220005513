import { SessionCalendarEntry } from "@pairprogram/graphql";
import { Session } from "inspector";
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import dayjs from "dayjs";
import Loader from "@/components/loader";

interface SessionCalendarProps {
  sessions: SessionCalendarEntry[];
  loading: boolean;
}

function generateMockSessionData(startDate: Date, endDate: Date): Array<SessionCalendarEntry> {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
  const result: SessionCalendarEntry[] = [];

  for (let d = startDate; d <= endDate; d = new Date(d.getTime() + oneDay)) {
    const count = Math.floor(Math.random() * 5); // Random count between 0 and 100
    const date = d.toISOString();
    result.push({ count, date });
  }
  return result;
}
export default function ActivityCalendar({ sessions, loading }: SessionCalendarProps) {
  const oneYearAgo = new Date(sessions?.[0]?.date);
  const today = new Date(sessions?.[sessions?.length - 1]?.date);

  return (
    <div className={''}>
      {
        loading ? (
          <div className={'flex items-center justify-center w-full h-44'}>
          <Loader className={'text-green-500'}/>
          </div>
          ) : (
          <CalendarHeatmap
            startDate={oneYearAgo}
            endDate={today}
            gutterSize={2}
            values={sessions}
            titleForValue={(value: SessionCalendarEntry) => {
              return `${value.count} activities on ${dayjs(value.date).format('MMMM D, YYYY')}`
            }}
            classForValue={(value: SessionCalendarEntry) => {
              if (!value) return 'color-empty';
              if (value?.count! < 1) return 'color-empty';
              if (value?.count! >= 1 && value?.count! < 2) return 'color-scale-1';
              if (value?.count! >= 2 && value?.count! < 3) return 'color-scale-2';
              if (value?.count! >= 3 && value?.count! < 4) return 'color-scale-3';
              if (value?.count! >= 4) return 'color-scale-4';
              return 'color-empty';
            }}
          />
        )
      }
    </div>
  );

}
