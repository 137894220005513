import * as React from "react"
import { VariantProps, cva } from "class-variance-authority"

import { cn } from "@/utils"

const badgeVariants = cva(
  "bg-gray-50 text-gray-600 ring-gray-500/10 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
  // "transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "bg-brand-50 hover:bg-brand-100 border-brand-100 text-brand-600 ring-brand-500/10",
        primary:
          "bg-primary hover:bg-primary/80 border-transparent text-primary-25",
        red:
          "bg-red-50 hover:bg-red-100 border-red-100 text-red-600 ring-red-500/10",
        purple:
          "bg-purple-50 hover:bg-purple-100 border-purple-100 text-purple-600 ring-purple-500/10",
        blue:
          "bg-blue-50 hover:bg-blue-100 border-blue-100 text-blue-600 ring-blue-500/10",
        indigo:
          "bg-indigo-50 hover:bg-indigo-100 border-indigo-100 text-indigo-600 ring-indigo-500/10",
        green:
          "bg-green-50 hover:bg-green-100 border-green-100 text-green-600 ring-green-500/10",
        sky:
          "bg-sky-50 hover:bg-sky-100 border-sky-100 text-sky-600 ring-sky-500/10",
        destructive:
          "bg-destructive hover:bg-destructive/80 border-transparent text-destructive-25",
        outline: "text-foreground",
        ghost: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
