import { useContext, useEffect, useState } from "react";
import { Location, useBlocker } from "react-router-dom";
import { AppContext } from "@/store/context/AppContext";
import { AppActions } from "@/store/actions/app.actions";
import { AppRoute, AuthRoutes } from "@/config/routes";
import { authStore, EventType, subscribeToEvent, unsubscribeFromEvent } from "@/store";

interface IRequiresAuthParams {
  withAuth: (callback?: () => any) => void;
  isAuthenticated: boolean;
  handleShowModal: (value: boolean) => void;
  showModal: boolean;
}

export default function useRequiresAuth(): IRequiresAuthParams {
  const { state: app, dispatch } = useContext(AppContext);
  const { isAuthenticated } = authStore();
  const [isAuth, setIsAuth] = useState(isAuthenticated());

  useEffect(() => {
    const handleAccessTokenUpdated = () => {
      setIsAuth(authStore().isAuthenticated());
    };

    subscribeToEvent(EventType.AccessTokenUpdated, handleAccessTokenUpdated)

    // Clean up the event listener on unmount
    return () => {
      unsubscribeFromEvent(EventType.AccessTokenUpdated, handleAccessTokenUpdated)
    };
  }, [isAuth]);

  function handleShowModal(value: boolean) {
    dispatch({ type: AppActions.DisplayLoginModal, payload: value });
  }

  function withAuth(callback?: () => any) {
    if (isAuth) {
      if (callback) {
        callback();
      }
    } else {
      handleShowModal(true);
    }
  }

  function isOnAuthRoute(location: Location) {
    if (location.pathname === AppRoute.Home) {
      return false;
    }
    return AuthRoutes.some(route => route.startsWith(location.pathname as AppRoute));
  }

  useBlocker(({ currentLocation, nextLocation }) => {
    if (!isAuth) {
      const isAuthRoute = isOnAuthRoute(currentLocation) || isOnAuthRoute(nextLocation);
      if (isAuthRoute) {
        handleShowModal(true);
        return true;
      }
    }
    return false;
  });

  return {
    withAuth,
    isAuthenticated: isAuth,
    handleShowModal,
    showModal: app.displayLoginModal
  };
}
