import { SessionActions } from "@/store/actions/session.actions";
import { Action } from "@/store/reducers/index";
import { SessionState } from "@/store/initial-state";

const sessionReducer = (state: SessionState, action: Action) => {
  switch (action.type) {
    case SessionActions.SessionJoined:
      // sentryLoginUser({ email: state?.user?.email });
      // sentrySetAuthContext(state);
      return {
        ...state,
        sessionToken: action.payload,
      };
    case SessionActions.SessionLeft:
      return {
        ...state,
        sessionToken: null,
      }
    default:
      return state;
  }
};

export default sessionReducer;
