import { Link, useLocation } from "react-router-dom";
import React from "react";
import { AppRoute } from "@/config/routes";

// capitalize first letter of string
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function Breadcrumbs() {


  const location = useLocation();

  const ignoredBreadcrumbRoutes = ["dashboard"];
  const nonHyperlinkedRoutes = ["posts"];
  const shouldConvertToPlural = ["post", "user", "chat", "task", "projects"];
  const shouldConvertToSingular = ["posts", "users", "chats", "tasks", "projects"];

  const crumbs = location.pathname.split("/").filter((crumb) => crumb !== "").map((crumb) => {
    if (shouldConvertToPlural.includes(crumb)) {
      return `${crumb}s`;
    }
    return crumb;
  });

  function shouldMaybeConvertBackToSingular(crumb: string) {
    if (shouldConvertToSingular.includes(crumb)) {
      return crumb.slice(0, -1);
    }
    return crumb;
  }

  return (
    <nav className={"w-full flex items-start"}>
      <ul className={"text-xs inline-flex items-center space-x-2"}>
        <li className={"text-scale-950/60 dark:text-scale-25/60"}>
          <Link to={AppRoute.Home}>Dashboard</Link>
        </li>
        {crumbs.map((crumb, index) => {

          const updatedCrumb = shouldMaybeConvertBackToSingular(crumb);

          const maybeUpdatedCrumbs = crumbs.slice(0, index + 1).map(crumb => shouldMaybeConvertBackToSingular(crumb)).join("/");

          if (ignoredBreadcrumbRoutes.includes(crumb)) {
            return null;
          }
          if (nonHyperlinkedRoutes.includes(crumb)) {
            return (
              <li key={index} className={"inline-flex items-center space-x-2 text-scale-600 dark:text-scale-300"}>
                <div>{"/"}</div>
                <div>{capitalizeFirstLetter(updatedCrumb)}</div>
              </li>
            );
          }
          return (
            <li key={index} className={"inline-flex items-center space-x-2 text-scale-600 dark:text-scale-300"}>
              <div>{"/"}</div>
              <Link to={`/${maybeUpdatedCrumbs}`}>{capitalizeFirstLetter(updatedCrumb)}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
