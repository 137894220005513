import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import {
  Avatar,
  AvatarImageWithStatus,
  Button,
  Dialog as RadixDialog,
  DialogContent as RadixDialogContent,
  DialogDescription as RadixDialogDescription,
  DialogHeader as RadixDialogHeader,
  DialogTitle as RadixDialogTitle,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from "@/components/ui";
import { cn } from "@/utils";
import { getRelativeTime, Timezone } from "@/utils/datetime.utils";
import { CalendarDays } from "lucide-react";
import { VideoSessionState } from "@/app/dashboard/post";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LocalUserChoices, PreJoin } from "@livekit/components-react";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import { Icons } from "@/assets/icons";
import useLocalizedTime from "@/hooks/use-localized-time";
import { useUpdateChatMessageStatus } from "@/hooks/use-update-chat-message-status";
import { useCreateVideoSession } from "@/hooks/use-create-video-session";
import ChatBox from "@/app/dashboard/chats/chat/chat-box";
import { useGetChat } from "@/hooks/use-get-chat";
import { AppRoute } from "@/config/routes";

export default function ChatPage() {
  const navigate = useNavigate();
  const { state: auth } = useContext(AuthContext);
  const params = useParams<{ chatId: string }>();
  const chatId = params?.chatId;

  if (!chatId) {
    navigate(AppRoute.Chat);
  }

  const { chat } = useGetChat(chatId!);

  const username = params?.chatId!;

  const { createVideoSessionToken, createVideoSessionData } = useCreateVideoSession();
  const { markAllMessagesForChatAsRead } = useUpdateChatMessageStatus();

  const [joinSessionModalOpen, setJoinSessionModalOpen] = useState(false);
  const otherChatParticipant = chat?.Participants?.data?.find((participant) => participant?.username !== auth?.user?.username);

  const handleJoinSession = () => {
    createVideoSessionToken({
      roomId: username,
      username: auth?.user?.username
    });
    setJoinSessionModalOpen(true);
  };

  const preJoinOnSubmit = (data: VideoSessionState) => {
    navigate(`${AppRoute.Session}/${username}`, {
      state: {
        ...data,
        sessionToken: createVideoSessionData?.CreateVideoSessionToken?.data?.token,
        chat: chat
      } as VideoSessionState
    });
  };


  useEffect(() => {
    if (chat?.id) {
      markAllMessagesForChatAsRead(chat.id);
    }
  }, [chat?.id]);

  const localizedTime = useLocalizedTime(otherChatParticipant?.timezone as Timezone);

  return (
    <div
      className="flex flex-col">
      <DashboardPageHeadingSection title={"Inbox"} />

      <div
        className={cn(
          "bg-scale-200 dark:bg-scale-950",
          "border border-scale-300 border-b-0 rounded-t-md dark:border-b-scale-700",
          "w-full py-4 px-4"
        )}>

        <div
          className={cn(
            "block sm:inline-flex items-center w-full justify-between"
          )}
        >
          <div className="flex flex-col">
            <div className={"inline-flex items-center"}>
              <HoverCard key={`hover-card-${username}`}>
                <Link to={`${AppRoute.User}/${otherChatParticipant?.username}`}>
                  <HoverCardTrigger className={"cursor-pointer"} asChild>
                    <Avatar>
                      <AvatarImageWithStatus
                        src={otherChatParticipant?.avatarUrl ?? "/images/avatar.png"}
                        isOnline={otherChatParticipant?.isOnline}
                        className="h-10 w-10 flex-none rounded-full ring-1 ring-scale-100"
                        alt={`@${otherChatParticipant?.username}'s avatar`}
                      />
                    </Avatar>
                  </HoverCardTrigger>
                </Link>
                <h3
                  className="ml-2 text-xl font-bold leading-6 text-scale-950 dark:text-scale-25">{otherChatParticipant?.username}</h3>
                <HoverCardContent className={
                  cn(
                    "w-80 bg-scale-25 dark:bg-scale-950",
                    "border border-scale-100 dark:border-scale-800"
                  )
                }>
                  <div className="flex space-x-4">
                    <Avatar>
                      <AvatarImageWithStatus isOnline={true} src={otherChatParticipant?.avatarUrl!} />
                    </Avatar>
                    <div className="space-y-1">
                      <h3
                        className="text-md font-bold leading-6 text-scale-950 dark:text-scale-25">{otherChatParticipant?.username}</h3>
                      <div className="flex items-center pt-2">
                        <CalendarDays className="mr-2 h-4 w-4 text-scale-800/70 dark:text-scale-200/70" />{" "}
                        <span className="text-xs text-scale-800/70 dark:text-scale-200/70">
                            Joined {getRelativeTime(otherChatParticipant?.createdAt)}
                          </span>
                      </div>
                    </div>
                  </div>
                </HoverCardContent>
              </HoverCard>
            </div>
            {
              otherChatParticipant?.timezone && (
                <div
                  className="hidden sm:inline-flex mt-2 text-scale-600 dark:text-scale-200 font-normal items-center justify-center sticky top-0 ml-2">
                  <Icons.Time className={"h-3 w-3 mr-2"} />
                  <p className={"text-xs"}>
                    Local time for
                    @{otherChatParticipant?.username} is <span className={"font-bold"}>{localizedTime}</span>
                  </p>
                </div>
              )
            }
          </div>
          <div className="py-4 sm:py-0">
            <Button
              variant={"default"}
              size={"sm"}
              onClick={handleJoinSession}
              className={"md:ml-4 flex-shrink-0 font-mono text-xs w-full sm:w-fit"}>
              {/*{isLiveSession ? "Join now" : "Start video session"}*/}
              <Icons.SessionJoined className={"h-4 w-4 mr-2"} />
              {false ? "Join now" : "Start video session"}
            </Button>
          </div>
        </div>

        {
          otherChatParticipant?.timezone && (
            <div className="pt-2 inline-flex text-scale-600 dark:text-scale-200 font-normal ml-2 sm:hidden">
              <Icons.Time className={"h-4 w-4 mr-2"} />
              <p className={"text-xs"}>
                Local time for @{otherChatParticipant?.username} is <span
                className={"font-bold"}>{localizedTime}</span>
              </p>
            </div>
          )
        }
      </div>

      <ChatBox height={'500px'} className={'bg-white'} chat={chat!} />

      <RadixDialog open={joinSessionModalOpen} onOpenChange={setJoinSessionModalOpen} key={"join-session-dialog"}>
        <RadixDialogContent
          className={"overflow-y-scroll bg-scale-100 dark:bg-scale-950 w-7xl max-h-screen sm:max-w-4xl border-scale-100 dark:border-scale-700"}>

          <RadixDialogHeader>
            <RadixDialogTitle>
              <h3
                className={"text-center text-scale-950 dark:text-scale-25"}>{`Video chat with ${otherChatParticipant?.username} now`}</h3>
            </RadixDialogTitle>
          </RadixDialogHeader>

          <RadixDialogDescription>
            <div className={"space-y-4"}>
              <PreJoin
                joinLabel={"Join session"}
                onSubmit={preJoinOnSubmit}
                onValidate={(data: LocalUserChoices) => {
                  return !!data?.username;
                }}
                defaults={{ username: auth?.user?.username, videoEnabled: false, audioEnabled: false }} />
            </div>

          </RadixDialogDescription>
        </RadixDialogContent>
      </RadixDialog>

    </div>
  );
}
