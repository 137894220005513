import { Tag, TagType } from "@pairprogram/graphql";
import { Badge } from "@/components/ui";
import React from "react";

export default function TagBadge({ tag, children }: { tag?: Tag, children?: React.ReactNode }) {
  let badgeVariant = "default";
  if (tag?.type === TagType.Technology) {
    badgeVariant = "indigo";
  } else if (tag?.type === TagType.Career) {
    badgeVariant = "sky";
  } else if (tag?.type === TagType.Topic) {
    badgeVariant = "blue";
  }

  return (
    <div className="py-0.25 px-0.5">
      <Badge
        className={"tracking-wide font-semibold"}
        variant={badgeVariant as any}>
        { children ? children : tag?.label }
      </Badge>
    </div>
  )
}
