"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStorage = void 0;
var LocalStorage;
(function (LocalStorage) {
    LocalStorage["AccessToken"] = "access_token";
    LocalStorage["RefreshToken"] = "refresh_token";
    LocalStorage["Theme"] = "theme";
    LocalStorage["IsAdmin"] = "is_admin";
    LocalStorage["Roles"] = "roles";
})(LocalStorage || (exports.LocalStorage = LocalStorage = {}));
