import { cn } from "@/utils";

interface ButtonGroupButton {
  label: string;
  onClick: () => void;
}
interface ButtonGroupProps {
  items: ButtonGroupButton[];
}
export default function ButtonGroup({ items }) {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      {
        items.map((item: ButtonGroupButton, key: number) => {
          return (
            <button
              key={key}
              type="button"
              onClick={item.onClick}
              className={cn(
                "relative inline-flex items-center rounded-md bg-white px-3 py-2 text-[11px] sm:text-sm font-medium text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                key === 0 ? "rounded-l-md rounded-r-none" : "-ml-px",
                key === items.length - 1 ? "rounded-r-md rounded-l-none" : "",
                key !== 0 && key !== items.length - 1 ? "rounded-none" : "",
                "hover:bg-gray-100"
              )}
            >
              <span>{item.label}</span>
            </button>
          )
        })
      }
    </span>
  )
}
