import { ProjectFilterEnum, ProjectSortEnum, TaskFilterEnum, TaskSortEnum, useGetProjectsQuery } from "@pairprogram/graphql";

export default function useGetProjects({ limit = 10, offset = 0, sort = ProjectSortEnum.Latest, filter = ProjectFilterEnum.All }) {
  const { data, loading, error, fetchMore } = useGetProjectsQuery({
    variables: {
      input: {
        limit,
        offset,
        sort,
        filter
      }
    }
  });

  return {
    projects: data?.GetProjects?.data,
    loading,
    error,
    fetchMore,
  };
}
