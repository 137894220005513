export default function ThreeIcon(props) {
  return (
    <svg
      className={"text-scale-700 dark:text-scale-300 h-8 w-8"} {...props} width="100%" height="100%" viewBox="0 0 800 800"
      version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2
      }}
    >
      <path d="M400.005,0.005C179.088,0.005 0,179.074 0,400.01C0,620.907 179.088,799.995 400.005,799.995C620.912,799.995 800,620.907 800,400.01C800,179.074 620.912,0.005 400.005,0.005ZM400.005,25.005C607.105,25.005 775,192.882 775,400.01C775,607.101 607.105,774.995 400.005,774.995C192.896,774.995 25,607.101 25,400.01C25,192.882 192.895,25.005 400.005,25.005Z" style={{ fill: "currentColor"}}/>
      <g transform="matrix(1.51899,0,0,1.51899,9.96203,-209.873)">
        <g transform="matrix(288,0,0,288,160,501.455)">
          <path
            style={{ fillRule: "nonzero", fill: "currentColor"}}
            d="M0.323,0.01C0.274,0.01 0.231,0.002 0.193,-0.015C0.155,-0.032 0.124,-0.056 0.102,-0.086C0.08,-0.115 0.068,-0.15 0.066,-0.19L0.178,-0.19C0.179,-0.168 0.187,-0.15 0.2,-0.134C0.213,-0.118 0.23,-0.106 0.251,-0.097C0.273,-0.089 0.296,-0.084 0.322,-0.084C0.351,-0.084 0.376,-0.089 0.399,-0.099C0.421,-0.109 0.438,-0.123 0.451,-0.141C0.463,-0.159 0.469,-0.18 0.469,-0.203C0.469,-0.227 0.463,-0.249 0.451,-0.268C0.438,-0.286 0.42,-0.301 0.397,-0.311C0.373,-0.322 0.344,-0.327 0.31,-0.327L0.249,-0.327L0.249,-0.417L0.31,-0.417C0.337,-0.417 0.361,-0.422 0.382,-0.431C0.402,-0.441 0.418,-0.455 0.43,-0.472C0.441,-0.489 0.447,-0.51 0.447,-0.533C0.447,-0.556 0.442,-0.575 0.432,-0.592C0.422,-0.609 0.408,-0.622 0.389,-0.631C0.371,-0.641 0.349,-0.645 0.325,-0.645C0.301,-0.645 0.279,-0.641 0.258,-0.633C0.238,-0.624 0.221,-0.612 0.208,-0.596C0.196,-0.58 0.189,-0.561 0.188,-0.538L0.082,-0.538C0.083,-0.578 0.095,-0.612 0.117,-0.642C0.139,-0.672 0.168,-0.695 0.204,-0.712C0.241,-0.729 0.281,-0.737 0.326,-0.737C0.373,-0.737 0.413,-0.728 0.447,-0.71C0.481,-0.692 0.507,-0.668 0.526,-0.638C0.544,-0.608 0.553,-0.575 0.553,-0.539C0.554,-0.498 0.542,-0.464 0.519,-0.435C0.497,-0.407 0.466,-0.388 0.428,-0.378L0.428,-0.372C0.477,-0.365 0.514,-0.346 0.541,-0.315C0.567,-0.284 0.58,-0.246 0.58,-0.2C0.58,-0.16 0.569,-0.124 0.547,-0.092C0.525,-0.06 0.494,-0.035 0.456,-0.017C0.417,0.001 0.373,0.01 0.323,0.01Z"/>
        </g>
      </g>
    </svg>

  );
};
