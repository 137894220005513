import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { cn } from "@/lib/utils";
import { Icons } from "@/assets/icons";
import { Link, Share2 } from "lucide-react";
import { Post, User, Task } from "@pairprogram/graphql";
import toast from "react-hot-toast";
import { AppRoute } from "@/config/routes";

interface SocialShareConfig {
  reddit: string,
  twitter: string,
  copy: string
}

type ConfigType = Post | User | Task;

interface IShareOptionsProps {
  itemType: "post" | "user" | "task",
  item?: ConfigType
}


export default function ShareOptions({ itemType, item }: IShareOptionsProps) {
  const socialShareConfig = {
    post: (post: Post): SocialShareConfig => ({
      reddit: `https://www.reddit.com/submit?url=${process.env.REACT_APP_BASE_URL}${AppRoute.Post}/${post?.id}&title=${post?.title}`,
      twitter: `https://twitter.com/intent/tweet?text=${post?.title} ${process.env.REACT_APP_BASE_URL}${AppRoute.Post}/${post?.id}`,
      copy: `${process.env.REACT_APP_BASE_URL}${AppRoute.Post}/${post?.id}`,
    }),
    user: (user: User): SocialShareConfig => ({
      reddit: `https://www.reddit.com/submit?url=${process.env.REACT_APP_BASE_URL}${AppRoute.User}/${user?.username}&title=Pair program with ${user?.username}!`,
      twitter: `https://twitter.com/intent/tweet?text=Join me on pairprogram.com and let's pair! ${process.env.REACT_APP_BASE_URL}${AppRoute.User}/${user?.username}`,
      copy: `${process.env.REACT_APP_BASE_URL}${AppRoute.User}/${user?.username}`,
    }),
    task: (task: Task): SocialShareConfig => ({
      reddit: `https://www.reddit.com/submit?url=${process.env.REACT_APP_BASE_URL}${AppRoute.Task}/${task?.id}&title=${task?.title}`,
      twitter: `https://twitter.com/intent/tweet?text=${task?.title} ${process.env.REACT_APP_BASE_URL}${AppRoute.Task}/${task?.id}`,
      copy: `${process.env.REACT_APP_BASE_URL}${AppRoute.Task}/${task?.id}`,
    }),
  }


  const configToUse = socialShareConfig[itemType](item as any);

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(configToUse.copy);
    toast.success("Link copied to clipboard");
  };

  const handleShareToTwitter = () => {
    const twitterUrl = configToUse.twitter;
    window.open(twitterUrl);
  };

  const handleShareToReddit = () => {
    const redditUrl = configToUse.reddit;
    window.open(redditUrl);
  };

  return (
    <div className="flex flex-shrink-0 self-center">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="relative -m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
            <span className="absolute -inset-1" />
            <span className="sr-only">Open share options</span>
            <Share2 className="h-4 w-4" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="cursor-pointer absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={handleShareToReddit}
                    className={cn(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex px-4 py-2 text-sm"
                    )}
                  >
                    <Icons.Reddit className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
                    <span>Share to Reddit</span>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={handleShareToTwitter}
                    className={cn(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex px-4 py-2 text-sm"
                    )}
                  >
                    <Icons.Twitter
                      className="mr-3 h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>Share to Twitter</span>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={handleCopyLink}
                    className={cn(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex px-4 py-2 text-sm"
                    )}
                  >
                    <Link className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
                    <span>Copy link</span>
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
