import { useGetUsersQuery, User } from "@pairprogram/graphql";

export default function useGetUsers(limit: number, offset: number) {
  const { data, fetchMore, loading, refetch } = useGetUsersQuery({
    variables: {
      input: {
        limit,
        offset
      }
    }
  });

  const users = data?.GetUsers?.data?.users as User[] ?? [];
  const count = data?.GetUsers?.data?.count as number ?? 0;

  return {
    users,
    count,
    fetchMore,
    loading,
    refetch
  };
}
