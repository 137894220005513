import { Session, useGetSessionsForUserQuery } from "@pairprogram/graphql";
import React, { useContext, useState } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import toast from "react-hot-toast";
import SessionItem from "@/components/session/session-item";
import SessionsEmpty from "@/app/dashboard/sessions/sessions-empty";
import DashboardPageHeading from "@/components/dashboard-page-heading";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";

export default function SessionsPage() {
  const { state: auth } = useContext(AuthContext);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [sessions, setSessions] = useState<Session[]>([]);
  useGetSessionsForUserQuery({
    onCompleted: (data) => {
      if (data.GetSessionsForUser.error) {
        return toast.error("Error fetching sessions", { icon: "🚨" });
      }
      setSessions(data.GetSessionsForUser.data as Session[]);
    },
    onError: (error) => {
      return toast.error("Unknown error fetching sessions", { icon: "🚨" });
    },
    variables: {
      input: {
        userId: auth?.user?.id,
        limit,
        offset
      }
    }
  });

  return (
    <div>
      <DashboardPageHeadingSection title={"My Sessions"} />
      <div>
        {
          sessions.map((session, i) => (
            <SessionItem key={i} session={session} />
          ))
        }
        {
          !sessions.length ? (
            <div className="p-8">
              <SessionsEmpty />
            </div>
          ) : null
        }
      </div>
    </div>
  );
}
