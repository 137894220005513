import { cn } from "@/utils";
import { getRelativeTime } from "@/utils/datetime.utils";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import { Chat, ChatMessage, User } from "@pairprogram/graphql";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { hasUnreadMessagesVar } from "@/store/reactive-vars";
import { AppRoute } from "@/config/routes";

export default function ChatItem({ chat }: { chat: Chat }) {
  const { state: auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleNavigateToChat = (chatId: string) => {
    navigate(`${AppRoute.Chat}/${chatId}`);
  };
  const otherChatParticipant = chat?.Participants?.data?.find((participant) => participant?.id !== auth?.user?.id);
  const hasUnreadMessages: boolean = useReactiveVar(hasUnreadMessagesVar);
  const [hasUnreadMessagesForChat, setHasUnreadMessagesForChat] = useState(false);

  const [mostRecentMessageObj, setMostRecentMessageObj] = useState<ChatMessage | null>(null);

  useEffect(() => {
    if (chat?.MostRecentMessage?.data?.id) {
      const mostRecentMessage = chat?.MostRecentMessage?.data;
      setMostRecentMessageObj(mostRecentMessage);
    }
  }, [chat?.MostRecentMessage?.data?.id]);

  useEffect(() => {
      const isMostRecentMessageSentToLoggedInUser = mostRecentMessageObj?.userId !== auth?.user?.id;
      const isMostRecentMessageRead = mostRecentMessageObj?.Status?.data?.isRead ?? true;
      if (isMostRecentMessageSentToLoggedInUser && !isMostRecentMessageRead) {
        setHasUnreadMessagesForChat(true);
      }
  }, [mostRecentMessageObj?.userId, auth?.user?.id, mostRecentMessageObj?.Status?.data?.isRead]);

  return (
    <div
      key={chat?.id}
      onClick={() => handleNavigateToChat(chat?.id!)}
      className="cursor-pointer relative flex justify-between gap-x-6 px-4 py-4 hover:bg-gray-50 sm:px-6">
      {hasUnreadMessagesForChat && (
        <div
          className="absolute top-2 left-2"
          aria-hidden="true"
        >
          <div className="absolute z-20 h-3 w-3 rounded-full bg-rose-500 border border-red-400" />
        </div>
      )}
      <div className="relative flex min-w-0 gap-x-4">
        <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={otherChatParticipant?.avatarUrl!}
             alt="" />
        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            <span className="absolute inset-x-0 -top-px bottom-0" />
            <span>{otherChatParticipant?.username}</span>
          </p>
          <p className="mt-1 flex text-xs leading-5 text-gray-500">
            <span className={cn(
              "relative truncate",
              hasUnreadMessagesForChat && "font-semibold",
              !mostRecentMessageObj && "italic"
            )}>
              {mostRecentMessageObj?.text ?? "No messages yet"}
            </span>
          </p>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-2">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          {!otherChatParticipant?.isOnline ? (
            <p className="text-xs leading-5 font-medium text-gray-400/80 italic">
                      <span>Last online <time
                        dateTime={otherChatParticipant?.lastOnlineAt}>{getRelativeTime(otherChatParticipant?.lastOnlineAt)}</time></span>
            </p>
          ) : (
            <div className="flex items-center gap-x-1.5">
              <div className="animate-ping duration-1000 flex-none rounded-full bg-emerald-500/20 p-1">
                <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
              </div>
              <p className="text-xs leading-5 text-gray-500 italic">Online now</p>
            </div>
          )}
        </div>
        <ChevronRightIcon className="h-5 w-5 flex-none text-gray-600" aria-hidden="true" />
      </div>
    </div>
  );
}
