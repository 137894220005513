import jwtDecode from 'jwt-decode';

export interface IAccessToken {
  email: string;
  sub: number;
  iat: number;
  exp: number;
  // roles: Role[];
}

export interface IRefreshToken extends IAccessToken {
  refreshToken: string;
}

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const decodeJwt = (token: string): string | IAccessToken => {
  if (token) {
    return jwtDecode(token) as IAccessToken;
  }
  return token as string;
};

export const isTokenExpired = (token: string | null) => {
  if (!token) {
    return true;
  }
  // if current time greater than token exp
  // then token is expired
  const decodedToken = decodeJwt(token) as IAccessToken;
  const currentTime = Date.now();
  const tokenExpiration = decodedToken?.exp * 1000;
  return currentTime > tokenExpiration;
};
