import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "@/store/context/AuthContext";
import {
  Button,
  Input,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  Textarea
} from "@/components/ui";
import { TimezoneMap } from "@/utils/datetime.utils";
import {
  GetCurrentUserQuery,
  GetUserTagSubscriptionsQuery,
  SkillLevel,
  Tag, TagType,
  UpdateProfileError,
  UpdateProfileMutation,
  UpdateTagsForUserMutation,
  useGetCurrentUserQuery,
  useGetUserTagSubscriptionsQuery,
  useUpdateProfileMutation,
  useUpdateTagsForUserMutation,
  useUpdateUserTagSubscriptionsMutation
} from "@pairprogram/graphql";
import toast from "react-hot-toast";
import ComboBox, { ComboBoxItem } from "@/components/combo-box";
import DashboardPageHeadingSection from "@/components/dashboard-page-heading-section";
import mixpanel from "@/analytics";
import { AnalyticsEvent } from "@/analytics/analytics-event";
import { Icons } from "@/assets/icons";
import useGetTags from "@/hooks/use-get-tags";
import { authStore } from "@/store";


export default function SettingsPage() {
  const { state: auth } = useContext(AuthContext);
  const { isAuthenticated } = authStore();
  const skillLevels = Object.keys(SkillLevel);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null | undefined>("");
  const [name, setName] = useState<string | null | undefined>("");
  const [skillLevel, setSkillLevel] = useState<string | null | undefined>(null);
  const [timezone, setTimezone] = useState<string | null | undefined>(null);
  const [bio, setBio] = useState<string | null | undefined>("");
  const [location, setLocation] = useState<string | null | undefined>("");
  const [linkedInUrl, setLinkedInUrl] = useState<string | null | undefined>("");
  const [personalWebsiteUrl, setPersonalWebsiteUrl] = useState<string | null | undefined>("");
  const [blogUrl, setBlogUrl] = useState<string | null | undefined>("");
  const [idealCodingPartner, setIdealCodingPartner] = useState<string | null | undefined>("");
  const [shortBio, setShortBio] = useState<string | null | undefined>("");
  const [twitterUsername, setTwitterUsername] = useState<string | null | undefined>("");
  const [discordUsername, setDiscordUsername] = useState<string | null | undefined>("");

  useGetCurrentUserQuery({
    onCompleted: (data: GetCurrentUserQuery) => {
      setCurrentUser(data?.GetCurrentUser?.data);

      // setSkillLevel(data?.GetCurrentUser?.data?.profile?.skillLevel);
      setTimezone(data?.GetCurrentUser?.data?.timezone);
      setName(data?.GetCurrentUser?.data?.name);
      setAvatarUrl(data?.GetCurrentUser?.data?.avatarUrl);

      setBio(data?.GetCurrentUser?.data?.profile?.bio);
      setShortBio(data?.GetCurrentUser?.data?.profile?.shortBio);
      setLocation(data?.GetCurrentUser?.data?.profile?.location);
      setTwitterUsername(data?.GetCurrentUser?.data?.profile?.twitterUsername);
      setDiscordUsername(data?.GetCurrentUser?.data?.profile?.discordUsername);
      setLinkedInUrl(data?.GetCurrentUser?.data?.profile?.linkedInUrl);
      setPersonalWebsiteUrl(data?.GetCurrentUser?.data?.profile?.personalWebsiteUrl);
      setBlogUrl(data?.GetCurrentUser?.data?.profile?.blogUrl);
      setIdealCodingPartner(data?.GetCurrentUser?.data?.profile?.idealCodingPartner);

      const tempSelectedTechTags: Tag[] = [];
      const tempSelectedCareerPathTags: Tag[] = [];
      const tempSelectedTopicTags: Tag[] = [];

      (data?.GetCurrentUser?.data?.tags as Tag[])?.forEach((tag: Tag) => {
        if (tag?.type === TagType.Technology) {
          tempSelectedTechTags.push(tag);
        } else if (tag?.type === TagType.Career) {
          tempSelectedCareerPathTags.push(tag);
        } else if (tag?.type === TagType.Topic) {
          tempSelectedTopicTags.push(tag);
        }
      });

      setSelectedTechTags(tempSelectedTechTags);
      setSelectedTrackTags(tempSelectedCareerPathTags);
      setSelectedTopicTags(tempSelectedTopicTags);
    },
    fetchPolicy: "cache-and-network",
    skip: !isAuthenticated()
  });

  const [selectedTechTags, setSelectedTechTags] = useState<Tag[]>([]);
  const [selectedTechTagSubscriptions, setSelectedTechTagSubscriptions] = useState<Tag[]>([]);

  const [selectedTrackTags, setSelectedTrackTags] = useState<Tag[]>([]);
  const [selectedTrackTagSubscriptions, setSelectedTrackTagSubscriptions] = useState<Tag[]>([]);

  const [selectedTopicTags, setSelectedTopicTags] = useState<Tag[]>([]);
  const [selectedTopicTagSubscriptions, setSelectedTopicTagSubscriptions] = useState<Tag[]>([]);

  const { techTags, trackTags, topicTags } = useGetTags();

  const [updateUserTagSubscriptions] = useUpdateUserTagSubscriptionsMutation({
    onError: (error) => {
      toast.error("Error updating tag subscriptions");
    },
    onCompleted: (data) => {
      if (data?.UpdateUserTagSubscriptions?.error) {
        return toast.error("Error updating tag subscriptions");
      }
      mixpanel.track(AnalyticsEvent.TagSubscriptionsUpdated, {
        user: auth?.user?.username,
        tags: [...selectedTechTagSubscriptions, ...selectedTrackTagSubscriptions, ...selectedTopicTagSubscriptions]?.map((tag) => tag?.value) as string[] ?? []
      });
    },
    variables: {
      input: {
        tagNames: [...selectedTechTagSubscriptions, ...selectedTrackTagSubscriptions, ...selectedTopicTagSubscriptions]?.map(tag => tag.value) as string[]
      }
    }
  });

  useGetUserTagSubscriptionsQuery({
    onCompleted: (data: GetUserTagSubscriptionsQuery) => {
      if (data?.GetUserTagSubscriptions?.error) {
        toast("Error fetching tag subscriptions", {
          icon: "🚨"
        });
      } else {
        const tempSelectedTechTagSubscriptions: Tag[] = [];
        const tempSelectedCareerPathTagSubscriptions: Tag[] = [];
        const tempSelectedTopicTagSubscriptions: Tag[] = [];

        (data?.GetUserTagSubscriptions?.data?.tags as Tag[])?.forEach((tag: Tag) => {
          if (tag?.type === TagType.Technology) {
            tempSelectedTechTagSubscriptions.push(tag);
          } else if (tag?.type === TagType.Career) {
            tempSelectedCareerPathTagSubscriptions.push(tag);
          } else if (tag?.type === TagType.Topic) {
            tempSelectedTopicTagSubscriptions.push(tag);
          }
        });
        setSelectedTechTagSubscriptions(tempSelectedTechTagSubscriptions);
        setSelectedTrackTagSubscriptions(tempSelectedCareerPathTagSubscriptions);
        setSelectedTopicTagSubscriptions(tempSelectedTopicTagSubscriptions);
      }
    },
    onError: (error) => {
      toast.error("Error fetching tag subscriptions");
    },
    fetchPolicy: "cache-and-network",
    skip: !isAuthenticated()
  });

  const [updateTagsForUser] = useUpdateTagsForUserMutation({
    onCompleted: (data: UpdateTagsForUserMutation) => {
      if (data?.UpdateTagsForUser?.error) {
        toast.error("Error updating tags", { icon: "🚨" });
      }
      mixpanel.track(AnalyticsEvent.TagsUpdated, {
        user: auth?.user?.username,
        tags: [...selectedTechTags, ...selectedTrackTags, ...selectedTopicTags].map((tag) => tag?.value) as string[] ?? []
      });
    },
    onError: (error) => {
      toast.error("Error updating tags");
    },
    variables: {
      input: {
        tags: [...selectedTechTags, ...selectedTrackTags, ...selectedTopicTags].map(tag => tag.value) as string[]
      }
    }
  });


  const [updateProfile] = useUpdateProfileMutation({
    onCompleted: (data: UpdateProfileMutation) => {
      if (data?.UpdateProfile?.error) {
        if (data?.UpdateProfile?.error === UpdateProfileError.Profanity) {
          return toast.error("Profile cannot contain profanity", { icon: "🚨" });
        }
        return toast.error("Error updating profile", { icon: "🚨" });
      }
      mixpanel.track(AnalyticsEvent.ProfileUpdated, {
        user: auth?.user?.username
      });
    },
    onError: (error) => {
      toast.error("Error updating profile");
    },
    variables: {
      input: {
        name,
        timezone,
        avatarUrl,
        linkedInUrl,
        personalWebsiteUrl,
        blogUrl,
        idealCodingPartner,
        bio,
        shortBio,
        location
        // skillLevel: skillLevel as SkillLevel
      }
    }
  });

  const handleUpdateProfile = async () => {
    try {
      updateProfile();
      updateTagsForUser();
      updateUserTagSubscriptions();
      toast.success("Profile updated");
    } catch (err) {
      toast.error("Error updating profile");
    }

  };


  return (
    <div className={"rounded-lg"}>
      <DashboardPageHeadingSection title={"Settings"} />
      <main>

        {/* Settings forms */}
        <div className="space-y-8">


          {/* Profile */}
          <div
            className="bg-scale-25 rounded-md shadow-sm border-scale-300 border grid max-w-7xl gap-x-8 gap-y-10 px-4 py-4 md:py-12 sm:px-6 grid-cols-3 lg:px-8">

            <div className={'col-span-3 md:col-span-1'}>
              <h2 className="text-base font-semibold leading-7 text-scale-950 dark:text-scale-25">Profile</h2>
              <p className="mt-1 text-sm leading-6 text-scale-700 dark:text-scale-400">
                Add basic details to your profile.
              </p>
            </div>

            <div className="col-span-3 md:col-span-2 space-y-4">

              <div className={"col-span-6"}>

                <div className="col-span-6 sm:col-span-1">
                  <label htmlFor="first-name"
                         className="block text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">
                    Avatar
                  </label>
                  <img
                    src={auth?.user?.avatarUrl ?? ""}
                    alt=""
                    className="shrink-0 h-12 w-12 flex-none rounded-full object-cover"
                  />
                </div>
                <div className="col-span-6 sm:col-span-5">
                  {/*<label htmlFor="first-name"*/}
                  {/*       className="block text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">*/}
                  {/*  Avatar URL*/}
                  {/*</label>*/}
                  <div className="mt-2">
                    <Input
                      type="text"
                      placeholder={"https://avatars.githubusercontent.com/u/17229444?v=4"}
                      onChange={(e) => setAvatarUrl(e.target.value)}
                      defaultValue={currentUser?.avatarUrl ?? ""}
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-6">
                  <Label htmlFor="first-name"
                         className="flex items-center text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">
                    <Icons.User className={"h-4 w-4 mr-1 text-scale-500"} />
                    <span>Name</span>
                  </Label>
                  <div className="mt-2">
                    <Input
                      type="text"
                      placeholder={"Linus Torvalds"}
                      onChange={(e) => setName(e.target.value)}
                      defaultValue={name ?? ""}
                    />
                  </div>
                </div>

                <div className="col-span-6">
                  <Label htmlFor="first-name"
                         className="flex items-center text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">
                    <Icons.Location className={"h-4 w-4 mr-1 text-scale-500"} />
                    <span>Location</span>
                  </Label>
                  <div className="mt-2">
                    <Input
                      type="text"
                      placeholder={"Boston, MA"}
                      onChange={(e) => setLocation(e.target.value)}
                      defaultValue={location ?? ""}
                    />
                  </div>
                </div>

                <div className="col-span-6">
                  <Label htmlFor="timezone"
                         className="flex items-center text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">
                    <Icons.Time className={"h-4 w-4 mr-1 text-scale-500"} />
                    <span>Timezone</span>
                  </Label>
                  <div className="mt-2">

                    <Select name={"timezone"} value={timezone ?? ""} onValueChange={setTimezone}>
                      <SelectTrigger
                        className={"shadow-sm text-scale-700 dark:text-scale-300 border-scale-200 dark:border-scale-800"}>
                        <SelectValue placeholder="Select your timezone" />
                      </SelectTrigger>
                      <SelectContent
                        className={"max-h-64 overflow-y-auto border-scale-100 dark:border-scale-800 bg-scale-100 dark:bg-scale-950 text-scale-800 dark:text-scale-100"}>
                        {/*<SelectScrollUpButton asChild={true} />*/}
                        <SelectGroup>
                          <SelectLabel>Timezones</SelectLabel>
                          {
                            Object.entries(TimezoneMap).map(([key, value]) => {
                              return (
                                <SelectItem key={key} className={"hover:bg-scale-100 dark:hover:bg-scale-800"}
                                            value={key}>{value}</SelectItem>
                              );
                            })
                          }
                        </SelectGroup>
                        {/*<SelectScrollDownButton asChild={true} />*/}
                      </SelectContent>
                    </Select>

                  </div>
                </div>

                <div className={"col-span-6"}>
                  <label htmlFor="personalWebsiteUrl"
                         className="flex items-center text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">
                    <Icons.Website className={"h-4 w-4 mr-1 text-scale-500"} />
                    <span>Personal Website URL (Optional)</span>
                  </label>
                  <div className="mt-2">
                    <Input
                      placeholder={"https://www.mywebsite.com"}
                      type="url"
                      onChange={(e) => setPersonalWebsiteUrl(e.target.value)}
                      defaultValue={personalWebsiteUrl ?? ""}
                      // className="block w-full border-scale-100 dark:border-scale-800 rounded-md border bg-scale-100/5 py-1.5 text-scale-950 dark:text-scale-25 shadow-sm ring-1 ring-inset ring-scale-25/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className={"col-span-6"}>
                  <label htmlFor="blogUrl"
                         className="flex items-center text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">
                    <Icons.Blog className={"h-4 w-4 mr-1 text-scale-500"} />
                    <span>Blog URL (Optional)</span>
                  </label>
                  <div className="mt-2">
                    <Input
                      placeholder={"https://codesnippet.io"}
                      type="url"
                      onChange={(e) => setBlogUrl(e.target.value)}
                      defaultValue={blogUrl ?? ""}
                      // className="block w-full border-scale-100 dark:border-scale-800 rounded-md border bg-scale-100/5 py-1.5 text-scale-950 dark:text-scale-25 shadow-sm ring-1 ring-inset ring-scale-25/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className={"col-span-6"}>
                  <label htmlFor="first-name"
                         className="flex items-center text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">
                    <Icons.LinkedIn className={"h-4 w-4 mr-1 text-scale-500"} />
                    <span>LinkedIn URL (Optional)</span>
                  </label>
                  <div className="mt-2">
                    <Input
                      type="url"
                      placeholder={"https://linkedin.com/in/username"}
                      onChange={(e) => setLinkedInUrl(e.target.value)}
                      defaultValue={linkedInUrl ?? ""}
                      // className="block w-full border-scale-200 dark:border-scale-800 rounded-md border bg-scale-100/5 py-1.5 text-scale-950 dark:text-scale-25 shadow-sm ring-1 ring-inset ring-scale-25/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8 flex justify-end">
                <Button
                  onClick={() => handleUpdateProfile()}
                  variant={"outline"}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>


          {/*<div className="inset-0 flex items-center" aria-hidden="true">*/}
          {/*  <div className="w-full border-t border-scale-100 dark:border-scale-800" />*/}
          {/*</div>*/}


          <div
            className="bg-scale-25 rounded-md shadow-sm border-scale-300 border grid max-w-7xl grid-cols-3 gap-x-8 gap-y-10 px-4 py-4 md:py-12 sm:px-6 lg:px-8">

            <div className={'col-span-3 md:col-span-1'}>
              <h2 className="text-base font-semibold leading-7 text-scale-950 dark:text-scale-25">About me</h2>
              <p className="mt-1 text-sm leading-6 text-scale-700 dark:text-scale-400">
                Add a bio to your profile to tell others about yourself, your programming interests, and your goals.
              </p>
            </div>

            <div className="col-span-3 md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-xl sm:grid-cols-6">

                <div className={"col-span-6"}>
                  <label htmlFor="shortBio"
                         className="block text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">
                    Tagline
                  </label>
                  <div className="mt-2">
                    <Input
                      type="url"
                      placeholder={"An aspiring full-stack developer learning JavaScript and React."}
                      onChange={(e) => setShortBio(e.target.value)}
                      defaultValue={shortBio ?? ""}
                    />
                  </div>
                </div>

                <div className="col-span-6">
                  <label htmlFor="first-name"
                         className="block text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">
                    About me
                  </label>
                  <div className="mt-2">
                    <Textarea
                      id="bio"
                      name="bio"
                      cols={10}
                      placeholder={"I'm a software developer with about 3 years of experience primarily in Python and JavaScript. I'm passionate about building scalable web applications and have a knack for problem-solving."}
                      onChange={(e) => setBio(e.target.value)}
                      defaultValue={currentUser?.profile?.bio ?? ""}
                      autoComplete="bio"
                    />
                  </div>
                </div>

              </div>

              <div className="mt-8 flex justify-end">
                <Button
                  onClick={() => handleUpdateProfile()}
                  variant={"outline"}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          {/*<div*/}
          {/*  className="bg-scale-25 rounded-md shadow-sm border-scale-300 border grid max-w-7xl grid-cols-3 gap-x-8 gap-y-10 px-4 py-4 md:py-12 sm:px-6 lg:px-8">*/}

          {/*  <div className={'col-span-3 md:col-span-1'}>*/}
          {/*    <h2 className="text-base font-semibold leading-7 text-scale-950 dark:text-scale-25">Ideal Coding*/}
          {/*      Partner</h2>*/}
          {/*    <p className={"mt-1 text-sm leading-6 text-scale-700 dark:text-scale-400"}>*/}
          {/*      Describe your ideal coding partner.*/}
          {/*    </p>*/}
          {/*    <p className={"mt-1 text-sm leading-6 text-scale-700 dark:text-scale-400"}>*/}
          {/*      Think about the skills, experience level, and qualities your coding partner would have that would make*/}
          {/*      for your ideal pair programming session.*/}
          {/*    </p>*/}
          {/*  </div>*/}

          {/*  <div className="col-span-3 md:col-span-2">*/}
          {/*    <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-xl sm:grid-cols-6">*/}


          {/*      <div className="col-span-6">*/}
          {/*        <label htmlFor="first-name"*/}
          {/*               className="block text-sm font-medium leading-6 text-scale-950 dark:text-scale-25">*/}
          {/*          Ideal coding partner*/}
          {/*        </label>*/}
          {/*        <div className="mt-2">*/}
          {/*          <Textarea*/}
          {/*            cols={10}*/}
          {/*            placeholder={"I'm looking for a coding partner who is as passionate about software development as I am. Ideally, you have a strong grasp of front-end technologies like React or Angular, as I could really use some expertise in that area to complement my backend skills in Node.js and Python."}*/}
          {/*            onChange={(e) => setIdealCodingPartner(e.target.value)}*/}
          {/*            defaultValue={currentUser?.profile?.idealCodingPartner ?? ""}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      </div>*/}

          {/*    </div>*/}

          {/*    <div className="mt-8 flex justify-end">*/}
          {/*      <Button*/}
          {/*        onClick={() => handleUpdateProfile()}*/}
          {/*        variant={"outline"}*/}
          {/*      >*/}
          {/*        Save*/}
          {/*      </Button>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}


          <div
            className="bg-scale-25 rounded-md shadow-sm border-scale-300 border grid max-w-7xl grid-cols-3 gap-x-8 gap-y-4 px-4 py-4 md:py-12 sm:px-6 lg:px-8">
            <div className={'col-span-3 md:col-span-1'}>
              <h2 className="text-base font-semibold leading-7 dark:text-scale-25 text-scale-950">Skills & Interests</h2>
              <p className="mt-1 text-sm leading-6 text-scale-700 dark:text-scale-400">
                Add your skills and interests to your profile to personalize your experience.
              </p>
            </div>

            <div className="col-span-3 md:col-span-2 space-y-4">
              <div className="w-full">
                <div className={"pb-2"}>
                  <Label htmlFor="tags" className="text-scale-700">
                    Technologies
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-scale-700">
                    Select the technologies you're familiar with or interested in
                  </p>
                </div>
                <ComboBox
                  badgeColor={"red"}
                  className={"w-full bg-white rounded-md"}
                  items={techTags as ComboBoxItem[]}
                  setSelectedItems={setSelectedTechTags}
                  selectedItems={selectedTechTags as ComboBoxItem[]}
                />
              </div>
              <div className="w-full">
                <div className={"pb-2"}>
                  <Label htmlFor="tags" className="text-scale-700">
                    Tracks
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-scale-700">
                    Select the tracks you're interested in or have experience with
                  </p>
                </div>
                <ComboBox
                  badgeColor={"purple"}
                  className={"w-full bg-white rounded-md"}
                  items={trackTags as ComboBoxItem[]}
                  setSelectedItems={setSelectedTrackTags}
                  selectedItems={selectedTrackTags as ComboBoxItem[]}
                />
              </div>
              <div className="w-full">
                <div className={"pb-2"}>
                  <Label htmlFor="tags" className="text-scale-700">
                    Topics
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-scale-700">
                    Select the topics you're interested in or have experience with
                  </p>
                </div>
                <ComboBox
                  badgeColor={"blue"}
                  className={"w-full bg-white rounded-md"}
                  items={topicTags as ComboBoxItem[]}
                  setSelectedItems={setSelectedTopicTags}
                  selectedItems={selectedTopicTags as ComboBoxItem[]}
                />
              </div>
              {/*<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">*/}
              {/*  <div className="col-span-1">*/}
              {/*    <div className={"pb-2"}>*/}
              {/*      <Label htmlFor="tags" className="text-scale-700 dark:text-scale-300">*/}
              {/*        Select the skills and technologies that will be displayed on your profile*/}
              {/*      </Label>*/}
              {/*    </div>*/}
              {/*    <ComboBox*/}
              {/*      className={'w-full'}*/}
              {/*      displayItemsSeparately={false}*/}
              {/*      items={tags as ComboBoxItem[]}*/}
              {/*      setSelectedItems={setSelectedTags}*/}
              {/*      selectedItems={selectedTags as ComboBoxItem[]}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="mt-8 flex justify-end">
                <Button
                  onClick={() => handleUpdateProfile()}
                  variant={"outline"}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          <div
            className="bg-scale-25 rounded-md shadow-sm border-scale-300 border grid max-w-7xl grid-cols-3 gap-x-8 gap-y-4 px-4 py-4 sm:py-12 sm:px-6 lg:px-8">
            <div className={'col-span-3 md:col-span-1'}>
              <h2 className="text-base font-semibold leading-7 dark:text-scale-25 text-scale-950">Email Notification
                Preferences</h2>
              <p className="mt-1 text-sm leading-6 text-scale-700 dark:text-scale-400">
                Receive email notifications for the tags you subscribe to so you never miss a relevant post.
              </p>
            </div>

            <div className="col-span-3 md:col-span-2 space-y-4">
              <div className="w-full">
                <div className={"pb-2"}>
                  <Label htmlFor="tags" className="text-scale-700">
                    Technologies
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-scale-700">
                    Select the technologies you'd like to receive email notifications for.
                  </p>
                </div>
                <ComboBox
                  badgeColor={"red"}
                  className={"w-full bg-white rounded-md"}
                  items={techTags as ComboBoxItem[]}
                  setSelectedItems={setSelectedTechTagSubscriptions}
                  selectedItems={selectedTechTagSubscriptions as ComboBoxItem[]}
                />
              </div>
              <div className="w-full">
                <div className={"pb-2"}>
                  <Label htmlFor="tags" className="text-scale-700">
                    Tracks
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-scale-700">
                    Select the tracks you'd like to receive email notifications for.
                  </p>
                </div>
                <ComboBox
                  badgeColor={"purple"}
                  className={"w-full bg-white rounded-md"}
                  items={trackTags as ComboBoxItem[]}
                  setSelectedItems={setSelectedTrackTagSubscriptions}
                  selectedItems={selectedTrackTagSubscriptions as ComboBoxItem[]}
                />
              </div>
              <div className="w-full">
                <div className={"pb-2"}>
                  <Label htmlFor="tags" className="text-scale-700">
                    Topics
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-scale-700">
                    Select the topics you'd like to receive email notifications for.
                  </p>
                </div>
                <ComboBox
                  badgeColor={"blue"}
                  className={"w-full bg-white rounded-md"}
                  items={topicTags as ComboBoxItem[]}
                  setSelectedItems={setSelectedTopicTagSubscriptions}
                  selectedItems={selectedTopicTagSubscriptions as ComboBoxItem[]}
                />
              </div>

              <div className="mt-8 flex justify-end">
                <Button
                  onClick={() => handleUpdateProfile()}
                  variant={"outline"}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

